$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;

.edit_profile {
  padding-top: 65px;
  height: 100%;

  .save_button {
    padding: 0px 32px;
    @media screen and (max-width:767px) {
      justify-content: center;
      margin: 20px auto 0px;
}           
    div{
      @media screen and (max-width:767px) {
        justify-content: center;
  }           
     }
  }

  .personal_info {
    padding-bottom: 50px;
    padding: 11px 30px 20px 20px;
    padding-bottom: 60px;
    @media screen and (max-width:767px) {
      padding-bottom: 30px;
  }
        h4{
          padding-bottom: 11px;
        }
  }

  .company_info {
    padding-bottom: 50px;
    padding: 11px 20px 20px 20px;
    padding-bottom: 60px;
    @media screen and (max-width:767px) {
      padding-bottom: 30px;
  }
  }

  .change_password {
    padding-bottom: 50px;
    padding: 11px 20px 20px 20px;
    padding-bottom: 60px;
    @media screen and (max-width:767px) {
      padding-bottom: 30px;
  }
  }

  .basic_info {
    padding-bottom: 50px;
    padding: 11px 20px 20px 20px;
    padding-bottom: 60px;
    @media screen and (max-width:767px) {
      padding-bottom: 30px;
  }
  }

  .delete-image {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize;
    padding-left: 6px;
  }

  .delete-image:hover {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer;
  }

  .form_name {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    color: $water-blue;
  }

  .edit_profile_component {
    padding-bottom: 62px;
    background-color: #ffffff;
    box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb;
    padding: 11px 20px 20px 20px;
    padding-bottom: 60px;
    @media screen and (max-width:767px) {
      padding-bottom: 25px;
  }
    .headings{
      h4{
        padding-bottom: 11px;
      }
    }
    .upload-block{
      .error{
        text-align: center;
        width: auto;
      }
      .crop-block{
        display: block;
        padding: 20px 0px;
        button{
          @media screen and (max-width:767px) {
            width:47% !important;
        }
      }
      }
    .wid-50{
      width: 50%;
      padding: 10px;
      display: inline-block;
      @media screen and (max-width:767px) {
        width:100% !important;
    }
      input{
        position: relative !important;
      }
    }
  }

    .help_icon {
      padding-top: 44px;
      cursor: pointer;
    }

    .edit_profile_component_form {
      @media screen and (max-width: 500px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      .field_div{
        .checkbox{
          .container{
            @media screen and (max-width: 767px) {
             margin-bottom: 0px;
            }
          }
        }
        .verified{
          display: flex;
          justify-content: right;
          color:#007E33;
          p{
            margin-top: -2px;
          }
          .material-icons{
            color:#007E33;
            font-size: 18px;
          }
        }
        .verify-it{
          color:#FF0000;
          display: flex;
          justify-content: right;
          .material-icons{
            color:#FF0000;
            font-size: 18px;
          }
          p{
            margin-top: -2px;
          }
          a{
            color:#FF0000;
            text-decoration: underline;
            &:hover{
              color:#FF0000;
            }
          }
        }
      }
    }

    // .headings {
    //   border: solid 1px #e1e1e1;
    // }

    .form_name {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: $water-blue;
      text-transform: capitalize;
    }

    .left_panel {
      @media screen and (min-width:1025px) {
        padding-right: 55px;
      }

      @media screen and (min-width: 960px) and (max-width: 1025px) {
        padding-right: 20px;
      }
    }
    .delete-image{
        text-align: center;
        color: #1397e1;
        text-transform: capitalize;
        // padding-left: 27px;
    }

    .right_panel {
      @media screen and (min-width:1025px) {
        padding-left: 57px;
      }

      @media screen and (min-width: 960px) and (max-width: 1025px) {
        padding-left: 20px;
      }
    }

    .password_field_div {
      padding-top: 20px;
      position: relative;
      .eye_icon , .eye_icon_disable{
        top: 56px;
      }
    }

    .field_div {
      padding-top: 20px;
      @media screen and (max-width:767px) {
        padding-top: 10px;
    }
    }

    .edit_profile_component {
      padding-bottom: 62px;
    }
  }

  .error {
    color: #FF0000;
    font-size: 11px;
    text-align: left;
    height: 10px;
    @media screen and (max-width:767px) {
      height: auto;
  }
  }
}

.edit_profile_component .headings p {
  text-align: center;
  padding-bottom: 15px;
  font-size: 14px;
  color:#ee2738;
}
.secondary-form{
  .emailField {
    width: 330px;
  }
  .red_icon_div {
    display: inline-block;
    padding: 2px 0px 2px 10px;
    color: #e20d0d;
    cursor: pointer;
  }
  .material-icons.delete {
    background: transparent;
  }

  .delete::before, 
  .delete::after {
    background: transparent;
  }

  .row-container-box {
    display: flex;
    flex-wrap: wrap;
  }

  .div-alignment-box {
    flex: 1 1 50%;
    padding: 8px 0;
    box-sizing: border-box; 
  }
  input.emailField {
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #f7f7f7 !important;
    padding: 9px 15px;
    margin-top: 4px;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: #202020;
  } 
  @media screen and (max-width:830px) {
    .div-alignment-box {
      flex: 1 1 100%;
    }
    .emailField{
      width: 85%;
    }
  }
}
.small-text-box{
  color: #ff0000;
  font-size: 10px;
  text-align: left;
}

.edit_profile_component{
  .emailField{
    padding-left: 30px;
  }
  .search_container > div:first-child:before{
    top: 30px;
    left: 5px;
    color: #878787;
    content: 'search' !important; 
  }
  .with-lable-search > div:first-child:before{
    top: 55px;
  }
  @media screen and (max-width:767px) {
    .search_container > div:first-child:before{
      top: 20px;
    }
    .with-lable-search > div:first-child:before{
      top: 45px;
    }
  }
}

.visiblity-hidden{
  visibility: hidden;
}