@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,600&display=swap);
.custom_menu_item .event_name {
  margin-bottom: -19px; }

.custom_menu_item .checkbox_grid {
  margin-top: -12px; }

.custom_menu_item .material-icons {
  font-size: 50px;
  color: #EBEBEB; }

.custom_menu_item .space_image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%; }

.custom_menu_item .hr_tag {
  margin: 5px; }

.notification-box .mr-bottom {
  margin-top: 30px; }

.notification-box h5 {
  font-size: 12px !important; }

/***
* == Notifiaction Popup Style
***/
@media (min-width: 600px) {
  .event-header header {
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; } }

.notification-block {
  display: inline-flex; }
  @media screen and (max-width: 767px) {
    .notification-block button {
      padding: 12px 5px; } }

.shadow-none {
  box-shadow: none; }

.logo_and_search {
  display: flex;
  align-items: center; }

.first_list_row p {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase; }

.first_list_row .gray {
  color: #62717a; }

.first_list_row .red {
  color: #ee2738; }
  @media screen and (max-width: 767px) {
    .first_list_row .red {
      padding-left: 10px; } }

.first_list_row .blue {
  color: #1397e1; }

.first_list_row:hover, .second_list_row:hover, .third_list_row:hover {
  background-color: #fff !important;
  cursor: default !important; }

.third_list_row {
  justify-content: flex-end !important;
  border-bottom: 0px !important; }

.second_list_row h4, .second_list_row a {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  color: #202020;
  margin: 0; }
  @media screen and (max-width: 767px) {
    .second_list_row h4, .second_list_row a {
      padding-left: 10px; } }

.second_list_row h5 {
  font-size: 13px;
  font-weight: bold;
  color: #62717a;
  margin: 15px 0; }
  @media screen and (max-width: 767px) {
    .second_list_row h5 {
      padding-left: 10px; } }

.second_list_row h6 {
  font-size: 10px;
  font-weight: bold;
  color: #62717a;
  margin: 15px 0; }
  @media screen and (max-width: 767px) {
    .second_list_row h6 {
      margin: 8px 0px 0px !important;
      padding-left: 10px; } }

.second_list_row .linkBox {
  display: flex;
  align-items: center;
  padding-left: 12px; }
  .second_list_row .linkBox a {
    font-size: 15px;
    color: #ee2738;
    font-weight: 600; }
  .second_list_row .linkBox a:hover {
    color: #ee2738; }

.request_button {
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  color: #ee2738;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer; }

.book_button {
  border: solid 1px #ee2738;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(to bottom, #f1435e, #ee2738);
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
  margin-left: 15px;
  cursor: pointer; }

.buttons {
  justify-content: end !important; }

.hr_tag {
  color: #e1e1e1;
  opacity: 0.3;
  height: 1px; }

.changeText {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #1397e1;
  margin-top: 32px;
  cursor: pointer;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.noneText {
  display: block;
  text-align: center;
  padding: 10px;
  font-weight: 500;
  color: black;
  font-size: 13px; }

.main_section {
  height: 220px;
  padding-top: 20px;
  overflow-y: scroll;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important; }
  @media screen and (max-width: 767px) {
    .main_section {
      height: 355px; } }
  .main_section .book_button:hover {
    background-image: linear-gradient(to bottom, #d80d2a, #d80d2a);
    color: #ffffff;
    opacity: 0.8; }
  .main_section .gc-box {
    float: left;
    width: 210px; }
  .main_section .ms-box {
    float: left;
    width: 210px;
    border-left: 1px solid #202020; }
  .main_section .ms-box.two, .main_section .ms-box.three {
    padding-top: 10px;
    border-top: 1px solid; }
  .main_section .ms-box.two {
    border-left: 0; }

.cal-text {
  text-align: center; }

.MuiDialog-paper-229 {
  overflow: visible !important; }

@media (max-width: 768px) {
  #menu-list-grow {
    left: -302px !important;
    width: 370px;
    top: 4px; } }

@media (max-width: 320px) {
  #menu-list-grow {
    left: -274px !important;
    width: 315px; } }

.notification-box {
  display: block;
  text-align: center; }
  .notification-box .notif-div {
    padding-left: 0px !important;
    display: block !important; }
  .notification-box Button {
    margin-bottom: 20px;
    background-color: transparent;
    color: #ee2738;
    box-shadow: none; }
    .notification-box Button a {
      color: #ee2738; }

.view-all-page {
  width: 100%;
  margin: 0px auto;
  display: block;
  background-color: #f7f7f7;
  padding-top: 80px; }
  .view-all-page .loading {
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-top: 150px;
    font-size: 18px;
    height: 100vh; }
  .view-all-page .custom_menu_item ul {
    background-color: #ffffff;
    padding: 10px 0px;
    width: 98%;
    margin: 0px auto 12px; }

/***
* == ModalBox Style
***/
.rightImg {
  width: 32px;
  height: 32px;
  border-radius: 50px; }

.rightMenuDiv {
  padding: 5px 1px 0px 0px; }

#customized-menu span {
  font-size: 14px;
  color: #36434b;
  font-family: 'Raleway', sans-serif; }

#customized-menu svg {
  font-size: 16px;
  color: #36434b; }

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .noThank_btn {
    padding: 12px 27%; } }

@media screen and (max-width: 1024px) {
  .rightImg {
    width: 32px;
    height: 32px;
    border-radius: 50px; }
  .rightMenuDiv {
    padding: 5px 1px 0px 0px; } }

/***
* == Event Header Style
***/
.topEventNav {
  width: 100% !important;
  color: #36434b;
  background-color: #ffffff;
  align-items: center;
  display: flex; }
  .topEventNav header {
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1; }
  .topEventNav a:hover {
    color: #ee2738 !important;
    background: transparent; }
  .topEventNav .home_page header {
    border-bottom-style: none !important;
    border-bottom-width: none !important;
    border-image-source: none !important;
    border-image-slice: none; }
  .topEventNav .EventPlannerHeader-appbar-106 {
    color: #36434b;
    box-shadow: inset 0 -1px 0 0 #fff !important;
    background-color: #ffffff !important; }
  .topEventNav .MuiListItem-root-188.MuiListItem-selected-199, .topEventNav .MuiListItem-root-188.MuiListItem-selected-199:hover, .topEventNav .MuiListItem-root-188.MuiListItem-selected-199:focus {
    background-color: transparent !important; }
  .topEventNav .EventPlannerHeader-image-529 {
    border: none !important; }
  .topEventNav .toplogoDiv {
    float: left; }
    .topEventNav .toplogoDiv img {
      height: 56px;
      border: none !important;
      border-radius: 50px;
      margin-bottom: -4px; }
    .topEventNav .toplogoDiv:hover {
      background: transparent; }
    .topEventNav .toplogoDiv:focus {
      background: transparent; }
  .topEventNav .topSearchDiv {
    width: 100%;
    display: inline-block; }
    .topEventNav .topSearchDiv .headesearch_container {
      width: 100% !important;
      height: 26px;
      border-radius: 13px;
      position: relative;
      padding: 0px 0px 0px 0px; }
      .topEventNav .topSearchDiv .headesearch_container > div:first-child:before {
        content: 'search';
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        position: absolute;
        top: 1px;
        left: 11px;
        color: #ccc;
        z-index: 1; }
      .topEventNav .topSearchDiv .headesearch_container input {
        position: relative;
        float: left;
        width: 100%;
        font-size: 12px;
        border: 0;
        border-radius: 13px;
        height: 26px;
        padding: 5px 0px 5px 35px;
        background-color: #f7f7f7;
        border: 1px #f0f0f0 solid;
        color: #9aa7af; }
        .topEventNav .topSearchDiv .headesearch_container input::-webkit-input-placeholder {
          color: #b0b0b0; }
        .topEventNav .topSearchDiv .headesearch_container input::-ms-input-placeholder {
          color: #b0b0b0; }
        .topEventNav .topSearchDiv .headesearch_container input::placeholder {
          color: #b0b0b0; }
      .topEventNav .topSearchDiv .headesearch_container div {
        width: 100% !important;
        position: relative;
        border-radius: 13px; }
      .topEventNav .topSearchDiv .headesearch_container .suggestionDiv {
        width: 80% !important;
        position: relative;
        font-size: 12px;
        line-height: 2.3;
        word-break: break-word; }
      .topEventNav .topSearchDiv .headesearch_container input[role="combobox"] + div {
        z-index: 9;
        top: 35px !important;
        left: 0px !important;
        position: absolute !important;
        width: 100% !important;
        min-width: 66% !important;
        min-height: 150px !important;
        background: #fff;
        overflow-y: scroll;
        height: 130px;
        box-shadow: 0px 15px 10px -15px #ccc; }
      .topEventNav .topSearchDiv .headesearch_container input[role="combobox"] + div .item {
        padding: 0px 29px;
        font-size: 12px !important; }
      .topEventNav .topSearchDiv .headesearch_container input[role="combobox"] + div .item-highlighted {
        background: #eee;
        width: 100% !important;
        cursor: pointer;
        font-size: 12px;
        border-radius: 0px; }
  .topEventNav .topEventMenu {
    margin-left: 20px;
    margin-right: 20px;
    text-transform: uppercase;
    text-decoration: none;
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #36434b;
    border-bottom: 1px solid transparent; }
  .topEventNav .MuiMenuItem-root-151 {
    background-color: transparent !important;
    border-bottom: none !important; }
  .topEventNav .MuiMenuItem-root-151:hover {
    background-color: transparent !important; }
  .topEventNav .MuiListItem-button-163:hover {
    text-decoration: none;
    background-color: transparent !important; }
  .topEventNav .MuiListItem-root-154.MuiListItem-selected-165,
  .topEventNav .MuiListItem-root-154.MuiListItem-selected-165:hover,
  .topEventNav .MuiListItem-root-154.MuiListItem-selected-165:focus {
    width: 25% !important;
    background-color: transparent !important;
    border-bottom: 2px solid #ee2738 !important; }
  .topEventNav .EventPlannerHeader-sectionDesktop-104 {
    width: 100% !important; }
  .topEventNav .rightImg {
    width: 32px;
    height: 32px;
    border-radius: 50px; }
  .topEventNav .rightImg:hover {
    background: transparent !important; }
  .topEventNav .MuiIconButton-root-169:hover {
    background-color: transparent !important; }
  .topEventNav .EventPlannerHeader-sectionDesktop-376 {
    width: 100% !important; }
  .topEventNav .EventPlannerHeader-image-380 {
    border: none !important; }
  .topEventNav .EventPlannerHeader-image-108 {
    height: 56px;
    border: none !important;
    border-radius: 50px; }

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .topEventNav {
    width: 100% !important;
    color: #36434b; }
    .topEventNav button > span:hover {
      background: transparent; }
    .topEventNav button > span:focus {
      background: transparent; }
    .topEventNav .EventPlannerHeader-sectionMobile-105 {
      display: flex;
      float: right; }
    .topEventNav .EventPlannerHeader-appbar-106 {
      color: #36434b;
      box-shadow: inset 0 -1px 0 0 #fff !important;
      background-color: #ffffff !important; }
    .topEventNav .MuiListItem-root-188.MuiListItem-selected-199, .topEventNav .MuiListItem-root-188.MuiListItem-selected-199:hover, .topEventNav .MuiListItem-root-188.MuiListItem-selected-199:focus {
      background-color: transparent !important; }
    .topEventNav .EventPlannerHeader-image-529 {
      border: none !important; }
    .topEventNav .toplogoDiv {
      float: left; }
      .topEventNav .toplogoDiv img {
        height: 56px;
        border: none !important;
        border-radius: 50px;
        margin-bottom: -4px; }
    .topEventNav .topSearchDiv {
      width: 76%;
      display: inline-block; }
      .topEventNav .topSearchDiv .headesearch_container {
        width: 100% !important;
        height: 26px;
        border-radius: 13px;
        position: relative; }
        .topEventNav .topSearchDiv .headesearch_container > div:first-child:before {
          content: 'search';
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 24px;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          position: absolute;
          top: 13px;
          left: 11px;
          color: #ccc;
          z-index: 1; }
        .topEventNav .topSearchDiv .headesearch_container input {
          position: relative;
          float: left;
          width: 100%;
          font-size: 12px;
          border: 0;
          border-radius: 13px;
          height: 30px;
          margin-top: 10px;
          padding: 0px 0px 3px 20px;
          background-color: #f7f7f7;
          border: 1px #f0f0f0 solid; }
        .topEventNav .topSearchDiv .headesearch_container div {
          width: 100% !important;
          position: relative;
          border-radius: 13px; }
        .topEventNav .topSearchDiv .headesearch_container .suggestionDiv {
          width: 80% !important;
          position: relative;
          font-size: 12px;
          line-height: 2.3;
          word-break: break-word; }
        .topEventNav .topSearchDiv .headesearch_container input[role="combobox"] + div {
          z-index: 9;
          top: 38px !important;
          left: 11px !important;
          position: absolute !important;
          width: 97% !important;
          min-width: 66% !important;
          min-height: 150px !important;
          background: #fff;
          overflow-y: scroll;
          height: 130px;
          box-shadow: 0px 15px 10px -15px #ccc; }
        .topEventNav .topSearchDiv .headesearch_container input[role="combobox"] + div .item {
          padding: 0px 29px;
          font-size: 12px !important; }
        .topEventNav .topSearchDiv .headesearch_container input[role="combobox"] + div .item-highlighted {
          background: #eee;
          width: 100% !important;
          cursor: pointer;
          font-size: 12px;
          border-radius: 0px; }
    .topEventNav .topEventMenu {
      width: auto !important;
      float: left;
      display: block;
      text-transform: uppercase;
      text-decoration: none;
      height: auto !important;
      font-family: Raleway;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: #757575;
      padding: 12px 0px 12px 4px; }
    .topEventNav .MuiMenuItem-root-151 {
      background-color: transparent !important;
      border-bottom: none !important; }
    .topEventNav .MuiMenuItem-root-151:hover {
      background-color: transparent !important; }
    .topEventNav .MuiListItem-button-163:hover {
      text-decoration: none;
      background-color: transparent !important; }
    .topEventNav .MuiListItem-root-154.MuiListItem-selected-165,
    .topEventNav .MuiListItem-root-154.MuiListItem-selected-165:hover,
    .topEventNav .MuiListItem-root-154.MuiListItem-selected-165:focus {
      width: 25%;
      background-color: transparent !important;
      border-bottom: 2px solid #ee2738 !important; }
    .topEventNav .EventPlannerHeader-sectionDesktop-104 {
      width: 100% !important; }
    .topEventNav .rightImg {
      width: 32px;
      height: 32px;
      border-radius: 50px; }
    .topEventNav .rightImg:hover {
      background: transparent !important; }
  .MuiIconButton-root-169:hover {
    background-color: transparent !important; }
  .EventPlannerHeader-sectionDesktop-376 {
    width: 100% !important; }
  .EventPlannerHeader-image-380, .EventPlannerHeader-image-108, .EventPlannerHeader-image-506 {
    width: 32.5px;
    height: 32.5px;
    border: none !important;
    border-radius: 50px; }
  .event-header header {
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    background-color: #ffffff !important;
    border-top: 0;
    border-left: 0;
    border-right: 0; } }

@media (min-width: 600px) {
  .event-header header {
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    background-color: #ffffff !important;
    border-top: 0;
    border-left: 0;
    border-right: 0; } }

.shadow-none {
  box-shadow: none; }

.logo_and_search {
  display: flex;
  align-items: center; }

.first_list_row p {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase; }

.first_list_row .gray {
  color: #62717a; }

.first_list_row .red {
  color: #ee2738; }

.first_list_row .blue {
  color: #1397e1; }

.second_list_row h4 {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  color: #202020;
  margin: 0; }

.second_list_row h5 {
  font-size: 13px;
  font-weight: bold;
  color: #62717a;
  margin: 15px 0; }

.second_list_row h6 {
  font-size: 10px;
  font-weight: bold;
  color: #62717a;
  margin: 15px 0; }

.request_button {
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  color: #ee2738;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px; }

.book_button {
  border: solid 1px #ee2738;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(to bottom, #f1435e, #ee2738);
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
  margin-left: 15px; }

.buttons {
  justify-content: end; }

.MuiIconButton-root-176:hover {
  background-color: transparent !important;
  color: #ee2738; }

input {
  font-family: 'Raleway', sans-serif !important; }

.event-header button:hover {
  background-color: transparent;
  color: #ee2738; }

.venue-header .save_button button {
  height: 30px !important;
  width: 90px !important; }

.venue-header .save_button button:hover {
  background: #d80e2a !important;
  color: #ffffff !important; }

.widget .topEventMenu {
  margin-left: 0px !important;
  background-color: transparent !important; }
  .widget .topEventMenu :hover {
    background: none; }

.widget .topEventNav {
  margin-right: 34px; }
  .widget .topEventNav .toplogoDiv {
    padding-left: 25px; }
  .widget .topEventNav li button {
    border: 1px solid #e1e1e1 !important;
    padding: 6px 16px;
    font-size: 13px;
    min-width: 64px;
    box-sizing: border-box;
    transition: none;
    line-height: 1.75;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 6px;
    text-transform: none;
    box-shadow: none; }
    .widget .topEventNav li button :hover {
      border-radius: 6px; }

.widget .header-div {
  padding-left: 0px;
  padding-right: 0px; }

.widget-place-hold :hover {
  background: #d80e2a !important;
  color: #fff !important;
  opacity: 0.9; }

.widget-place-hold button :hover {
  color: #ffffff !important; }

@media screen and (max-width: 767px) {
  .header-mobile button {
    padding: 12px 5px; } }

/***
* == Header Style
***/
input {
  font-family: 'Raleway', sans-serif !important; }

a {
  color: #36434b; }

.venue-header {
  margin-top: -8px; }
  .venue-header button:hover {
    background-color: transparent;
    color: #ee2738; }
  .venue-header header {
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    box-shadow: none;
    height: 65px;
    background-color: #ffffff !important;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
    @media screen and (max-width: 767px) and (min-width: 600px) {
      .venue-header header .name .top-header-name :nth-child(2) {
        padding: 13px 10px 0px 30px; } }
  .venue-header .rightMenuDiv {
    padding: 5px 15px 0px 0px; }
  .venue-header .link {
    color: #36434b; }
    .venue-header .link:focus {
      color: #0000EE; }
    .venue-header .link:hover {
      color: #0000EE; }
    .venue-header .link img {
      width: 30px;
      margin-top: 8px; }

input {
  font-family: 'Raleway', sans-serif !important; }

.top-header-name a.link.background {
  line-height: 49px; }
  .top-header-name a.link.background span {
    background-color: #36434b !important;
    color: #ffffff !important;
    font-weight: 600 !important;
    border-radius: 4px;
    font-size: 20px; }

.sync_calendar_popup.event_calendar {
  margin-top: 70px; }

.venue-header.space-planner-edit.webCalendar .event_info_popup {
  z-index: 1101;
  height: 270px;
  max-width: 360px;
  width: 360px; }
  @media screen and (max-width: 767px) {
    .venue-header.space-planner-edit.webCalendar .event_info_popup {
      height: 270px;
      max-width: 282px; }
      .venue-header.space-planner-edit.webCalendar .event_info_popup .cal-box {
        padding: 30px 10px !important; }
      .venue-header.space-planner-edit.webCalendar .event_info_popup label.bold-text {
        font-size: 13px; } }
  @media screen and (max-width: 480px) {
    .venue-header.space-planner-edit.webCalendar .event_info_popup {
      max-width: 265px; }
      .venue-header.space-planner-edit.webCalendar .event_info_popup label.bold-text {
        font-size: 12px; }
      .venue-header.space-planner-edit.webCalendar .event_info_popup .p-5 {
        padding: 30px 10px 15px 10px; } }

.venue-header.space-planner-edit.webCalendar .cal-box {
  padding: 30px 40px !important; }
  .venue-header.space-planner-edit.webCalendar .cal-box .calendar-icon {
    padding: 15px;
    margin: 10px 25px !important; }

.venue-header.space-planner-edit.webCalendar .p-5 {
  padding: 30px 15px 15px 15px; }

.venue-header.space-planner-edit.webCalendar .mt-5 {
  margin-top: 15px; }

.venue-header.space-planner-edit.webCalendar label.bold-text {
  font-weight: 600;
  line-height: 55px; }

.venue-header.space-planner-edit.webCalendar .input-field-section {
  margin: 10px 0; }

.venue-header.space-planner-edit.webCalendar button:hover {
  background-color: #d80e2a;
  color: #ffffff; }

@media screen and (max-width: 991px) {
  .venue-header.space-planner-edit.webCalendar .sync_calendar_popup.event_calendar {
    margin-top: 0px; } }

@media screen and (max-width: 767px) {
  .venue-header.space-planner-edit.webCalendar .event_info_popup {
    margin-top: 0px;
    left: 50px; }
  .venue-header.space-planner-edit.webCalendar .sync_calendar_popup.event_calendar {
    margin-top: 70px; } }

/***
* == ModalBox Style
***/
.dialouge_heading {
  color: red;
  font-weight: 700; }

.dialouge_text {
  margin: 1rem 0;
  text-align: center; }

.success_modal_container .dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px; }

.success_modal_container .action-center {
  justify-content: center; }

.success_modal_container .padding-left {
  padding-left: 70px; }
  @media screen and (max-width: 767px) {
    .success_modal_container .padding-left {
      padding-left: 0px; } }

.success_modal_container .padding-right {
  padding-right: 70px; }
  @media screen and (max-width: 767px) {
    .success_modal_container .padding-right {
      padding-right: 0px; } }

.success_modal_container .boxMatter {
  padding: 15px 12%;
  text-align: left; }

.success_modal_container button {
  padding: 6px 30px; }

.dont_single {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.95px;
  text-align: center !important;
  color: #1397e1; }

.dont_p {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.75px;
  text-align: left !important;
  color: #62717a;
  padding-bottom: 16px; }

@media screen and (max-width: 767px) {
  .dont_p-d-none-sm {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .dont_p-d-none-lg {
    display: none; } }

.noThank_btn {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  text-align: center;
  color: #ee2738;
  padding: 12px 40%;
  cursor: pointer; }

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .noThank_btn {
    padding: 12px 27%; } }

.grey-delete {
  display: inline;
  display: initial; }
  @media screen and (max-width: 767px) {
    .grey-delete p {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -13px; } }

.centerClass {
  text-align: center !important; }

@media screen and (max-width: 1599px) and (min-width: 1025px) {
  .sync-popup .boxMatter {
    padding: 0px 17px; } }

.centerWord {
  text-align: center; }
  .centerWord span {
    padding: 0px; }

.matter {
  font-family: Raleway !important; }

.space-planner-edit {
  overflow-x: hidden;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  overflow-x: hidden;
  padding-left: 90px; }
  .space-planner-edit .black-heading {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0;
    float: left;
    width: 100%; }
  .space-planner-edit .space-planner-edit-outer-grid {
    padding: 0px 0 10px; }
    .space-planner-edit .space-planner-edit-outer-grid .are-there-restrictions {
      border: solid 1px #f0f0f0;
      border-radius: 6px;
      padding: 10px 15px; }
  .space-planner-edit .planner-title-block {
    display: inline-flex;
    padding-bottom: 15px; }
    .space-planner-edit .planner-title-block .help_icon {
      width: 10px;
      text-align: left;
      margin-top: -4px; }
      .space-planner-edit .planner-title-block .help_icon button {
        background-color: transparent;
        box-shadow: none;
        padding: 0px;
        width: auto; }
        .space-planner-edit .planner-title-block .help_icon button span {
          justify-content: flex-start; }
  .space-planner-edit .make-annual {
    padding-left: 94px; }
    @media screen and (min-width: 1400px) {
      .space-planner-edit .make-annual {
        padding-left: 103px; } }
    @media screen and (max-width: 1300px) and (min-width: 1024px) {
      .space-planner-edit .make-annual {
        padding-left: 85px; } }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      .space-planner-edit .make-annual {
        padding-left: 0px; } }
    @media screen and (max-width: 767px) {
      .space-planner-edit .make-annual {
        padding-left: 2px; } }
  .space-planner-edit .grid-section-vertical.pt-2 {
    padding-top: 10px; }
    .space-planner-edit .grid-section-vertical.pt-2 .select-field:before {
      content: 'arrow_drop_down';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 0.8;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      top: 10px;
      right: 5px;
      color: #62717a;
      z-index: 1;
      cursor: pointer; }
  .space-planner-edit .grid-section-vertical {
    width: 100%;
    overflow: auto;
    text-align: left;
    display: block; }
    .space-planner-edit .grid-section-vertical .other-info-label {
      text-align: left; }
    .space-planner-edit .grid-section-vertical .left-space {
      width: 23%;
      display: block;
      float: left; }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        .space-planner-edit .grid-section-vertical .left-space {
          display: none; } }
    @media screen and (max-width: 767px) {
      .space-planner-edit .grid-section-vertical .d-none {
        display: none; } }
    .space-planner-edit .grid-section-vertical .end-p {
      width: 59px;
      display: inline-block; }
      @media screen and (max-width: 767px) {
        .space-planner-edit .grid-section-vertical .end-p {
          width: 30%; } }
    .space-planner-edit .grid-section-vertical .select-end {
      display: inline-block;
      position: relative;
      width: 100px; }
      @media screen and (max-width: 767px) {
        .space-planner-edit .grid-section-vertical .select-end {
          width: 70%; } }
      .space-planner-edit .grid-section-vertical .select-end .error {
        width: 100%; }
      .space-planner-edit .grid-section-vertical .select-end select {
        width: 100px !important;
        border: 1px solid #e1e1e1;
        font-weight: 500;
        color: #48494a !important; }
        @media screen and (max-width: 767px) {
          .space-planner-edit .grid-section-vertical .select-end select {
            width: 100% !important; } }
    .space-planner-edit .grid-section-vertical .block-left {
      display: block;
      float: left;
      width: auto;
      padding-right: 15px;
      padding-top: 10px; }
      @media screen and (max-width: 767px) {
        .space-planner-edit .grid-section-vertical .block-left {
          width: 46%; } }
      .space-planner-edit .grid-section-vertical .block-left p {
        display: inline-block;
        font-size: 14px;
        font-weight: 500; }
      .space-planner-edit .grid-section-vertical .block-left label {
        margin-left: 0px !important;
        margin-right: 0px !important; }
      .space-planner-edit .grid-section-vertical .block-left .radioButton {
        padding-top: 18px; }
      .space-planner-edit .grid-section-vertical .block-left div {
        display: block;
        float: left;
        padding-top: 0px; }
        .space-planner-edit .grid-section-vertical .block-left div span {
          padding-left: 0px; }
      .space-planner-edit .grid-section-vertical .block-left .error {
        display: block;
        float: none;
        width: 120px; }
    .space-planner-edit .grid-section-vertical .block-right {
      display: block;
      float: left;
      width: 41.5%;
      padding-top: 10px; }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        .space-planner-edit .grid-section-vertical .block-right {
          width: 48%; } }
      @media screen and (max-width: 767px) {
        .space-planner-edit .grid-section-vertical .block-right {
          width: 54%;
          padding-bottom: 5px; } }
      .space-planner-edit .grid-section-vertical .block-right p {
        display: inline-block;
        font-size: 14px;
        font-weight: 500; }
      .space-planner-edit .grid-section-vertical .block-right label {
        margin-left: 0px !important;
        margin-right: 0px !important; }
      .space-planner-edit .grid-section-vertical .block-right div {
        display: block;
        float: left;
        padding-top: 0px; }
        .space-planner-edit .grid-section-vertical .block-right div span {
          padding-left: 0px; }
      .space-planner-edit .grid-section-vertical .block-right .radioButton {
        padding-top: 18px; }
    .space-planner-edit .grid-section-vertical .box-left {
      display: inline-block;
      width: auto; }
      @media screen and (max-width: 767px) {
        .space-planner-edit .grid-section-vertical .box-left {
          width: 100%; } }
      .space-planner-edit .grid-section-vertical .box-left p {
        float: left;
        display: inline-block;
        padding-right: 10px;
        margin: 11px auto;
        width: 37%; }
        @media screen and (max-width: 767px) {
          .space-planner-edit .grid-section-vertical .box-left p {
            width: 30%; } }
      .space-planner-edit .grid-section-vertical .box-left ::-webkit-input-placeholder {
        /* Safari, Chrome and Opera */
        color: #b0b0b0 !important; }
      .space-planner-edit .grid-section-vertical .box-left ::-ms-input-placeholder {
        /* Safari, Chrome and Opera */
        color: #b0b0b0 !important; }
      .space-planner-edit .grid-section-vertical .box-left ::placeholder {
        /* Safari, Chrome and Opera */
        color: #b0b0b0 !important; }
      .space-planner-edit .grid-section-vertical .box-left .datePicker {
        display: inline-block;
        float: left;
        width: auto;
        max-width: 100px; }
        @media screen and (max-width: 767px) {
          .space-planner-edit .grid-section-vertical .box-left .datePicker {
            max-width: 100%;
            width: 70%; } }
        .space-planner-edit .grid-section-vertical .box-left .datePicker .datepiker.select_field {
          border: 1px solid #e1e1e1;
          font-weight: 500; }
    .space-planner-edit .grid-section-vertical .box-right {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      padding-top: 10px; }
      .space-planner-edit .grid-section-vertical .box-right p {
        float: left;
        font-size: 14px;
        font-weight: 500;
        display: inline-block;
        padding-right: 0px;
        text-align: center;
        width: 30%; }
        @media screen and (max-width: 767px) {
          .space-planner-edit .grid-section-vertical .box-right p {
            text-align: left; } }
      .space-planner-edit .grid-section-vertical .box-right button {
        float: left;
        display: inline-block;
        width: auto;
        max-width: 100px;
        padding-left: 0px; }
      .space-planner-edit .grid-section-vertical .box-right .current-date {
        color: #1397e1;
        border: 0px;
        font-size: 14px;
        padding-top: 0px;
        background-color: transparent;
        font-weight: 500;
        cursor: pointer; }
        .space-planner-edit .grid-section-vertical .box-right .current-date :hover {
          transition: 0.3s ease-in-out;
          color: #e20d0d; }
        .space-planner-edit .grid-section-vertical .box-right .current-date Icon {
          margin-left: -2px; }
  .space-planner-edit .restriction-button {
    display: block;
    width: 100%;
    text-align: center; }
    .space-planner-edit .restriction-button .red-button {
      width: 120px;
      margin-bottom: 5px; }
  .space-planner-edit .other-info-label {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: var(--black);
    float: left;
    width: 20%;
    padding-right: 20px;
    margin-top: 10px; }
  .space-planner-edit select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    font-weight: normal;
    padding: 5px;
    -webkit-appearance: menulist;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: black;
    padding-left: 5px;
    width: 40%; }
  .space-planner-edit .small-text {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    margin: 0; }
  .space-planner-edit .grey {
    color: #62717a; }
  .space-planner-edit .sub-section {
    width: 100%;
    display: block;
    margin: 15px 0; }
  .space-planner-edit .sync_calendar_popup .event_info_popup {
    background: #ffffff;
    width: auto; }
    .space-planner-edit .sync_calendar_popup .event_info_popup .cal-box {
      padding: 10px; }
      .space-planner-edit .sync_calendar_popup .event_info_popup .cal-box .calendar-icon {
        display: inline-block;
        border: 1px solid #1497e1;
        border-radius: 8px;
        padding: 8px;
        cursor: pointer; }
        .space-planner-edit .sync_calendar_popup .event_info_popup .cal-box .calendar-icon.microsoft {
          margin-left: 8px; }
        .space-planner-edit .sync_calendar_popup .event_info_popup .cal-box .calendar-icon img {
          width: 48px;
          height: 44px; }
        .space-planner-edit .sync_calendar_popup .event_info_popup .cal-box .calendar-icon p {
          cursor: pointer;
          font-size: 13px;
          font-weight: 600;
          width: 64px;
          height: 66px;
          border-radius: 8px;
          display: flex;
          position: absolute;
          margin: -57px 0 0 -8px;
          background-color: #4d4d4d;
          opacity: 0.9;
          color: #fff;
          justify-content: center;
          align-items: center; }
          .space-planner-edit .sync_calendar_popup .event_info_popup .cal-box .calendar-icon p.EventTemple {
            text-align: center; }
  .space-planner-edit .subsec-btn {
    display: flex;
    justify-content: space-between;
    overflow: visible;
    height: auto; }
    @media screen and (max-width: 1100px) and (min-width: 1024px) {
      .space-planner-edit .subsec-btn .white-button-div, .space-planner-edit .subsec-btn .red-button-div {
        width: 120px;
        height: 30px;
        overflow: hidden; }
        .space-planner-edit .subsec-btn .white-button-div .calendar-icon, .space-planner-edit .subsec-btn .red-button-div .calendar-icon {
          display: inline-block;
          border: 1px solid #1497e1;
          border-radius: 8px;
          padding: 8px;
          cursor: pointer; }
          .space-planner-edit .subsec-btn .white-button-div .calendar-icon.microsoft, .space-planner-edit .subsec-btn .red-button-div .calendar-icon.microsoft {
            margin-left: 8px; }
          .space-planner-edit .subsec-btn .white-button-div .calendar-icon img, .space-planner-edit .subsec-btn .red-button-div .calendar-icon img {
            width: 48px;
            height: 44px; }
          .space-planner-edit .subsec-btn .white-button-div .calendar-icon p, .space-planner-edit .subsec-btn .red-button-div .calendar-icon p {
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            width: 64px;
            height: 66px;
            border-radius: 8px;
            display: flex;
            position: absolute;
            margin: -57px 0 0 -8px;
            background-color: #4d4d4d;
            opacity: 0.9;
            color: #fff;
            justify-content: center;
            align-items: center; } }
    .space-planner-edit .subsec-btn .red-button-div, .space-planner-edit .subsec-btn .white-button-div {
      display: flex;
      align-items: center;
      justify-content: center; }
      @media screen and (max-width: 767px) {
        .space-planner-edit .subsec-btn .red-button-div, .space-planner-edit .subsec-btn .white-button-div {
          width: 83px !important; } }
      @media screen and (max-width: 500px) {
        .space-planner-edit .subsec-btn .red-button-div, .space-planner-edit .subsec-btn .white-button-div {
          width: 100px !important; } }
      @media screen and (max-width: 767px) {
        .space-planner-edit .subsec-btn .red-button-div button, .space-planner-edit .subsec-btn .white-button-div button {
          height: auto !important;
          padding: 5px 5px;
          width: 100%;
          line-height: normal; } }
      .space-planner-edit .subsec-btn .red-button-div .calendar-icon, .space-planner-edit .subsec-btn .white-button-div .calendar-icon {
        display: inline-block;
        border: 1px solid #1497e1;
        border-radius: 8px;
        padding: 8px;
        cursor: pointer; }
        .space-planner-edit .subsec-btn .red-button-div .calendar-icon.microsoft, .space-planner-edit .subsec-btn .white-button-div .calendar-icon.microsoft {
          margin-left: 8px; }
        .space-planner-edit .subsec-btn .red-button-div .calendar-icon img, .space-planner-edit .subsec-btn .white-button-div .calendar-icon img {
          width: 48px;
          height: 44px; }
        .space-planner-edit .subsec-btn .red-button-div .calendar-icon p, .space-planner-edit .subsec-btn .white-button-div .calendar-icon p {
          cursor: pointer;
          font-size: 13px;
          font-weight: 600;
          width: 64px;
          height: 66px;
          border-radius: 8px;
          display: flex;
          position: absolute;
          margin: -57px 0 0 -8px;
          background-color: #4d4d4d;
          opacity: 0.9;
          color: #fff;
          justify-content: center;
          align-items: center; }
  .space-planner-edit .bordered {
    border-radius: 6px;
    border: solid 1px #f0f0f0;
    background-color: #ffffff;
    width: 100%;
    height: auto;
    padding: 22px;
    font-size: 15px;
    line-height: 1.07; }
    @media screen and (max-width: 767px) {
      .space-planner-edit .bordered {
        padding: 15px; } }
  .space-planner-edit .float-left {
    float: left; }
  .space-planner-edit .float-right {
    float: right; }
  .space-planner-edit .red-button {
    width: auto;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border: 0 !important;
    cursor: pointer;
    line-height: 2;
    padding: 0px 15px;
    min-height: 33px; }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      .space-planner-edit .red-button {
        width: auto;
        height: auto;
        min-height: 33px; } }
    .space-planner-edit .red-button span {
      font-size: 11px; }
  .space-planner-edit .white-button {
    width: auto;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    width: auto;
    min-height: 33px; }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      .space-planner-edit .white-button {
        width: auto;
        min-height: 33px;
        height: auto; } }
    .space-planner-edit .white-button span {
      font-size: 11px; }
    .space-planner-edit .white-button a {
      color: #ee2738; }
  .space-planner-edit .plan_description {
    height: auto;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #62717a;
    list-style: none;
    margin: auto;
    text-align: right;
    -webkit-padding-start: 0 !important;
            padding-inline-start: 0 !important; }
    @media screen and (max-width: 767px) {
      .space-planner-edit .plan_description {
        padding: 0px 15px !important; } }
    .space-planner-edit .plan_description li {
      overflow: auto;
      width: 100% !important; }
  .space-planner-edit .text {
    display: inline-flex;
    width: 70%;
    padding: 0px 0 0 5px;
    text-align: left;
    vertical-align: top; }
    .space-planner-edit .text a {
      color: #36434b; }
  .space-planner-edit .plan-descp-icons {
    display: inline-flex;
    width: 22%;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .space-planner-edit .plan-descp-icons {
        width: 30%; } }
    .space-planner-edit .plan-descp-icons a {
      color: #62717a; }
      @media screen and (max-width: 991px) {
        .space-planner-edit .plan-descp-icons a .material-icons {
          font-size: 16px; } }
    .space-planner-edit .plan-descp-icons .red_icon_div {
      margin-top: -2px;
      padding-top: 0px; }
      @media screen and (max-width: 767px) {
        .space-planner-edit .plan-descp-icons .red_icon_div {
          margin-top: 0px; } }
      @media screen and (max-width: 991px) {
        .space-planner-edit .plan-descp-icons .red_icon_div span {
          font-size: 16px; } }
  .space-planner-edit .blue_icon_div {
    display: inline-flex;
    padding-top: 2px;
    color: #1397e1; }
    .space-planner-edit .blue_icon_div .picture_as_pdf {
      color: #e20d0d; }
  .space-planner-edit .red_icon_div {
    display: inline-block;
    padding-top: 2px;
    color: #e20d0d;
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .space-planner-edit .red_icon_div .delete {
        font-size: 22px; } }
  .space-planner-edit .blue_icon {
    width: 20px;
    height: 20px;
    border: 0;
    background-color: #1397e1; }
  .space-planner-edit .react-calendar {
    margin: 15px 0;
    border: solid 1px #f0f0f0;
    border-radius: 6px;
    width: 90%;
    background-color: #f0f0f0; }
  .space-planner-edit abbr[title] {
    text-decoration: none !important; }
  .space-planner-edit .react-calendar__navigation {
    margin: 0;
    background-color: #ffffff; }
  .space-planner-edit .react-calendar__navigation__prev-button {
    font-family: MaterialIcons;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ee2738; }
  .space-planner-edit .react-calendar__navigation__next-button {
    font-family: MaterialIcons;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ee2738; }
  .space-planner-edit .react-calendar__navigation__label {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1397e1; }
  .space-planner-edit .react-calendar__viewContainer {
    box-shadow: inset 0 1px 0 0 #f0f0f0, inset 0 -1px 0 0 #f0f0f0;
    background-color: var(--white); }
  .space-planner-edit .react-calendar__month-view__days__day--neighboringMonth {
    width: 50px;
    height: 50px;
    box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
    background-color: #f7f7f7 !important;
    font-family: Raleway;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e1e1e1 !important; }
  .space-planner-edit .react-calendar__month-view__days__day {
    width: 50px;
    height: 50px;
    box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }
  .space-planner-edit .react-calendar__month-view__weekdays__weekday {
    width: 26px;
    height: 26px;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    text-decoration: none; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
    .space-planner-edit {
      padding-top: 20px; }
      .space-planner-edit .venue-space-edit-inner-container {
        width: 100% !important;
        position: relative; }
      .space-planner-edit .grid-section-vertical .red-button-div {
        width: 100%;
        display: flex;
        padding: 10px 0; }
      .space-planner-edit .grid-section-vertical .white-button-div {
        width: 100%;
        display: flex;
        padding: 10px 0; } }

.button-calendar > div {
  display: inline-block; }

.button-calendar input.today {
  border: 0 !important;
  text-align: center;
  width: 100px; }

.button-calendar .react-datepicker__header.react-datepicker-year-header {
  font-weight: 400;
  padding: 5px 0;
  background: linear-gradient(to bottom, #EF0927, #ee2738);
  color: #ffffff; }

.button-calendar .react-datepicker__month-wrapper {
  padding: 5px 10px; }

.button-calendar .react-datepicker__month .react-datepicker__month-text {
  width: 80px;
  font-weight: 500; }

.button-calendar .react-datepicker__month--selected {
  background: lightblue; }

.success_modal_container div:nth-child(2) div:first-child {
  max-width: 450px; }

.success_modal_container .modalBox_content,
.success_modal_container .dialog-content {
  background-color: transparent !important; }

@media screen and (max-width: 767px) {
  .success_modal_container .modalBox_content,
  .success_modal_container .dialog-content {
    padding: 10px 0; } }

@media screen and (max-width: 540px) {
  .success_modal_container div:nth-child(2) div:first-child .dialog-content {
    min-height: auto !important; }
  .success_modal_container div:nth-child(2) div:first-child,
  .success_modal_container .dialog-content {
    overflow: auto !important; } }

.venue-publish-popup-box {
  width: 100%;
  height: auto;
  padding: 10px 15px;
  text-align: center;
  padding-top: 20px;
  display: block; }
  .venue-publish-popup-box .approval-btn {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0px 0px 16px; }
    .venue-publish-popup-box .approval-btn button {
      width: 130px;
      height: 30px;
      border-radius: 6px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738);
      font-family: Raleway;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      margin: 0 5px;
      border: 0 !important;
      cursor: pointer; }
  .venue-publish-popup-box a,
  .venue-publish-popup-box a:hover {
    color: #1397e1;
    font-size: 16px;
    text-decoration: underline; }
  .venue-publish-popup-box .group-buttons {
    display: inline-block;
    padding-top: 15px; }
    .venue-publish-popup-box .group-buttons .banner_button {
      display: inline-block;
      margin: 10px 19px;
      width: auto; }
      @media screen and (max-width: 767px) {
        .venue-publish-popup-box .group-buttons .banner_button {
          margin: 10px 10px; } }
      @media screen and (max-width: 500px) {
        .venue-publish-popup-box .group-buttons .banner_button {
          width: 100% !important;
          margin: 0px; } }
    .venue-publish-popup-box .group-buttons .white-button-div button {
      background-color: transparent;
      color: #ee2738;
      background: transparent;
      box-shadow: none; }
      .venue-publish-popup-box .group-buttons .white-button-div button :hover {
        opacity: 0.8;
        transition: 0.6ms; }
    .venue-publish-popup-box .group-buttons button {
      margin: 5px auto;
      width: auto !important; }
      @media screen and (max-width: 767px) {
        .venue-publish-popup-box .group-buttons button {
          width: 166px !important;
          padding: 6px 15px; } }

.view-listing-popup .container-box {
  display: block;
  width: 98%;
  height: auto;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  margin: 10px auto 15px !important;
  padding: 25px 10px 25px; }

.view-listing-popup .header {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 5px;
  font-weight: 500;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0; }
  @media screen and (max-width: 767px) {
    .view-listing-popup .header {
      margin-top: -5px; } }

@media screen and (max-width: 767px) {
  .ready-to-publish {
    height: 205px; } }

@media screen and (max-width: 500px) {
  .ready-to-publish {
    height: 275px; } }

.ready-to-publish .dont_single {
  font-size: 18px; }
  @media screen and (max-width: 767px) {
    .ready-to-publish .dont_single {
      font-size: 16px; } }

.ready-to-publish p {
  padding: 20px 15px;
  display: block;
  font-size: 16px; }

.ready-to-publish .ready-button {
  text-align: center;
  display: flex;
  justify-content: center; }
  .ready-to-publish .ready-button button {
    width: 130px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    margin: 0 5px;
    border: 0 !important;
    cursor: pointer; }
    .ready-to-publish .ready-button button :hover {
      opacity: 0.8; }

.ready-to-publish .click-here {
  padding-top: 0; }
  .ready-to-publish .click-here a {
    display: inline-block;
    font-size: 18px;
    color: #1397e1; }

.forgot-something-popup .venue-publish-popup-box {
  display: block;
  width: 100%;
  padding: 25px 20px 5px; }
  @media screen and (max-width: 500px) {
    .forgot-something-popup .venue-publish-popup-box {
      height: auto; } }

.forgot-something-popup .what-we-love-block {
  margin-top: 0px; }
  .forgot-something-popup .what-we-love-block .what-we-love-listing {
    display: flex;
    justify-content: space-between;
    margin-left: 25px; }
    .forgot-something-popup .what-we-love-block .what-we-love-listing .right-list {
      text-align: right; }
      @media screen and (max-width: 500px) {
        .forgot-something-popup .what-we-love-block .what-we-love-listing .right-list {
          padding-left: 30px; } }
      .forgot-something-popup .what-we-love-block .what-we-love-listing .right-list li:before {
        padding-right: 15px; }

@media screen and (max-width: 767px) {
  .submit-for-approval {
    height: 270px; } }

@media screen and (max-width: 500px) {
  .submit-for-approval {
    height: 400px; } }

.submit-for-approval .dont_single {
  padding: 10px 0px 15px;
  font-size: 18px; }
  @media screen and (max-width: 767px) {
    .submit-for-approval .dont_single {
      font-size: 16px; } }

.submit-for-approval p {
  padding: 0px 0px;
  display: block;
  text-align: center;
  font-size: 16px; }

.submit-for-approval .approval-btn {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 20px 0px 0px; }
  .submit-for-approval .approval-btn button {
    width: 130px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    margin: 0 5px;
    border: 0 !important;
    cursor: pointer; }
    .submit-for-approval .approval-btn button :hover {
      opacity: 0.8; }

.MuiPaper-rounded-125 {
  border-radius: 2px; }

.MuiMenu-paper-149 {
  width: 230px !important; }

.MuiSelect-selectMenu-107 {
  padding: 0 !important; }

.CustomTimeSelect-selectContainer-99 {
  padding: 0 !important;
  border: solid 1px #f0f0f0 !important; }

.MuiGrid-spacing-xs-16-24 {
  margin: 0 !important; }

.webCalendar .header {
  width: 100%;
  height: auto;
  border-bottom: none !important; }
  .webCalendar .header:before {
    content: '';
    top: 0;
    left: 0; }

.webCalendar .main {
  margin: 60px auto 0 45px; }
  .webCalendar .main .large-text {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #36434b;
    margin: 0 !important; }
    @media screen and (max-width: 767px) {
      .webCalendar .main .large-text {
        font-size: 24px; } }
  @media screen and (max-width: 767px) {
    .webCalendar .main .sm-d-none {
      display: none !important; } }
  .webCalendar .main .content {
    width: 50%;
    display: grid;
    margin: 28px 0px 10px; }
    .webCalendar .main .content .bold-text {
      height: 20px;
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
      display: flex;
      justify-content: space-between; }
      .webCalendar .main .content .bold-text .left {
        width: 225px;
        display: inline-flex; }
      .webCalendar .main .content .bold-text .right {
        width: 240px;
        display: inline-flex; }
    .webCalendar .main .content .blue-text {
      height: auto;
      display: flex;
      align-items: center;
      border: solid 1px #e1e1e1;
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #1397e1;
      padding: 0 15px;
      justify-content: space-between; }
      @media screen and (max-width: 767px) {
        .webCalendar .main .content .blue-text {
          display: block;
          padding: 20px 15px; } }
      .webCalendar .main .content .blue-text .blue-left {
        margin-right: 20px;
        width: 225px;
        position: relative;
        padding-top: 1rem;
        display: inline-block; }
        @media screen and (max-width: 767px) {
          .webCalendar .main .content .blue-text .blue-left {
            width: 100%;
            padding-top: 0px; } }
      .webCalendar .main .content .blue-text select {
        padding-right: 34px !important;
        margin-right: 15px;
        width: 100%; }
      .webCalendar .main .content .blue-text .left {
        width: 60%;
        float: left; }
      .webCalendar .main .content .blue-text .right {
        width: 225px;
        display: inline-block; }
        @media screen and (max-width: 767px) {
          .webCalendar .main .content .blue-text .right {
            width: 100% !important;
            margin-top: 20px; } }
    .webCalendar .main .content .MuiIconButton-root-110 {
      padding: 12px 8px 12px 0 !important;
      float: left; }
    .webCalendar .main .content .custom_select_with_scroll {
      width: 100%; }
  .webCalendar .main .content-sm {
    display: none; }
    @media screen and (max-width: 767px) {
      .webCalendar .main .content-sm {
        display: block; } }
    .webCalendar .main .content-sm .blue-left {
      background-color: #f7f7f7 !important; }
      .webCalendar .main .content-sm .blue-left select {
        color: #202020 !important;
        font-size: 14px;
        text-transform: capitalize; }
    .webCalendar .main .content-sm .left {
      width: 100% !important;
      color: #202020; }
    .webCalendar .main .content-sm .heading-right {
      margin: 0px auto;
      margin-top: 0px !important;
      color: #202020; }
  .webCalendar .main .pad-top {
    margin: 0px; }
    @media screen and (max-width: 767px) {
      .webCalendar .main .pad-top {
        display: none; } }
  .webCalendar .main .buttonContent {
    margin-top: 30px;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .webCalendar .main .buttonContent {
        margin-top: 30px;
        margin-bottom: 10px; } }
    .webCalendar .main .buttonContent .red-button-small {
      width: 130px;
      height: 36px;
      border-radius: 6px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738);
      text-align: center;
      color: #ffffff;
      cursor: pointer; }

@media only screen and (max-width: 1000px) {
  .webCalendar .MuiInputBase-fullWidth-132 {
    width: 75% !important; }
  .webCalendar .main {
    margin: 60px auto !important;
    width: 95% !important; }
    .webCalendar .main .content {
      width: 100% !important; }
    .webCalendar .main .large-text {
      height: auto;
      overflow: auto; }
  .webCalendar .left, .webCalendar .right {
    width: 50%; }
  .webCalendar .bold-text, .webCalendar .normal-text {
    height: 40px !important;
    width: 100% !important; } }

@media only screen and (max-width: 768px) {
  .buttonContent {
    text-align: center; }
  .webCalendar .error {
    width: auto; }
  .webCalendar .left {
    width: 40% !important; }
  .webCalendar .right {
    width: 60% !important; }
  .webCalendar .custom_select_with_scroll > div {
    padding: 0 5px 0px 15px; }
  .webCalendar .custom_select_with_scroll svg {
    right: -7px; } }

@media only screen and (max-width: 767px) {
  .webCalendar .header {
    position: relative; } }

.select {
  width: 100%;
  border: solid 1px #e1e1e1;
  height: 36px;
  padding: 0px 10px;
  background: #f7f7f7 !important;
  margin-top: 4px;
  border-radius: 6px;
  color: #202020;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize; }

.blue-text p::before {
  content: 'arrow_drop_down';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 0.8;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 24px;
  right: 15px;
  color: #62717a; }
  @media screen and (max-width: 767px) {
    .blue-text p::before {
      top: 10px;
      right: 5px; } }

.webCalendar .custom_select_with_scroll #select-googlecalendar {
  padding-right: 15px; }

.webCalendar .custom_select_with_scroll div {
  margin-top: 0px; }

.webCalendar .custom_select_with_scroll svg {
  right: 0px; }

.webCalendar .main .content.triplesheat .blue-text {
  align-items: start; }
  .webCalendar .main .content.triplesheat .blue-text .blue-left {
    padding-top: 14px; }
  .webCalendar .main .content.triplesheat .blue-text .material-icons.help-button {
    margin-top: 20px; }

::-webkit-scrollbar {
  width: 5px !important; }

.MuiDialog-paperWidthSm-226 {
  max-width: 100% !important;
  width: 100% !important;
  max-height: 100% !important; }

.MuiPaper-elevation24-146 {
  box-shadow: none !important; }

.MuiBackdrop-root-308 {
  background-color: rgba(0, 0, 0, 0.7) !important; }

.MuiPaper-root-120 {
  background: none !important; }

.react-datepicker-popper {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px; }

.datepiker {
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #717a62; }

.dialogActions span {
  cursor: pointer !important; }

.closeDialog {
  width: 22px;
  height: 22px;
  font-family: material;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff; }

.dialog-content {
  height: 100%;
  width: 60%;
  margin: auto; }

.modalBox_content {
  padding: 2rem;
  text-align: center; }

.main_place_hold_section {
  width: 600px;
  border-radius: 6px;
  background-color: #f0f0f0;
  border: 2px solid white !important;
  height: 100%; }
  .main_place_hold_section .error {
    display: inline-block;
    width: auto; }

.header {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  margin-top: 0;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0; }

.success_modal_container .dialog-content {
  height: 100% !important;
  width: 100% !important;
  margin: auto !important; }

.success_modal_container {
  width: 100% !important;
  min-height: auto !important;
  margin-top: 30px;
  /* create new event form */
  /* Events */ }
  .success_modal_container .react-datepicker__navigation {
    padding: 0 !important; }
  .success_modal_container .MuiDialog-paperWidthSm-226 {
    max-width: 100% !important;
    width: 100% !important;
    max-height: 100% !important; }
  .success_modal_container .MuiPaper-elevation24-146 {
    box-shadow: none !important; }
  .success_modal_container .MuiBackdrop-root-308 {
    background-color: rgba(0, 0, 0, 0.7) !important; }
  .success_modal_container .MuiPaper-root-120 {
    background: none !important;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    margin: 0px; }
  .success_modal_container .MuiDialog-paper-222 {
    margin: 0 !important;
    position: absolute;
    top: 0; }
  .success_modal_container .dialogActions {
    height: 40px;
    font-family: material;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0px; }
  .success_modal_container .closeButton {
    cursor: pointer;
    position: absolute;
    font-size: 30px;
    top: -70px;
    right: -38px;
    color: #ffffff;
    font-weight: bold; }
  .success_modal_container .modalBox_content {
    height: auto;
    background-color: #ffffff; }
  .success_modal_container .main_place_hold_section {
    width: 600px;
    border-radius: 6px;
    background-color: #f0f0f0;
    border: 2px solid white !important;
    height: 100%; }
    @media screen and (max-width: 767px) {
      .success_modal_container .main_place_hold_section .end-date-sm {
        padding-top: 20px; } }
  .success_modal_container .follow-inner-popup {
    display: block;
    padding: 15px;
    max-width: 450px;
    font-size: 15px;
    line-height: 23px; }
    .success_modal_container .follow-inner-popup button {
      margin-top: 15px;
      width: auto !important;
      padding: 5px 15px; }
  .success_modal_container .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .success_modal_container .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px; }
  .success_modal_container .header-button-div {
    float: right; }
  .success_modal_container .uploader {
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
    background-image: url(https://www.first-hold.com/static/media/perm_contact_calendar.dd9786b4.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-color: #f0f0f0; }
  .success_modal_container .success_modal_container {
    min-height: 100px;
    overflow-y: auto !important; }
  .success_modal_container .uploader-content {
    width: 300px;
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto; }
  .success_modal_container .red-text-content {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ee2738;
    margin: 140px 0 5px 0; }
  .success_modal_container .black-text-content {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
    margin-top: 5px; }
  .success_modal_container .white-button-big {
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    float: left;
    cursor: pointer; }
    .success_modal_container .white-button-big span {
      width: 100%; }
  .success_modal_container .select_field {
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #202020 !important;
    text-transform: none !important;
    width: 100%;
    height: 36px;
    margin: 0 !important;
    padding-left: 10px; }
  .success_modal_container .form .form-content {
    padding: 20px;
    display: inline-block; }
  .success_modal_container .form .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .success_modal_container .form .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px; }
  .success_modal_container .form .header-button-div-form {
    float: right;
    width: 210px; }
  .success_modal_container .form .dialogActions {
    height: 120px;
    font-family: material;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    padding: 20px;
    margin: 0;
    display: block; }
    .success_modal_container .form .dialogActions span {
      cursor: pointer; }
  .success_modal_container .form .field-div {
    width: 100%;
    height: 80px;
    float: left; }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .field-div {
        float: none !important; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .spacer.disp-none-sm {
      display: none; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-start {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req-up {
      margin-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-end {
      margin-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req-down {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req-seating, .success_modal_container .form .sm-pd-venue {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req {
      padding-top: 5px; } }
  .success_modal_container .form .disp-none-big {
    display: none; }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .disp-none-big {
        display: block; } }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .disp-none-big .sm-pd-seating {
        padding-top: 20px !important; } }
  .success_modal_container .form .input-label {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    position: relative;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    width: 100%;
    float: left;
    padding-bottom: 5px; }
  .success_modal_container .form .input-field {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: transparent;
    float: left;
    margin: 0 !important;
    font-size: 16px;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    padding-left: 10px; }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .input-field {
        padding-left: 15px;
        font-size: 15px !important; } }
  .success_modal_container .form .input[role="combobox"] + div {
    z-index: 99;
    top: 35px !important;
    left: 0px !important;
    position: absolute !important;
    min-height: 95px !important;
    background: #fff;
    overflow-y: scroll;
    height: 150px;
    padding-left: 15px;
    box-shadow: 0px 15px 10px -15px #ccc;
    border: 1px solid #e1e1e1;
    font-weight: 500;
    cursor: pointer; }
  .success_modal_container .form .city-sm .suggestionDiv {
    padding-left: 15px; }
  .success_modal_container .form .input-field-section {
    width: 100%;
    float: left;
    color: #202020;
    position: relative; }
  .success_modal_container .form .big {
    width: 100% !important; }
  .success_modal_container .form .dark-black {
    color: #36434b !important; }
  .success_modal_container .form .light-black {
    color: #202020 !important; }
  .success_modal_container .form .lighter-black {
    color: #62717a !important; }
  .success_modal_container .form .small {
    width: 46% !important; }
    .success_modal_container .form .small select {
      font-size: 15px !important; }
    .success_modal_container .form .small input {
      font-size: 15px; }
  .success_modal_container .form .smaller {
    width: 40% !important; }
    .success_modal_container .form .smaller select {
      font-size: 12px !important;
      background-color: #ffffff !important;
      text-transform: uppercase !important; }
      @media screen and (max-width: 767px) {
        .success_modal_container .form .smaller select {
          text-transform: capitalize !important;
          font-size: 15px !important;
          border: 1px solid #e1e1e1 !important; } }
    .success_modal_container .form .smaller input {
      font-size: 12px;
      color: #1397e1 !important;
      text-align: left;
      height: 36px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff; }
      @media screen and (max-width: 767px) {
        .success_modal_container .form .smaller input {
          font-size: 15px;
          font-weight: normal;
          color: #202020;
          text-transform: capitalize;
          border: solid 1px #e1e1e1 !important; } }
  .success_modal_container .form .tall {
    height: 140px; }
  .success_modal_container .form .spacer {
    width: 20%;
    padding: 25px 0 0 0;
    overflow: hidden;
    text-align: center; }
  .success_modal_container .form select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    padding: 5px;
    -webkit-appearance: menulist;
    text-align: left;
    padding-left: 5px;
    width: 100%;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal; }
  .success_modal_container .form .float-right {
    float: right; }
  .success_modal_container .form .white-button-small {
    width: 100px !important;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    padding: 0 !important;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    float: left;
    cursor: pointer; }
    .success_modal_container .form .white-button-small span {
      width: 100%; }
  .success_modal_container .form .red-button-small {
    width: 100px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer; }
  @media screen and (max-device-width: 767px) {
    .success_modal_container .form .smaller {
      width: 100% !important; }
    .success_modal_container .form .small {
      width: 100%; }
    .success_modal_container .form .modalBox_content {
      height: 100%;
      display: flex; }
    .success_modal_container .form .newEventPopupContainer {
      width: 100% !important; }
    .success_modal_container .form .small {
      width: 100% !important; }
    .success_modal_container .form .header {
      height: 60px; }
    .success_modal_container .form .header-text {
      width: 100%;
      text-align: center; }
    .success_modal_container .form .header-button-div-form {
      width: 100%;
      margin: 10px auto;
      padding: 0 0px; }
    .success_modal_container .form .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid; }
    .success_modal_container .form .red-text-content {
      margin-top: 100px; }
    .success_modal_container .form .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important; } }
  .success_modal_container .eventDiv {
    width: 98%;
    height: 80px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    margin: 10px auto 15px !important;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between; }
    .success_modal_container .eventDiv .checkBoxDiv {
      display: flex;
      width: auto;
      align-items: center; }
      .success_modal_container .eventDiv .checkBoxDiv label .radio {
        margin: 0px 0px 50px 0px !important; }
        .success_modal_container .eventDiv .checkBoxDiv label .radio :hover {
          color: #42a5f5; }
    .success_modal_container .eventDiv .blue-text {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #1397e1;
      margin: 0; }
    .success_modal_container .eventDiv .grey-date {
      color: #62717a; }
  .success_modal_container .events-content {
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto;
    overflow-y: scroll; }
  .success_modal_container .checkBoxDiv {
    width: 5%;
    float: left;
    display: flex;
    height: 100%;
    align-items: center; }
  .success_modal_container .detailsDiv {
    width: 95%;
    float: left;
    display: flex;
    height: 100%;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .success_modal_container .detailsDiv {
        align-items: center; } }
    .success_modal_container .detailsDiv .actionDiv {
      width: 20%;
      float: left;
      display: flex;
      height: 100%;
      align-items: center; }
      .success_modal_container .detailsDiv .actionDiv .hand-icon {
        width: 9.5px;
        height: 19.7px;
        background-color: #62717a;
        margin-right: 10px; }
      .success_modal_container .detailsDiv .actionDiv .grey-text {
        font-family: Raleway;
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        color: #62717a;
        margin: 0;
        min-width: 63px; }
  .success_modal_container .checkBox {
    width: 20px;
    height: 20px;
    background-color: #9aa7af !important; }
  .success_modal_container .large-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    width: auto;
    word-break: break-word;
    cursor: pointer; }
  .success_modal_container .edit-icon {
    display: inline-flex;
    cursor: pointer; }
  .success_modal_container .eventsSelected {
    width: 100%;
    height: 440px;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 30px; }
    .success_modal_container .eventsSelected .footerButtonDiv {
      width: 20%;
      margin: 20px auto; }
    .success_modal_container .eventsSelected .grey-button:disabled {
      height: 36px;
      border-radius: 6px;
      background-color: #9aa7af !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: none; }
    .success_modal_container .eventsSelected .grey-button:enabled {
      height: 36px;
      border-radius: 6px;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738); }
    .success_modal_container .eventsSelected .image {
      width: 38px;
      height: 38px;
      object-fit: contain;
      display: inline-block !important; }
    .success_modal_container .eventsSelected .large-text {
      font-family: Raleway;
      font-size: 24px !important;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin: 10px auto;
      cursor: pointer; }
    .success_modal_container .eventsSelected .description {
      font-family: Raleway;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #202020;
      margin: auto; }
    .success_modal_container .eventsSelected .blue-text {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1397e1;
      margin: 15px auto 0 auto; }
    .success_modal_container .eventsSelected .grey-text {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9aa7af;
      margin: 20px auto 10px auto;
      text-transform: uppercase; }
      @media screen and (max-width: 767px) {
        .success_modal_container .eventsSelected .grey-text {
          min-width: 60px; } }
    .success_modal_container .eventsSelected .optionsDiv {
      width: 90%;
      margin: auto;
      display: block;
      overflow: auto; }
      .success_modal_container .eventsSelected .optionsDiv .option {
        width: 33%;
        float: left;
        text-align: center; }
        .success_modal_container .eventsSelected .optionsDiv .option label {
          margin-left: auto !important;
          margin-right: auto !important; }
      .success_modal_container .eventsSelected .optionsDiv .option-text {
        width: 60%;
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #62717a;
        margin: auto; }
      .success_modal_container .eventsSelected .optionsDiv .grey {
        color: #62717a !important; }
      .success_modal_container .eventsSelected .optionsDiv .blue {
        color: #1f9ce3 !important; }
      .success_modal_container .eventsSelected .optionsDiv .blue-circle {
        width: 20px;
        height: 20px;
        border: solid 2px #1397e1;
        background-color: #ffffff;
        margin-top: 30px; }
  .success_modal_container .dialogFooter {
    height: 50px;
    border-radius: 6px;
    box-shadow: inset 0 1px 0 0 var(--white);
    background-color: #f7f7f7;
    display: flex;
    align-items: center; }
    .success_modal_container .dialogFooter .buttonDiv {
      margin: auto; }
  .success_modal_container .red-button {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer; }
  @media screen and (max-width: 767px) {
    .success_modal_container .dialog-content {
      height: 100%;
      width: 90% !important;
      margin: auto; }
    .success_modal_container .modalBox_content {
      height: 100%; }
    .success_modal_container .header {
      height: 60px;
      margin: auto; }
    .success_modal_container .header-text {
      width: 100%;
      text-align: center; }
    .success_modal_container .header-button-div {
      width: 80%;
      text-align: center;
      margin: 10px 0; }
    .success_modal_container .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid; }
    .success_modal_container .red-text-content {
      margin-top: 100px; }
    .success_modal_container .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important; }
    .success_modal_container .eventDiv {
      margin: 0;
      padding: 10px;
      height: 100px; }
    .success_modal_container .checkBoxDiv {
      width: 10% !important; }
    .success_modal_container .detailsDiv {
      width: 90% !important;
      position: relative; }
    .success_modal_container .large-text {
      width: 100% !important;
      cursor: pointer; }
    .success_modal_container .actionDiv {
      width: 100% !important;
      height: 70% !important;
      justify-content: flex-end; }
    .success_modal_container .eventsSelected {
      height: auto;
      padding: 10px; }
    .success_modal_container .optionsDiv {
      margin: 0 !important;
      width: 100% !important; }
    .success_modal_container .option {
      width: 80% !important;
      float: left;
      align-items: center;
      display: flex;
      margin: auto; }
      .success_modal_container .option .option-text {
        float: left;
        text-align: left !important; }
      .success_modal_container .option input {
        float: left;
        margin: 0 !important; }
    .success_modal_container .footerButtonDiv {
      width: 130px !important;
      margin: 20px auto; } }

.MuiDialog-paper-235 {
  margin: 20px !important; }

.event-loading .load-box {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 700;
  padding-top: 50px;
  padding-bottom: 50px; }

input#city {
  border-radius: 6px;
  border: solid 1px #e1e1e1 !important;
  background-color: #f7f7f7;
  width: 100%;
  padding: 9px 15px;
  font-size: 15px;
  line-height: 1.07;
  text-align: left;
  color: #202020; }

.suggestionDiv {
  width: 100% !important;
  position: relative;
  font-size: 12px;
  line-height: 2.3;
  word-break: break-all; }

.success_modal_container div:nth-child(2) div:first-child {
  max-width: 600px !important; }

.detailsDiv .actionDiv .delete::before, .detailsDiv .actionDiv .delete::after {
  display: none; }

.detailsDiv .actionDiv .delete {
  background-color: transparent;
  margin-top: -5px; }

.Contact-Venue-popup {
  padding-bottom: 20px; }
  .Contact-Venue-popup .header {
    border: 0px;
    text-align: center;
    display: block;
    height: auto;
    padding-bottom: 0px; }
    .Contact-Venue-popup .header .header-text {
      text-align: center;
      float: none;
      color: #1397e1; }
      @media screen and (max-width: 767px) {
        .Contact-Venue-popup .header .header-text {
          font-size: 15px !important; } }
  .Contact-Venue-popup button span {
    color: #ffffff; }
  .Contact-Venue-popup .message-section {
    padding: 10px 15px;
    line-height: 1.7; }
    @media screen and (max-width: 767px) {
      .Contact-Venue-popup .message-section {
        font-size: 13px !important; } }

@media screen and (max-width: 767px) {
  .place-hold-popup div:nth-child(2) div:first-child {
    max-width: 280px !important; } }

.place-hold-popup .modalBox_content .add-new-form-block .custom_select_with_scroll > div {
  padding: 0px 5px 0px 15px; }

@media screen and (max-width: 767px) {
  .place-hold-popup .modalBox_content .add-new-form-block {
    padding: 10px 20px 20px;
    height: 312px; } }

@media screen and (max-width: 767px) {
  .place-hold-popup .modalBox_content .add-new-form-block .disp-none-small {
    display: none; } }

@media screen and (max-width: 767px) {
  .place-hold-popup .modalBox_content .add-new-form-block .field-div {
    height: auto; } }

.place-hold-popup .modalBox_content .add-new-form-block .smaller input {
  padding: 0px 14px; }

.place-hold-popup .modalBox_content .add-new-form-block .smaller svg {
  right: -7px; }
  @media screen and (max-width: 767px) {
    .place-hold-popup .modalBox_content .add-new-form-block .smaller svg {
      right: 0px; } }

.place-hold-popup .error {
  display: block;
  position: absolute;
  bottom: -19px; }

.place-hold-popup .add-new-form-block {
  height: 440px; }
  @media screen and (min-width: 1600px) {
    .place-hold-popup .add-new-form-block {
      height: 560px; } }
  @media screen and (max-width: 767px) {
    .place-hold-popup .add-new-form-block {
      height: 326px; } }

.place-hold-popup .grey-text {
  text-align: center;
  cursor: pointer; }

.place-hold-popup .select-down-icon::before {
  content: 'arrow_drop_down';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 0.8;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 8px;
  right: 5px;
  color: #62717a; }

.place-hold-popup .select-down-icon {
  position: relative; }

.place-hold-popup .form .smaller select {
  background-color: transparent !important; }

.place-hold-popup .events-content {
  height: 292px; }

@media screen and (max-width: 767px) {
  .place-hold-popup #eventsContent {
    margin: 18px auto 0px !important; } }

@media screen and (max-width: 767px) {
  .place-hold-popup .header-button-div {
    margin: 10px 0 0px;
    width: 85%; } }

.add-new-form-block {
  overflow-y: scroll;
  height: 422px; }

@media screen and (max-width: 767px) {
  .follow-date-popup div:nth-child(2) div:first-child {
    max-width: 280px !important; } }

@media screen and (max-width: 767px) {
  .follow-date-popup .detailsDiv {
    width: 100% !important; } }

.follow-date-popup #eventsContent {
  padding-left: 10px;
  padding-right: 6px; }
  @media screen and (max-width: 767px) {
    .follow-date-popup #eventsContent {
      margin: 5px auto 0px !important; } }
  @media screen and (max-width: 767px) {
    .follow-date-popup #eventsContent .header-text {
      font-size: 15px; } }
  @media screen and (max-width: 767px) {
    .follow-date-popup #eventsContent button {
      width: 90px !important; } }

.follow-date-popup .events-content {
  margin: 5px auto 10px;
  height: 290px; }

::-webkit-input-placeholder {
  /* Edge */
  color: #62717a !important; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #62717a !important; }

.follow-up-signin > div {
  width: 100%; }

.follow-date-popup div:nth-child(2) div:first-child {
  width: 100%; }

.city-section input[role="combobox"] + div {
  z-index: 99;
  top: 35px !important;
  left: 0px !important;
  position: absolute !important;
  min-height: 95px !important;
  background: #fff;
  overflow-y: scroll;
  height: 160px;
  box-shadow: 0px 15px 10px -15px #ccc;
  border: 1px solid #e1e1e1;
  font-weight: 500;
  cursor: pointer; }

.city-section .suggestionDiv {
  width: 100% !important;
  padding-left: 15px; }

.what-we-love-block {
  padding-right: 25px;
  margin-top: 25px; }
  @media screen and (max-width: 767px) {
    .what-we-love-block {
      margin: 15px auto 10px; } }
  .what-we-love-block .what-we-love-listing {
    display: block;
    margin-left: 45px;
    width: 100%;
    margin-bottom: 25px; }
    .what-we-love-block .what-we-love-listing ul {
      display: block;
      width: 50%;
      float: left;
      list-style: none;
      padding-left: 20px; }
      @media screen and (max-width: 767px) {
        .what-we-love-block .what-we-love-listing ul {
          width: 100%; } }
      .what-we-love-block .what-we-love-listing ul li {
        position: relative;
        padding-top: 0px !important;
        padding-bottom: 0px !important; }
      .what-we-love-block .what-we-love-listing ul li:before {
        content: "\2022";
        color: #3C97E1;
        font-weight: bold;
        position: absolute;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 25px;
        top: 0;
        margin-top: -8px; }

.placehold-event .detailsDiv {
  width: 20% !important; }
  @media screen and (max-width: 767px) {
    .placehold-event .detailsDiv {
      width: 40% !important; } }
  .placehold-event .detailsDiv .actionDiv {
    width: 100%; }
    @media screen and (max-width: 767px) {
      .placehold-event .detailsDiv .actionDiv .grey-text {
        min-width: 48px; } }

.placehold-event .detailsDivLeft {
  display: flex;
  width: auto;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .placehold-event .detailsDivLeft .edit-icon {
      padding-left: 5px; } }

.placehold-event .checkBoxDiv {
  display: flex;
  width: auto;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .placehold-event .checkBoxDiv {
      width: 50% !important; } }
  .placehold-event .checkBoxDiv .radioTextLeft {
    width: 100%;
    display: flex;
    align-items: center; }
    .placehold-event .checkBoxDiv .radioTextLeft div {
      width: 10%;
      display: inline-block; }
      .placehold-event .checkBoxDiv .radioTextLeft div label .radio {
        margin: 0px 0px 0px 0px !important; }
    .placehold-event .checkBoxDiv .radioTextLeft .radioText {
      width: 90%;
      display: inline-block;
      padding-left: 10px; }
      @media screen and (max-width: 767px) {
        .placehold-event .checkBoxDiv .radioTextLeft .radioText p {
          font-size: 13px; } }
    .placehold-event .checkBoxDiv .radioTextLeft .large-text {
      display: flex; }
    .placehold-event .checkBoxDiv .radioTextLeft .grey-date {
      display: flex;
      min-width: 160px; }
    .placehold-event .checkBoxDiv .radioTextLeft label .radio {
      margin: 0px 0px 50px 0px !important; }
      .placehold-event .checkBoxDiv .radioTextLeft label .radio :hover {
        color: #42a5f5; }

.venue-link {
  color: #f12331;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal; }

.link-container {
  width: 168px;
  position: absolute;
  left: 47%;
  margin-left: -50px; }

.publish-error {
  min-width: 420px; }
  .publish-error .dont_single {
    text-transform: none;
    color: #4a4a4a;
    padding-bottom: 12px; }
  .publish-error .set-up-paid-link-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .publish-error .set-up-paid-link-container .button-container {
      border-radius: 6px;
      background-image: linear-gradient(to bottom, #f1435e, #ee2738);
      padding: 8px;
      text-align: center; }
      .publish-error .set-up-paid-link-container .button-container .venue-link {
        color: #ffffff;
        font-family: Raleway;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        text-decoration: none; }
        .publish-error .set-up-paid-link-container .button-container .venue-link:hover {
          color: #ffffff; }
  .publish-error .bottom-text-blue {
    color: #1397e1;
    text-align: center;
    padding-top: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43; }
    .publish-error .bottom-text-blue a {
      color: #1397e1;
      text-decoration: underline; }
      .publish-error .bottom-text-blue a:hover {
        color: #1397e1; }

.img-logo {
  width: 60px !important;
  text-align: center !important;
  margin: 0 auto; }

.contact-box {
  padding: 16px 12% 4px 12%; }
  .contact-box .dont_p {
    width: 100%;
    text-align: center !important; }

.datepiker.color-blue {
  color: #1397e1 !important; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", 'Raleway', sans-serif,
    monospace;
}

.Content {
  width: 100%;
}

p,
li,
textarea,
button,
input {
  font-family: 'Raleway', sans-serif !important;
}

:focus {
  outline: 0 !important;
}

.fourohfour {
  text-align: center;
  position: fixed;
  top: 30%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
}

.fourohfour h1 {
  color: #5e5c5c;
  text-align: center;
  font-size: 200px;
  font-weight: 900;
  margin: 0;

}
.fourohfour h3 , .fourohfour h2{
  font-weight: 700;
}

.datepiker::-webkit-input-placeholder {
  opacity: 1;
  color: #62717a;
}

.datepiker::-ms-input-placeholder {
  opacity: 1;
  color: #62717a;
}

.datepiker::placeholder {
  opacity: 1;
  color: #62717a;
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {

    .logo_image {
      width: 194px;

    }

    .verified_image {
      width: 16px;
    }
  }
}

.gallery-modal .gallery {
  padding: 20px;
}

@media not all and (min-resolution:767px) {
  @supports (-webkit-appearance:none) {

    .logo_image {
      width: 100% !important;
    }

  }
}

.gallery-modal .gallery {
  padding: 20px;
}

@media screen and (max-device-width:768px) {
  .gallery-modal--close {

    padding: 0px !important;
  }

  .gallery-modal--close button.gallery-close {
    font-size: 8px;
  }

  .success_modal_container .form .smaller input {
    cursor: default;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  .ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }

}

.place-hold {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .logo_image {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) and (min-width: 604px) {
  div.example {
    font-size: 50px;
    padding: 50px;
    border: 8px solid black;
    background: yellow;
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: #b0b0b0 !important;
}


:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b0b0b0 !important;
}



input,
textarea {
  color: #202020;
}

.custom_select_with_scroll div {
  padding: 0;
}

.custom_select_with_scroll>div {
  padding: 0 15px;
}

.modalBox_content .custom_select_with_scroll>div {
  padding: 0 5px;
}
@media screen and (max-width:767px) {
  .modalBox_content .custom_select_with_scroll>div {
  padding: 0px 15px;
}
}

.datepiker::-webkit-input-placeholder {
  color: #62717a;
}

.search_container2 input::-moz-input-placeholder {
  color: #b0b0b0 !important;
}

.search_container2 input::-webkit-input-placeholder {
  color: #b0b0b0;
}

.search_container2 input::-ms-input-placeholder {
  color: #b0b0b0;
}

.search_container2 input::placeholder {
  color: #b0b0b0;
}


input::-webkit-input-placeholder {
  color: #b0b0b0;

}

input:-ms-input-placeholder {
  color: #b0b0b0;

}

input::-moz-input-placeholder {
  color: #b0b0b0;

}

input::-webkit-input-placeholder, select::-webkit-input-placeholder{
  color: #b0b0b0;

}

input::-ms-input-placeholder, select::-ms-input-placeholder{
  color: #b0b0b0;

}

input::placeholder , select::placeholder{
  color: #b0b0b0;

}

.search_container2 input {
  color: rgb(98, 113, 122) !important;
}

.home-search .search_container2 ::-webkit-input-placeholder {
  color: rgb(98, 113, 122) !important;

}

.home-search .search_container2::-webkit-input-placeholder {
  color: rgb(98, 113, 122) !important;

}

.home-search .search_container2::-ms-input-placeholder {
  color: rgb(98, 113, 122) !important;

}

.home-search .search_container2::placeholder {
  color: rgb(98, 113, 122) !important;

}

.home-search .search_container2 ::-moz-input-placeholder {
  color: rgb(98, 113, 122) !important;

}

.space-profile-edit input::-webkit-input-placeholder {
  color: #b0b0b0 !important;

}

.space-profile-edit input::-ms-input-placeholder {
  color: #b0b0b0 !important;

}

.space-profile-edit input::placeholder {
  color: #b0b0b0 !important;

}

.space-profile-edit input::-moz-input-placeholder {
  color: #b0b0b0 !important;

}

.searchBgBox select {
  width: 100%;
  cursor: pointer;
}

.header-search-icon{    
  position: absolute;
  z-index: 9;
  color: #e0e0e0;
  cursor: pointer;
}

.success_modal_container div:nth-child(2) div:first-child{
  overflow-y: visible !important;
}
.hold_settings div:nth-child(2) div:first-child{
    max-width:550px;
}

  /* #menu-state div:nth-child(2){
  top: 400.062px !important;
  } */
  /* #menu-state{
    top: 117px !important;
  } */
  .edit_profile_component .edit_profile_component_form .custom_input .eye_icon{
    top: 50px;
  }

@media only screen and (min-width : 1920px) {
  #root {
    position: relative;
    min-height: 100vh;
  }

  .how_it_work_screen {
    width: 80% !important;
    margin: auto;
  }

  .similar_venue_card {
    position: relative;
    min-height: 65vh;
  }
}

.homeheader-icon:hover {
  background-color: transparent !important;
}

@media(max-width:767px) {
  #customized-menu div:nth-child(2) {
    right: 20px !important
  }
  .my_event_container .eventDiv{
    width: 100% !important;
  }
 
  .tall {
    height: auto !important;
  }
  #menu-endTime div:nth-child(2) , #menu-startTime div:nth-child(2){
    border-radius: 0px;
  }
}

.Notify #menu-list-grow {
  left: -318px;
}

@media(max-width:959px) {
  .header-search-icon{    
    font-size: 18px !important;
    top: 16px;
    left: 3px;
  }
}

@media(min-width:960px) {
  .logo_and_search button {
    display: none
  }

  .topEventNav .topSearchDiv .headesearch_container>div:first-child:before {
    top: 1px;
  }

  .topEventNav .topSearchDiv .headesearch_container input {
    height: 26px;
  }
  .header-search-icon{    
    position: absolute;
    z-index: 9;
    color: #e0e0e0;
    font-size: 18px !important;
    cursor: pointer;
    top: 5px;
    left: 15px;
  }
}

.add_space_close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 15px;
  cursor: pointer;
  display: block;
}

.position-relative {
  position: relative;
}

.dialogActions {
  color: #202020;
  top: 0px;
}

.venue_page .space-info{
  padding-bottom: 10px;
}

@media(max-width:1199px) {
  .space-info .seatindAndCapacityGrid .carousel div {
    width: 75px !important;
    max-width: 100% !important;
  }}
  @media(max-width:767px){
    .space-info .seatindAndCapacityGrid .carousel div {
      margin: 7px 0px !important;
    }
  }
.success_modal_container .closeButton:hover{
  color: #ee2738 !important;
}
/* .success_modal_container .events-content{
  overflow-y: scroll
} */
.success_modal_container dv:nth-child(2) div:first-child{
  overflow-y: visible;
}
.custom_select_with_scroll.time_select div{
  overflow-y: hidden !important;
}
.carousel .slide img {
  vertical-align: top;
  border: 0;
  object-fit: cover;
  object-position: bottom;
}
.listyourspace .banner_image{
  background: linear-gradient( rgb(0, 0, 0), rgb(0, 0, 0) );
  opacity: 0.5;
}
.listyourspace .banner-wrap {
  background: linear-gradient( rgb(0, 0, 0), rgb(0, 0, 0) );
}
.banner_image {
  height: 600px;
}
.signup-personal , .company-info-form{
background-color: #3C97E1 !important;
}
.contact_modal_container div:nth-child(2) div{
  overflow: visible !important;
}
.closeButton:hover{
  color: #ee2738 !important;
}
.banner .carousel img
{
  max-width: 1600px;
  max-height: 90vh;
}
.nextButton img{
  margin-right: 15px;
}
.carousel .slide{
  background: #ffffff !important;
}
/* .success_modal_container div div {
  overflow-y: visible !important;
  max-height: 450px;
} */
.browse_venue_search .custom-select:before{
  z-index: 1;
  right: 15px;
  cursor: pointer;
}
.banner_button .previousButton:hover{
  background-color: transparent;
}
.searchBgBox .search_container2 .date_pick{
  background-color: #f7f7f7 !important;
  cursor: pointer;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}
.select_field{
  z-index: 2 !important;
  background-color: transparent !important;
  cursor: pointer;
}
.header-button-div  .red-button-small{
  padding: 0px;
  background: linear-gradient(to bottom, #f1435e, #ee2738);
  font-family: Raleway;
  border-radius: 6px;
  height: 36px;
  width: 165px;
  border: 0px;
  font-weight: 500;
  color: #fff;
}
.searchBgBox .search_container2 .custom-select{
  border-top: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.searchBgBox .custom-select:before{
  z-index: 1;
}
@media(max-width:991px){
  .view_photos_container .venue-gallery {
    min-width: 120px;
    /* padding-top: 10px; */
  }
}
@media(max-width:767px){

  .listyourspace .banner_image{
    opacity: 1;
  }
 
   .sm-100{
    width: 100% !important;
  }
  /* .carousel .slide img{
    height:300px !important;
  } */
  .buttons-group .btn-right , .buttons-group .btn-left{
      width: 48.7%;
      margin-top: 10px;
      float: left;
  }
  .btn-publish{
    margin-left: 7px !important;
  }
  .buttons-header .buttons-group .grey-button{
    width: 144px !important;
  }
  .venue_space_venue_card{
      width: 100% !important;
      display: block;
      text-align: center;
    }
    .space-planner-edit{
      padding-left: 0px !important;
      padding-top: 0px !important;
    }
    .venue-address-edit .textarea-small , .space-planner-edit .bordered{
      width: 100% !important;
    }   
  .success_modal_container .main_place_hold_section{
    max-width: 380px;
  }
  /* .checkBoxDiv div:first-child span{
      margin: 0px 0px 40px 0px;
  } */
  .form-content{
      padding: 20px 10px;
  }
  .place-hold button{
      border:none !important;
  }
  .banner_image{
    height: auto !important;
  }
  .contact_modal_container div:nth-child(2) div{
    max-width: 100%;
    /* width: 290px; */
  }
  #eventsContent{
    margin: 30px auto 0px !important;
  }
  .my_events .my_event_container .main_place_hold_section #eventsContent{
    margin: 5px auto !important;
}
  .success_modal_container .events-content{
    height: 240px !important;
    width:100%;
  }
  .success_modal_container .closeButton , .contact_modal_container .closeButton{
    right: -10px !important;
  }
  .success_modal_container .closeButton{
    color: #202020;
    top: -40px !important;
  }
  .end .react-datepicker-popper {
    right: 0px !important;
    left: auto !important;
    }
  .end{
      position: relative;
  }
  .end .react-datepicker__triangle{
    right: 20px !important;
    left: auto !important;
  }
  .add_venue .step_progress_bar{
    padding: 10px 20px; 
    }
    .main-form{
      padding: 15px !important;
  }
  .syncing_calendar .main-form{
    display: block !important;
    width: 100% !important;
    padding: 15px 4px !important;
}
.my_event_container .loading{
    left: 35% !important;
}
.my_event_container .form .white-button-small{
  width: auto !important;
}
}
.featured_venue_carousel .alice-carousel{
  margin-top: 0px;
}
.venue_page .space-planner-edit{
  padding-left: 0px;
}
.venue_page .contackInfoDiv .managerInfoDiv{
  padding: 0px 0px 0px 4px;
}
/* @media (min-width:1600px){
  .venue-profile-edit {
    height: 1350px;
  }
} */
.venue_page .venueProfile-section .contact_button{
  display: none;
}
@media (min-width:1920px){
  .venue_page .venue-available-right .venueRightContainer{
    overflow-y: scroll;
    /* height: 550px; */
    height: auto;
  }
}
.custom_input input:focus , .my_event_container .form .small select:focus , .my_event_container .form .small select:active, .signup input:focus , .success_modal_container .signup .emailField:focus, .login .emailField:focus , .form .input-field:focus , .success_modal_container .form .select_field:focus , .success_modal_container .form .select_field:active , .success_modal_container .form .searchSelect:active , .success_modal_container .form .searchSelect:focus{
  border: 1px solid #bbbbbb !important;
}
.state_div .custom_select_with_scroll:focus , .custom_select_with_scroll div:nth-child(2):focus , .custom_select_with_scroll div:nth-child(2):focus-within , .custom_select_with_scroll div:nth-child(2):active{
  border: 1px solid #bbbbbb;
}
.my_events select , .main_place_hold_section select{
  padding: 8px 15px !important;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: transparent;
  font-family: Raleway;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: #202020 !important;
  text-transform: none !important;
  cursor: pointer;
}
.no-data-loader{
  display: block;
  text-align: center;
  padding: 20px;
  font-weight: 500;
  color: black;
}
.index_container .custom_select_with_scroll>div{
     padding: 10px;
}
.box-spacing-sm div:nth-child(2) , .box-spacing-sm div:nth-child(3){
      padding: 15px 10px;
}
li {
  white-space: normal !important;
}

.need_to_login_btn{
  display: block;
  width:100%;
  text-align: center;
  padding-top: 15px;
}
.need_to_login_btn button{
  margin: 5px;
  width: auto !important;
  text-transform: capitalize !important;
  padding: 6px 15px !important;
  font-size: 14px !important;
}
@media screen and (max-width:767px){
  .success_modal_container .main_place_hold_section{
    max-width: 310px;
  }
  .box-spacing-sm .start-sm , .box-spacing-sm .end-sm{
        height: 45px;
  }
  .time_select{
    position: relative;
  }
  .box-spacing-sm .end-sm{
    padding-bottom: 80px !important;
  }
  .end-sm
    .error{
      padding: 0px !important;
    }
  .need_to_login_btn button{
    width:70px !important;
    padding: 5px 10px !important;
    text-transform: capitalize !important;
    font-size:12px !important;
   }
}
#triangle-container{
  height: 100px;
  width: 100px;
  overflow: hidden;
  background-image: url(http://www.webdesign.org/img_articles/14881/site-background-pattern-07.jpg);
} */
.triangle-topleft {
  width: 0;
  height: 0;
  border-top: 100px solid red;
  border-right: 100px solid transparent;
}
.triangle-0 {

  /* border-bottom: solid 30px rgb(200,30,50);
  border-right: solid 30px rgb(200,30,50); */
  width: 0;
  height: 0;
  border-bottom: 100px solid red;
  border-left: 100px solid transparent;
}
.company-info-form .pd-top-title{
  padding-top: 15px;
}
.company-info-form .state_div div em{
  color: #202020a6;
}
.dont-miss-a{
  margin-top: 0px !important;
}
/*  BROWSERS THAT FIRST-HOLD WILL NOT BE ON  */
.message-block{
  display: block;
  align-items: center;
  margin: auto;
  padding: 80px 15px;
  padding-top: 120px;
  width: 100%;
  text-align: center;
  height: 100%;
  min-height: 100vh;
  color: #ffffff;
  background-color: #1397e1;
}
.message-block .logo_image{
  width: 180px;
}
.message-block .big-text{
    font-weight: 600;
    font-size: 30px;
    padding-bottom: 25px;
}
.message-block .small-text{
  font-weight: 500;
  font-size: 24px;
  padding-bottom: 60px;
}
.message-block .smallest-text{
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
}
.message-block .block-btn a , .message-block .block-btn button{
  width: auto;
  padding: 10px 30px;
  font-size: 13px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background: linear-gradient(to bottom, #f1435e, #ee2738);
  font-weight: 600;
  border-radius: 6px;
  color: #ffffff;
  text-transform: unset;
  font-size: 16px;
}
@media screen and (max-width:991px){
  .message-block .logo_image{
    max-width: 120px;
    min-width: 120px;
  }
  .message-block .big-text{
    font-size: 22px;
  }
  .message-block .small-text{
    font-size: 18px;
  }
}
.notif-div{
  display: inline-block;
  min-width: 200px;
}
.notif-div.mar-left {
  padding-left: 102px;
}
.custom_menu_item li:focus{
background-color: #ffffff;
}
.covid-19-text{
  text-align: center;
  color: #ee2738;
  margin: 0;
  margin-top: -25px;
  font-size: 14px;
  font-weight: 500;
}
.covid-text-right{
  width: 200px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px 15px;
  position: absolute;
  right: 10px;
  line-height: 1.4;
}
.covid-text-right h4  {
  margin: 0;
  color: #ee2738;
  font-weight: 900;
  font-size: 14px;
}
.covid-text-right p{
  font-weight: 600;
  margin: 0;
  color: #ee2738;
  font-size: 11px;
}
.left_panel-pos{
  position: relative;
}
@media screen and (max-width:1024px) and (min-width:992px){
  .covid-text-right{
    width: 170px;
    padding: 15px 10px;
  }
}
@media screen and (max-width:991px){
  .covid-text-right{
   position: relative;
   padding: 10px 10px;
   margin: 5px auto;
  }
}
@media screen and (max-width:767px){
  .action-center.disp-none-sm{
    display: none;
  }
  .covid-19-text{
    margin-top: -6px;
  }
  .notif-div{
    min-width: 100%;
  }
  .browse_venue_page .sign-up1{
    width: 100% !important;
  }
  .message-block{
    height: 100%;
    min-height: 100vh;
    padding: 60px 15px;
    padding-top: 90px;
  }
  .message-block .logo_image{
    max-width: 100px;
    min-width: 100px;
  }
  .message-block .big-text{
    font-size: 20px;
  }
  .message-block .small-text{
    font-size: 16px;
  }
  .error-messages-snackbar div div{
      display: flex;
      justify-content: space-between;
  }
  .error-messages-snackbar div div div{
    display: inline-flex;
    padding-left: 0px;
  }
  .notif-div.mar-left {
    padding-left: 0px;
  }
}
.jss290-btn{
  transform: none !important;
}

.dont_p_text {
  padding-left: 12px;
}

@media screen and ( min-width:1600px) {
  .signup.company-info-form, .signup.signup-personal, .signup ,.login,.profile_confirmation{
    height: 100vh !important;
  }
}

.alice-carousel {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: auto; }

.alice-carousel__wrapper {
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  overflow: hidden; }

.alice-carousel__stage {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  box-sizing: border-box; }
  .alice-carousel__stage-item {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    line-height: 0; }
    .alice-carousel__stage-item * {
      line-height: normal;
      line-height: initial; }
    .alice-carousel__stage-item.__cloned {
      opacity: 0;
      visibility: hidden; }
  .alice-carousel__stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  width: 50%;
  padding: 15px 10px;
  box-sizing: border-box; }
  .alice-carousel__prev-btn [data-area]::after,
  .alice-carousel__next-btn [data-area]::after {
    content: attr(data-area);
    position: relative;
    text-transform: capitalize; }

.alice-carousel__prev-btn {
  text-align: right; }

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  color: #465798;
  cursor: pointer; }
  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    color: darkred; }
  .alice-carousel__prev-btn-item.__inactive,
  .alice-carousel__next-btn-item.__inactive {
    opacity: .4; }

.alice-carousel__play-btn {
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 20px; }
  .alice-carousel__play-btn:hover {
    cursor: pointer; }
  .alice-carousel__play-btn-wrapper {
    position: relative;
    background-color: #fff;
    padding: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%; }

.alice-carousel__play-btn-item {
  cursor: pointer;
  position: absolute;
  background: transparent;
  width: 32px;
  height: 32px;
  outline: none;
  border: 0; }
  .alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    border-style: solid;
    border-color: transparent;
    border-width: 8px 0 8px 15px;
    border-left-color: #465798;
    transition: all 0.3s linear; }
  .alice-carousel__play-btn-item::before {
    height: 14px;
    left: 5px; }
  .alice-carousel__play-btn-item::after {
    left: 18px;
    top: 7px; }
  .alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
    border-width: 0 0 0 10px;
    height: 30px; }
  .alice-carousel__play-btn-item.__pause::after {
    left: 18px;
    top: 0; }

.alice-carousel__dots {
  margin: 30px 0 5px;
  text-align: center;
  list-style: none;
  padding: 0; }
  .alice-carousel__dots-item {
    display: inline-block;
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #e0e4fb; }
    .alice-carousel__dots-item:not(:last-child) {
      margin-right: 15px; }
    .alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active {
      background-color: #6e7ebc; }

.alice-carousel__slide-info {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6); }
  .alice-carousel__slide-info-item {
    line-height: 0;
    vertical-align: middle; }

.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.alice-carousel .animated-out {
  z-index: 1; }

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

span {
  font-family: 'Raleway',sans-serif; }

#nprogress .spinner {
  z-index: 99999999999999 !important; }

#nprogress .bar {
  z-index: 99999999999999 !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.custom_input label {
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #62717a; }

.custom_input input {
  text-align: left;
  border-radius: 6px;
  border: solid 1px #e1e1e1 !important;
  background-color: #f7f7f7;
  width: 100%;
  padding: 9px 15px;
  margin-top: 4px;
  font-size: 15px;
  line-height: 1.07;
  text-align: left;
  color: #202020; }
  .custom_input input::-webkit-input-placeholder {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #b0b0b0; }
  .custom_input input::-ms-input-placeholder {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #b0b0b0; }
  .custom_input input::placeholder {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #b0b0b0; }

.custom_input .error {
  color: #FF0000;
  font-size: 11px;
  text-align: left;
  width: 333px; }

.custom_input .eye_icon {
  font-size: 20px;
  position: absolute;
  right: 10px;
  cursor: pointer;
  bottom: 18px;
  color: #878787;
  cursor: pointer;
  top: 12px; }
  @media screen and (max-width: 767px) {
    .custom_input .eye_icon {
      top: 14px; } }

.custom_input .view-red-btn {
  color: #FF0000; }

.custom_input .eye_icon_disable {
  font-size: 20px;
  position: absolute;
  right: 10px;
  bottom: 10px !important;
  color: #9aa7af;
  cursor: pointer;
  top: 12px; }
  @media screen and (max-width: 767px) {
    .custom_input .eye_icon_disable {
      top: 14px; } }

.upper_case_input input {
  text-transform: uppercase; }

/***
* == Header Style
***/
span,
div,
p,
li,
a {
  font-family: 'Raleway', sans-serif; }

input {
  font-family: 'Raleway', sans-serif !important; }

label {
  font-size: 14px; }

.signup, .login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  background-color: #ee2738;
  height: auto;
  /* Select User Type Page */ }
  @media screen and (max-width: 767px) {
    .signup, .login {
      height: 100vh; } }
  .signup:before, .login:before {
    content: '';
    top: 0;
    left: 0;
    margin-left: -10%;
    width: calc(100% + 20%);
    height: 50%;
    position: absolute;
    background: #f0f0f0;
    border-radius: 0 0 100% 100% / 40%; }
  .signup .signup-personal, .login .signup-personal {
    background-color: #1397e1; }
    .signup .signup-personal label, .login .signup-personal label {
      position: relative; }
    .signup .signup-personal label + div, .login .signup-personal label + div {
      margin: 0; }
    .signup .signup-personal .checkbox-wrap label, .login .signup-personal .checkbox-wrap label {
      width: 100%;
      margin: 0; }
    .signup .signup-personal .checkbox-wrap span, .login .signup-personal .checkbox-wrap span {
      padding: 0; }
      .signup .signup-personal .checkbox-wrap span p, .login .signup-personal .checkbox-wrap span p {
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        margin-left: 5px;
        font-size: 14px;
        text-align: left;
        line-height: normal; }
  .signup .company-info-form, .login .company-info-form {
    background-color: #1397e1; }
    .signup .company-info-form label, .login .company-info-form label {
      position: relative; }
    .signup .company-info-form label + div, .login .company-info-form label + div {
      margin: 0; }
  .signup .signup__inner, .login .signup__inner {
    margin-top: 10px;
    height: 540px;
    position: relative;
    bottom: 0; }
  .signup .firstHold, .login .firstHold {
    color: #36434b;
    font-size: 16px;
    font-weight: bold;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 27px; }
  .signup .createAnAccount, .login .createAnAccount {
    color: #202020;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal; }
  .signup .yourBusiness, .login .yourBusiness {
    font-size: 12px;
    font-weight: 500;
    color: #62717a;
    margin-bottom: 35px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin-top: 14px; }
  .signup .signupForm, .login .signupForm {
    margin: 17px 0px 25px 0px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0)); }
  .signup .createPasswordForm, .login .createPasswordForm {
    margin: 80px 0px 25px 0px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0)); }
  .signup .formFields, .login .formFields {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%; }
  .signup .signupDiv, .login .signupDiv {
    display: table;
    margin: 0 auto;
    padding-top: 43px;
    padding-bottom: 25px; }
  .signup .signupButton, .login .signupButton {
    font-size: 13px;
    width: 130px;
    height: 36px;
    border-radius: 6px;
    background-image: linear-gradient(#f1435e, #ee2738);
    color: #ffffff;
    font-weight: 500;
    border: none;
    margin-bottom: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    margin-top: 45px; }
  .signup .greySignupButton, .login .greySignupButton {
    margin-top: 45px; }
  .signup .greySignupButton, .login .greySignupButton {
    font-size: 13px;
    width: 130px;
    height: 36px;
    border-radius: 6px;
    color: #ffffff;
    font-weight: 500;
    border: none;
    margin-bottom: 30px;
    background-color: #9aa7af !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 30px; }
  .signup .already, .login .already {
    font-size: 12px;
    color: #ffffff;
    font-weight: 500;
    margin-top: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal; }
  .signup .signinLink, .login .signinLink {
    color: #ffffff;
    font-weight: bold; }
    .signup .signinLink:hover, .login .signinLink:hover {
      text-decoration: underline; }
  .signup .textField, .login .textField {
    width: 339px;
    margin-bottom: 15px; }
  .signup .image, .login .image {
    width: 26px;
    height: 24px; }
  .signup .mailIcon, .login .mailIcon {
    padding: 0px 0px 0px 9px; }
    .signup .mailIcon:focus, .login .mailIcon:focus {
      background: none; }
    .signup .mailIcon:hover, .login .mailIcon:hover {
      background: "none"; }
  .signup .emailLabel, .login .emailLabel {
    color: #62717a;
    font-weight: 500;
    font-size: 11px;
    width: 359px;
    margin-bottom: 0px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    margin-top: 0px; }
  .signup .red, .login .red {
    color: #FF0000; }
  .signup .agree_check, .login .agree_check {
    padding-top: 15px;
    width: 100%;
    display: block; }
  .signup .special_offer_check, .login .special_offer_check {
    padding-bottom: 15px; }
  .signup .select_tier, .login .select_tier {
    display: block;
    padding-top: 10px;
    width: 100%; }
    .signup .select_tier div, .login .select_tier div {
      justify-content: center; }
  .signup .agree_error, .login .agree_error {
    margin-top: -10px;
    padding-bottom: 10px;
    padding-left: 22px; }
  .signup .agree-text-size, .login .agree-text-size {
    font-size: 12px;
    color: #62717a; }
  .signup .plan_link, .login .plan_link {
    color: #1397e1;
    font-size: 12px; }
  .signup .terms, .login .terms {
    color: #1397e1; }
  .signup .emailField, .login .emailField {
    width: 100%;
    height: 36px;
    border: 1px solid #e1e1e1 !important;
    background-color: #f7f7f7 !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 4px;
    margin-bottom: 0px; }
    .signup .emailField:hover, .login .emailField:hover {
      border: 'none'; }
  .signup .inputField, .login .inputField {
    width: 100%;
    height: 36px;
    border: 1px solid #e1e1e1 !important;
    background-color: #f7f7f7 !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 8px;
    margin-bottom: 15px; }
    .signup .inputField:hover, .login .inputField:hover {
      border: 'none'; }
  .signup .passwordField, .login .passwordField {
    width: 100%;
    height: 36px;
    border: 1px solid #e1e1e1 !important;
    background-color: #f7f7f7 !important;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 8px;
    margin-bottom: 15px; }
  .signup .phoneNumberGrid, .login .phoneNumberGrid {
    padding-right: 5px; }
  .signup .extensionGrid, .login .extensionGrid {
    padding-left: 5px; }
    .signup .extensionGrid .emailLabel, .login .extensionGrid .emailLabel {
      padding-left: 10px; }
  .signup .personalInfoPhone, .login .personalInfoPhone {
    margin-top: 12px; }
  .signup .passwordField::-webkit-input-placeholder, .login .passwordField::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: '#9aa7af !important'; }
  .signup .passwordField:-moz-placeholder, .login .passwordField:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: '#9aa7af !important';
    opacity: 1; }
  .signup .passwordField::-moz-placeholder, .login .passwordField::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: '#9aa7af !important';
    opacity: 1; }
  .signup .passwordField:-ms-input-placeholder, .login .passwordField:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: '#9aa7af !important'; }
  .signup .emailField::-webkit-input-placeholder, .login .emailField::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: '#9aa7af !important'; }
  .signup .emailField:-moz-placeholder, .login .emailField:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: '$light-grey !important';
    opacity: 1; }
  .signup .emailField::-moz-placeholder, .login .emailField::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: '$light-grey !important'; }
  .signup .emailField:-ms-input-placeholder, .login .emailField:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: '$light-grey !important'; }
  .signup .passwordLabel, .login .passwordLabel {
    color: #62717a;
    color: '#9aa7af !important'; }
  .signup .passwordField:-moz-placeholder, .login .passwordField:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: '#9aa7af !important';
    opacity: 1; }
  .signup .passwordField::-moz-placeholder, .login .passwordField::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: '#9aa7af !important';
    opacity: 1; }
  .signup .passwordField:-ms-input-placeholder, .login .passwordField:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: '#9aa7af !important'; }
  .signup .emailField::-webkit-input-placeholder, .login .emailField::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: '#9aa7af !important'; }
  .signup .emailField:-moz-placeholder, .login .emailField:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: '#9aa7af !important';
    opacity: 1; }
  .signup .emailField::-moz-placeholder, .login .emailField::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: '#9aa7af !important'; }
  .signup .emailField:-ms-input-placeholder, .login .emailField:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: '#9aa7af !important'; }
  .signup .passwordLabel, .login .passwordLabel {
    color: #62717a;
    font-weight: 500;
    font-size: 11px;
    width: 359px;
    margin-bottom: 0px;
    margin-top: 34px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    display: block;
    letter-spacing: normal; }
  .signup .button, .login .button {
    background-image: linear-gradient(#f1435e, #ee2738);
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    width: 232px; }
  .signup .error, .login .error {
    color: #FF0000;
    font-size: 11px;
    text-align: left;
    width: 100%; }
  .signup .image_error, .login .image_error {
    color: #FF0000;
    font-size: 11px;
    text-align: center; }
  .signup input:focus,
  .signup input.form-control:focus, .login input:focus,
  .login input.form-control:focus {
    outline: none !important;
    color: #202020;
    font-size: 14px;
    text-align: left; }
  .signup input:focus, .signup input.form-control:focus, .login input:focus, .login input.form-control:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none; }
  .signup .welcome__text, .login .welcome__text {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ee2738;
    margin-top: 30px; }
  .signup .info__text, .login .info__text {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
    margin-top: 10px; }
  .signup .rectangle, .login .rectangle {
    width: 160px;
    height: 120px;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    display: flex;
    margin-right: 9px !important;
    margin-bottom: 10px !important;
    border: none !important; }
  .signup .rectangle__container, .login .rectangle__container {
    margin-bottom: 127px;
    margin-top: 83px; }
    @media screen and (max-width: 767px) {
      .signup .rectangle__container, .login .rectangle__container {
        margin: 50px auto; } }
  .signup .text__center, .login .text__center {
    text-align: center; }
  .signup .user__type__text, .login .user__type__text {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #ee2738;
    margin-top: 10px; }
  .signup .icon1, .login .icon1 {
    font-size: 20px;
    margin-bottom: 18px;
    color: #ee2738;
    position: absolute;
    top: 10px; }
  .signup .icon2, .login .icon2 {
    font-size: 20px;
    margin-bottom: 8px;
    color: #ee2738;
    position: absolute;
    top: 10px; }
  .signup .rectangle__button_hover:hover, .login .rectangle__button_hover:hover {
    background-color: #00a0fc !important;
    box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to top, var(--water-blue), #1cb3ff), linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0)); }
    .signup .rectangle__button_hover:hover .user__type__text, .login .rectangle__button_hover:hover .user__type__text {
      color: #ffffff !important; }
    .signup .rectangle__button_hover:hover .icon1,
    .signup .rectangle__button_hover:hover .icon2, .login .rectangle__button_hover:hover .icon1,
    .login .rectangle__button_hover:hover .icon2 {
      color: #ffffff !important; }
      .signup .rectangle__button_hover:hover .icon1 .icon,
      .signup .rectangle__button_hover:hover .icon2 .icon, .login .rectangle__button_hover:hover .icon1 .icon,
      .login .rectangle__button_hover:hover .icon2 .icon {
        color: #ffffff !important; }
  .signup form, .login form {
    width: inherit; }
  .signup .img-fluid, .login .img-fluid {
    max-width: 100%;
    height: auto; }
  .signup .uploadPhoto, .login .uploadPhoto {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #f0f0f0;
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .signup .uploadPhoto, .login .uploadPhoto {
        width: 110px;
        height: 110px; } }
    .signup .uploadPhoto .icon, .login .uploadPhoto .icon {
      font-size: 90px;
      color: #e1e1e1;
      width: 100%;
      text-align: center; }
    .signup .uploadPhoto .user-icon, .login .uploadPhoto .user-icon {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden; }
    .signup .uploadPhoto:hover, .login .uploadPhoto:hover {
      font-family: 'Material Icons';
      content: "Upload Photo \E2C3";
      background-color: rgba(98, 113, 122, 0.6);
      color: #ffffff;
      cursor: pointer; }
      .signup .uploadPhoto:hover .user-icon, .login .uploadPhoto:hover .user-icon {
        display: none; }
      .signup .uploadPhoto:hover .upload-image, .login .uploadPhoto:hover .upload-image {
        display: flex; }
    .signup .uploadPhoto .upload-image, .login .uploadPhoto .upload-image {
      width: 100%;
      height: 100%;
      background: #ccc;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      display: none; }
      .signup .uploadPhoto .upload-image span.material-icons, .login .uploadPhoto .upload-image span.material-icons {
        width: 28px;
        height: 28px; }
      .signup .uploadPhoto .upload-image p, .login .uploadPhoto .upload-image p {
        color: inherit;
        text-align: center;
        line-height: normal;
        font-size: 13px;
        font-family: 'Raleway'; }
  .signup .hintText, .login .hintText {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #1397e1;
    margin-top: 19px;
    margin-bottom: 27px;
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
  @media only screen and (max-width: 991px) {
    .signup .hintText, .login .hintText {
      width: auto; }
    .signup .personal-info-form,
    .signup .company-info,
    .signup .type-info-form, .login .personal-info-form,
    .login .company-info,
    .login .type-info-form {
      width: auto !important; } }
  .signup .profile_confirmation, .login .profile_confirmation {
    width: 100%;
    min-height: 640px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    position: relative;
    background-color: #2497e2; }
    .signup .profile_confirmation:before, .login .profile_confirmation:before {
      content: '';
      top: 0;
      left: 0;
      margin-left: -10%;
      width: calc(100% + 20%);
      height: 50%;
      position: absolute;
      background: #f0f0f0;
      border-radius: 0 0 100% 100% / 40%; }
  .signup .profile_confirmation__inner, .login .profile_confirmation__inner {
    margin-top: 10px;
    height: 540px;
    position: relative;
    bottom: 0; }
  .signup .profile_confirmation_form, .login .profile_confirmation_form {
    margin: 50px 0px 25px 0px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
    padding: 20px 15px 30px 15px; }
    @media only screen and (min-width: 568px) {
      .signup .profile_confirmation_form, .login .profile_confirmation_form {
        padding: 20px 50px 30px 50px; } }
  .signup .edit_link, .login .edit_link {
    text-align: center; }
  .signup .profile_confirmation_heading, .login .profile_confirmation_heading {
    color: #36434b;
    font-size: 16px;
    padding: 0px 0px 5px 0px; }
  .signup .profile_confirmation_content, .login .profile_confirmation_content {
    font-size: 14px;
    text-align: center;
    color: #62717a;
    font-weight: 500;
    line-height: 1.43; }
  .signup .email_phone, .login .email_phone {
    color: #2497e2;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal; }
  .signup .edit_link, .login .edit_link {
    text-decoration: none;
    color: #36434b;
    font-size: 14px; }
  .signup .contact_container, .login .contact_container {
    margin-top: 30px; }
  @media only screen and (min-width: 568px) {
    .signup .phone_container, .login .phone_container {
      padding-left: 78px; } }
  @media only screen and (min-width: 568px) {
    .signup .email_container, .login .email_container {
      padding-right: 79px; } }
  .signup .email_edit, .login .email_edit {
    background-color: #f7f7f7 !important;
    color: rgba(0, 0, 0, 0.87);
    border: none;
    height: 22px;
    font-size: 12px;
    width: 177px;
    text-align: center; }
    .signup .email_edit:hover, .login .email_edit:hover {
      border: 'none'; }
    .signup .email_edit:focus, .login .email_edit:focus {
      text-align: center;
      font-size: 12px; }
  .signup .phone_edit, .login .phone_edit {
    background-color: #f7f7f7 !important;
    color: rgba(0, 0, 0, 0.87);
    border: none;
    height: 22px;
    font-size: 12px;
    width: 177px;
    text-align: center; }
    .signup .phone_edit:hover, .login .phone_edit:hover {
      border: 'none'; }
    .signup .phone_edit:focus, .login .phone_edit:focus {
      text-align: center;
      font-size: 12px; }
  @media only screen and (max-width: 411px) {
    .signup .phone_edit_button, .signup .phone_edit_back_button, .login .phone_edit_button, .login .phone_edit_back_button {
      padding-bottom: 15px; } }
  .signup .email_phone_edit_error, .login .email_phone_edit_error {
    margin-bottom: -20px;
    color: #FF0000;
    font-size: 11px;
    text-align: center;
    width: 173px; }
  .signup .icon, .login .icon {
    font-size: 90px;
    color: #e1e1e1;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center; }
  .signup .user-icon, .login .user-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden; }
    .signup .user-icon :nth-child(1), .login .user-icon :nth-child(1) {
      width: 100%;
      height: 100%; }
  .signup .confirm, .login .confirm {
    width: 100%;
    min-height: 660px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    position: relative;
    background-color: #2497e2;
    height: 100vh; }
    .signup .confirm:before, .login .confirm:before {
      content: '';
      top: 0;
      left: 0;
      margin-left: -10%;
      width: calc(100% + 20%);
      height: 50%;
      position: absolute;
      background: #f0f0f0;
      border-radius: 0 0 100% 100% / 40%; }
  .signup .confirmationBox, .login .confirmationBox {
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0)); }
  .signup .confirmationPadding, .login .confirmationPadding {
    color: #36434b;
    font-size: 16px;
    font-weight: bold;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 45px; }
  .signup .confirmDiv, .login .confirmDiv {
    top: 20px;
    display: table;
    margin: 0 auto; }
  .signup .emailSentTo, .login .emailSentTo {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    padding: 0px 0px; }
  .signup .phoneNumber, .login .phoneNumber {
    font-family: Raleway;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1397e1; }
  .signup .emailText, .login .emailText {
    font-family: Raleway;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: center;
    color: #1397e1;
    padding: 0px 0px; }
  .signup .phoneCode, .login .phoneCode {
    width: 276px;
    height: 16px;
    font-family: Raleway;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .signup .phoneInput, .login .phoneInput {
    width: 363px;
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7; }
  .signup .emailLastText, .login .emailLastText {
    font-family: Raleway;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #62717a;
    padding: -1px 0px 0px 0px; }
  .signup .emailLabel, .login .emailLabel {
    color: #62717a;
    font-weight: 500;
    font-size: 11px;
    width: 359px;
    margin-bottom: 0px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    margin-top: 0px; }
  .signup .emailField, .login .emailField {
    width: 100%;
    height: 36px;
    border: none;
    background-color: #f7f7f7 !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px; }
    .signup .emailField:hover, .login .emailField:hover {
      border: 'none'; }
  .signup .PhoneField, .login .PhoneField {
    width: 330px;
    height: 36px;
    border: none;
    background-color: #f7f7f7 !important;
    font-size: 15px !important;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #202020;
    border-radius: 6px;
    padding-left: 18px;
    padding-right: 15px; }
    .signup .PhoneField:hover, .login .PhoneField:hover {
      border: 'none'; }
  .signup .phoneLabel, .login .phoneLabel {
    width: 357px;
    font-family: Raleway;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    margin-top: 10px;
    margin-bottom: 7px; }
  .signup .conFIrmformFields, .login .conFIrmformFields {
    margin-top: -18px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 89%; }
  .signup .state_div, .login .state_div {
    width: 100%;
    margin-bottom: 15px; }
    .signup .state_div .custom_select_with_scroll, .login .state_div .custom_select_with_scroll {
      margin-bottom: 0px;
      margin-top: 4px; }
      @media screen and (max-width: 767px) {
        .signup .state_div .custom_select_with_scroll > div, .login .state_div .custom_select_with_scroll > div {
          padding: 0 5px 0px 15px; } }
  .signup .stateLabel, .login .stateLabel {
    color: #62717a;
    font-weight: 500;
    font-size: 11px;
    width: 359px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    margin-top: 15px; }
  .signup .emailDiv, .login .emailDiv {
    display: table;
    margin: -45px 0px 0px 142px; }
  .signup .phoneDivBtn, .login .phoneDivBtn {
    display: table;
    margin: -3px 0px 0px 142px; }
  .signup .emailSentTo, .login .emailSentTo {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87); }
  .signup .texSentTo, .login .texSentTo {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87); }
  .signup .forgotPassword, .login .forgotPassword {
    padding-top: 20px;
    width: 359px; }
    .signup .forgotPassword a, .login .forgotPassword a {
      text-decoration: none;
      font-size: 14px;
      color: #62717a;
      font-weight: 500; }

.checkbox {
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
  /* Style the checked checkbox label */ }
  .checkbox .container input, .checkbox .blue_text_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .checkbox .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border: 1px solid #2497e2;
    border-radius: 2px; }
  .checkbox .container:hover input ~ .checkmark {
    background-color: #ffffff; }
  .checkbox .container input:checked ~ .checkmark {
    background-color: #2497e2; }
  .checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .checkbox .container input:checked ~ .checkmark:after {
    display: block; }
  .checkbox .container input:checked ~ .checkmark {
    border: 1px solid #2497e2; }
  .checkbox .container .checkmark:after {
    left: 3px;
    top: -1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); }
  .checkbox .container {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #2497e2; }

.button_container {
  padding: 25px 0px 22px 0px; }

.signup-personal .personal-info-form {
  text-align: center; }

.signup .emailLabel, .signup-personal .login .emailLabel, .signup .personalInfoPhone, .signup-personal .login .personalInfoPhone {
  text-align: left; }

.signup-personal .personal-info-form .upload-block .crop-block {
  display: block; }
  .signup-personal .personal-info-form .upload-block .crop-block .wid-50 {
    width: 50%;
    display: inline-block;
    padding: 10px 0px; }
    @media screen and (max-width: 767px) {
      .signup-personal .personal-info-form .upload-block .crop-block .wid-50 {
        padding: 20px 0px; } }
    .signup-personal .personal-info-form .upload-block .crop-block .wid-50 input {
      position: relative !important; }
      @media screen and (max-width: 767px) {
        .signup-personal .personal-info-form .upload-block .crop-block .wid-50 input {
          width: 108px; } }
  @media screen and (max-width: 767px) {
    .signup-personal .personal-info-form .upload-block .crop-block button {
      width: 45% !important; } }
  .signup-personal .personal-info-form .upload-block .crop-block button input {
    position: relative !important; }

.signup-personal .personal-info-form .upload-block .error {
  width: auto;
  text-align: center; }

.signup-personal .company-info .emailLabel {
  padding-top: 10px; }

.signup-personal .personal-info-form .emailField {
  margin-top: 5px; }

.signup-personal .personal-info-form .emailLabel {
  margin-top: 27px; }

.signup-personal .personal-info-form .inputField {
  margin-top: 5px;
  margin-bottom: 0px; }

.signup-personal .personal-info-form .hintText, .signup-personal .personal-info-form .checkbox .container {
  margin-bottom: 0px; }

.signup-personal .personal-info-form .signupDiv {
  padding-top: 38px; }

.signup-personal .personal-info-form .personalInfoPhone {
  margin-top: 20px; }

@media screen and (max-width: 767px) {
  .signup-personal .personal-info-form .signup .personalInfoPhone {
    margin-top: 0px !important; } }

.company-info .sm-pd-top {
  padding-top: 15px; }

.login .login-btn-ui button > span,
.signup .login-btn-ui button > span {
  border: 1px solid #0071c5 !important;
  background: #0071c5 !important;
  color: #ffffff;
  padding: 4px; }
  .login .login-btn-ui button > span img,
  .signup .login-btn-ui button > span img {
    background: #ffffff;
    padding: 4px; }

.login .login-btn-ui button > span:hover,
.signup .login-btn-ui button > span:hover {
  border: 1px solid #0071c5 !important;
  background: transparent !important;
  color: #0071c5;
  transition: background-color 0.3s ease-in-out; }

.login .login-btn-ui span.login-btn-text,
.signup .login-btn-ui span.login-btn-text {
  padding: 0 5px; }

/***
* == Header Style
***/
.image {
  width: 26px;
  height: 24px; }

.uploadPhoto {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0px;
  background-color: #f0f0f0;
  margin-top: 25px;
  position: relative;
  cursor: pointer;
  text-align: center;
  display: inline-block; }
  .uploadPhoto .image_upload_file {
    display: none; }
  .uploadPhoto .icon {
    font-size: 90px;
    color: #e1e1e1;
    width: 100%;
    height: 100%;
    text-align: center; }
  .uploadPhoto .user-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden; }
  .uploadPhoto:hover {
    font-family: 'Material Icons';
    content: "Upload Photo \E2C3";
    background-color: rgba(98, 113, 122, 0.6);
    color: #ffffff;
    cursor: pointer; }
    .uploadPhoto:hover .upload-image {
      display: flex; }
  .uploadPhoto .upload-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba(98, 113, 122, 0.6);
    display: none; }
    .uploadPhoto .upload-image span.material-icons {
      width: 28px;
      height: 28px;
      padding: 2px 0px; }
    .uploadPhoto .upload-image p {
      color: inherit;
      text-align: center;
      line-height: normal;
      font-size: 13px;
      font-family: 'Raleway'; }

.hintText {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #1397e1;
  margin-top: 19px;
  margin-bottom: 27px;
  width: 370px;
  margin-left: auto;
  margin-right: auto; }

.image_upload_form {
  margin-top: -100px; }

.save_button {
  display: block;
  text-align: center; }

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .hintText {
    width: auto; }
  .personal-info-form,
  .company-info,
  .type-info-form {
    width: auto !important; } }

a.fill-div {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none; }

.custom_checkbox {
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
  /* Style the checked checkbox label */ }
  .custom_checkbox .container {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .custom_checkbox .container input, .custom_checkbox .blue_text_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .custom_checkbox .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border: 1px solid #9aa7af;
    border-radius: 2px; }
  .custom_checkbox .container:hover input ~ .checkmark, .custom_checkbox .blue_text_container:hover input ~ .checkmark {
    background-color: #ffffff; }
  .custom_checkbox .container input:checked ~ .checkmark, .custom_checkbox .blue_text_container input:checked ~ .checkmark {
    background-color: #1397e1; }
  .custom_checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .custom_checkbox .container input:checked ~ .checkmark:after, .custom_checkbox .blue_text_container input:checked ~ .checkmark:after {
    display: block; }
  .custom_checkbox .container input:checked ~ .checkmark, .custom_checkbox .blue_text_container input:checked ~ .checkmark {
    border: 1px solid #1397e1; }
  .custom_checkbox .container .checkmark:after, .custom_checkbox .blue_text_container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); }
  .custom_checkbox .blue_text_container {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #1397e1; }
  .custom_checkbox .disabled_day {
    color: #9aa7af; }
  .custom_checkbox .disabled_checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 2px; }

.signup__inner .custom_checkbox .blue_text_container .checkmark:after,
.signup__inner .checkbox .container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px; }

.edit_profile {
  padding-top: 65px;
  height: 100%; }
  .edit_profile .save_button {
    padding: 0px 32px; }
  .edit_profile .personal_info {
    padding-bottom: 50px; }
  .edit_profile .company_info {
    padding-bottom: 50px; }
  .edit_profile .change_password {
    padding-bottom: 50px; }
  .edit_profile .delete-image {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize;
    padding-left: 27px; }
  .edit_profile .delete-image:hover {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer; }
  .edit_profile .headings {
    border-bottom: solid 1px #e1e1e1; }
  .edit_profile .form_name {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    color: #1397e1; }
  .edit_profile .edit_profile_component {
    padding-bottom: 62px;
    background-color: #ffffff;
    box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb; }
    .edit_profile .edit_profile_component .help_icon {
      padding-top: 44px;
      cursor: pointer; }
    @media screen and (max-width: 500px) {
      .edit_profile .edit_profile_component .edit_profile_component_form {
        padding-left: 20px;
        padding-right: 20px; } }
    .edit_profile .edit_profile_component .form_name {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #1397e1;
      text-transform: capitalize; }
    @media screen and (min-width: 1025px) {
      .edit_profile .edit_profile_component .left_panel {
        padding-right: 55px; } }
    @media screen and (min-width: 960px) and (max-width: 1025px) {
      .edit_profile .edit_profile_component .left_panel {
        padding-right: 20px; } }
    @media screen and (min-width: 1025px) {
      .edit_profile .edit_profile_component .right_panel {
        padding-left: 57px; } }
    @media screen and (min-width: 960px) and (max-width: 1025px) {
      .edit_profile .edit_profile_component .right_panel {
        padding-left: 20px; } }
    .edit_profile .edit_profile_component .password_field_div {
      padding-top: 20px;
      position: relative; }
    .edit_profile .edit_profile_component .field_div {
      padding-top: 20px; }
    .edit_profile .edit_profile_component .edit_profile_component {
      padding-bottom: 62px; }
  .edit_profile .error {
    color: #FF0000;
    font-size: 11px;
    text-align: left;
    height: 10px; }

::-webkit-scrollbar {
  width: 5px !important; }

.MuiDialog-paperWidthSm-226 {
  max-width: 100% !important;
  width: 100% !important;
  max-height: 100% !important; }

.MuiPaper-elevation24-146 {
  box-shadow: none !important; }

.MuiBackdrop-root-308 {
  background-color: rgba(0, 0, 0, 0.7) !important; }

.MuiPaper-root-120 {
  background: none !important; }

.react-datepicker-popper {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px; }

.datepiker {
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #62717a; }

.dialogActions span {
  cursor: pointer !important; }

.closeDialog {
  width: 22px;
  height: 22px;
  font-family: material;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff; }

.dialog-content {
  height: 100%;
  width: 60%;
  margin: auto; }

.modalBox_content {
  height: 492px; }

.main_place_hold_section {
  width: 600px;
  border-radius: 6px;
  background-color: #f0f0f0;
  border: 2px solid white !important;
  height: 100%; }
  .main_place_hold_section .error {
    display: inline-block;
    width: auto; }

.header {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  margin-top: 0;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0; }

.success_modal_container .dialog-content {
  height: 100% !important;
  width: 100% !important;
  margin: auto !important; }

.success_modal_container {
  width: 100% !important;
  min-height: auto !important;
  margin-top: 30px;
  /* create new event form */
  /* Events */ }
  .success_modal_container .react-datepicker__navigation {
    padding: 0 !important; }
  .success_modal_container .MuiDialog-paperWidthSm-226 {
    max-width: 100% !important;
    width: 100% !important;
    max-height: 100% !important; }
  .success_modal_container .MuiPaper-elevation24-146 {
    box-shadow: none !important; }
  .success_modal_container .MuiBackdrop-root-308 {
    background-color: rgba(0, 0, 0, 0.7) !important; }
  .success_modal_container .MuiPaper-root-120 {
    background: none !important;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    margin: 0px; }
  .success_modal_container .MuiDialog-paper-222 {
    margin: 0 !important;
    position: absolute;
    top: 0; }
  .success_modal_container .dialogActions {
    height: 40px;
    font-family: material;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0px; }
  .success_modal_container .closeButton {
    cursor: pointer;
    position: absolute;
    font-size: 30px;
    top: -70px;
    right: -38px;
    color: #ffffff;
    font-weight: bold; }
  .success_modal_container .modalBox_content {
    height: auto;
    background-color: #ffffff; }
  .success_modal_container .main_place_hold_section {
    width: 600px;
    border-radius: 6px;
    background-color: #f0f0f0;
    border: 2px solid white !important;
    height: 100%; }
    @media screen and (max-width: 767px) {
      .success_modal_container .main_place_hold_section .end-date-sm {
        padding-top: 20px; } }
  .success_modal_container .follow-inner-popup {
    display: block;
    padding: 15px;
    max-width: 450px;
    font-size: 15px;
    line-height: 23px; }
    .success_modal_container .follow-inner-popup button {
      margin-top: 15px;
      width: auto !important;
      padding: 5px 15px; }
  .success_modal_container .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .success_modal_container .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px; }
  .success_modal_container .header-button-div {
    float: right; }
  .success_modal_container .uploader {
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
    background-image: url(https://www.first-hold.com/static/media/perm_contact_calendar.dd9786b4.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-color: #f0f0f0; }
  .success_modal_container .success_modal_container {
    min-height: 100px;
    overflow-y: auto !important; }
  .success_modal_container .uploader-content {
    width: 300px;
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto; }
  .success_modal_container .red-text-content {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ee2738;
    margin: 140px 0 5px 0; }
  .success_modal_container .black-text-content {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
    margin-top: 5px; }
  .success_modal_container .white-button-big {
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    float: left;
    cursor: pointer; }
    .success_modal_container .white-button-big span {
      width: 100%; }
  .success_modal_container .select_field {
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #202020 !important;
    text-transform: none !important;
    width: 100%;
    height: 36px;
    margin: 0 !important;
    padding-left: 10px; }
  .success_modal_container .form .form-content {
    padding: 20px;
    display: inline-block; }
  .success_modal_container .form .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .success_modal_container .form .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px; }
  .success_modal_container .form .header-button-div-form {
    float: right;
    width: 210px; }
  .success_modal_container .form .dialogActions {
    height: 120px;
    font-family: material;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    padding: 20px;
    margin: 0;
    display: block; }
    .success_modal_container .form .dialogActions span {
      cursor: pointer; }
  .success_modal_container .form .field-div {
    width: 100%;
    height: 80px;
    float: left; }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .field-div {
        float: none !important; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .spacer.disp-none-sm {
      display: none; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-start {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req-up {
      margin-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-end {
      margin-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req-down {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req-seating, .success_modal_container .form .sm-pd-venue {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req {
      padding-top: 5px; } }
  .success_modal_container .form .disp-none-big {
    display: none; }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .disp-none-big {
        display: block; } }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .disp-none-big .sm-pd-seating {
        padding-top: 20px !important; } }
  .success_modal_container .form .input-label {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    position: relative;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    width: 100%;
    float: left;
    padding-bottom: 5px; }
  .success_modal_container .form .input-field {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: transparent;
    float: left;
    margin: 0 !important;
    font-size: 16px;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    padding-left: 10px; }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .input-field {
        padding-left: 15px;
        font-size: 15px !important; } }
  .success_modal_container .form .input[role="combobox"] + div {
    z-index: 99;
    top: 35px !important;
    left: 0px !important;
    position: absolute !important;
    min-height: 95px !important;
    background: #fff;
    overflow-y: scroll;
    height: 150px;
    padding-left: 15px;
    box-shadow: 0px 15px 10px -15px #ccc;
    border: 1px solid #e1e1e1;
    font-weight: 500;
    cursor: pointer; }
  .success_modal_container .form .city-sm .suggestionDiv {
    padding-left: 15px; }
  .success_modal_container .form .input-field-section {
    width: 100%;
    float: left;
    color: #202020;
    position: relative; }
  .success_modal_container .form .big {
    width: 100% !important; }
  .success_modal_container .form .dark-black {
    color: #36434b !important; }
  .success_modal_container .form .light-black {
    color: #202020 !important; }
  .success_modal_container .form .lighter-black {
    color: #62717a !important; }
  .success_modal_container .form .small {
    width: 46% !important; }
    .success_modal_container .form .small select {
      font-size: 15px !important; }
    .success_modal_container .form .small input {
      font-size: 15px; }
  .success_modal_container .form .smaller {
    width: 40% !important; }
    .success_modal_container .form .smaller select {
      font-size: 12px !important;
      background-color: #ffffff !important;
      text-transform: uppercase !important; }
      @media screen and (max-width: 767px) {
        .success_modal_container .form .smaller select {
          text-transform: capitalize !important;
          font-size: 15px !important;
          border: 1px solid #e1e1e1 !important; } }
    .success_modal_container .form .smaller input {
      font-size: 12px;
      color: #1397e1 !important;
      text-align: left;
      height: 36px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff; }
      @media screen and (max-width: 767px) {
        .success_modal_container .form .smaller input {
          font-size: 15px;
          font-weight: normal;
          color: #202020;
          text-transform: capitalize;
          border: solid 1px #e1e1e1 !important; } }
  .success_modal_container .form .tall {
    height: 140px; }
  .success_modal_container .form .spacer {
    width: 20%;
    padding: 25px 0 0 0;
    overflow: hidden;
    text-align: center; }
  .success_modal_container .form select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    padding: 5px;
    -webkit-appearance: menulist;
    text-align: left;
    padding-left: 5px;
    width: 100%;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal; }
  .success_modal_container .form .float-right {
    float: right; }
  .success_modal_container .form .white-button-small {
    width: 100px !important;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    padding: 0 !important;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    float: left;
    cursor: pointer; }
    .success_modal_container .form .white-button-small span {
      width: 100%; }
  .success_modal_container .form .red-button-small {
    width: 100px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer; }
  @media screen and (max-device-width: 767px) {
    .success_modal_container .form .smaller {
      width: 100% !important; }
    .success_modal_container .form .small {
      width: 100%; }
    .success_modal_container .form .modalBox_content {
      height: 100%;
      display: flex; }
    .success_modal_container .form .newEventPopupContainer {
      width: 100% !important; }
    .success_modal_container .form .small {
      width: 100% !important; }
    .success_modal_container .form .header {
      height: 60px; }
    .success_modal_container .form .header-text {
      width: 100%;
      text-align: center; }
    .success_modal_container .form .header-button-div-form {
      width: 100%;
      margin: 10px auto;
      padding: 0 0px; }
    .success_modal_container .form .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid; }
    .success_modal_container .form .red-text-content {
      margin-top: 100px; }
    .success_modal_container .form .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important; } }
  .success_modal_container .eventDiv {
    width: 98%;
    height: 80px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    margin: 10px auto 15px !important;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between; }
    .success_modal_container .eventDiv .checkBoxDiv {
      display: flex;
      width: auto;
      align-items: center; }
      .success_modal_container .eventDiv .checkBoxDiv label .radio {
        margin: 0px 0px 50px 0px !important; }
        .success_modal_container .eventDiv .checkBoxDiv label .radio :hover {
          color: #42a5f5; }
    .success_modal_container .eventDiv .blue-text {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #1397e1;
      margin: 0; }
    .success_modal_container .eventDiv .grey-date {
      color: #62717a; }
  .success_modal_container .events-content {
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto;
    overflow-y: scroll; }
  .success_modal_container .checkBoxDiv {
    width: 5%;
    float: left;
    display: flex;
    height: 100%;
    align-items: center; }
  .success_modal_container .detailsDiv {
    width: 95%;
    float: left;
    display: flex;
    height: 100%;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .success_modal_container .detailsDiv {
        align-items: center; } }
    .success_modal_container .detailsDiv .actionDiv {
      width: 20%;
      float: left;
      display: flex;
      height: 100%;
      align-items: center; }
      .success_modal_container .detailsDiv .actionDiv .hand-icon {
        width: 9.5px;
        height: 19.7px;
        background-color: #62717a;
        margin-right: 10px; }
      .success_modal_container .detailsDiv .actionDiv .grey-text {
        font-family: Raleway;
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        color: #62717a;
        margin: 0;
        min-width: 63px; }
  .success_modal_container .checkBox {
    width: 20px;
    height: 20px;
    background-color: #9aa7af !important; }
  .success_modal_container .large-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    width: auto;
    word-break: break-word;
    cursor: pointer; }
  .success_modal_container .edit-icon {
    display: inline-flex;
    cursor: pointer; }
  .success_modal_container .eventsSelected {
    width: 100%;
    height: 440px;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 30px; }
    .success_modal_container .eventsSelected .footerButtonDiv {
      width: 20%;
      margin: 20px auto; }
    .success_modal_container .eventsSelected .grey-button:disabled {
      height: 36px;
      border-radius: 6px;
      background-color: #9aa7af !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: none; }
    .success_modal_container .eventsSelected .grey-button:enabled {
      height: 36px;
      border-radius: 6px;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738); }
    .success_modal_container .eventsSelected .image {
      width: 38px;
      height: 38px;
      object-fit: contain; }
    .success_modal_container .eventsSelected .large-text {
      font-family: Raleway;
      font-size: 24px !important;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin: 10px auto;
      cursor: pointer; }
    .success_modal_container .eventsSelected .description {
      font-family: Raleway;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #202020;
      margin: auto; }
    .success_modal_container .eventsSelected .blue-text {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1397e1;
      margin: 15px auto 0 auto; }
    .success_modal_container .eventsSelected .grey-text {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9aa7af;
      margin: 20px auto 10px auto;
      text-transform: uppercase; }
      @media screen and (max-width: 767px) {
        .success_modal_container .eventsSelected .grey-text {
          min-width: 60px; } }
    .success_modal_container .eventsSelected .optionsDiv {
      width: 90%;
      margin: auto;
      display: block;
      overflow: auto; }
      .success_modal_container .eventsSelected .optionsDiv .option {
        width: 33%;
        float: left;
        text-align: center; }
        .success_modal_container .eventsSelected .optionsDiv .option label {
          margin-left: auto !important;
          margin-right: auto !important; }
      .success_modal_container .eventsSelected .optionsDiv .option-text {
        width: 60%;
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #62717a;
        margin: auto; }
      .success_modal_container .eventsSelected .optionsDiv .grey {
        color: #62717a !important; }
      .success_modal_container .eventsSelected .optionsDiv .blue {
        color: #1f9ce3 !important; }
      .success_modal_container .eventsSelected .optionsDiv .blue-circle {
        width: 20px;
        height: 20px;
        border: solid 2px #1397e1;
        background-color: #ffffff;
        margin-top: 30px; }
  .success_modal_container .dialogFooter {
    height: 50px;
    border-radius: 6px;
    box-shadow: inset 0 1px 0 0 var(--white);
    background-color: #f7f7f7;
    display: flex;
    align-items: center; }
    .success_modal_container .dialogFooter .buttonDiv {
      margin: auto; }
  .success_modal_container .red-button {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer; }
  @media screen and (max-width: 767px) {
    .success_modal_container .dialog-content {
      height: 100%;
      width: 90% !important;
      margin: auto; }
    .success_modal_container .modalBox_content {
      height: 100%; }
    .success_modal_container .header {
      height: 60px;
      margin: auto; }
    .success_modal_container .header-text {
      width: 100%;
      text-align: center; }
    .success_modal_container .header-button-div {
      width: 80%;
      text-align: center;
      margin: 10px 0; }
    .success_modal_container .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid; }
    .success_modal_container .red-text-content {
      margin-top: 100px; }
    .success_modal_container .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important; }
    .success_modal_container .eventDiv {
      margin: 0;
      padding: 10px;
      height: 100px; }
    .success_modal_container .checkBoxDiv {
      width: 10% !important; }
    .success_modal_container .detailsDiv {
      width: 90% !important;
      position: relative; }
    .success_modal_container .large-text {
      width: 100% !important;
      cursor: pointer; }
    .success_modal_container .actionDiv {
      width: 100% !important;
      height: 70% !important;
      justify-content: flex-end; }
    .success_modal_container .eventsSelected {
      height: auto;
      padding: 10px; }
    .success_modal_container .optionsDiv {
      margin: 0 !important;
      width: 100% !important; }
    .success_modal_container .option {
      width: 80% !important;
      float: left;
      align-items: center;
      display: flex;
      margin: auto; }
      .success_modal_container .option .option-text {
        float: left;
        text-align: left !important; }
      .success_modal_container .option input {
        float: left;
        margin: 0 !important; }
    .success_modal_container .footerButtonDiv {
      width: 130px !important;
      margin: 20px auto; } }

.MuiDialog-paper-235 {
  margin: 20px !important; }

.event-loading .load-box {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 700;
  padding-top: 50px;
  padding-bottom: 50px; }

input#city {
  border-radius: 6px;
  border: solid 1px #e1e1e1 !important;
  background-color: #f7f7f7;
  width: 100%;
  padding: 9px 15px;
  font-size: 15px;
  line-height: 1.07;
  text-align: left;
  color: #202020; }

.suggestionDiv {
  width: 100% !important;
  position: relative;
  font-size: 12px;
  line-height: 2.3;
  word-break: break-all; }

.success_modal_container div:nth-child(2) div:first-child {
  max-width: 600px !important; }

.detailsDiv .actionDiv .delete::before, .detailsDiv .actionDiv .delete::after {
  display: none; }

.detailsDiv .actionDiv .delete {
  background-color: transparent;
  margin-top: -5px; }

.Contact-Venue-popup {
  padding-bottom: 20px; }
  .Contact-Venue-popup .header {
    border: 0px;
    text-align: center;
    display: block;
    height: auto;
    padding-bottom: 0px; }
    .Contact-Venue-popup .header .header-text {
      text-align: center;
      float: none;
      color: #1397e1; }
      @media screen and (max-width: 767px) {
        .Contact-Venue-popup .header .header-text {
          font-size: 15px !important; } }
  .Contact-Venue-popup button span {
    color: #ffffff; }
  .Contact-Venue-popup .message-section {
    padding: 10px 15px;
    line-height: 1.7; }
    @media screen and (max-width: 767px) {
      .Contact-Venue-popup .message-section {
        font-size: 13px !important; } }

@media screen and (max-width: 767px) {
  .place-hold-popup div:nth-child(2) div:first-child {
    max-width: 280px !important; } }

.place-hold-popup .modalBox_content .add-new-form-block .custom_select_with_scroll > div {
  padding: 0px 5px 0px 15px; }

@media screen and (max-width: 767px) {
  .place-hold-popup .modalBox_content .add-new-form-block {
    padding: 10px 20px 20px;
    height: 312px; } }

@media screen and (max-width: 767px) {
  .place-hold-popup .modalBox_content .add-new-form-block .disp-none-small {
    display: none; } }

@media screen and (max-width: 767px) {
  .place-hold-popup .modalBox_content .add-new-form-block .field-div {
    height: auto; } }

.place-hold-popup .modalBox_content .add-new-form-block .smaller input {
  padding: 0px 14px; }

.place-hold-popup .modalBox_content .add-new-form-block .smaller svg {
  right: -7px; }
  @media screen and (max-width: 767px) {
    .place-hold-popup .modalBox_content .add-new-form-block .smaller svg {
      right: 0px; } }

.place-hold-popup .error {
  display: block;
  position: absolute;
  bottom: -19px; }

.place-hold-popup .add-new-form-block {
  height: 440px; }
  @media screen and (min-width: 1600px) {
    .place-hold-popup .add-new-form-block {
      height: 560px; } }
  @media screen and (max-width: 767px) {
    .place-hold-popup .add-new-form-block {
      height: 326px; } }

.place-hold-popup .grey-text {
  text-align: center;
  cursor: pointer; }

.place-hold-popup .select-down-icon::before {
  content: 'arrow_drop_down';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 0.8;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 8px;
  right: 5px;
  color: #62717a; }

.place-hold-popup .select-down-icon {
  position: relative; }

.place-hold-popup .form .smaller select {
  background-color: transparent !important; }

.place-hold-popup .events-content {
  height: 292px; }

@media screen and (max-width: 767px) {
  .place-hold-popup #eventsContent {
    margin: 18px auto 0px !important; } }

@media screen and (max-width: 767px) {
  .place-hold-popup .header-button-div {
    margin: 10px 0 0px;
    width: 85%; } }

.add-new-form-block {
  overflow-y: scroll;
  height: 422px; }

@media screen and (max-width: 767px) {
  .follow-date-popup div:nth-child(2) div:first-child {
    max-width: 280px !important; } }

@media screen and (max-width: 767px) {
  .follow-date-popup .detailsDiv {
    width: 100% !important; } }

.follow-date-popup #eventsContent {
  padding-left: 10px;
  padding-right: 6px; }
  @media screen and (max-width: 767px) {
    .follow-date-popup #eventsContent {
      margin: 5px auto 0px !important; } }
  @media screen and (max-width: 767px) {
    .follow-date-popup #eventsContent .header-text {
      font-size: 15px; } }
  @media screen and (max-width: 767px) {
    .follow-date-popup #eventsContent button {
      width: 90px !important; } }

.follow-date-popup .events-content {
  margin: 5px auto 10px;
  height: 290px; }

::-webkit-input-placeholder {
  /* Edge */
  color: #62717a !important; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #62717a !important; }

.follow-up-signin > div {
  width: 100%; }

.follow-date-popup div:nth-child(2) div:first-child {
  width: 100%; }

.city-section input[role="combobox"] + div {
  z-index: 99;
  top: 35px !important;
  left: 0px !important;
  position: absolute !important;
  min-height: 95px !important;
  background: #fff;
  overflow-y: scroll;
  height: 160px;
  box-shadow: 0px 15px 10px -15px #ccc;
  border: 1px solid #e1e1e1;
  font-weight: 500;
  cursor: pointer; }

.city-section .suggestionDiv {
  width: 100% !important;
  padding-left: 15px; }

.what-we-love-block {
  padding-right: 25px;
  margin-top: 25px; }
  @media screen and (max-width: 767px) {
    .what-we-love-block {
      margin: 15px auto 10px; } }
  .what-we-love-block .what-we-love-listing {
    display: block;
    margin-left: 45px;
    width: 100%;
    margin-bottom: 25px; }
    .what-we-love-block .what-we-love-listing ul {
      display: block;
      width: 50%;
      float: left;
      list-style: none;
      padding-left: 20px; }
      @media screen and (max-width: 767px) {
        .what-we-love-block .what-we-love-listing ul {
          width: 100%; } }
      .what-we-love-block .what-we-love-listing ul li {
        position: relative;
        padding-top: 0px !important;
        padding-bottom: 0px !important; }
      .what-we-love-block .what-we-love-listing ul li:before {
        content: "\2022";
        color: #3C97E1;
        font-weight: bold;
        position: absolute;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 25px;
        top: 0;
        margin-top: -8px; }

.placehold-event .detailsDiv {
  width: 20% !important; }
  @media screen and (max-width: 767px) {
    .placehold-event .detailsDiv {
      width: 40% !important; } }
  .placehold-event .detailsDiv .actionDiv {
    width: 100%; }
    @media screen and (max-width: 767px) {
      .placehold-event .detailsDiv .actionDiv .grey-text {
        min-width: 48px; } }

.placehold-event .detailsDivLeft {
  display: flex;
  width: auto;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .placehold-event .detailsDivLeft .edit-icon {
      padding-left: 5px; } }

.placehold-event .checkBoxDiv {
  display: flex;
  width: auto;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .placehold-event .checkBoxDiv {
      width: 50% !important; } }
  .placehold-event .checkBoxDiv .radioTextLeft {
    width: 100%;
    display: flex;
    align-items: center; }
    .placehold-event .checkBoxDiv .radioTextLeft div {
      width: 10%;
      display: inline-block; }
      .placehold-event .checkBoxDiv .radioTextLeft div label .radio {
        margin: 0px 0px 0px 0px !important; }
    .placehold-event .checkBoxDiv .radioTextLeft .radioText {
      width: 90%;
      display: inline-block;
      padding-left: 10px; }
      @media screen and (max-width: 767px) {
        .placehold-event .checkBoxDiv .radioTextLeft .radioText p {
          font-size: 13px; } }
    .placehold-event .checkBoxDiv .radioTextLeft .large-text {
      display: flex; }
    .placehold-event .checkBoxDiv .radioTextLeft .grey-date {
      display: flex; }
    .placehold-event .checkBoxDiv .radioTextLeft label .radio {
      margin: 0px 0px 50px 0px !important; }
      .placehold-event .checkBoxDiv .radioTextLeft label .radio :hover {
        color: #42a5f5; }

.venue-link {
  color: #f12331;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal; }

.link-container {
  width: 168px;
  position: absolute;
  left: 47%;
  margin-left: -50px; }

@media screen and (min-width: 1600px) {
  .place-hold-popup .new-box-height .add-new-form-block {
    height: 545px !important; } }

/***
* == Header Style
***/
span,
div,
p,
li,
a,
input {
  font-family: 'Raleway', sans-serif; }

.submitDiv {
  padding-bottom: 24px; }

.confirmPhoneSubmit {
  padding-top: 46px; }

.confirm {
  width: 100%;
  min-height: 660px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  height: 100vh; }
  .confirm:before {
    content: '';
    top: 0;
    left: 0;
    margin-left: -10%;
    width: calc(100% + 20%);
    height: 50%;
    position: absolute;
    border-radius: 0 0 100% 100% / 40%; }

.min-wid-email {
  min-width: 400px; }
  @media screen and (max-width: 767px) {
    .min-wid-email {
      min-width: 280px; } }

.confirmationBox {
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0)); }
  .confirmationBox h1 {
    padding: 20px 0px; }
  .confirmationBox .phoneNumber {
    padding-bottom: 25px; }
  .confirmationBox .confirmPhoneSubmit {
    padding: 20px 0px; }

.confirm-email {
  padding: 0px 15px; }
  @media screen and (max-width: 767px) {
    .confirm-email .phoneLabel {
      padding-left: 0px; } }
  .confirm-email .emailSentTo {
    padding: 20px 0px; }
  .confirm-email .yourBusiness {
    padding-bottom: 30px; }
  .confirm-email .submitDiv {
    padding: 20px 0px; }

.confirmationPadding {
  color: #36434b;
  font-size: 16px;
  font-weight: bold;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 45px; }

.confirmDiv {
  top: 20px;
  display: table;
  margin: 0 auto; }

.emailSentTo {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  padding: 0px 0px; }

.phoneNumber {
  font-family: Raleway;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1397e1;
  padding-bottom: 10px; }

.emailText {
  font-family: Raleway;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #1397e1;
  padding: 0px 0px; }

.phoneCode {
  width: 276px;
  height: 16px;
  font-family: Raleway;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #62717a; }

.phoneInput {
  width: 363px;
  height: 36px;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: #f7f7f7; }

.emailLastText {
  font-family: Raleway;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #62717a;
  padding: -1px 0px 0px 0px; }

.emailLabel {
  color: #62717a;
  font-weight: 500;
  font-size: 11px;
  width: 359px;
  margin-bottom: 5px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  margin-top: 15px; }

.emailField {
  width: 330px;
  height: 36px;
  border: none;
  background-color: #f7f7f7 !important;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px; }
  .emailField:hover {
    border: 'none'; }

.PhoneField, .inputField {
  width: 330px;
  height: 36px;
  border: none;
  background-color: #f7f7f7 !important;
  font-size: 15px !important;
  font-family: Raleway;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: #202020;
  border-radius: 6px;
  padding-left: 18px;
  padding-right: 15px; }
  .PhoneField:hover, .inputField:hover {
    border: 'none'; }

.phoneLabel, .fieldLabel {
  width: 357px;
  font-family: Raleway;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #62717a;
  margin-top: 10px;
  margin-bottom: 7px;
  padding-left: 15px; }
  @media only screen and (max-width: 767px) {
    .phoneLabel, .fieldLabel {
      padding-left: 0px; } }

.conFIrmformFields {
  margin-top: -18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 89%; }
  .conFIrmformFields span {
    font-family: Raleway; }

.emailDiv {
  display: table;
  margin: -45px 0px 0px 142px; }

.phoneDivBtn {
  display: table;
  margin: -3px 0px 0px 142px; }

.emailSentTo {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.87); }

.texSentTo {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.87); }

.signup__inner {
  margin-top: 10px;
  height: 540px;
  position: relative;
  bottom: 0; }

.profile_confirmation {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  background-color: #2497e2; }
  .profile_confirmation .textAndEmailButton {
    padding: 9px 0px 22px 0px; }
  .profile_confirmation:before {
    content: '';
    top: 0;
    left: 0;
    margin-left: -10%;
    width: calc(100% + 20%);
    height: 50%;
    position: absolute;
    background: #f0f0f0;
    border-radius: 0 0 100% 100% / 40%; }

.profile_confirmation__inner {
  margin-top: 10px;
  height: 540px;
  position: relative;
  bottom: 0; }

.profile_confirmation_form {
  margin: 50px 0px 25px 0px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
  padding: 20px 15px 30px 15px; }
  @media only screen and (min-width: 568px) {
    .profile_confirmation_form {
      padding: 20px 50px 30px 50px; } }

.edit_link {
  text-align: center; }

.profile_confirmation_heading {
  color: #36434b;
  font-size: 16px;
  padding: 0px 0px 5px 0px; }

.profile_confirmation_content {
  font-size: 14px;
  text-align: center;
  color: #62717a;
  font-weight: 500;
  line-height: 1.43; }

.email_phone {
  color: #2497e2;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal; }

.edit_link {
  text-decoration: none;
  color: #36434b;
  font-size: 14px; }

.contact_container {
  margin-top: 30px; }

@media only screen and (min-width: 568px) {
  .phone_container {
    padding-left: 78px; } }

@media only screen and (min-width: 568px) {
  .email_container {
    padding-right: 79px; } }

.email_edit {
  background-color: #f7f7f7 !important;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  height: 22px;
  font-size: 12px;
  width: 177px;
  text-align: center; }
  .email_edit:hover {
    border: 'none'; }
  .email_edit:focus {
    text-align: center;
    font-size: 12px; }

.phone_edit {
  background-color: #f7f7f7 !important;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  height: 22px;
  font-size: 12px;
  width: 177px;
  text-align: center; }
  .phone_edit:hover {
    border: 'none'; }
  .phone_edit:focus {
    text-align: center;
    font-size: 12px; }

@media only screen and (max-width: 411px) {
  .phone_edit_button, .phone_edit_back_button {
    padding-bottom: 15px; } }

.email_phone_edit_error {
  margin-bottom: -20px;
  color: #FF0000;
  font-size: 11px;
  text-align: center;
  width: 173px;
  padding-top: 6px; }

.icon {
  font-size: 90px;
  color: #e1e1e1;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center; }

.user-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden; }

.help {
  color: #757575;
  padding: 10px 0px 0px 25px;
  font-size: 13px; }

.instruction {
  padding: 0px 50px 30px 60px;
  text-align: left;
  font-size: 14px;
  color: #9a9a9a; }

.sendButton {
  padding-bottom: 24px;
  padding-top: 35px; }

.sentMail {
  margin-top: 106px; }

.resetPassword {
  padding-bottom: 20px; }

.error {
  color: #FF0000;
  font-size: 11px;
  text-align: left;
  width: 333px; }

.user-icon-profile {
  text-align: center;
  background-color: #f7f7f7;
  width: 90px;
  margin-bottom: 15px;
  border-radius: 50%; }
  .user-icon-profile .icon {
    font-size: 80px; }

.contact-us-link {
  text-align: center; }
  .contact-us-link p {
    color: #747474;
    font-weight: 600;
    font-size: 12px; }
  .contact-us-link a,
  .contact-us-link a:hover {
    color: #1397e1; }

.confirm.mobile-otp .logo {
  text-align: center; }
  .confirm.mobile-otp .logo img {
    width: 100px; }

.confirm.mobile-otp .phoneNumber,
.confirm.mobile-otp h1 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  padding: 0; }

.confirm.mobile-otp .otp-input-box {
  align-items: center;
  justify-content: center;
  padding: 20px; }

.confirm.mobile-otp .expire {
  padding-top: 10px; }

.confirm.mobile-otp input.otp-input {
  width: 40px !important;
  text-align: center;
  height: 40px;
  margin: 12px;
  border-radius: 4px;
  border: 1px solid #1397e1;
  padding: 0; }

.confirm.mobile-otp h2.need-help {
  text-align: center;
  color: #24a0f1;
  font-weight: 600; }

.confirm.mobile-otp .signup__inner {
  max-height: 400px; }

.confirm.mobile-otp .confirmationPadding {
  margin-top: 0px; }

.confirm.mobile-otp .confirm-email .yourBusiness {
  padding-bottom: 0px; }

.confirm.mobile-otp .blue-text {
  color: #1397e1;
  font-size: 14px;
  text-align: center;
  font-weight: 600; }

.confirm.mobile-otp h2.texSentTo {
  margin-top: 4px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px; }

.confirm.mobile-otp .min-wid-email {
  min-width: 480px !important;
  min-height: 348px !important; }

.need-help {
  display: block;
  padding: 50px;
  text-align: center;
  height: 320px;
  width: 435px; }

.help-container .need-help-block {
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
  height: 340px; }

.help-container .dark-text p {
  font-weight: 600;
  font-size: 17px;
  margin-top: 20px; }

.help-container .blue-text {
  color: #1397e1;
  font-size: 14px;
  text-align: center;
  font-weight: 600; }

.matter {
  font-family: Raleway !important; }

.venue_page {
  overflow-x: hidden;
  margin-top: 62px; }
  @media screen and (max-device-width: 500px) {
    .venue_page {
      margin-top: 66.5px; } }
  @media screen and (min-device-width: 501px) and (max-device-width: 1023px) {
    .venue_page {
      margin-top: 74px; } }
  .venue_page .aboutHead {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }
  .venue_page .aboutMatter {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    word-break: break-word;
    text-align: left;
    color: #62717a; }
  .venue_page .EventPlannerHeader-appbar-106 {
    color: #36434b;
    box-shadow: inset 0 -1px 0 0 #fff !important;
    background-color: #ffffff !important; }
  .venue_page .MuiTypography-body1-208 {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .venue_page .MuiTypography-h4-214 {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }
  .venue_page .leftPanel {
    width: 80%; }
    .venue_page .leftPanel .topImage {
      border: #000 1px solid;
      height: 394px; }
  .venue_page .rightPanel {
    width: 20%; }
    .venue_page .rightPanel .rightMap {
      border: #000 1px solid;
      height: 394px; }
  .venue_page .midContainer {
    margin: 25px; }
    .venue_page .midContainer .venue-available {
      width: 100%;
      font-family: Raleway !important; }
      .venue_page .midContainer .venue-available .avilableDescriptionDiv {
        width: 80%; }
        .venue_page .midContainer .venue-available .avilableDescriptionDiv .avilableDescription {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #62717a; }
      .venue_page .midContainer .venue-available .dayTimeDiv {
        width: 100%;
        color: #202020; }
        .venue_page .midContainer .venue-available .dayTimeDiv .mondFriDiv {
          width: 13%;
          float: left; }
        .venue_page .midContainer .venue-available .dayTimeDiv .satSunfDiv {
          width: 26%;
          float: left; }
      .venue_page .midContainer .venue-available .availabilitytext {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.12;
        letter-spacing: normal;
        text-align: left;
        color: #36434b; }
  .venue_page .spaceVanueDiv {
    width: 100%;
    min-height: 385px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff; }
  .venue_page .similarDiv {
    width: 100%; }
    .venue_page .similarDiv .similarDiv .active_icon_over_image {
      left: -44px; }
    .venue_page .similarDiv .similarDiv .active_icon_over_image {
      color: red;
      position: absolute;
      width: 100%;
      top: 2px; }
    .venue_page .similarDiv .similarContainer {
      width: 100%;
      padding: 10px; }
      .venue_page .similarDiv .similarContainer .similerText {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #36434b;
        padding-bottom: 10px; }
        @media screen and (max-width: 767px) {
          .venue_page .similarDiv .similarContainer .similerText {
            padding-bottom: 0px; } }
  .venue_page .venue-available-right {
    width: 98%; }
    .venue_page .venue-available-right h3 {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b; }
    .venue_page .venue-available-right .help_icon {
      padding: 12px 0px 12px;
      margin-left: -15px; }
      .venue_page .venue-available-right .help_icon button {
        background-color: transparent;
        border: 0px;
        box-shadow: none;
        display: block;
        padding: 0px; }
    .venue_page .venue-available-right .venueRightContainer {
      width: 97%;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff; }
      .venue_page .venue-available-right .venueRightContainer .spaceImage {
        width: 100%; }

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .venue_page .EventPlannerHeader-appbar-106 {
    color: #36434b;
    box-shadow: inset 0 -1px 0 0 #fff !important;
    background-color: #ffffff !important; }
  .venue_page .MuiTypography-body1-208 {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .venue_page .MuiTypography-h4-214 {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }
  .venue_page .leftPanel {
    width: 80%; }
    .venue_page .leftPanel .topImage {
      border: #000 1px solid;
      height: 394px; }
  .venue_page .rightPanel {
    width: 20%; }
    .venue_page .rightPanel .rightMap {
      border: #000 1px solid;
      height: 394px; }
  .venue_page .midContainer {
    margin: 14px;
    padding-left: 10px;
    padding-right: 10px; }
    .venue_page .midContainer .venue-available {
      width: 100%;
      font-family: Raleway !important; }
      .venue_page .midContainer .venue-available .avilableDescriptionDiv {
        width: 80%; }
        .venue_page .midContainer .venue-available .avilableDescriptionDiv .avilableDescription {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #62717a; }
      .venue_page .midContainer .venue-available .dayTimeDiv {
        width: 100%;
        color: #202020; }
        .venue_page .midContainer .venue-available .dayTimeDiv .mondFriDiv {
          width: 40%;
          float: left; }
        .venue_page .midContainer .venue-available .dayTimeDiv .satSunfDiv {
          width: 50%;
          float: left; }
      .venue_page .midContainer .venue-available .availabilitytext {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.12;
        letter-spacing: normal;
        word-break: break-word;
        text-align: left;
        color: #36434b; }
  .venue_page .spaceVanueDiv {
    width: 100%;
    min-height: 385px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff; }
  .venue_page .similarDiv {
    width: 100%; }
    .venue_page .similarDiv .similarContainer {
      width: 100%;
      padding: 10px; }
      .venue_page .similarDiv .similarContainer .similerText {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #36434b; }
  .venue_page .venue-available-right {
    width: 100%; }
    .venue_page .venue-available-right h3 {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      word-break: break-word;
      letter-spacing: normal;
      text-align: left;
      color: #36434b; }
    .venue_page .venue-available-right .venueRightContainer {
      width: 100%;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff; }
      .venue_page .venue-available-right .venueRightContainer .spaceImage {
        width: 100%; } }

.venue_space_venue_card {
  padding: 21px 8px; }

.similar_venue_card {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (max-width: 767px) {
    .similar_venue_card {
      padding: 10px;
      width: 100%; } }
  .similar_venue_card .venueCardWithFav .venue_image {
    width: 100%;
    transition: 200ms ease-in-out; }

.image-div {
  width: 100%;
  height: 393.8px;
  background-color: #f0f0f0; }
  @media screen and (max-width: 767px) {
    .image-div {
      height: auto; } }
  .image-div .no-image-div {
    margin: auto;
    height: 122px;
    width: 91px;
    background-image: url(https://www.first-hold.com/static/media/file-image-regular.5c792018.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
    background-clip: content-box, border-box; }

.space_details_image {
  position: relative; }
  .space_details_image .image_of_venue {
    width: 100%;
    height: 394px; }
  .space_details_image .venue-image {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .space_details_image .space_details_over_image {
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(6, 6, 6, 0.2));
    position: absolute;
    bottom: 0;
    /* attaches the element to the bottom */
    left: 0;
    /* attaches the element to the left-side */
    right: 0;
    /* attaches the element to the right-side */
    align-items: flex-end;
    padding: 20px 20px 20px 20px; }
    @media screen and (max-width: 767px) {
      .space_details_image .space_details_over_image {
        position: relative;
        background-image: none; } }
    .space_details_image .space_details_over_image .div1 {
      width: 45%; }
      .space_details_image .space_details_over_image .div1 .space_name_in_venue_details_image {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.33;
        text-align: left;
        text-align: left;
        word-break: break-word;
        color: #ffffff; }
        @media screen and (max-width: 767px) {
          .space_details_image .space_details_over_image .div1 .space_name_in_venue_details_image {
            color: #36434b; } }
      .space_details_image .space_details_over_image .div1 .venue_name {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        word-break: break-word;
        color: #ffffff; }
        @media screen and (max-width: 767px) {
          .space_details_image .space_details_over_image .div1 .venue_name {
            color: #36434b; } }
  .space_details_image .space_address_details_in_venue_details_image {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    width: 40%;
    height: auto;
    display: flex;
    align-items: flex-end; }
    @media screen and (max-width: 767px) {
      .space_details_image .space_address_details_in_venue_details_image {
        color: #36434b; } }
    .space_details_image .space_address_details_in_venue_details_image .icon_container {
      margin-top: 4px;
      padding-left: 23px; }
  .space_details_image .place_icon_in_space_details_image {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ee2738;
    margin-right: 5px; }
    @media screen and (max-device-width: 767px) {
      .space_details_image .place_icon_in_space_details_image {
        position: absolute;
        left: -24px;
        top: 8px; } }
  .space_details_image .view_photos {
    border: #ffffff 1px solid; }
  .space_details_image .view_photos_container {
    margin-left: 8px; }
    @media screen and (max-width: 500px) {
      .space_details_image .view_photos_container {
        padding: 10px 15px 10px 25px; } }
    .space_details_image .view_photos_container .white-button {
      padding: 5px 12px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.46px;
      color: #ee2738;
      cursor: pointer; }
      .space_details_image .view_photos_container .white-button span {
        margin: 0 !important;
        padding: 0 !important; }
  @media screen and (max-width: 767px) {
    .space_details_image .space_details_over_image {
      height: auto !important; }
      .space_details_image .space_details_over_image .div1 {
        padding-top: 5px;
        width: 100%; }
      .space_details_image .space_details_over_image .space_address_details_in_venue_details_image {
        width: 100%;
        padding: 5px 0 !important;
        margin-left: 20px;
        position: relative; }
      .space_details_image .space_details_over_image .view_photos_container {
        padding: 5px 0 !important;
        width: 100%; } }

.crop-the-image {
  display: block;
  color: #ee2738;
  font-size: 11px;
  position: absolute; }
  @media screen and (max-width: 767px) {
    .crop-the-image {
      top: 23px; } }

.error-copyright {
  position: absolute;
  top: 38px; }
  @media screen and (max-width: 767px) {
    .error-copyright {
      top: 75px; } }

.success_modal_container.venueprofilepopupui button {
  padding: 6px 10px; }

.gallery-modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2000; }

.gallery-modal .gallery-modal--overlay {
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 0; }

.gallery-modal--preload {
  display: none; }

.gallery-modal--container {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 1; }

.gallery-modal--table {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%; }

.gallery-modal--cell {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle; }

.gallery-modal--content {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  height: 100%;
  max-width: 100%; }

.gallery-content {
  display: table;
  width: 100%;
  height: 100%; }

.gallery-top {
  display: table-row; }

.gallery-top--inner {
  padding: 60px 15px 20px; }

.gallery {
  display: table;
  font-size: 1rem;
  width: 100%;
  padding-bottom: 10px; }

.gallery-modal .gallery {
  display: table-row;
  height: 100%;
  padding-bottom: 0; }

.gallery .gallery-main {
  position: relative;
  z-index: 9; }

.gallery .gallery-photos {
  width: 100%;
  max-width: 125vh;
  margin: 0px auto; }

.gallery .gallery-photo {
  position: relative;
  width: 100%;
  height: 0px;
  padding-bottom: 75%; }

.gallery .gallery-photos .gallery-empty {
  color: #ffffff;
  font-size: 1.45rem;
  display: block;
  text-align: center;
  padding: 25% 0; }

.gallery .gallery-photo--current {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%; }

.gallery ul.gallery-images--ul {
  position: relative;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0; }

.gallery ul.gallery-images--ul li.gallery-media-photo {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  z-index: 2; }

.gallery ul.gallery-images--ul li.gallery-media-photo,
.gallery ul.gallery-images--ul li.gallery-media-photo div.picture {
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.gallery ul.gallery-images--ul li.gallery-media-photo button.photo-button {
  display: block;
  background: none;
  border-width: 0;
  cursor: pointer;
  padding: 0 !important;
  -webkit-appearance: button; }

.gallery ul.gallery-images--ul li.gallery-media-photo button.photo-button:focus,
.gallery ul.gallery-images--ul li.gallery-media-photo button.photo-button:active {
  outline: none;
  border-width: 0; }

.gallery ul.gallery-images--ul li.gallery-media-photo div.picture img.photo {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  z-index: 0;
  transform: translateY(-50%); }

.gallery .gallery-control {
  cursor: pointer;
  position: absolute;
  top: 0px;
  height: 100%;
  z-index: 3;
  font-size: 0.5em;
  width: 85px;
  background: none;
  border-width: 0px;
  border-style: none;
  border-style: initial;
  border-color: currentColor;
  border-color: initial;
  border-image: none;
  border-image: initial; }

.gallery .gallery-control--prev {
  left: 0; }

.gallery .gallery-control--next {
  right: 0; }

.gallery-figcaption {
  background-image: none;
  background-color: #000000;
  position: relative;
  top: 8%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  text-align: center;
  color: white;
  overflow: hidden;
  border-radius: 0; }

.mode-light .gallery-figcaption {
  color: #111111; }

.gallery-figcaption--content {
  max-width: 105vh;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; }

.gallery-figcaption--inner {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transform: translateY(0);
  transition: transform 0.2s ease-out 0s; }

.hide .gallery-figcaption--inner {
  transform: translateY(70px); }

.gallery-figcaption--info {
  padding: 8px;
  display: table;
  width: 100%; }

.gallery-figcaption--info .caption-left {
  text-align: left;
  vertical-align: middle;
  display: table-cell;
  height: 40px; }
  @media screen and (max-width: 767px) {
    .gallery-figcaption--info .caption-left {
      text-align: center; } }

.gallery-figcaption--info .caption-right {
  text-align: right;
  vertical-align: middle;
  display: none; }

.gallery-figcaption--info .photo-caption,
.gallery-figcaption--info .photo-subcaption {
  margin: 0;
  line-height: 1.31; }

.gallery-figcaption--info .photo-caption {
  text-transform: capitalize;
  color: #1397e1;
  font-weight: 600;
  font-size: 1rem; }

.gallery-figcaption--info .photo-subcaption {
  color: rgba(255, 255, 255, 0.65);
  font-size: 0.851rem; }

.mode-light .gallery-figcaption--info .photo-subcaption {
  color: rgba(0, 0, 0, 0.65); }

.gallery-figcaption--thumbnails {
  position: relative;
  height: 67px;
  overflow: hidden; }

.gallery-figcaption--thumbnails .caption-thumbnails {
  position: absolute; }

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  left: 0;
  transition: margin 0.3s ease-out 0s; }

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list::before,
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list::after {
  content: "";
  display: table; }

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list::after {
  clear: both; }

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li {
  float: left;
  background-color: black;
  padding: 8px; }

.mode-light .gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li {
  background-color: white; }

/*.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li + li {
  //margin-left: 10px; 
}*/
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button {
  cursor: pointer;
  position: relative;
  display: block;
  vertical-align: bottom;
  overflow: hidden;
  background-color: #bbb;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button div.loading-spinner {
  position: absolute;
  z-index: 1001;
  width: 2em;
  height: 2em;
  top: 50%;
  left: 50%;
  margin: -1em 0 0 -1em;
  border-top-width: 0.15em;
  border-right-width: 0.15em;
  border-bottom-width: 0.15em;
  border-left-width: 0.15em;
  transform: translate(-50%, -50%); }

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button.active {
  cursor: default; }

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li img.thumbnail {
  width: 100px;
  height: 67px;
  display: block;
  border-width: 0;
  position: relative;
  z-index: 1;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: 0.1s ease opacity; }

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button.active img,
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button:hover img {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100); }

.gallery-figcaption .gallery-thumbnails--toggle {
  cursor: pointer;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  display: inline-block;
  color: inherit;
  font-weight: inherit;
  position: relative; }

.gallery-figcaption .gallery-thumbnails--toggle::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.181rem; }

.gallery-figcaption .gallery-thumbnails--toggle.hide::after {
  border-top: 6px solid #ffffff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent; }

.mode-light .gallery-figcaption .gallery-thumbnails--toggle.hide::after {
  border-top-color: #111111; }

.gallery-figcaption .gallery-thumbnails--toggle.open::after {
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent; }

.mode-light .gallery-figcaption .gallery-thumbnails--toggle.open::after {
  border-bottom-color: #111111; }

.gallery-modal--close {
  position: absolute;
  z-index: 10;
  right: 0px;
  top: 0px;
  padding: 15px; }

.gallery-modal--close button.gallery-close {
  cursor: pointer;
  background: none transparent;
  color: 'buttontext';
  border: 0;
  display: block;
  padding: 0.5rem; }

div.loading-spinner,
div.loading-spinner::after,
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button::before,
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button::after {
  border-radius: 50%; }

div.loading-spinner,
div.loading-spinner::after {
  width: 6em;
  height: 6em; }

div.loading-spinner,
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button::after {
  font-size: 10px;
  text-indent: -9999em;
  transform: translateZ(0);
  -webkit-animation: GalleryLoadingSpinner 1.1s infinite linear;
  animation: GalleryLoadingSpinner 1.1s infinite linear; }

div.loading-spinner {
  margin: 60px auto;
  position: relative;
  border-top: 0.35em solid rgba(255, 255, 255, 0.2);
  border-right: 0.35em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.35em solid rgba(255, 255, 255, 0.2);
  border-left: 0.35em solid #ffffff; }

.mode-light div.loading-spinner {
  border-top-color: rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-left-color: #111111; }

@-webkit-keyframes GalleryLoadingSpinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes GalleryLoadingSpinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.gallery ul.gallery-images--ul li.gallery-media-photo.loading::before {
  z-index: 999;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black; }

.mode-light .gallery ul.gallery-images--ul li.gallery-media-photo.loading::before {
  background-color: white; }

.gallery ul.gallery-images--ul li.gallery-media-photo.loading div.loading-spinner,
.gallery ul.gallery-images--ul li.gallery-media-photo div.picture.loading div.loading-spinner {
  position: absolute;
  z-index: 1001;
  top: 50%;
  left: 50%;
  margin: -1rem 0 0 -1rem;
  /* visually centered */
  transform: translate(-50%, -50%); }

/* gallery animation */
.gallery__animation-appear {
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  transform: translateZ(0); }

.gallery__animation-appear-active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  transition: opacity 150ms ease-out; }

.gallery__image-transition--backwards-enter {
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  transform: translateX(-5px); }

.gallery__image-transition--forwards-enter {
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  transform: translateX(5px); }

.gallery__image-transition--backwards-enter-active,
.gallery__image-transition--forwards-enter-active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  transform: translateX(0);
  transition: opacity 150ms ease-out, transform 150ms ease-out; }

.gallery__image-transition--backwards-leave,
.gallery__image-transition--forwards-leave {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  transform: translateZ(0); }

.gallery__image-transition--backwards-leave-active,
.gallery__image-transition--forwards-leave-active {
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  transition: opacity 150ms ease-out; }

@media (max-width: 743px) {
  .gallery-figcaption--info {
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem; }
  .gallery-figcaption .gallery-thumbnails--toggle {
    display: none; } }

@media (min-width: 744px) {
  .gallery .gallery-photo {
    padding-bottom: 67%; }
  .gallery-figcaption--info {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; } }

@media (min-width: 1128px) {
  .gallery .gallery-control {
    width: 20%;
    font-size: medium; } }

/***
* == Gallery Style
***/
button.gallery-thumbnails--toggle {
  text-transform: uppercase !important;
  font-weight: bold !important; }

img.thumbnail.media-image {
  width: 121px !important;
  height: 77px !important; }

ul.thumbnails-list {
  margin-left: 0px !important;
  width: 100% !important; }

img.thumbnail.media-image {
  width: 136px !important;
  height: auto !important; }

div.caption-thumbnails {
  width: 100% !important; }

div.gallery-top--inner {
  padding: 0px 15px 20px; }

div.gallery-modal--overlay {
  opacity: 0.9 !important; }

.gallery .gallery-control--prev {
  background-position: center center !important;
  left: 0 !important;
  background-image: url(https://www.first-hold.com/static/media/arrow-left-circle.e672e715.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 20% !important; }
  .gallery .gallery-control--prev svg {
    opacity: 0; }

.gallery .gallery-control--next {
  background-position: center center !important;
  right: 0 !important;
  background-image: url(https://www.first-hold.com/static/media/arrow-right-circle.560e5c48.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 20% !important; }
  .gallery .gallery-control--next svg {
    opacity: 0; }

.gallery .gallery-photo {
  padding-bottom: 55% !important; }

button.gallery-thumbnails--toggle {
  font-weight: normal !important; }

.hide .gallery-figcaption--inner {
  transform: translateY(0px) !important; }

.hide .gallery-figcaption--thumbnails {
  transform: translateY(70px) !important; }

.gallery-figcaption--thumbnails {
  overflow: auto;
  height: 185px; }
  @media screen and (max-width: 767px) {
    .gallery-figcaption--thumbnails {
      height: 98px;
      overflow: hidden;
      width: 97%;
      margin-left: auto;
      margin-right: auto;
      overflow-y: auto; } }
  @media screen and (max-width: 500px) {
    .gallery-figcaption--thumbnails {
      height: 290px; } }

img.thumbnail.media-image {
  height: 60px !important;
  object-fit: cover; }
  @media screen and (max-width: 767px) {
    img.thumbnail.media-image {
      width: 56px !important;
      height: 30px !important;
      object-fit: cover; } }

ul.thumbnails-list {
  /*li{
  margin-bottom: 20px;
  @media screen and (max-width:767px) {
    margin-bottom: 20px;
  }
}*/ }
  @media screen and (max-width: 767px) {
    ul.thumbnails-list {
      margin-left: 20px !important;
      margin-right: 20px !important; } }

.gallery-figcaption--content {
  max-width: 96%; }

.edit_venue_image {
  padding: 0px 10px; }
  .edit_venue_image li {
    list-style-type: none; }
  .edit_venue_image .image_name {
    text-align: center; }
  .edit_venue_image .error {
    text-align: center;
    color: #FF0000;
    font-size: 11px; }
  .edit_venue_image .name_and_progess_bar_container {
    background-color: #d8d8d8;
    padding: 55px 15px;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .edit_venue_image .name_and_progess_bar_container {
        text-align: center;
        display: block; } }
  .edit_venue_image .drag_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 56px;
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .edit_venue_image .drag_icon_container {
        padding-left: 12px; } }
  @media screen and (max-width: 767px) {
    .edit_venue_image .delete_icon_container {
      text-align: center;
      width: 100%;
      min-width: 100%;
      justify-content: center;
      display: flex;
      max-width: 100%;
      align-items: center;
      position: relative; } }
  .edit_venue_image .delete_icon_container button {
    background-color: transparent;
    border: none;
    color: #36434b;
    display: inline-block; }
    @media screen and (max-width: 767px) {
      .edit_venue_image .delete_icon_container button {
        padding-left: 0px;
        min-width: 42px;
        padding-top: 11px; } }
  .edit_venue_image .delete_icon_container .venue-gallery {
    width: auto;
    display: inline-block;
    margin-right: 10px; }
    @media screen and (max-width: 991px) {
      .edit_venue_image .delete_icon_container .venue-gallery {
        margin-right: 15px;
        padding-top: 2px; } }
  @media screen and (max-width: 767px) {
    .edit_venue_image .delete_icon_container a {
      display: block; } }
  .edit_venue_image .delete_icon_container span {
    cursor: pointer;
    margin-left: 10px; }
    @media screen and (max-width: 991px) {
      .edit_venue_image .delete_icon_container span {
        margin-left: 0px;
        padding-top: 2px; } }
    @media screen and (max-width: 767px) {
      .edit_venue_image .delete_icon_container span {
        display: block; } }
  .edit_venue_image .image_details {
    border: solid 1px #f0f0f0;
    padding-bottom: 16px;
    padding-top: 11px;
    margin: 5px;
    width: 98%;
    align-items: center; }
    .edit_venue_image .image_details .custom_input {
      padding: 4px; }
      .edit_venue_image .image_details .custom_input input {
        text-align: left;
        border-radius: 6px;
        border: solid 1px #e1e1e1;
        background-color: #f7f7f7;
        height: 34px;
        width: 192px;
        padding: 0px 15px;
        margin-top: 0px;
        font-size: 12px;
        line-height: 1.07;
        text-align: left;
        color: #202020; }
        .edit_venue_image .image_details .custom_input input::-webkit-input-placeholder {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.07;
          letter-spacing: normal;
          text-align: left;
          color: #b0b0b0; }
        .edit_venue_image .image_details .custom_input input::-ms-input-placeholder {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.07;
          letter-spacing: normal;
          text-align: left;
          color: #b0b0b0; }
        .edit_venue_image .image_details .custom_input input::placeholder {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.07;
          letter-spacing: normal;
          text-align: left;
          color: #b0b0b0; }
  .edit_venue_image .v_image_container {
    display: flex;
    align-items: center;
    justify-content: center; }
  .edit_venue_image .venue_image {
    width: 140px;
    height: auto;
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .edit_venue_image .venue_image {
        width: 100%; } }

.file_type_field {
  display: none; }

.sortable-list {
  padding-left: 0px !important; }

.draggable {
  margin: 10px; }

.dragged {
  opacity: 0.7; }

.item {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #ffffff; }

.contact-dialog-container {
  padding: 20px; }
  .contact-dialog-container p {
    width: 100%; }
  .contact-dialog-container .heading-1 {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    color: #1397e1;
    text-transform: uppercase; }
  .contact-dialog-container .heading-2 {
    margin-top: 8px;
    font-size: 16px;
    text-align: center; }
  .contact-dialog-container .left-container {
    padding-right: 8px; }
    @media screen and (max-width: 800px) {
      .contact-dialog-container .left-container {
        padding-right: 0; } }
  .contact-dialog-container .bottomSpacing {
    margin-top: 8px; }
  .contact-dialog-container .button-box {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .contact-dialog-container .button-box .contact-btn {
      background-color: #ee2738;
      font-weight: 600; }
  .contact-dialog-container .select_field {
    padding: 8px 12px !important;
    border-radius: 6px;
    border: solid 1px #f7f7f7;
    background-color: #f7f7f7 !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    text-align: left;
    text-transform: capitalize;
    width: 100%; }
    .contact-dialog-container .select_field option {
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #62717a;
      border: 0;
      border: 0;
      padding: 8px !important;
      min-height: 50px !important;
      height: 30px !important;
      padding: 0px 12px; }
  .contact-dialog-container .inputField {
    width: 100%;
    font-size: 12px; }
    .contact-dialog-container .inputField::-webkit-input-placeholder {
      font-size: 12px;
      line-height: 1.67; }
  .contact-dialog-container .error {
    width: auto; }
  .contact-dialog-container .label {
    font-size: 12px;
    padding: 4px 0 0 0 !important; }
  @media screen and (max-width: 620px) {
    .contact-dialog-container .select_field,
    .contact-dialog-container .inputField {
      width: 320px; } }
  @media screen and (max-width: 450px) {
    .contact-dialog-container .select_field,
    .contact-dialog-container .inputField {
      width: 200px; }
    .contact-dialog-container .bottomSpacing iframe {
      width: 210px;
      border: 1px solid #d3d3d3; }
    .contact-dialog-container .bottomSpacing > div > div > div {
      width: 210px !important;
      height: 100px !important; }
    .contact-dialog-container .rc-anchor {
      width: 200px !important; } }
  @media screen and (max-width: 340px) {
    .contact-dialog-container .select_field,
    .contact-dialog-container .inputField {
      max-width: 160px;
      width: 100%; }
    .contact-dialog-container .bottomSpacing iframe {
      width: 160px;
      border: 1px solid #d3d3d3; }
    .contact-dialog-container .bottomSpacing > div > div > div {
      width: 160px !important;
      height: 100px !important; }
    .contact-dialog-container .rc-anchor {
      width: 200px !important; } }

.matter {
  font-family: Raleway !important; }

::-webkit-scrollbar-thumb {
  background: none !important;
  border-radius: 0; }

@media screen and (max-width: 767px) {
  .disp-none-sm {
    display: none; } }

.venue-profile-edit {
  overflow-x: hidden;
  padding-left: 10px;
  font-family: Raleway;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal; }
  @media screen and (max-width: 767px) {
    .venue-profile-edit {
      padding-left: 0px; } }
  .venue-profile-edit .mobile-pdf {
    display: block;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .venue-profile-edit .mobile-pdf {
        display: none; } }
    .venue-profile-edit .mobile-pdf .grid-section-vertical {
      overflow-x: hidden; }
  @media screen and (max-width: 767px) {
    .venue-profile-edit .wid-100 {
      width: 100% !important; } }
  .venue-profile-edit .blue-heading {
    color: #1397e1 !important;
    margin: 20px 0 0 0; }
  .venue-profile-edit .left-side {
    width: 50%;
    text-align: left;
    float: left;
    padding-top: 8px; }
  .venue-profile-edit .right-side {
    width: 50%;
    text-align: right;
    float: left; }
  .venue-profile-edit .black-heading {
    width: 90%;
    float: left;
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0; }
  .venue-profile-edit .right-container {
    width: 98% !important; }
  .venue-profile-edit .textarea {
    width: 100%;
    height: 120px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    padding: 12px;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    margin: 10px 0 !important; }
  .venue-profile-edit .textarea-small {
    width: 352px;
    height: 90px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7; }
  .venue-profile-edit .rectangle {
    width: 100%;
    height: auto;
    border: solid 1px #f0f0f0 !important;
    background-color: #ffffff;
    padding: 12px;
    margin: 0;
    box-shadow: none;
    margin: 0 !important; }
    @media screen and (max-width: 767px) {
      .venue-profile-edit .rectangle {
        padding: 12px 12px 0px; } }
    .venue-profile-edit .rectangle .amenities {
      width: 508px;
      background-color: var(--white); }
      @media screen and (max-width: 767px) {
        .venue-profile-edit .rectangle .amenities {
          width: 100%; } }
  .venue-profile-edit .expansionPanel {
    border: none;
    border-radius: 0;
    width: 100%;
    box-shadow: none;
    position: inherit;
    margin: 0; }
  .venue-profile-edit .expansionPanelSummary {
    padding: 0;
    margin: 0;
    min-height: 42px; }
    .venue-profile-edit .expansionPanelSummary div {
      padding: 0;
      margin: 20px 0 0 0;
      float: left;
      display: block; }
    .venue-profile-edit .expansionPanelSummary div[role=button] {
      margin: 10px 0 0 0; }
  .venue-profile-edit .grid-section {
    width: 50%;
    float: left; }
  .venue-profile-edit .grid-section-vertical {
    width: 100%;
    padding: 10px 0; }
  .venue-profile-edit .check-box {
    width: 15px;
    height: 15px;
    background-color: #9aa7af; }
  .venue-profile-edit .checkbox-heading {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: black;
    margin: 5px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5; }
  .venue-profile-edit .check-box-label {
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: #62717a;
    margin: 5px; }
  .venue-profile-edit .check-box-div {
    float: left;
    width: 100%;
    height: auto; }
    .venue-profile-edit .check-box-div .custom .big {
      margin-bottom: 15px; }
    .venue-profile-edit .check-box-div span {
      padding: 0px;
      color: #9aa7af; }
  .venue-profile-edit .grey-text {
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: #62717a; }
  .venue-profile-edit .other-info-label {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.45;
    text-align: left;
    color: #62717a;
    float: left;
    display: block;
    padding: 10px 10px 10px 0px; }
    @media screen and (max-width: 767px) {
      .venue-profile-edit .other-info-label {
        margin-bottom: 12px;
        padding: 0px; } }
  .venue-profile-edit .input-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    padding-left: 5px; }
  .venue-profile-edit .big {
    width: auto;
    padding: 10px 15px; }
    @media screen and (max-width: 767px) {
      .venue-profile-edit .big {
        width: 100%;
        display: block; } }
  .venue-profile-edit .small {
    width: 90px; }
  .venue-profile-edit select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    font-weight: normal;
    padding: 5px;
    -webkit-appearance: menulist;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: black;
    padding-left: 5px; }
  .venue-profile-edit .grid-section-small {
    float: left;
    margin-right: 20px; }
  .venue-profile-edit .white-button {
    width: 100px;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738; }
    @media screen and (max-width: 767px) {
      .venue-profile-edit .white-button {
        margin-top: 12px; } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
    .venue-profile-edit .venue-profile-edit .input-field {
      font-size: 12px; }
    .venue-profile-edit .grid-section {
      width: 100%;
      float: left; }
    .venue-profile-edit .grid-section-small {
      float: left;
      margin-right: 10px;
      width: 20%; }
      .venue-profile-edit .grid-section-small .input-field {
        width: 100%; }
    .venue-profile-edit .venue-space-edit {
      padding-left: 10px; } }

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .venue-profile-edit .input-field {
    font-size: 12px; }
  .venue-profile-edit select.select-field {
    font-size: 12px; } }

.desktop-view {
  display: block;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .desktop-view {
      display: none; } }

.exp {
  position: relative; }
  .exp .exp_warning {
    position: absolute;
    z-index: 10;
    top: 10px; }

.matter {
  font-family: Raleway !important; }

::-webkit-scrollbar-thumb {
  background: none !important;
  border-radius: 0; }

.document_upload_file {
  opacity: 0;
  display: none; }

.space-profile-edit {
  overflow-x: hidden;
  padding-left: 10px;
  font-family: Raleway;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal; }
  @media screen and (max-width: 767px) {
    .space-profile-edit {
      padding-left: 0px; } }
  @media screen and (max-width: 767px) {
    .space-profile-edit .wid-100 {
      width: 100% !important; } }
  .space-profile-edit .blue-heading {
    color: #1397e1 !important;
    margin: 20px 0 0 0; }
  .space-profile-edit .left-side {
    width: 50%;
    text-align: left;
    float: left; }
    @media screen and (max-width: 767px) {
      .space-profile-edit .left-side {
        padding-top: 5px; } }
  .space-profile-edit .right-side {
    width: 50%;
    text-align: right;
    float: left; }
  .space-profile-edit .black-heading {
    width: 100%;
    float: left;
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0; }
  .space-profile-edit .right-container {
    width: 98% !important; }
  .space-profile-edit .seatindAndCapacityGrid {
    max-width: 100% !important;
    padding-bottom: 0px;
    position: relative; }
    .space-profile-edit .seatindAndCapacityGrid .prev-arrow {
      cursor: pointer; }
    .space-profile-edit .seatindAndCapacityGrid .prev-arrow:hover {
      color: black; }
    .space-profile-edit .seatindAndCapacityGrid .next-arrow {
      cursor: pointer; }
  .space-profile-edit .textarea {
    width: 100%;
    height: 120px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    padding: 12px;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    margin: 10px 0 !important; }
  .space-profile-edit .textarea-small {
    width: 352px;
    height: 90px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7; }
  .space-profile-edit .rectangle {
    width: 100%;
    height: auto;
    border-radius: 6px;
    border: solid 1px #f0f0f0 !important;
    background-color: #ffffff;
    padding: 12px;
    margin: 0;
    box-shadow: none;
    margin: 0 !important;
    overflow-y: auto;
    overflow-x: hidden; }
    .space-profile-edit .rectangle .amenities {
      width: 508px;
      background-color: var(--white); }
  .space-profile-edit .expansionPanel {
    border: none;
    border-radius: 0;
    width: 100%;
    box-shadow: none;
    position: inherit;
    margin: 0; }
  .space-profile-edit .expansionPanelSummary {
    padding: 0;
    margin: 0;
    min-height: 65px; }
    .space-profile-edit .expansionPanelSummary div {
      padding: 0;
      margin: 20px 0 0 0;
      float: left;
      display: block; }
    .space-profile-edit .expansionPanelSummary div[role=button] {
      margin: 10px 0 0 0; }
  .space-profile-edit .grid-section {
    width: 50%;
    float: left; }
  .space-profile-edit .grid-section-vertical {
    width: 100%;
    padding: 10px 0;
    overflow: auto; }
  .space-profile-edit .check-box {
    width: 15px;
    height: 15px;
    background-color: #9aa7af; }
  .space-profile-edit .checkbox-heading {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: black;
    margin: 5px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5; }
  .space-profile-edit .check-box-label {
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: #62717a;
    margin: 5px; }
  .space-profile-edit .check-box-div {
    float: left;
    width: 100%;
    height: 25px; }
    .space-profile-edit .check-box-div span {
      padding: 0px;
      color: #9aa7af; }
  .space-profile-edit .grey-text {
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: #62717a; }
  .space-profile-edit .other-info-label {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.45;
    text-align: left;
    color: #62717a; }
  .space-profile-edit .input-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    padding-left: 5px; }
  .space-profile-edit .big {
    width: 250px; }
  .space-profile-edit .small {
    width: 90px; }
    @media screen and (max-width: 767px) {
      .space-profile-edit .small {
        width: auto; } }
  .space-profile-edit select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    font-weight: normal;
    padding: 5px;
    -webkit-appearance: menulist;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: black;
    padding-left: 5px; }
  .space-profile-edit .grid-section-small {
    float: left;
    margin-right: 20px; }
    .space-profile-edit .grid-section-small .error {
      position: absolute; }
  .space-profile-edit .white-button {
    width: 100px;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738; }
    @media screen and (max-width: 767px) {
      .space-profile-edit .white-button {
        margin-top: 0px; } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
    .space-profile-edit .grid-section {
      width: 100%;
      float: left; }
    .space-profile-edit .grid-section-small {
      float: left;
      margin-right: 10px;
      width: 20%; }
      .space-profile-edit .grid-section-small .input-field {
        width: 100%; }
    .space-profile-edit .venue-space-edit {
      padding-left: 10px; } }

.MuiDialog-paperWidthSm {
  max-width: 480px; }

.edit-pdf-dialog-container {
  padding: 24px; }
  .edit-pdf-dialog-container p {
    width: 100%; }
  .edit-pdf-dialog-container .heading-1 {
    font-size: 18px;
    text-align: center;
    font-weight: 500; }
  .edit-pdf-dialog-container .heading-2 {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 600;
    text-align: center; }
  .edit-pdf-dialog-container .bottom-heading {
    margin: 16px 0;
    font-size: 14px;
    text-align: center; }
  .edit-pdf-dialog-container .bottom-link {
    margin: 4px 0 4px;
    font-size: 14px;
    text-align: center;
    font-weight: 600; }
    .edit-pdf-dialog-container .bottom-link a {
      color: #1397e1; }
  .edit-pdf-dialog-container .login-container .button-box {
    margin-top: 18px; }
    .edit-pdf-dialog-container .login-container .button-box .sign-btn {
      background-color: #ee2738;
      font-weight: 700;
      font-size: 16px; }
  .edit-pdf-dialog-container .login-container .input-box {
    margin-top: 12px; }
    .edit-pdf-dialog-container .login-container .input-box label {
      font-size: 14px;
      font-weight: 600;
      padding-left: 4px; }
    .edit-pdf-dialog-container .login-container .input-box .inputField {
      width: 100%; }

.select_field {
  padding: 8px 12px !important;
  border-radius: 6px;
  border: solid 1px #f0f0f0;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  text-align: left;
  text-transform: uppercase; }
  .select_field .category_option {
    text-transform: none; }
  .select_field option {
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #62717a;
    border: 0;
    border: 0;
    padding: 8px !important;
    min-height: 50px !important;
    height: 30px !important;
    padding: 0px 12px; }

.customized-select {
  position: relative;
  background: #f7f7f7; }
  .customized-select:before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 3px;
    right: 5px;
    color: #62717a;
    z-index: 999; }

.video-dialog-container {
  padding: 24px; }
  .video-dialog-container p {
    width: 100%; }
  .video-dialog-container .heading-1 {
    font-size: 18px;
    text-align: center;
    font-weight: 500; }
  .video-dialog-container .heading-2 {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 600;
    text-align: center; }
  .video-dialog-container .bottom-heading {
    margin: 16px 0;
    font-size: 14px;
    text-align: center; }
  .video-dialog-container .bottom-link {
    margin: 4px 0;
    font-size: 14px;
    text-align: center;
    font-weight: 600; }
    .video-dialog-container .bottom-link a {
      color: #1397e1; }
  .video-dialog-container .v-container .button-box {
    margin-top: 18px; }
    .video-dialog-container .v-container .button-box .sign-btn {
      background-color: #ee2738;
      font-weight: 700;
      font-size: 16px; }
  .video-dialog-container .v-container .input-label {
    margin-left: 4px; }
  .video-dialog-container .v-container .input-box {
    margin-bottom: 8px; }
    .video-dialog-container .v-container .input-box label {
      font-size: 14px;
      font-weight: 600;
      padding-left: 4px; }
    .video-dialog-container .v-container .input-box .inputField {
      width: 100%; }
    .video-dialog-container .v-container .input-box .hint {
      font-size: 11px;
      padding-left: 8px; }

.add-videos .grid-section-vertical h3 {
  color: #62717a;
  font-weight: bold;
  padding: 10px 10px 10px 0; }

.add-videos .grid-section-vertical p {
  font-size: 14px;
  font-weight: 400;
  padding-left: 4px;
  color: #ee2738; }

.add-videos .coupon-list-container {
  margin-top: 4px;
  min-width: 300px;
  max-width: 300px; }
  .add-videos .coupon-list-container ul {
    width: 100%; }
    .add-videos .coupon-list-container ul li .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6px; }
    .add-videos .coupon-list-container ul li .c-text {
      padding: 12px; }
      .add-videos .coupon-list-container ul li .c-text h3 {
        text-transform: capitalize;
        font-weight: 500; }
    .add-videos .coupon-list-container ul li .action-icon button {
      padding: 2px; }
      .add-videos .coupon-list-container ul li .action-icon button .redIcon {
        color: #ee2738; }
    .add-videos .coupon-list-container ul li .blueIcon {
      color: #1397e1;
      font-size: 30px; }

.grid-section-vertical .select_field {
  -webkit-appearance: auto; }

.matter {
  font-family: Raleway !important; }

.venue-attraction-edit {
  font-family: Raleway;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal; }
  .venue-attraction-edit .custom_input {
    display: block;
    width: 100%;
    margin-bottom: 5px; }
    .venue-attraction-edit .custom_input input {
      min-width: 100%;
      margin-bottom: 5px !important; }
    .venue-attraction-edit .custom_input ::-webkit-input-placeholder {
      opacity: 0.5; }
    .venue-attraction-edit .custom_input ::-ms-input-placeholder {
      opacity: 0.5; }
    .venue-attraction-edit .custom_input ::placeholder {
      opacity: 0.5; }
  .venue-attraction-edit .blue-heading {
    color: #1397e1 !important;
    margin: 20px 0 0; }
  .venue-attraction-edit .black-heading {
    color: #36434b !important;
    margin: 0px 0px 13px; }
  .venue-attraction-edit .bordered {
    width: 100% !important; }

.venueCardWithFav a {
  text-decoration: none; }
  .venueCardWithFav a:hover {
    text-decoration: none; }

.venueCardWithFav .venue {
  position: relative; }
  @media screen and (max-width: 767px) {
    .venueCardWithFav .venue {
      width: 100%;
      padding: 5px 0px; } }
  .venueCardWithFav .venue .venue_content {
    padding-top: 0px;
    padding-bottom: 10px; }
  .venueCardWithFav .venue .venue_name {
    font-size: 14px;
    line-height: 1.71;
    color: #202020; }
  .venueCardWithFav .venue .venue_address {
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left; }
  .venueCardWithFav .venue .input_address {
    padding-top: 4px;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    color: #1397e1;
    text-transform: uppercase; }
  .venueCardWithFav .venue .place_icon {
    font-size: 12px !important;
    padding-top: 1px; }
  .venueCardWithFav .venue .overlay_icon {
    position: absolute;
    width: 100% !important; }
    @media screen and (max-width: 767px) {
      .venueCardWithFav .venue .overlay_icon {
        width: 90% !important; } }
  @media screen and (max-width: 500px) {
    .venueCardWithFav .venue .venue_image {
      width: 100% !important;
      height: auto !important; } }
  .venueCardWithFav .venue .venue_image:hover {
    box-shadow: 0 9px 8px 0 rgba(128, 127, 127, 0.7);
    transition: 300ms ease-in-out; }

.venueCardWithFav .venue_address {
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #36434b; }

.venueCardWithFav .venue_city {
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #1397e1; }

.venue_space_venue_card .venue_image {
  width: 100% !important;
  object-fit: cover;
  height: auto !important; }

.matter {
  font-family: Raleway !important; }

.venue-space-edit {
  overflow-x: hidden;
  font-style: normal;
  font-stretch: normal;
  line-height: normal; }
  .venue-space-edit .black-heading {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0;
    float: left; }
  .venue-space-edit .add-spaces-button {
    width: 50%;
    text-align: right;
    float: right;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.46px;
    text-align: right;
    color: #ee2738;
    font-family: raleway; }
  .venue-space-edit .venue-space-empty {
    width: 47%;
    height: 84.4px;
    background-color: #f0f0f0;
    margin: 5px; }
    .venue-space-edit .venue-space-empty img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .venue-space-edit .rectangle {
    width: 100%;
    height: auto;
    border-radius: 6px;
    border: solid 1px #f0f0f0 !important;
    background-color: #ffffff;
    padding: 12px;
    box-shadow: none;
    margin: 10px 0 !important; }
    .venue-space-edit .rectangle .amenities {
      width: 508px;
      background-color: var(--white); }
  .venue-space-edit .venue-space-edit-inner-container {
    width: 100%;
    position: relative; }
    .venue-space-edit .venue-space-edit-inner-container .block {
      display: block;
      width: 50%; }
      .venue-space-edit .venue-space-edit-inner-container .block .material-icons {
        color: #1397e1;
        margin-left: -5px; }
    .venue-space-edit .venue-space-edit-inner-container .add-space {
      position: absolute;
      right: 0px; }
      @media screen and (max-width: 767px) {
        .venue-space-edit .venue-space-edit-inner-container .add-space {
          top: 12px; } }
  .venue-space-edit .add-space-button {
    width: 100px;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738;
    border: 0 !important;
    font-family: raleway;
    cursor: pointer;
    text-decoration: none; }

.venue_space_venue_card_single {
  width: 100%;
  padding: 8px 8px; }

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .venue-space-edit {
    padding-left: 10px; }
  .venue-space-edit-inner-container {
    width: 100% !important; } }

.matter {
  font-family: Raleway !important; }

.venue-address-edit {
  overflow-x: hidden;
  font-family: Raleway;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal; }
  .venue-address-edit .blue-heading {
    color: #1397e1 !important;
    margin: 20px 0 0; }
  .venue-address-edit .black-heading {
    color: #36434b !important;
    margin: 20px 0;
    font-weight: bold; }
  .venue-address-edit .textarea-small {
    width: 100%;
    height: 90px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    padding: 10px;
    font-size: 15px;
    line-height: 1.07; }
  .venue-address-edit .bordered {
    width: 100% !important; }

.matter {
  font-family: Raleway !important; }

.buttons-header {
  overflow-x: hidden;
  padding: 20px 30px 10px; }
  @media screen and (max-width: 767px) {
    .buttons-header {
      padding: 10px 15px; } }
  .buttons-header .title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    word-wrap: break-word;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    float: left;
    width: 50% !important; }
    @media screen and (max-width: 767px) {
      .buttons-header .title {
        width: 100%; } }
  .buttons-header .buttons-group {
    width: 50%;
    text-align: right;
    display: inline-flex;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.46px;
    text-align: right;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    justify-content: flex-end;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .buttons-header .buttons-group {
        width: 100%;
        text-align: center;
        display: block;
        justify-content: space-between; }
        .buttons-header .buttons-group span {
          padding-bottom: 5px; } }
    .buttons-header .buttons-group .small-grey-text {
      font-size: 10px;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      color: #62717a;
      margin: 0 5px;
      text-transform: uppercase; }
      @media screen and (max-width: 767px) {
        .buttons-header .buttons-group .small-grey-text {
          display: block;
          margin-left: 0px; } }
    .buttons-header .buttons-group .btn-right {
      display: inline-flex;
      margin-left: 7px !important; }
      @media screen and (max-width: 767px) {
        .buttons-header .buttons-group .btn-right {
          margin-left: 0px !important;
          margin-top: 0px; } }
    .buttons-header .buttons-group .btn-publish {
      text-align: center;
      display: inline;
      justify-content: center;
      display: inline; }
      @media screen and (max-width: 767px) {
        .buttons-header .buttons-group .btn-publish {
          margin-left: 7px !important; } }
    .buttons-header .buttons-group .red-button {
      width: 130px;
      height: 30px;
      border-radius: 6px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738);
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      margin: 0 5px;
      border: 0 !important;
      cursor: pointer; }
      @media screen and (max-width: 767px) {
        .buttons-header .buttons-group .red-button {
          width: 135px;
          justify-content: center;
          display: inline-flex;
          margin: 0px 0px;
          padding: 0px 15px; } }
    .buttons-header .buttons-group .grey-button {
      width: 150px;
      height: 30px;
      border-radius: 6px;
      background-color: #9aa7af;
      font-family: Raleway !important;
      font-size: 13px;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      color: #ffffff;
      margin: 0 5px;
      border: 0 !important;
      cursor: pointer;
      position: relative; }
      @media screen and (max-width: 767px) {
        .buttons-header .buttons-group .grey-button {
          width: 135px !important;
          height: 32px; } }
      .buttons-header .buttons-group .grey-button .help_icon {
        position: absolute;
        right: 5px; }

/***
* == Gallery Style
***/
.alice-carousel__prev-btn [data-area]::after, .alice-carousel__next-btn [data-area]::after {
  content: none; }

.venue_page .seatindAndCapacityGrid .alice-carousel__wrapper li {
  margin: 0px 0px 0px 0px;
  padding: 20px; }

.seatindAndCapacityGrid .alice-carousel__wrapper {
  margin-left: 24px;
  margin-right: 24px;
  width: auto !important; }

.seatindAndCapacityGrid .alice-carousel__stage-item div h6 {
  min-height: 30px; }

.seatindAndCapacityGrid .alice-carousel__stage-item div:nth-child(2) {
  margin: auto !important; }

.seatindAndCapacityGrid .alice-carousel {
  margin-top: 45px; }

.seatindAndCapacityGrid .alice-carousel ul li img {
  height: 280px !important; }

.seatindAndCapacityGrid .single-images .alice-carousel__stage-item.__active {
  width: 100% !important; }

.seatindAndCapacityGrid .alice-carousel__wrapper {
  background-color: #f7f7ff;
  padding: 5px 0px 5px;
  margin: 0px; }
  @media screen and (max-width: 767px) {
    .seatindAndCapacityGrid .alice-carousel__wrapper {
      padding: 0px; } }

.seatindAndCapacityGrid .alice-carousel__stage-item {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px; }
  .seatindAndCapacityGrid .alice-carousel__stage-item .venue_image {
    width: 100% !important; }
  .seatindAndCapacityGrid .alice-carousel__stage-item .venue_content {
    text-align: left; }

.seatindAndCapacityGrid .prev-arrow, .seatindAndCapacityGrid .alice-carousel__prev-btn-wrapper p {
  width: 4%;
  height: 215px;
  border-radius: 6px 0 0 6px;
  border: solid 1px #f0f0f0;
  background-color: #62717a;
  float: left;
  background-position: center center !important;
  left: 0 !important;
  background-image: url(https://www.first-hold.com/static/media/arrow-left.bac1a90f.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 30% !important;
  position: absolute;
  z-index: 2;
  top: 0px;
  margin-top: -2px;
  left: 0 !important; }
  @media screen and (max-width: 767px) {
    .seatindAndCapacityGrid .prev-arrow, .seatindAndCapacityGrid .alice-carousel__prev-btn-wrapper p {
      height: 210px; } }
  .seatindAndCapacityGrid .prev-arrow svg, .seatindAndCapacityGrid .alice-carousel__prev-btn-wrapper p svg {
    opacity: 0; }

.seatindAndCapacityGrid .prev-arrow:hover, .seatindAndCapacityGrid .alice-carousel__prev-btn-wrapper p:hover {
  background-image: url(https://www.first-hold.com/static/media/arrow-left-black.df582f85.svg) !important; }

.seatindAndCapacityGrid .next-arrow, .seatindAndCapacityGrid .alice-carousel__next-btn-wrapper p {
  width: 4%;
  height: 215px;
  border-radius: 0 6px 6px 0;
  border: solid 1px #f0f0f0;
  background-color: #62717a;
  float: right;
  background-position: center center !important;
  background-image: url(https://www.first-hold.com/static/media/arrow-right.f2fb6ded.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 30% !important;
  position: absolute;
  z-index: 2;
  top: 0px;
  margin-top: -2px;
  right: 0 !important; }
  @media screen and (max-width: 767px) {
    .seatindAndCapacityGrid .next-arrow, .seatindAndCapacityGrid .alice-carousel__next-btn-wrapper p {
      height: 210px; } }
  .seatindAndCapacityGrid .next-arrow svg, .seatindAndCapacityGrid .alice-carousel__next-btn-wrapper p svg {
    opacity: 0; }

.seatindAndCapacityGrid .next-arrow:hover, .seatindAndCapacityGrid .alice-carousel__next-btn-wrapper p:hover {
  background-image: url(https://www.first-hold.com/static/media/arrow-right-black.4b55c6bd.svg) !important; }

.venue_page .seatindAndCapacityGrid .alice-carousel__wrapper li div:first-child:hover {
  background-color: #fff !important;
  transition: 200ms ease-in-out;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18); }

.alice-red .seatindAndCapacityGrid .alice-carousel__prev-btn-wrapper p, .alice-red .seatindAndCapacityGrid .alice-carousel__next-btn-wrapper p {
  background-color: #ee2738 !important; }

@media (min-width: 1200px) {
  .venue_page .seatindAndCapacityGrid .alice-carousel__wrapper li {
    padding: 20px 0px 20px 0px;
    margin: 0px 6px; } }

@media (max-width: 1199px) {
  .prev-arrow, .alice-carousel__prev-btn-wrapper p, .next-arrow, .alice-carousel__next-btn-wrapper p {
    width: 8%; }
  .venue_page .seatindAndCapacityGrid .alice-carousel__wrapper li {
    padding: 8px; } }

@media (max-width: 767px) {
  .prev-arrow, .alice-carousel__prev-btn-wrapper p, .next-arrow, .alice-carousel__next-btn-wrapper p {
    width: 12% !important; }
  .alice-carousel__prev-btn, .alice-carousel__next-btn {
    padding: 0px !important; }
  .venue_page .alice-carousel__wrapper li {
    padding-top: 7px !important;
    padding-bottom: 7px !important; } }

.single-img-max {
  max-width: 200px !important;
  margin: 0px auto !important; }

.single-images .alice-carousel .__active {
  width: 100% !important; }

.calendar {
  border: 1px solid #f0f0f0;
  border-radius: 6px; }
  .calendar .button-calendar {
    display: block;
    text-align: center;
    border-bottom: 1px solid #f0f0f0; }
    .calendar .button-calendar button {
      background-color: transparent;
      color: #ee2738;
      box-shadow: none;
      font-weight: 700;
      font-size: 18px; }
    .calendar .button-calendar .today {
      color: #1397e1;
      min-width: 140px;
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold; }
  .calendar .flexContainer {
    background-color: #f7f7f7; }
    .calendar .flexContainer .weekname {
      padding: 8px 0px;
      text-transform: uppercase; }
    .calendar .flexContainer .flexColumn {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #202020; }
    .calendar .flexContainer .day {
      background-color: #f7f7f7;
      color: #36434b;
      height: 50px;
      font-weight: normal;
      font-size: 14px; }
      .calendar .flexContainer .day .inner-grid {
        padding-left: 0px;
        position: relative;
        padding-right: 0px; }
        .calendar .flexContainer .day .inner-grid .date {
          text-align: left;
          left: 5px;
          display: block;
          top: 0;
          width: 100%;
          position: absolute;
          z-index: 2; }
        .calendar .flexContainer .day .inner-grid .event {
          position: absolute;
          width: 100%;
          height: 10px;
          top: 0px; }
        .calendar .flexContainer .day .inner-grid .single-event {
          height: 100%; }
        .calendar .flexContainer .day .inner-grid .blockOut {
          background-color: grey !important;
          height: 100%; }
        .calendar .flexContainer .day .inner-grid .makeAvailableClass {
          background-color: #ffffff !important;
          height: 100% !important; }
        .calendar .flexContainer .day .inner-grid .holdClassSecond {
          top: 0px; }
        .calendar .flexContainer .day .inner-grid .holdClassSingle {
          height: 100%;
          background-color: #69c7ee !important; }
        .calendar .flexContainer .day .inner-grid .blockClassSingle {
          height: 100%;
          background-color: grey !important; }
    .calendar .flexContainer .inactive {
      background-color: #f7f7f7;
      color: #e1e1e1; }
    .calendar .flexContainer .inner-grid {
      padding: 17px 8px;
      box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
      border-radius: 0 !important; }
      .calendar .flexContainer .inner-grid .event-slot {
        min-height: auto; }

.widget .bookClasssingle {
  background-color: red !important;
  height: 100% !important; }

.widget .h3 {
  margin-top: 105px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 16px; }
  @media screen and (max-width: 992px) {
    .widget .h3 {
      margin-top: 130px; } }

.widget .calendar {
  margin: 30px 180px 10px !important; }
  @media screen and (min-width: 1360px) {
    .widget .calendar {
      margin: 30px 400px 10px !important; } }
  @media screen and (min-width: 1024px) {
    .widget .calendar {
      margin: 30px 250px 10px !important; } }
  @media screen and (max-width: 767px) {
    .widget .calendar {
      margin: 30px 75px 10px !important; } }
  .widget .calendar .day {
    height: 80px !important; }
    @media screen and (max-width: 992px) {
      .widget .calendar .day {
        height: 48px !important; } }
  .widget .calendar .weekname {
    padding: 20px 0px !important; }
  .widget .calendar .headesearch_container p, .widget .calendar .headesearch_container b {
    word-wrap: break-word; }
  @media screen and (min-width: 1400px) {
    .widget .calendar .holdClassRight {
      border-right: 150px solid transparent; } }
  @media screen and (max-width: 1399px) {
    .widget .calendar .holdClassRight {
      border-right: 116px solid transparent; } }
  @media screen and (max-width: 1200px) {
    .widget .calendar .holdClassRight {
      border-right: 80px solid transparent; } }
  @media screen and (max-width: 1024px) {
    .widget .calendar .holdClassRight {
      border-right: 90px solid transparent; } }
  @media screen and (max-width: 991px) {
    .widget .calendar .holdClassRight {
      border-right: 90px solid transparent; } }
  @media screen and (max-width: 860px) {
    .widget .calendar .holdClassRight {
      border-right: 68px solid transparent; } }
  @media screen and (max-width: 610px) {
    .widget .calendar .holdClassRight {
      border-right: 65px solid transparent; } }
  @media screen and (max-width: 575px) {
    .widget .calendar .holdClassRight {
      border-right: 50px solid transparent; } }
  @media screen and (max-width: 460px) {
    .widget .calendar .holdClassRight {
      border-right: 33px solid transparent; } }
  @media screen and (max-width: 767px) {
    .widget .calendar .topEventNav Button {
      width: auto !important; } }

.widget .calendar_instruction {
  padding-left: 115px; }

@media screen and (max-width: 767px) {
  .widget-place-hold Button {
    width: auto !important;
    min-width: 85px !important; } }

.widget-header .topEventMenu, .widget-header .topEventNav {
  margin-right: 10px !important; }
  @media screen and (max-width: 767px) {
    .widget-header .topEventMenu, .widget-header .topEventNav {
      margin-right: 4px !important; } }

.success_modal_container div:nth-child(2) div:first-child {
  max-width: 450px; }

.view-listing-popup-box {
  width: 100%;
  overflow: scroll !important;
  height: 335px; }
  @media screen and (max-width: 767px) {
    .view-listing-popup-box {
      height: auto; } }

.view-listing-popup .container-box {
  display: block;
  width: 98%;
  height: auto;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  margin: 10px auto 15px !important;
  padding: 25px 10px 25px; }

.view-listing-popup .header {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 5px;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0; }
  @media screen and (max-width: 767px) {
    .view-listing-popup .header {
      margin-top: -5px;
      height: 72px !important; } }

.view-listing-popup .checkBoxDiv {
  display: inline-block;
  width: 5%;
  float: none;
  margin-top: -11px; }
  .view-listing-popup .checkBoxDiv span {
    margin: 0px !important;
    padding: inherit; }

.view-listing-popup .annual {
  font-size: 14px;
  font-weight: 600;
  padding-top: 2px;
  padding-left: 6px; }

.view-listing-popup .end-date {
  padding-left: 46px; }

.view-listing-popup .blue-text {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #1397e1;
  margin: 0; }

.view-listing-popup .detailsbox {
  display: inline-block;
  width: 95%;
  vertical-align: top; }
  @media screen and (max-width: 767px) {
    .view-listing-popup .detailsbox {
      width: 90%; } }
  .view-listing-popup .detailsbox .large-text {
    display: inline-block;
    width: 70%;
    padding-left: 10px; }
    @media screen and (max-width: 767px) {
      .view-listing-popup .detailsbox .large-text {
        width: 70% !important;
        padding-left: 0px; } }
    .view-listing-popup .detailsbox .large-text p {
      float: left;
      margin: 0px;
      word-break: break-word; }
      @media screen and (max-width: 767px) {
        .view-listing-popup .detailsbox .large-text p {
          padding-left: 10px; } }
  .view-listing-popup .detailsbox .actionDiv {
    display: inline-block;
    width: 30%;
    text-align: right;
    vertical-align: top; }
    @media screen and (max-width: 767px) {
      .view-listing-popup .detailsbox .actionDiv {
        width: 30% !important; } }
    .view-listing-popup .detailsbox .actionDiv p {
      display: inline-block;
      min-width: 60px;
      text-align: center;
      margin: 0;
      vertical-align: super; }
      @media screen and (max-width: 767px) {
        .view-listing-popup .detailsbox .actionDiv p {
          min-width: auto; } }
    .view-listing-popup .detailsbox .actionDiv span {
      display: inline-block; }

.view-listing-popup .not_available {
  text-align: center;
  padding: 150px 0px 120px; }
  @media screen and (max-width: 767px) {
    .view-listing-popup .not_available {
      padding: 30px 0px; } }

.view-listing-popup-box .actionDiv .delete::before, .view-listing-popup-box .actionDiv .delete::after {
  display: none; }

.view-listing-popup-box .actionDiv .delete {
  background-color: transparent; }

.modalBox_content {
  overflow: visible; }

.success_modal_container .dialog-content {
  overflow-y: visible !important; }

.restriction-popup .dialog-content {
  width: 100% !important; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

.flexContainer {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.flexColumn {
  display: flex;
  width: 14.2857142857%;
  flex: 0 1 auto;
  justify-content: center; }

.day.inactive {
  background-color: #f8f8f8;
  color: #ccc; }

.day.today {
  background-color: #fcf8e3; }

.day .inner-grid {
  width: 100%;
  position: relative; }

.day .event-slot {
  position: relative;
  margin: ".5rem" 0;
  min-height: 28px;
  font-size: "14px"; }

.day .event-slot.event {
  background: transparent;
  color: black;
  white-space: nowrap;
  text-indent: -10000px;
  cursor: pointer; }

.day .event-slot .event-title {
  position: absolute;
  top: "14px"/2/2;
  left: ".5rem";
  z-index: 100;
  color: black;
  z-index: 1;
  overflow: visible;
  text-indent: 0; }

.day .event.event-first-day {
  margin-left: ".5rem";
  border-top-left-radius: ".3rem";
  border-bottom-left-radius: ".3rem"; }

.day .event.event-last-day {
  margin-right: ".5rem";
  border-top-right-radius: ".3rem";
  border-bottom-right-radius: ".3rem"; }

.day .date {
  padding: ".25rem .5rem";
  text-align: right; }

.blankClass {
  background-color: transparent !important; }

.single-event {
  height: 100%; }

.blockClasssingle {
  background-color: grey !important;
  height: 100% !important; }

.bookClassSingle {
  background-color: red !important;
  height: 100% !important; }

.holdClassLeft {
  width: 0;
  height: 0;
  border-bottom: 50px solid #ccebf9;
  border-left: 62px solid transparent;
  background: transparent !important; }
  @media screen and (max-width: 991px) {
    .holdClassLeft {
      border-left: 44px solid transparent; } }

.holdClassRight {
  width: 0;
  height: 0;
  border-top: 50px solid #69c7ee;
  border-right: 62px solid transparent;
  background: transparent !important; }
  @media screen and (max-width: 991px) {
    .holdClassRight {
      border-right: 44px solid transparent; } }

.blockClassRight {
  width: 0;
  height: 0;
  border-top: 52px solid grey;
  border-right: 62px solid transparent;
  background: transparent !important; }

.blockClassLeft {
  width: 0;
  height: 0;
  border-bottom: 50px solid grey;
  border-left: 60px solid transparent;
  background: transparent !important; }

.bookClassRight {
  width: 0;
  height: 0;
  border-top: 50px solid red;
  border-right: 60px solid transparent;
  background: transparent !important; }

.bookClassLeft {
  width: 0;
  height: 0;
  border-bottom: 50px solid red;
  border-left: 60px solid transparent;
  background: transparent !important; }

.holdClass {
  background-color: #69c7ee !important;
  height: 12px !important;
  top: 0px !important; }

.holdClassOne4 {
  background-color: #ccebf9 !important;
  top: 12px !important;
  height: 12px !important; }

.holdClassTwo4 {
  background-color: #69c7ee !important;
  top: 24px !important;
  height: 12px !important; }

.holdClassThree4 {
  background-color: #ccebf9 !important;
  top: 36px !important;
  height: 12px !important; }

.bookClass {
  background-color: red !important;
  height: 12px !important;
  top: 0px !important; }

.bookClassOne4 {
  background-color: red !important;
  top: 12px !important;
  height: 12px !important; }

.bookClassTwo4 {
  background-color: red !important;
  top: 24px !important;
  height: 12px !important; }

.bookClassThree4 {
  background-color: red !important;
  top: 36px !important;
  height: 12px !important; }

.blockClass {
  background-color: grey !important;
  height: 12px !important;
  top: 0px !important; }

.blockClassOne4 {
  background-color: grey !important;
  top: 12px !important;
  height: 12px !important; }

.blockClassTwo4 {
  background-color: grey !important;
  top: 24px !important;
  height: 12px !important; }

.blockClassThree4 {
  background-color: grey !important;
  top: 36px !important;
  height: 12px !important; }

.holdClass3 {
  background-color: #69c7ee !important;
  height: 15px !important;
  top: 0px !important; }

.holdClassOne3 {
  background-color: #ccebf9 !important;
  top: 16px !important;
  height: 15px !important; }

.holdClassTwo3 {
  background-color: #69c7ee !important;
  top: 32px !important;
  height: 15px !important; }

.bookClass3 {
  background-color: red !important;
  height: 15px !important;
  top: 0px !important; }

.bookClassOne3 {
  background-color: red !important;
  top: 16px !important;
  height: 15px !important; }

.bookClassTwo3 {
  background-color: red !important;
  top: 32px !important;
  height: 15px !important; }

.blockClass3 {
  background-color: grey !important;
  height: 15px !important;
  top: 0px !important; }

.blockClassOne3 {
  background-color: grey !important;
  top: 16px !important;
  height: 15px !important; }

.blockClassTwo3 {
  background-color: grey !important;
  top: 32px !important;
  height: 15px !important; }

.blockOut {
  background-color: grey !important; }

.makeAvailableClass {
  background-color: #ffffff !important;
  color: black;
  height: 100% !important; }

.bookClasssingle {
  background-color: red !important;
  height: 100% !important; }

.bookClassLeft {
  width: 0;
  height: 0;
  border-bottom: 50px solid red;
  border-left: 60px solid transparent;
  background: transparent !important; }
  @media screen and (max-width: 991px) {
    .bookClassLeft {
      border-left: 44px solid transparent; } }

.bookClassRight {
  width: 0;
  height: 0;
  border-top: 50px solid red;
  border-right: 60px solid transparent;
  background: transparent !important; }
  @media screen and (max-width: 991px) {
    .bookClassRight {
      border-right: 44px solid transparent; } }

.bookClassRight {
  width: 0;
  height: 0;
  border-top: 50px solid red;
  border-right: 60px solid transparent;
  background: transparent !important; }

.bookClassLeft {
  width: 0;
  height: 0;
  border-bottom: 50px solid red;
  border-left: 60px solid transparent;
  background: transparent !important; }

.bookClass {
  background-color: red !important;
  height: 12px !important;
  top: 0px !important; }

.bookClassOne4 {
  background-color: red !important;
  top: 12px !important;
  height: 12px !important; }

.bookClassTwo4 {
  background-color: red !important;
  top: 24px !important;
  height: 12px !important; }

.bookClassThree4 {
  background-color: red !important;
  top: 36px !important;
  height: 12px !important; }

.bookClass {
  background-color: red !important;
  height: 12px !important;
  top: 0px !important; }

.bookClassOne4 {
  background-color: red !important;
  top: 12px !important;
  height: 12px !important; }

.bookClassTwo4 {
  background-color: red !important;
  top: 24px !important;
  height: 12px !important; }

.bookClassThree4 {
  background-color: red !important;
  top: 36px !important;
  height: 12px !important; }

.bookClass3 {
  background-color: red !important;
  height: 15px !important;
  top: 0px !important; }

.bookClassOne3 {
  background-color: red !important;
  top: 16px !important;
  height: 15px !important; }

.bookClassTwo3 {
  background-color: red !important;
  top: 32px !important;
  height: 15px !important; }

.bookClass3 {
  background-color: red !important;
  height: 15px !important;
  top: 0px !important; }

.bookClassOne3 {
  background-color: red !important;
  top: 16px !important;
  height: 15px !important; }

.bookClassTwo3 {
  background-color: red !important;
  top: 32px !important;
  height: 15px !important; }

.venue_manager_venue_add {
  margin-top: 59px;
  font-family: Raleway;
  width: 100%; }
  .venue_manager_venue_add .plantext {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #62717a;
    width: 50%;
    text-align: center; }
    .venue_manager_venue_add .plantext a {
      cursor: pointer; }
    .venue_manager_venue_add .plantext .red-text {
      color: #f12331;
      text-decoration: underline; }
  .venue_manager_venue_add .unsubscribe {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #62717a;
    width: 50%;
    float: right;
    padding-right: 6px;
    padding-top: 4px; }
    .venue_manager_venue_add .unsubscribe a {
      cursor: pointer; }
    .venue_manager_venue_add .unsubscribe .red-text {
      color: #f12331;
      text-decoration: underline; }
  .venue_manager_venue_add .venue-manager-header {
    padding: 0 30px; }
    @media screen and (max-width: 767px) {
      .venue_manager_venue_add .venue-manager-header {
        padding: 0px 5px; } }
    @media screen and (min-width: 768px) {
      .venue_manager_venue_add .venue-manager-header .desktop-disp-none {
        display: none; } }
    .venue_manager_venue_add .venue-manager-header .desktop-disp-none .plantext {
      width: 100%;
      padding-bottom: 20px;
      text-align: left;
      padding-left: 15px; }
    .venue_manager_venue_add .venue-manager-header .header {
      border-bottom-style: none !important;
      height: auto;
      display: flex;
      align-items: center; }
      .venue_manager_venue_add .venue-manager-header .header .venue-details {
        width: 36%; }
      @media screen and (max-width: 767px) {
        .venue_manager_venue_add .venue-manager-header .header .mobile-disp-none {
          display: none; } }
      .venue_manager_venue_add .venue-manager-header .header .plan-validity-text .plantext {
        width: 100%; }
    .venue_manager_venue_add .venue-manager-header .venue-manager-image {
      float: left;
      text-align: left;
      padding: 0 0; }
      @media screen and (max-width: 767px) {
        .venue_manager_venue_add .venue-manager-header .venue-manager-image {
          padding: 0px; } }
      .venue_manager_venue_add .venue-manager-header .venue-manager-image .image-div {
        margin: 10px 0 0 10px;
        height: auto;
        background: none; }
      .venue_manager_venue_add .venue-manager-header .venue-manager-image .image {
        width: 60px;
        height: 60px;
        border: solid 2px #1397e1;
        border-radius: 50%; }
    .venue_manager_venue_add .venue-manager-header .venue-details {
      padding: 10px 0 0 10px;
      margin: 0; }
      @media screen and (max-width: 767px) {
        .venue_manager_venue_add .venue-manager-header .venue-details {
          width: 70%;
          padding: 0px; } }
      .venue_manager_venue_add .venue-manager-header .venue-details .details {
        margin: 10px 0 0 10px; }
        .venue_manager_venue_add .venue-manager-header .venue-details .details .title {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          word-wrap: break-word;
          color: var(--black); }
        .venue_manager_venue_add .venue-manager-header .venue-details .details .blue-text {
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: normal;
          color: #1397e1; }
  .venue_manager_venue_add .venue-container {
    margin-bottom: 30px; }
  .venue_manager_venue_add .grey-div {
    width: 100%;
    height: auto;
    vertical-align: center;
    background-color: #f0f0f0;
    padding-top: 40px; }
    @media screen and (max-width: 767px) {
      .venue_manager_venue_add .grey-div {
        padding-top: 25px; } }
  .venue_manager_venue_add .light-grey-div {
    width: 100%;
    height: 374px;
    vertical-align: center;
    background-color: #f7f7f7;
    padding-top: 40px; }
  .venue_manager_venue_add .title-header {
    width: 80%;
    margin: auto; }
    @media screen and (max-width: 767px) {
      .venue_manager_venue_add .title-header {
        width: 90% !important;
        padding: 0px !important; } }
  .venue_manager_venue_add .my-venues {
    width: 80%;
    background-color: #ffffff;
    align-items: center;
    margin: auto;
    border: solid 1px #e1e1e1; }
    @media screen and (max-width: 767px) {
      .venue_manager_venue_add .my-venues {
        width: 90%;
        padding: 0px 15px; } }
    .venue_manager_venue_add .my-venues .add-space-button-div {
      text-align: right !important; }
      @media screen and (max-width: 767px) {
        .venue_manager_venue_add .my-venues .add-space-button-div {
          text-align: left !important; } }
      .venue_manager_venue_add .my-venues .add-space-button-div .white-btn-div {
        display: inline-block; }
        @media screen and (max-width: 767px) {
          .venue_manager_venue_add .my-venues .add-space-button-div .white-btn-div {
            display: block; } }
  .venue_manager_venue_add .button_alignment {
    padding-bottom: 140px; }
  .venue_manager_venue_add .button_padding {
    padding: 0px 10px; }
  .venue_manager_venue_add .white-button {
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738;
    margin: auto;
    text-decoration: none;
    display: inline-block; }
    .venue_manager_venue_add .white-button span {
      vertical-align: -webkit-baseline-middle;
      vertical-align: middle; }
  .venue_manager_venue_add .red-button {
    width: 144px;
    height: 36px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
    padding: 5px;
    float: right; }
    .venue_manager_venue_add .red-button span {
      vertical-align: -webkit-baseline-middle;
      vertical-align: -moz-middle-with-baseline; }
  .venue_manager_venue_add .edit-button {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #b0b0b0;
    border: none;
    background: none;
    vertical-align: -webkit-baseline-middle;
    text-decoration: none;
    vertical-align: -moz-middle-with-baseline; }
  .venue_manager_venue_add .add-space-button-div {
    width: 84%;
    float: left;
    text-align: right; }
    @media screen and (max-width: 767px) {
      .venue_manager_venue_add .add-space-button-div {
        width: 90%; } }
  .venue_manager_venue_add .edit-button-div {
    width: 8%;
    float: left;
    text-align: center;
    padding-left: 10px; }
  .venue_manager_venue_add .setting-button-div {
    width: 8%;
    float: left;
    text-align: center;
    padding-top: 8px; }
    @media screen and (max-width: 767px) {
      .venue_manager_venue_add .setting-button-div {
        padding-left: 7px; } }
  .venue_manager_venue_add .edit-profile-section {
    width: 80%;
    background-color: #f7f7f7;
    align-items: center;
    margin: auto;
    border: solid 1px #e1e1e1;
    padding: 10px; }
    @media screen and (max-width: 767px) {
      .venue_manager_venue_add .edit-profile-section {
        width: 90%; } }
    .venue_manager_venue_add .edit-profile-section .venue-details {
      width: 48%; }
    .venue_manager_venue_add .edit-profile-section .buyout-image {
      float: left;
      text-align: left; }
      @media screen and (max-width: 767px) {
        .venue_manager_venue_add .edit-profile-section .buyout-image {
          float: none; } }
      .venue_manager_venue_add .edit-profile-section .buyout-image .image {
        width: 60px;
        height: 60px;
        margin: 10px 0 10px 0;
        object-fit: cover;
        object-position: top center; }
        @media screen and (max-width: 767px) {
          .venue_manager_venue_add .edit-profile-section .buyout-image .image {
            width: 100%;
            height: 100%;
            margin: 10px auto 0px; } }
    .venue_manager_venue_add .edit-profile-section .subsection {
      width: 48.9%;
      height: 80px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      float: left;
      margin: 5px;
      padding: 0 10px; }
      @media screen and (max-width: 1329px) {
        .venue_manager_venue_add .edit-profile-section .subsection {
          width: 48.8%; } }
      @media screen and (max-width: 1200px) {
        .venue_manager_venue_add .edit-profile-section .subsection {
          width: 48%; } }
      @media screen and (max-width: 1024px) {
        .venue_manager_venue_add .edit-profile-section .subsection {
          width: 48%; } }
      @media screen and (max-width: 767px) {
        .venue_manager_venue_add .edit-profile-section .subsection {
          height: auto;
          width: 100%;
          margin: 5px 0px; } }
      .venue_manager_venue_add .edit-profile-section .subsection .venue-details {
        float: left; }
      .venue_manager_venue_add .edit-profile-section .subsection .subsection-text {
        height: 60px;
        margin: 10px 0 10px 10px;
        font-family: Raleway;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        word-break: break-word;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--black); }
        @media screen and (max-width: 767px) {
          .venue_manager_venue_add .edit-profile-section .subsection .subsection-text {
            height: auto;
            margin: 5px 0; } }
      .venue_manager_venue_add .edit-profile-section .subsection .add-space-div {
        width: 35%;
        height: 100%;
        float: right;
        text-align: end; }
        @media screen and (max-width: 767px) {
          .venue_manager_venue_add .edit-profile-section .subsection .add-space-div {
            width: 100%;
            text-align: left; } }
        .venue_manager_venue_add .edit-profile-section .subsection .add-space-div .published {
          padding-right: 0px;
          display: block; }
          @media screen and (min-width: 1200px) {
            .venue_manager_venue_add .edit-profile-section .subsection .add-space-div .published {
              padding-right: 5px; } }
  .venue_manager_venue_add .title-header {
    margin-bottom: 40px;
    height: 30px;
    padding: 0 5px; }
    @media screen and (max-width: 767px) {
      .venue_manager_venue_add .title-header {
        width: 100%;
        margin: auto;
        height: 60px;
        text-align: center; } }
  .venue_manager_venue_add .my-venues-title {
    width: auto;
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    word-break: break-all;
    text-align: left;
    color: #202020;
    float: left;
    margin-top: auto;
    padding-top: 5px; }
  .venue_manager_venue_add .venue-image {
    float: left;
    text-align: left; }
    @media screen and (max-width: 767px) {
      .venue_manager_venue_add .venue-image {
        width: 100% !important;
        height: 100%; } }
    .venue_manager_venue_add .venue-image .image {
      width: 60px;
      height: 60px;
      margin: 10px 0 10px 10px;
      object-fit: cover;
      object-position: top center; }
      @media screen and (max-width: 767px) {
        .venue_manager_venue_add .venue-image .image {
          width: 100%;
          height: 100%;
          margin: 15px auto 0px; } }
  .venue_manager_venue_add .plan-details {
    width: 30%;
    float: left;
    padding-top: 30px;
    padding-bottom: 30px; }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      .venue_manager_venue_add .plan-details {
        width: 29%; } }
    @media screen and (max-width: 767px) {
      .venue_manager_venue_add .plan-details {
        width: 100%;
        padding-top: 0px;
        text-align: left;
        padding-bottom: 0px; } }
    .venue_manager_venue_add .plan-details .plantext {
      width: auto; }
      @media screen and (max-width: 767px) {
        .venue_manager_venue_add .plan-details .plantext {
          text-align: left;
          padding: 5px 0px 0px; } }
      .venue_manager_venue_add .plan-details .plantext .red-text {
        display: block; }
  .venue_manager_venue_add .venue-details {
    width: 30%;
    float: left; }
    .venue_manager_venue_add .venue-details .details {
      width: 100%;
      height: auto;
      margin: 10px 0 10px 10px; }
      @media screen and (max-width: 767px) {
        .venue_manager_venue_add .venue-details .details {
          margin: 5px 0px 0px; } }
      .venue_manager_venue_add .venue-details .details .title {
        width: 100%;
        height: auto;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        word-wrap: break-word;
        color: #36434b;
        margin-bottom: 4px; }
      .venue_manager_venue_add .venue-details .details p {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #62717a;
        word-break: break-word;
        margin: 0; }
  .venue_manager_venue_add .add-space-div {
    width: 30%;
    float: right;
    text-align: right;
    margin: auto;
    padding-top: 10px; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
    .venue_manager_venue_add .my-venues {
      width: 90%;
      justify-content: left;
      padding-right: 15px; }
    .venue_manager_venue_add .venue-image {
      float: left;
      text-align: left; }
    .venue_manager_venue_add .venue-details {
      float: left; } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) and (max-width: 767px) {
    .venue_manager_venue_add .venue-details {
      width: 38%; } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
    .venue_manager_venue_add .add-space-div {
      float: right;
      text-align: center;
      padding: 10px !important;
      margin: auto; }
      .venue_manager_venue_add .add-space-div .edit-button-div {
        float: left;
        padding-left: 8px; }
    .venue_manager_venue_add .title-header {
      width: 85%;
      margin: auto;
      height: 60px;
      text-align: center; }
      .venue_manager_venue_add .title-header .my-venues-title {
        text-align: left;
        float: left;
        width: auto; }
      .venue_manager_venue_add .title-header .red-button {
        float: right; }
    .venue_manager_venue_add .red-button-div {
      width: 100%;
      text-align: center;
      margin: auto; }
    .venue_manager_venue_add .venue-manager-header .subtext {
      margin: 20px auto 20px 0;
      width: 100%; }
    .venue_manager_venue_add .venue-manager-header .header {
      width: 100% !important;
      margin: auto !important;
      overflow: auto !important;
      border-bottom-style: none !important; }
    .venue_manager_venue_add .edit-profile-section {
      padding: 8px;
      width: 90%; }
      .venue_manager_venue_add .edit-profile-section .subsection {
        width: 100%;
        margin: 5px auto; }
    .venue_manager_venue_add .white-button {
      font-size: 12px;
      padding: 5px; } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) and (max-width: 767px) {
    .venue_manager_venue_add .white-button {
      font-size: 11px; } }

.create-event-header .uploader {
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 0.5px;
  border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
  border-image-slice: 1;
  background-image: url(/static/media/perm_contact_calendar.dd9786b4.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-color: #f0f0f0; }

.create-event-header .header {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  margin-top: 0;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0; }

.create-event-header .header-text {
  font-family: Raleway;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #36434b;
  float: left;
  padding-top: 5px; }

.create-event-header .uploader-content {
  width: 300px;
  height: 300px;
  font-family: material;
  font-size: 300px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #eae9e9;
  margin: 30px auto; }

.create-event-header .red-text-content {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ee2738;
  margin: 140px 0 5px 0; }

.create-event-header .sm-text-event {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202020;
  margin: 0px; }

.create-event-header .add-space-div {
  padding-top: 0px; }

@media (max-width: 767px) {
  .create-event-header .add-space-div {
    width: 30%;
    padding: 0 !important; }
  .create-event-header .header {
    padding: 15px 5px; }
  .venue_manager_venue_add .venue-manager-header .header {
    padding-left: 0px;
    margin-left: 0px !important; } }

.dont_p .error {
  width: 444px; }

.published {
  margin: 0px;
  padding-right: 20px;
  display: inline-block;
  font-size: 13px; }
  @media screen and (max-width: 767px) {
    .published {
      padding-right: 12px;
      display: block; } }

@media screen and (max-width: 767px) {
  .venue_manager_venue_add .venue-details {
    width: 100% !important; } }

.venue_manager_venue_add .add-space-button-div {
  width: 90% !important;
  text-align: left;
  padding-top: 15px; }

.venue_manager_venue_add .edit-button-div {
  padding-top: 15px; }

@media screen and (max-width: 767px) {
  .venue_manager_venue_add .add-space-div {
    width: 100% !important;
    padding: 0px 0px 15px !important; } }

.venue-plan .expired-msg {
  background-color: #36434b;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  font-weight: 600;
  padding: 14px 0; }
  .venue-plan .expired-msg .subscrib-btn {
    border-radius: 1px;
    color: #ffffff;
    font-weight: 600;
    background-color: #1397e1; }
    .venue-plan .expired-msg .subscrib-btn:hover {
      background-color: #1397e1; }

.venue-plan .email-verification {
  background-color: #f0f0f0;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 8px 0; }
  .venue-plan .email-verification p {
    font-size: 14px; }
    .venue-plan .email-verification p a {
      color: #4a4a4a;
      text-decoration: underline; }

@media screen and (min-width: 800px) {
  .mobile-view {
    display: none !important; } }

.desktop-view {
  display: block; }
  @media screen and (max-width: 767px) {
    .desktop-view {
      display: none; } }

.landing-footer {
  background-color: #1a181e;
  padding: 24px; }
  @media screen and (max-width: 768px) {
    .landing-footer {
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center; }
      .landing-footer .footer-top-links {
        text-align: center !important; } }
  .landing-footer .footer-top-links {
    width: 100%;
    text-align: right; }
    .landing-footer .footer-top-links .footer_links {
      padding: 12px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600; }
  .landing-footer .footer-btn {
    margin: 20px 0; }
  .landing-footer .copyright {
    font-size: 12px;
    padding-top: 90px;
    color: #747474; }
  .landing-footer .logo-section {
    justify-content: center;
    margin-left: 25px; }
  .landing-footer .logo {
    width: 100px; }
    @media screen and (max-width: 768px) {
      .landing-footer .logo {
        padding-bottom: 20px; } }
  .landing-footer .footer_links {
    font-size: 13px;
    line-height: 1.54;
    color: #ffffff;
    padding-top: 7px; }
    .landing-footer .footer_links:hover {
      text-decoration: none;
      cursor: pointer; }
  @media screen and (min-width: 768px) {
    .landing-footer .button_section {
      justify-content: flex-end; } }
  @media screen and (max-width: 500px) {
    .landing-footer .button_section {
      padding-top: 10px;
      justify-content: center; } }
  .landing-footer .links_section {
    text-align: left; }
  .landing-footer ul.social-link {
    margin: 8px 0 0 0px; }
    .landing-footer ul.social-link li {
      display: inline-block;
      padding: 5px 10px;
      margin: 5px;
      line-height: 0; }
  .landing-footer .social-block {
    display: inline-block;
    padding: 6px 50px; }
    .landing-footer .social-block span {
      color: #ffffff;
      text-transform: uppercase; }
  .landing-footer .footer-text {
    color: #ffffff;
    text-align: right;
    margin-top: 17px; }
    .landing-footer .footer-text h2 {
      font-weight: 700;
      margin-bottom: 10px; }
    .landing-footer .footer-text p {
      font-size: 13px; }
  .landing-footer .socail-link-section img.icons {
    background-color: #ffffff;
    width: 30px;
    border-radius: 30px; }
  @media screen and (max-width: 768px) {
    .landing-footer .social-block {
      padding: 6px 30px;
      font-size: 14px; }
    .landing-footer .section-one {
      padding-left: 10% !important; }
    .landing-footer .send-mail {
      padding-left: 20px; }
    .landing-footer .logo-section {
      margin-left: 0px !important; }
    .landing-footer .footer-text {
      text-align: center; } }
  .landing-footer .send-mail {
    margin-top: 15px;
    padding-left: 45px; }
    .landing-footer .send-mail a {
      color: #fff;
      text-decoration: underline;
      font-size: 15px; }
  .landing-footer .section-one {
    padding-left: 0%;
    margin-top: 20px; }
  @media screen and (max-width: 580px) {
    .landing-footer .send-mail {
      padding-left: 0px !important; }
    .landing-footer .socail-link-section {
      margin: 30px 0; }
    .landing-footer .section-one {
      padding-left: 25% !important; }
    .landing-footer .footer-top-links .footer_links {
      display: block; } }
  @media screen and (max-width: 480px) {
    .landing-footer .section-one {
      padding-left: 15% !important; } }
  .landing-footer .socail-link-section {
    margin-left: -10px;
    margin-top: 11px; }

.venue_plan_container {
  margin-top: 60px;
  font-family: Raleway;
  width: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
  height: 100%; }
  .venue_plan_container .plan_outer_container {
    margin: 0 auto;
    text-align: center;
    padding-top: 50px; }
    .venue_plan_container .plan_outer_container .venue-mouse-over button {
      background-color: transparent;
      color: #4a4a4a;
      box-shadow: none;
      padding: 0px;
      min-width: auto;
      font-size: 20px;
      font-weight: 400; }
    .venue_plan_container .plan_outer_container .heading-red {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.33;
      color: #36434b; }
    .venue_plan_container .plan_outer_container .head-text {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.43;
      color: #36434b;
      margin: auto;
      padding-top: 10px;
      padding-bottom: 10px; }
    .venue_plan_container .plan_outer_container .head-text-blue {
      color: #1397e1;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.43; }
    .venue_plan_container .plan_outer_container .tier-box {
      display: block;
      justify-content: space-between;
      width: 100%; }
    .venue_plan_container .plan_outer_container .blue-heading {
      color: #1397e1;
      padding-bottom: 12px;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.43; }
  .venue_plan_container .advertisement {
    font-family: Raleway;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ee2738;
    margin: 10px auto 20px;
    text-align: center; }
  .venue_plan_container .plan_inner_container {
    width: 300px;
    min-height: auto;
    border-radius: 6px;
    vertical-align: top;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    margin: 30px 15px;
    display: inline-block; }
  .venue_plan_container .plan_title {
    font-family: Raleway;
    font-size: 48px;
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.96;
    letter-spacing: normal;
    color: #36434b;
    margin: 20px auto 10px; }
  .venue_plan_container .blue {
    color: #1397e1 !important; }
  .venue_plan_container .bigsub_title {
    font-size: 20px;
    padding-bottom: 15px; }
    .venue_plan_container .bigsub_title a {
      color: #36434b; }
  .venue_plan_container .red-text {
    width: 100%;
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ee2738;
    margin: 24px auto 10px; }
  .venue_plan_container .small-font {
    width: 74px;
    height: 32px;
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #36434b;
    margin: 10px auto !important; }
  .venue_plan_container .sub_title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #747474; }
  .venue_plan_container .plan_description {
    width: 80%;
    height: 920px;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #62717a;
    list-style: none;
    margin: auto;
    text-align: right;
    -webkit-padding-start: 0 !important;
            padding-inline-start: 0 !important; }
    .venue_plan_container .plan_description li {
      overflow: auto;
      width: 100% !important;
      padding: 2px 0; }
      .venue_plan_container .plan_description li .red {
        color: #ee2738;
        font-weight: 700; }
  .venue_plan_container .list {
    display: list-item; }
  .venue_plan_container .big {
    width: 240px; }
  .venue_plan_container .text {
    display: inline-block;
    width: 90%;
    padding: 8px 0 0 4px;
    text-align: left; }
  .venue_plan_container .blue_icon_div {
    width: 10%;
    display: inline-block;
    float: left;
    padding-top: 10px; }
  .venue_plan_container .blue_icon {
    width: 16px;
    height: 16px;
    border: 0;
    background-color: none; }
  .venue_plan_container .red-section-1 {
    width: 100%;
    height: 91px;
    background-color: #ee2738;
    overflow: hidden; }
    @media only screen and (min-width: 992px) {
      .venue_plan_container .red-section-1 {
        background: transparent;
        position: relative; }
        .venue_plan_container .red-section-1:before {
          content: '';
          top: 0;
          left: 0;
          margin-left: -10%;
          width: calc(100% + 20%);
          height: 100%;
          position: absolute;
          background: #ef4a39;
          border-radius: 100% 0 0% 0% / 35%; } }
  .venue_plan_container .red-section-2 {
    width: 100%;
    height: 91px;
    overflow: hidden;
    background-color: #ee2738; }
    @media only screen and (min-width: 992px) {
      .venue_plan_container .red-section-2 {
        background: transparent;
        position: relative; }
        .venue_plan_container .red-section-2:before {
          content: '';
          top: -8px;
          left: 0;
          margin-left: -10%;
          width: calc(100% + 20%);
          height: calc(100% + 14px);
          position: absolute;
          background: #ef4a39;
          border-radius: 100% 100% 0% 0% / 25%; } }
  .venue_plan_container .red-section-3 {
    width: 100%;
    height: 91px;
    overflow: hidden;
    background-color: #ee2738; }
    @media only screen and (min-width: 992px) {
      .venue_plan_container .red-section-3 {
        background: transparent;
        position: relative; }
        .venue_plan_container .red-section-3:before {
          content: '';
          font-family: Raleway;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #ee2738;
          margin: 0px auto 26px;
          top: 0;
          left: 0;
          margin-left: -10%;
          width: calc(100% + 20%);
          height: 100%;
          position: absolute;
          background: #ef4a39;
          border-radius: 0% 100% 0% 0% / 35%; } }
  .venue_plan_container .button-bg {
    width: 130px;
    height: 36px;
    border-radius: 6px;
    background-image: linear-gradient(to bottom, #ffffff, #ececec);
    margin-top: 32px;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738; }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .venue_plan_container .plan_inner_container {
      float: left;
      width: 46%; } }
  @media only screen and (max-width: 767px) {
    .venue_plan_container .plan_outer_container {
      width: 80%; }
    .venue_plan_container .plan_inner_container {
      width: 100%;
      min-width: 100%;
      padding-top: 15px;
      margin: 30px 40px 30px 0px; }
    .venue_plan_container .plan_description {
      height: auto;
      margin-bottom: 20px; } }

.checkall-block fieldset {
  display: block; }

.paymentconfirmation {
  margin-left: auto;
  margin-right: auto;
  width: 50%; }
  @media screen and (max-width: 767px) {
    .paymentconfirmation {
      width: 95%; } }
  .paymentconfirmation .paymentimage {
    padding-top: 10px;
    border-bottom: 3px solid #000;
    padding-bottom: 10px; }
  .paymentconfirmation .title {
    padding: 15px 0;
    font-weight: 600;
    font-size: 16px;
    color: #000; }
  .paymentconfirmation .midContainer-payment {
    text-align: right;
    width: 70%;
    margin-left: auto !important;
    display: block;
    padding-right: 0;
    text-align: left;
    padding-top: 15px; }
    @media screen and (max-width: 767px) {
      .paymentconfirmation .midContainer-payment {
        width: 100%; } }
    .paymentconfirmation .midContainer-payment .right {
      display: block; }
      .paymentconfirmation .midContainer-payment .right h3 {
        text-align: left;
        font-size: 25px;
        font-weight: 600;
        color: #000; }
      .paymentconfirmation .midContainer-payment .right p {
        font-size: 18px;
        color: #4185f4;
        font-weight: 600;
        padding: 20px 0px; }
  .paymentconfirmation .annual-left {
    float: left;
    width: 50%;
    border-top: 2px solid #dddddd;
    padding-top: 30px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 30px; }
    .paymentconfirmation .annual-left p {
      font-size: 16px;
      font-weight: bold;
      color: #000; }
  .paymentconfirmation .annual-right {
    float: left;
    width: 50%;
    border-top: 2px solid #dddddd;
    padding-top: 30px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 30px; }
    .paymentconfirmation .annual-right p {
      font-weight: 700;
      color: #000; }
    .paymentconfirmation .annual-right span {
      font-size: 16px;
      font-weight: bold;
      padding: 0px 5px;
      font-size: 16px;
      color: #000; }
  .paymentconfirmation .payment-method {
    margin: 0px auto;
    padding-bottom: 20px;
    display: inline-block; }
    .paymentconfirmation .payment-method .one {
      padding-top: 20px;
      font-weight: bold;
      color: #3a3333;
      font-size: 14px; }
    .paymentconfirmation .payment-method .two {
      margin: 0px;
      color: #3a3333;
      font-size: 12px; }
    .paymentconfirmation .payment-method .three {
      margin: 0px;
      color: #3a3333;
      padding-bottom: 20px;
      font-size: 12px; }

.MuiDialog-paperWidthSm {
  max-width: 480px; }

#customized-dialog-title {
  border-bottom: 1px solid #f1efef; }

.c-dialog-container {
  padding: 24px; }
  .c-dialog-container .logo {
    text-align: center; }
    .c-dialog-container .logo img {
      height: 92px; }
  .c-dialog-container p {
    width: 100%; }
  .c-dialog-container .login-container .button-box {
    padding-left: 12px; }
    .c-dialog-container .login-container .button-box .sign-btn {
      background-color: #ee2738 !important;
      color: #fff;
      font-weight: 700;
      font-size: 16px; }
  .c-dialog-container .login-container .input-box .inputField {
    width: 100%;
    height: 42px; }
  .c-dialog-container .coupon-list-container {
    margin-top: 32px; }
    .c-dialog-container .coupon-list-container ul {
      width: 100%; }
      .c-dialog-container .coupon-list-container ul li {
        border: 1px solid #f1efef; }
        .c-dialog-container .coupon-list-container ul li .avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px; }
        .c-dialog-container .coupon-list-container ul li .c-text {
          padding: 6px; }
          .c-dialog-container .coupon-list-container ul li .c-text h3 {
            text-transform: uppercase;
            font-weight: 500; }
          .c-dialog-container .coupon-list-container ul li .c-text p {
            font-size: 12px; }
            .c-dialog-container .coupon-list-container ul li .c-text p span {
              color: #ee2738; }
        .c-dialog-container .coupon-list-container ul li .redIcon {
          color: #ee2738;
          font-size: 30px; }

.payment {
  width: 60%;
  margin: 60px auto auto; }
  @media screen and (max-width: 767px) {
    .payment {
      width: 100%;
      padding: 0 20px; } }
  .payment * {
    box-sizing: border-box; }
  .payment body,
  .payment html {
    background-color: #f6f9fc;
    font-size: 18px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif; }
  .payment h1 {
    color: #32325d;
    font-weight: 400;
    line-height: 50px;
    font-size: 40px;
    margin: 20px 0;
    padding: 0; }
  .payment .Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px; }
  .payment label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em; }
  .payment button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    transition: all 150ms ease;
    margin-top: 10px; }
  .payment form {
    margin-bottom: 40px; }
  .payment button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08); }
  .payment .StripeElement,
  .payment input {
    display: block;
    margin: 10px 0 20px;
    max-width: 100%;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0 1px 3px, rgba(0, 0, 0, 0.0196078) 0 1px 0;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white; }
  .payment input::-webkit-input-placeholder {
    color: #b0b0b0; }
  .payment input::-ms-input-placeholder {
    color: #b0b0b0; }
  .payment input::placeholder {
    color: #b0b0b0; }
  .payment .StripeElement--focus,
  .payment input:focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0 4px 6px, rgba(0, 0, 0, 0.0784314) 0 1px 3px;
    transition: all 150ms ease; }
  .payment .StripeElement.IdealBankElement,
  .payment .StripeElement.PaymentRequestButton {
    padding: 0; }
  .payment .StripeElement {
    background-color: #f7f7f7;
    padding: 20px 15px;
    margin: 20px 0;
    border-bottom: 3px solid #e6ebf1; }
  .payment .payment-process-text {
    display: block; }
    .payment .payment-process-text svg {
      max-width: 16px;
      float: left;
      padding-top: 5px; }
    .payment .payment-process-text p {
      display: inline-block;
      padding-left: 5px; }
      @media screen and (max-width: 767px) {
        .payment .payment-process-text p {
          font-size: 13px; } }
      .payment .payment-process-text p span {
        font-size: 20px;
        font-weight: bold;
        color: #6671e5; }
  .payment .inputlabel {
    display: inline-block;
    padding: 5px 15px 10px;
    background-color: #f7f7f7;
    width: 100%;
    border-bottom: 3px solid #e6ebf1; }
    .payment .inputlabel label {
      color: #202020;
      font-weight: 500; }
    .payment .inputlabel input {
      width: 100%;
      box-shadow: none;
      padding-left: 0;
      padding-top: 3px;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      background-color: #f7f7f7;
      font-size: 16px; }
      .payment .inputlabel input ::-webkit-input-placeholder {
        color: #202020 !important; }
      .payment .inputlabel input ::-ms-input-placeholder {
        color: #202020 !important; }
      .payment .inputlabel input ::placeholder {
        color: #202020 !important; }
  .payment .credit-card {
    padding: 15px 10px;
    border-radius: 0;
    border: 2px solid #1397e1;
    background-color: #1397e114 !important;
    display: flex;
    justify-content: space-between;
    padding-left: 20px; }
    .payment .credit-card .circle {
      display: block;
      width: 25px;
      height: 25px;
      background-color: #fff;
      border-radius: 50%; }
      .payment .credit-card .circle .small-circle {
        display: block;
        width: 15px;
        height: 15px;
        background-color: #1397e1;
        border-radius: 50%;
        margin: 5px; }
    .payment .credit-card div div {
      background-color: transparent;
      text-align: center; }
    .payment .credit-card svg {
      width: 59px; }
  .payment .coupon-applied {
    padding: 4px 4px 0px 0px;
    margin-bottom: 25px; }
    .payment .coupon-applied p {
      font-weight: 500;
      font-size: 14px;
      color: red; }
      .payment .coupon-applied p span {
        color: #ee2738;
        font-weight: 600; }
  .payment .coupon-card {
    margin-top: 12px;
    border: 1px solid #949494;
    background-color: #f7f7f7 !important;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
    .payment .coupon-card .lable {
      padding: 8px 8px 12px 8px; }
      .payment .coupon-card .lable img {
        float: left;
        width: 32px; }
      .payment .coupon-card .lable p {
        font-weight: 600;
        font-size: 16px;
        padding: 4px;
        float: left; }
    .payment .coupon-card .apply-box {
      float: right;
      padding: 8px; }
      .payment .coupon-card .apply-box button {
        background-color: #fff;
        border: 1px solid #ee2738;
        color: #ee2738;
        float: inherit;
        margin-top: 0;
        width: 124px;
        font-size: 14px; }
  .payment .pay-btn {
    display: flex;
    justify-content: space-between; }
    .payment .pay-btn .pay-now {
      background-color: #ee2738;
      height: 50px;
      padding: 0 120px;
      text-transform: capitalize;
      border-radius: 0; }
      @media screen and (max-width: 767px) {
        .payment .pay-btn .pay-now {
          padding: 0 30px;
          height: auto; } }
    .payment .pay-btn .cancel {
      background-color: transparent;
      border: 1px solid #ee2738;
      color: #ee2738;
      text-transform: capitalize;
      border-radius: 0; }
      .payment .pay-btn .cancel a {
        color: #ee2738; }
  .payment .payment-heading {
    display: block;
    padding: 10px 0;
    font-weight: bold;
    font-size: 18px; }

.tier-info {
  padding: 10px 0;
  color: #20b2ea;
  font-weight: bold; }

.navbar-link:not(.is-arrowless)::after,
.select:not(.is-multiple):not(.is-loading)::after {
  display: none !important; }

.plan-information,
.checkbox-text-alignment label .text-color {
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  color: #202020; }

.plan-information span {
  color: #ee2738; }

.checkbox-text-alignment .checkboxLabel {
  align-items: flex-start; }

.checkbox-text-alignment .checkboxLabel > *:first-child {
  margin-top: -8px; }

button:disabled {
  cursor: not-allowed !important; }

.paymentconfirmation {
  margin-left: auto;
  margin-right: auto;
  width: 50%; }
  @media screen and (max-width: 767px) {
    .paymentconfirmation {
      width: 95%; } }
  .paymentconfirmation .paymentimage {
    padding-top: 10px;
    border-bottom: 3px solid #000;
    padding-bottom: 10px; }
  .paymentconfirmation .title {
    padding: 15px 0;
    font-weight: 600;
    font-size: 16px;
    color: #000; }
  .paymentconfirmation .midContainer-payment {
    text-align: right;
    width: 70%;
    margin-left: auto !important;
    display: block;
    padding-right: 0;
    text-align: left;
    padding-top: 15px; }
    @media screen and (max-width: 767px) {
      .paymentconfirmation .midContainer-payment {
        width: 100%; } }
    .paymentconfirmation .midContainer-payment .right {
      display: block; }
      .paymentconfirmation .midContainer-payment .right h3 {
        text-align: left;
        font-size: 25px;
        font-weight: 600;
        color: #000; }
      .paymentconfirmation .midContainer-payment .right p {
        font-size: 18px;
        color: #4185f4;
        font-weight: 600;
        padding: 20px 0px; }
  .paymentconfirmation .annual-left {
    float: left;
    width: 50%;
    border-top: 2px solid #dddddd;
    padding-top: 30px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 30px; }
    .paymentconfirmation .annual-left p {
      font-size: 16px;
      font-weight: bold;
      color: #000; }
  .paymentconfirmation .annual-right {
    float: left;
    width: 50%;
    border-top: 2px solid #dddddd;
    padding-top: 30px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 30px; }
    .paymentconfirmation .annual-right p {
      font-weight: 700;
      color: #000; }
    .paymentconfirmation .annual-right span {
      font-size: 16px;
      font-weight: bold;
      padding: 0px 5px;
      font-size: 16px;
      color: #000; }
  .paymentconfirmation .payment-method {
    margin: 0px auto;
    padding-bottom: 20px;
    display: inline-block; }
    .paymentconfirmation .payment-method .one {
      padding-top: 20px;
      font-weight: bold;
      color: #3a3333;
      font-size: 14px; }
    .paymentconfirmation .payment-method .two {
      margin: 0px;
      color: #3a3333;
      font-size: 12px; }
    .paymentconfirmation .payment-method .three {
      margin: 0px;
      color: #3a3333;
      padding-bottom: 20px;
      font-size: 12px; }

.featured_venue_slider .featured_venue_heading {
  padding-bottom: 10px !important; }

.featured_venue_slider .featured_venue_carousel {
  background-color: #f7f7f7;
  padding: 16px 0px; }
  @media screen and (max-width: 767px) {
    .featured_venue_slider .featured_venue_carousel .venueCardWithFav {
      margin: 0px 15px; } }

.featured_venue_slider .overlay_icon {
  right: 15px !important; }
  @media screen and (max-width: 767px) {
    .featured_venue_slider .overlay_icon {
      right: 5px !important; } }

.featured_venue_slider .venue_content {
  padding-top: 5px !important;
  padding-bottom: 0px !important; }

@media screen and (max-width: 767px) {
  .venueTypeCard {
    margin: auto 15px;
    width: 100%; } }

.venueTypeCard a {
  text-decoration: none; }
  .venueTypeCard a:hover {
    text-decoration: none; }

.venueTypeCard .find_feature_venue_image:hover {
  box-shadow: 0 9px 8px 0 rgba(128, 127, 127, 0.7); }

@media screen and (min-width: 1150px) {
  .venueTypeCard .find_feature_venue_image {
    width: 321.3px;
    max-height: 200px;
    object-fit: cover;
    object-position: bottom; } }

@media screen and (max-width: 1151px) {
  .venueTypeCard .find_feature_venue_image {
    width: 265.3px;
    max-height: 160.8px;
    object-fit: cover;
    object-position: bottom; } }

@media screen and (max-width: 991px) {
  .venueTypeCard .find_feature_venue_image {
    width: 100%;
    max-height: 200px; } }

.venueTypeCard .find_venue_event {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #36434b;
  padding-top: 3px; }

.venueTypeCard .venues {
  padding: 10px 20px !important; }

.venues .venue_card {
  width: 306px; }

/***
* == Search Style
***/
/*search box css start*/
.suggestionDiv > .heading1, .heading2 {
  display: none; }

.box {
  width: 300px;
  height: 50px; }

.searchBgBox {
  width: 100%;
  max-width: 1130px;
  z-index: 9999999999; }
  @media screen and (max-width: 1024px) and (min-width: 991px) {
    .searchBgBox {
      max-width: 985px; } }

/* search box */
/*-------------------------------------*/
.cf:before,
.cf:after {
  content: "";
  display: table; }

.cf:after {
  clear: both; }

.cf {
  zoom: 1;
  width: 100%; }

/*-------------------------------------*/
.form-wrapper-2 {
  box-shadow: 5px 10px 18px #04040438; }

* {
  box-sizing: border-box; }

.search_container {
  position: relative;
  width: 100% !important; }

.search_container input#states-autocomplete {
  position: relative;
  float: left;
  width: 100%;
  padding: 0px 15px 0px 69px;
  height: 50px;
  font-size: 14px;
  border: 0;
  background: #fff; }
  .search_container input#states-autocomplete::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-style: normal; }
  .search_container input#states-autocomplete::-moz-placeholder {
    /* Firefox 19+ */
    font-style: normal; }
  .search_container input#states-autocomplete:-ms-input-placeholder {
    /* IE 10+ */
    font-style: normal; }
  .search_container input#states-autocomplete:-moz-placeholder {
    /* Firefox 18- */
    font-style: normal; }

.search_container div {
  width: 83.3% !important;
  position: relative; }
  @media screen and (max-width: 767px) {
    .search_container div {
      max-width: 80%; } }
  @media screen and (max-width: 349px) {
    .search_container div {
      max-width: 79%; } }
  @media screen and (max-width: 325px) {
    .search_container div {
      max-width: 77.7%; } }

.search_container2 .react-datepicker-popper {
  top: 40px !important; }

.search_container input:focus {
  outline: 0;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.8) inset; }

.search_container input::-webkit-input-placeholder {
  color: #b0b0b0;
  font-weight: normal;
  font-style: italic; }

.search_container input:-moz-placeholder {
  color: #b0b0b0;
  font-weight: normal;
  font-style: italic; }

.form-wrapper-2 input:-ms-input-placeholder {
  color: #b0b0b0;
  font-weight: normal;
  font-style: italic; }

/* Style the submit button */
.search_container button {
  text-transform: capitalize;
  float: left;
  width: 16.7% !important;
  margin: 0px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
  display: block;
  border-radius: 0px;
  height: 50px;
  background-image: linear-gradient(to bottom, #f1435e, #ee2738); }
  @media screen and (max-width: 767px) {
    .search_container button {
      width: 20% !important;
      min-width: 69px !important; } }
  @media screen and (max-width: 320px) {
    .search_container button {
      min-width: 64px !important; } }
  .search_container button:before {
    content: "Search";
    cursor: pointer;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px; }

/* Clear floats */
.search_container::after {
  content: "";
  clear: both;
  display: table; }

.custom-select {
  position: relative;
  background: #f7f7f7; }
  .custom-select:before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 0.8;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 15px;
    right: 5px;
    color: #62717a;
    z-index: 0; }

select {
  text-transform: uppercase;
  font-family: 'Raleway';
  width: 99%;
  padding: 15px 25px 15px 15px;
  text-align: left;
  float: left;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #62717a;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background: transparent;
  z-index: 3; }

.searchSelect option {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #62717a;
  border: 0;
  border: 0;
  padding: 8px !important;
  min-height: 50px !important;
  height: 30px !important;
  padding: 0px 12px; }

.search_container2 {
  background-color: #eee; }

.search_container2 input {
  width: 100%;
  z-index: 2;
  background-color: transparent;
  cursor: pointer; }

div.react-datepicker__input-container {
  display: block; }

.search_container > div:first-child:before {
  content: 'search';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 15px;
  left: 25px;
  color: #ccc;
  z-index: 1; }

.search_container input[role="combobox"] + div {
  z-index: 9999;
  top: 50px !important;
  left: 0px !important;
  position: absolute !important;
  width: 120% !important;
  min-width: 80% !important;
  max-height: 145px !important;
  background: #fff;
  overflow-y: scroll;
  height: auto;
  box-shadow: 0px 15px 10px -15px #ccc;
  padding: 10px 0; }

.search_container input[role="combobox"] + div .item {
  padding: 0px 65px;
  font-size: 15px !important; }
  @media screen and (max-width: 767px) {
    .search_container input[role="combobox"] + div .item {
      padding: 0px 15px !important; } }
  @media screen and (max-width: 320px) {
    .search_container input[role="combobox"] + div .item {
      padding-right: 0px !important;
      padding-left: 15px !important; } }

.search_container input[role="combobox"] + div .item-highlighted {
  background-image: linear-gradient(to bottom, #eee, #eee);
  width: 100% !important;
  cursor: pointer;
  font-size: 12px; }

.home-search {
  margin-top: -90px; }

.datepiker {
  position: relative;
  text-transform: uppercase;
  font-family: 'Raleway';
  width: 100%;
  padding: 18px 44px 18px 22px;
  float: left;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #62717a;
  border: 0;
  outline: none;
  background: #f7f7f7; }
  .datepiker:before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 15px;
    right: 5px;
    color: #62717a; }

.datepiker:focus {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67; }

.suggestionDiv {
  width: 80% !important;
  position: relative;
  font-size: 12px;
  line-height: 2.3;
  word-break: break-word; }

.react-datepicker-wrapper {
  display: block !important; }

.search_bottom {
  width: 100% !important;
  padding: 6px 0px 0px 0px; }
  .search_bottom > div:first-child {
    float: right; }
  .search_bottom span {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #62717a; }

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .search_container2 {
    width: 100% !important;
    margin: 0px; }
    .search_container2 input {
      width: 100% !important;
      padding: 19px 0px 11px 30px; }
  .search_container input#states-autocomplete {
    position: relative;
    float: left;
    width: 98%;
    padding: 4px 6px 5px 2px;
    height: 50px;
    font-size: 14px;
    border: 0;
    background: #fff;
    border-radius: 0px; }
    .search_container input#states-autocomplete::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal; }
    .search_container input#states-autocomplete::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal; }
    .search_container input#states-autocomplete:-ms-input-placeholder {
      /* IE 10+ */
      font-style: normal; }
    .search_container input#states-autocomplete:-moz-placeholder {
      /* Firefox 18- */
      font-style: normal; }
  .search_container div {
    width: 79% !important;
    position: relative; }
  .search_container > div:first-child:before {
    content: ' '; }
  .search_container button {
    text-transform: capitalize;
    float: left;
    width: 17%;
    margin: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    height: 50px;
    background-image: linear-gradient(to bottom, #f1435e, #ee2738); } }
  @media screen and (max-width: 992px) and (max-width: 767px) {
    .search_container button {
      width: 20% !important; } }

@media screen and (max-width: 992px) {
    .search_container button:before {
      content: 'search';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: contents;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      color: #fff;
      z-index: 1;
      top: 14px;
      text-align: center;
      left: 24px;
      padding: 0px; }
  .search_container input[role="combobox"] + div .item {
    padding: 0px 15px;
    font-size: 15px !important; }
  .form-wrapper-2 {
    box-shadow: 0px 0px 8px 0px #fff; }
  .home-search {
    margin-top: 3px; }
  .custom-select {
    width: 50%;
    position: relative;
    background: #f7f7f7; }
  select {
    padding: 12px 40px 15px 39px; }
  .search_bottom {
    padding: 5px 0px 0px 51px; } }
  @media screen and (max-width: 992px) and (max-width: 767px) {
    .search_bottom {
      padding: 5px 0px 0px 15px !important; } }

@media screen and (max-width: 992px) {
    .search_bottom > div:first-child {
      float: right;
      width: 60%; } }
    @media screen and (max-width: 992px) and (max-width: 767px) {
      .search_bottom > div:first-child {
        width: 100% !important;
        text-align: left; } }

@media screen and (max-width: 992px) {
      .search_bottom > div:first-child:before {
        content: ''; }
    .search_bottom span {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #62717a; } }
    @media screen and (max-width: 992px) and (max-width: 767px) {
      .search_bottom span {
        text-align: center;
        display: block; } }

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .search_container2 {
    width: 100% !important;
    margin: 0px; }
    .search_container2 input {
      width: 100% !important;
      padding: 19px 0px 11px 30px; } }

@media screen and (max-width: 600px) and (max-width: 768px) {
  input#states-autocomplete {
    font-size: 11px !important; }
  .search_container input[role="combobox"] + div {
    min-width: 100% !important; }
  .suggestionDiv div {
    max-width: 100% !important; } }

@media screen and (max-width: 600px) {
  .search_container input#states-autocomplete {
    position: relative;
    float: left;
    width: 98%;
    padding: 4px 6px 5px 2px;
    height: 50px;
    font-size: 14px;
    border: 0;
    background: #fff; }
    .search_container input#states-autocomplete::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal;
      color: #b0b0b0; }
    .search_container input#states-autocomplete::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal;
      color: #b0b0b0; }
  .search_container div {
    width: 76% !important;
    position: relative; }
  .search_container > div:first-child:before {
    content: ' '; }
  .search_container button {
    text-transform: capitalize;
    float: left;
    width: 17%;
    margin: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    height: 50px;
    background-image: linear-gradient(to bottom, #f1435e, #ee2738); } }
  @media screen and (max-width: 600px) and (max-width: 767px) {
    .search_container button {
      width: 20% !important; } }

@media screen and (max-width: 600px) {
    .search_container button:before {
      content: 'search';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      color: #fff;
      z-index: 1;
      padding: 0px;
      top: 14px;
      text-align: center;
      left: 24px; }
  .search_container input[role="combobox"] + div .item {
    padding: 0px 15px;
    font-size: 15px !important; }
  .form-wrapper-2 {
    box-shadow: 0px 0px 8px 0px #fff; }
  .home-search {
    margin-top: 3px; }
  .custom-select {
    width: 50%;
    position: relative;
    background: #f7f7f7; }
  select {
    padding: 12px 40px 15px 39px; }
  .search_bottom {
    padding: 5px 0px 0px 51px; }
    .search_bottom > div:first-child {
      float: right;
      width: 60%; }
      .search_bottom > div:first-child:before {
        content: ''; }
    .search_bottom span {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #62717a; } }

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .search_container2 {
    width: 100% !important;
    margin: 0px; }
    .search_container2 input {
      width: 100% !important;
      padding: 19px 0px 11px 30px; }
  .search_container input#states-autocomplete {
    position: relative;
    float: left;
    width: 100%;
    padding: 4px 14px 5px 14px;
    height: 50px;
    font-size: 14px;
    border: 0;
    background: #fff;
    border-top: 1px solid #f7f7f7;
    border-left: 1px solid #f7f7f7; }
    .search_container input#states-autocomplete::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal; }
    .search_container input#states-autocomplete::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal; }
    .search_container input#states-autocomplete:-ms-input-placeholder {
      /* IE 10+ */
      font-style: normal; }
    .search_container input#states-autocomplete:-moz-placeholder {
      /* Firefox 18- */
      font-style: normal; }
  .search_container div {
    width: 79% !important;
    position: relative; }
  .search_container > div:first-child:before {
    content: ' '; }
  .search_container button {
    text-transform: capitalize;
    float: left;
    margin: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    height: 50px;
    background-image: linear-gradient(to bottom, #f1435e, #ee2738); }
    .search_container button:before {
      content: 'search';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: contents;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      color: #fff;
      z-index: 1;
      padding: 0px;
      top: 14px;
      text-align: center;
      left: 24px;
      padding: 0px; }
  .search_container input[role="combobox"] + div .item {
    padding: 0px 15px;
    font-size: 15px !important; }
  .form-wrapper-2 {
    box-shadow: 0px 0px 8px 0px #fff;
    padding: 0px 15px; }
  .home-search {
    margin-top: 3px; }
  .custom-select {
    width: 50%;
    position: relative;
    background: #f7f7f7; }
  select {
    padding: 12px 40px 15px 30px; }
  .search_bottom {
    text-align: right;
    padding: 5px 20px 0px 51px; }
    .search_bottom > div:first-child {
      float: right;
      width: 60%; }
      .search_bottom > div:first-child:before {
        content: ''; }
    .search_bottom span {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #62717a; } }

.date_pick {
  position: relative; }
  .date_pick::before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 0.8;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 15px;
    right: 5px;
    z-index: 1;
    color: #62717a; }

.datepiker {
  color: #62717a !important; }

@media only screen and (max-device-width: 991px) and (min-device-width: 320px) {
  .date_pick {
    width: 50%;
    position: relative; } }

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .search_container div {
    min-width: 83% !important; } }

@media only screen and (max-width: 767px) and (min-width: 375px) {
  .search_container div {
    min-width: 80% !important; } }

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #b0b0b0; }

::-webkit-input-placeholder {
  color: #b0b0b0; }

::-ms-input-placeholder {
  color: #b0b0b0; }

::placeholder {
  color: #b0b0b0; }

body {
  overflow-x: hidden; }

.home_page {
  overflow-x: hidden; }

.text_over_image {
  position: absolute;
  left: 0px;
  width: 100%;
  top: 149px; }
  @media screen and (max-width: 800px) {
    .text_over_image {
      display: none !important; } }

@media screen and (min-width: 800px) {
  .mobile_view {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .mobile_view {
    padding-left: 50px;
    padding-right: 50px; } }

@media screen and (max-width: 500px) {
  .mobile_view {
    padding-left: 15px;
    padding-right: 15px; } }

.banner .carousel {
  border-radius: 0 0 100% 100% / 112px;
  overflow: hidden;
  position: -webkit-sticky;
  margin-left: -10%;
  width: calc(100% + 20%) !important; }

@media screen and (min-width: 800px) {
  .banner_heading {
    font-size: 48px;
    font-weight: 200; } }

@media screen and (min-width: 800px) {
  .banner_heading {
    text-align: left;
    color: #ffffff; } }

@media screen and (max-width: 800px) {
  .banner_heading {
    text-align: center;
    color: #000000; } }

@media screen and (max-width: 768px) {
  .banner_heading {
    font-size: 24px;
    font-weight: 200;
    padding-top: 20px; } }

.banner_sub_heading {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding-top: 16px; }
  @media screen and (min-width: 800px) {
    .banner_sub_heading {
      text-align: left;
      color: #ffffff;
      padding-bottom: 8px; } }
  @media screen and (max-width: 800px) {
    .banner_sub_heading {
      padding-top: 3px;
      text-align: center;
      color: #000000; } }

.banner_description {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71; }
  @media screen and (min-width: 800px) {
    .banner_description {
      text-align: left;
      width: 334px;
      color: #ffffff; } }
  @media screen and (max-width: 800px) {
    .banner_description {
      text-align: center;
      color: #000000; } }

@media screen and (max-width: 767px) {
  .d-none-sm {
    display: none; } }

.download_link {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  padding-top: 7px;
  padding-bottom: 10px; }
  @media screen and (min-width: 800px) {
    .download_link {
      text-align: left; } }
  @media screen and (max-width: 800px) {
    .download_link {
      text-align: center; } }
  .download_link .link:hover {
    text-decoration: none; }
  @media screen and (min-width: 800px) {
    .download_link .link {
      color: #ffffff; } }

@media screen and (min-width: 800px) {
  .banner_content {
    padding-left: 67px; } }

@media screen and (min-width: 800px) {
  .banner_button {
    text-align: left; } }

@media screen and (max-width: 800px) {
  .banner_button {
    text-align: center; } }

.previousButton {
  position: absolute !important;
  top: 250px;
  left: 26px;
  margin-left: -21px !important; }
  @media screen and (max-width: 800px) {
    .previousButton {
      display: none !important; } }
  .previousButton :hover {
    background-color: transparent !important; }

.nextButton {
  position: absolute !important;
  top: 250px;
  right: 30px; }
  @media screen and (max-width: 800px) {
    .nextButton {
      display: none !important; } }
  .nextButton :hover {
    background-color: transparent !important; }

.featured_venue_heading {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202020;
  padding: 0px; }
  @media screen and (max-width: 767px) {
    .featured_venue_heading {
      padding-bottom: 15px;
      padding-top: 12px; } }

.featured_venue_carousel .venue .venue_image {
  height: 200px !important;
  object-fit: cover;
  object-position: top center;
  width: 95% !important; }
  @media screen and (max-width: 767px) {
    .featured_venue_carousel .venue .venue_image {
      width: 100% !important; } }

.featured_venue_carousel .alice-carousel__stage-item .venue_content {
  padding-left: 5px; }

@media screen and (max-width: 767px) {
  .featured_venue_carousel .alice-carousel__stage-item {
    margin-left: auto;
    margin-right: auto; } }

.find_venue_heading {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202020;
  padding: 60px 0px 20px !important; }

.find_venues {
  padding-bottom: 50px;
  position: relative;
  background: #f7f7f7; }
  .find_venues:before {
    content: '';
    top: 0;
    left: 0;
    margin-left: -10%;
    width: calc(100% + 20%);
    height: 100%;
    position: absolute;
    background: #ffffff;
    border-radius: 0 0 100% 100% / 125px; }
  .find_venues > div {
    z-index: 1; }

.event_planner {
  padding-top: 32px;
  background-color: #f7f7f7;
  padding: 0px 15px; }

.get-qualified {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202020;
  padding-top: 0px !important;
  padding-bottom: 20px; }

.event-planner {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #202020;
  padding-top: 2px;
  padding-bottom: 10px !important; }
  @media screen and (max-width: 767px) {
    .event-planner {
      font-size: 20px !important; } }

.theresa_image {
  margin-top: 52px !important; }
  @media screen and (min-width: 1024px) {
    .theresa_image {
      margin-right: -73px !important; } }
  @media screen and (min-width: 500px) and (max-width: 1024px) {
    .theresa_image {
      margin-right: -52px !important; } }

.jennie_image {
  margin-top: 35px !important; }
  @media screen and (min-width: 1024px) {
    .jennie_image {
      margin-left: -102px !important; } }
  @media screen and (min-width: 700px) and (max-width: 1024px) {
    .jennie_image {
      margin-left: -82px !important; } }

@media screen and (max-width: 360px) {
  .event_planner_image {
    width: 270px; } }

@media screen and (min-width: 375) and (max-width: 450px) {
  .event_planner_image {
    width: 350px; } }

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .event_planner_image {
    width: 271px; } }

.event_planner_heading {
  width: 70%;
  max-width: 70% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  display: block;
  padding-top: 32px;
  padding-left: 0px !important; }
  @media screen and (max-width: 767px) {
    .event_planner_heading {
      text-align: center;
      max-width: 100% !important; } }
  @media screen and (min-width: 800px) {
    .event_planner_heading {
      padding-left: 75px; } }

.event_planner_name {
  font-size: 16px;
  line-height: 1.5;
  color: #202020; }

.verified_image {
  padding-left: 5px; }

.be_ready {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .be_ready {
      font-size: 20px !important; } }

.device_image {
  padding-top: 22px; }

@media screen and (max-width: 500px) {
  .device_image_dimension {
    width: 310px;
    height: 208px; } }

.event_planners {
  font-size: 16px;
  font-weight: bold;
  color: #202020 !important; }
  @media screen and (min-width: 1024px) {
    .event_planners {
      padding: 0px !important; } }
  @media screen and (max-width: 700px) {
    .event_planners {
      text-align: center; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .event_planners {
      padding: 0px 12px; } }

@media screen and (max-width: 767px) {
  .contact-information {
    padding: 0px 15px; } }

.size_style {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #36434b;
  text-transform: uppercase; }
  @media screen and (min-width: 1024px) {
    .size_style {
      padding: 0px; } }
  @media screen and (max-width: 700px) {
    .size_style {
      text-align: center;
      padding-top: 10px;
      font-size: 20px; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .size_style {
      padding: 0px 12px; } }

.event_planner_content {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #36434b; }
  @media screen and (min-width: 1024px) {
    .event_planner_content {
      padding: 0px !important; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .event_planner_content {
      padding: 0px 28px 0px 12px; } }

.event-blue-text {
  font-size: 16px;
  font-weight: 700;
  color: #1397e1; }
  @media screen and (max-width: 767px) {
    .event-blue-text {
      text-align: center; } }

.venue_managers {
  font-size: 16px;
  font-weight: 600 !important;
  color: #202020 !important; }
  @media screen and (max-width: 700px) {
    .venue_managers {
      text-align: center; } }
  @media screen and (min-width: 1024px) {
    .venue_managers {
      padding: 0px !important; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .venue_managers {
      padding: 0px 12px; } }

.sync_your {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #36434b;
  text-transform: uppercase; }
  @media screen and (min-width: 1024px) {
    .sync_your {
      padding: 0px !important; } }
  @media screen and (max-width: 700px) {
    .sync_your {
      text-align: center;
      padding-top: 10px;
      font-size: 20px; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .sync_your {
      padding: 0px 0px 0px 12px; } }

.venue_manager_content {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #36434b; }
  @media screen and (min-width: 1024px) {
    .venue_manager_content {
      padding: 0px !important; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .venue_manager_content {
      padding: 0px 41px 0px 12px; } }

.how_it_work_screen {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 58px !important; }
  @media screen and (max-width: 768px) {
    .how_it_work_screen {
      padding-bottom: 0px; } }
  .how_it_work_screen .how-pd-auto {
    padding: 0px 30px; }
    @media screen and (max-width: 767px) {
      .how_it_work_screen .how-pd-auto {
        padding: 0px; } }

.how_it_works {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.87); }
  @media screen and (max-width: 767px) {
    .how_it_works {
      padding-top: 50px; } }
  @media screen and (max-width: 500px) {
    .how_it_works {
      padding: 40px 0px 10px 0px; } }
  @media screen and (min-width: 768px) {
    .how_it_works {
      padding: 28px 0px 0px 0px !important; } }

.how-it-works-heading h3 {
  font-size: 20px;
  padding-top: 20px;
  font-weight: 500;
  color: #202020;
  display: block; }
  @media screen and (max-width: 767px) {
    .how-it-works-heading h3 {
      padding-top: 0px;
      font-size: 19px; } }

.how-it-works-link p {
  font-size: 16px;
  font-weight: 500;
  text-align: center; }
  .how-it-works-link p a {
    color: #202020;
    display: block; }

.how-it-works-text {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: 'Raleway', sans-serif;
  font-stretch: normal;
  line-height: 1.33;
  width: 65% !important;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: normal;
  color: #202020;
  padding-top: 2px;
  text-align: center;
  padding: 0px 0px 50px; }
  @media screen and (max-width: 768px) {
    .how-it-works-text {
      width: 100% !important;
      padding: 0px 0px 20px; } }

.it-free {
  font-size: 12px;
  margin-top: 4px;
  color: #747474;
  font-weight: 500; }
  @media screen and (max-width: 800px) {
    .it-free {
      margin-bottom: 20px; } }

.how-it-works-blue-text p {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #1397e1; }

@media screen and (max-width: 767px) {
  .how-it-works-blue-text .d-none {
    display: none; } }

.pt-5 {
  padding-top: 20px; }

@media screen and (max-width: 768px) {
  .event_planners_section {
    padding: 10px 0px 20px 0px;
    text-align: center; } }

@media screen and (max-width: 768px) {
  .venue_managers_section {
    padding: 0px 0px 40px 0px;
    text-align: center; } }

.venue_managers_section a {
  font-size: 13px;
  width: 176px;
  height: 49px;
  font-size: 13px;
  font-style: normal;
  text-align: center;
  padding: 10px 15px;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background: linear-gradient(to bottom, #f1435e, #ee2738) !important;
  color: #ffffff; }

.venue_managers_section a:hover {
  background: #d80e2a !important;
  color: #f7f7f7;
  opacity: 0.9; }

.venue_managers_section .how-it-works-text {
  padding: 25px 0px 25px;
  font-weight: 500;
  font-size: 20px; }
  @media screen and (max-width: 767px) {
    .venue_managers_section .how-it-works-text {
      padding-top: 20px; } }

.venue_managers_section .pb-0 {
  padding-bottom: 0px; }

.list-venue_managers {
  padding-top: 40px; }
  .list-venue_managers .how-it-works-text {
    padding-top: 5px; }

.image_link:hover {
  text-decoration: none !important;
  cursor: pointer; }

.footer {
  background-color: #1a181e !important; }
  @media screen and (min-width: 1024px) {
    .footer {
      padding: 52px 200px 20px 200px !important; } }
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    .footer {
      padding: 52px 30px 20px 30px !important; } }
  @media screen and (max-width: 768px) {
    .footer {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      text-align: center; } }
  @media screen and (max-width: 768px) and (max-width: 767px) {
    .footer .hidden-mbl {
      display: none; } }
  .footer .copyright {
    font-size: 12px;
    padding-top: 83px;
    color: #747474; }
    @media screen and (max-width: 991px) {
      .footer .copyright {
        justify-content: center !important; } }
  .footer .logo {
    padding-bottom: 38px; }
    @media screen and (max-width: 768px) {
      .footer .logo {
        padding-bottom: 20px; } }
  .footer .footer_links {
    font-size: 13px;
    line-height: 1.54;
    color: #ffffff; }
    .footer .footer_links:hover {
      text-decoration: none;
      cursor: pointer; }
  @media screen and (min-width: 768px) {
    .footer .button_section {
      justify-content: flex-end; } }
  @media screen and (max-width: 991px) {
    .footer .button_section {
      justify-content: center !important;
      padding-top: 10px; } }
  @media screen and (max-width: 767px) {
    .footer .button_section {
      padding-top: 10px; } }
  @media screen and (min-width: 768px) {
    .footer .links_section {
      justify-content: left; } }
  @media screen and (max-width: 767px) {
    .footer .links_section {
      justify-content: center;
      text-align: center; } }
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .footer .footer {
      padding: 52px 50px 20px 50px !important; } }

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .footer {
    padding: 52px 50px 20px 50px !important; } }

a {
  text-decoration: none; }

button[disabled] {
  opacity: 0.5; }

.banner {
  position: relative; }
  .banner .nextButton {
    position: absolute !important;
    top: 50%;
    right: 40px; }
    .banner .nextButton:hover {
      background-color: transparent; }
  .banner .nextButton {
    position: absolute !important;
    top: 50%;
    right: auto; }
  .banner .previousButton {
    position: absolute !important;
    top: 50%;
    left: auto; }

/* container */
.fh-container {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto; }

.color-trans {
  background-color: transparent !important;
  box-shadow: none !important; }

.pos-0 {
  left: 0 !important;
  position: absolute !important; }

@media (max-width: 1199px) {
  .fh-container {
    max-width: 1170px; } }

.blue_theme_date_picker {
  width: 100%;
  height: 44px;
  padding: 15px;
  border-radius: 6px;
  border: solid 1px #f0f0f0;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: #1397e1;
  text-transform: uppercase; }
  .blue_theme_date_picker:focus {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    color: #1397e1; }
  .blue_theme_date_picker::-webkit-input-placeholder {
    color: #1397e1 !important; }
  .blue_theme_date_picker:-moz-placeholder {
    color: #1397e1;
    opacity: 1; }
  .blue_theme_date_picker::-moz-placeholder {
    color: #1397e1;
    opacity: 1; }
  .blue_theme_date_picker:-ms-input-placeholder {
    color: #1397e1; }
  @media screen and (max-width: 375px) {
    .blue_theme_date_picker {
      padding-left: 15px;
      padding-right: 10px;
      color: #1397e1; } }
  @media screen and (min-width: 376px) and (max-width: 767px) {
    .blue_theme_date_picker {
      padding-left: 15px;
      padding-right: 15px;
      color: #1397e1; } }
  @media screen and (min-width: 1025px) {
    .blue_theme_date_picker {
      padding-left: 15px !important;
      padding-right: 3px;
      color: #1397e1; } }

.black_theme_date_picker {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  border: solid 1px #f0f0f0;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  text-align: center;
  color: #62717a;
  text-transform: uppercase; }
  .black_theme_date_picker:focus {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    text-align: center;
    color: #1397e1; }
  .black_theme_date_picker::-webkit-input-placeholder {
    color: #62717a;
    text-align: center; }
  .black_theme_date_picker:-moz-placeholder {
    color: #1397e1;
    opacity: 1;
    text-align: center; }
  .black_theme_date_picker::-moz-placeholder {
    color: #1397e1;
    opacity: 1;
    text-align: center; }
  .black_theme_date_picker:-ms-input-placeholder {
    color: #1397e1;
    text-align: center; }

.custom_select_with_scroll label {
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #62717a;
  margin-bottom: 4px; }

.custom_select_with_scroll .label_container {
  padding-bottom: 4px;
  padding-left: 0; }

::-webkit-scrollbar {
  width: 5px !important; }

::-webkit-scrollbar-track {
  background: #ffffff !important; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1397e1 !important;
  border-radius: 10px; }

.browse_venue_page {
  background-color: #f7f7f7;
  margin-top: 62px; }
  .browse_venue_page a:hover {
    text-decoration: none; }
  .browse_venue_page a {
    text-decoration: none; }
  @media screen and (max-width: 767px) {
    .browse_venue_page {
      margin-top: 49px; } }
  .browse_venue_page .right_panel {
    background-color: #f7f7f7;
    height: 100%;
    min-height: auto; }
  .browse_venue_page .browse_venue_search {
    background-color: #ffffff;
    padding-bottom: 30px; }
    @media screen and (max-width: 768px) {
      .browse_venue_page .browse_venue_search {
        padding-bottom: 17px; } }
    .browse_venue_page .browse_venue_search .search {
      padding-top: 17px; }
      @media screen and (max-width: 768px) {
        .browse_venue_page .browse_venue_search .search {
          width: 100%;
          margin: 0; } }
      .browse_venue_page .browse_venue_search .search .starting-address {
        font-size: 11px;
        font-weight: 500;
        line-height: 1.45;
        text-align: left;
        color: #62717a;
        padding-bottom: 7px; }
      .browse_venue_page .browse_venue_search .search .start-search div:nth-child(1) {
        width: 100%; }
        .browse_venue_page .browse_venue_search .search .start-search div:nth-child(1) #states-autocomplete {
          position: relative;
          width: 100%;
          height: 36px;
          font-size: 14px;
          border: solid 1px #e1e1e1;
          padding: 0px 15px;
          background-color: #ffffff;
          border-radius: 6px;
          line-height: 30px;
          z-index: 3; }
      .browse_venue_page .browse_venue_search .search .start-search .suggestionDiv {
        width: 100% !important;
        position: relative;
        font-size: 12px;
        line-height: 2.3;
        word-break: break-word;
        border: solid 1px #e1e1e1;
        margin-top: -3px; }
        .browse_venue_page .browse_venue_search .search .start-search .suggestionDiv div {
          border: 0px;
          border-radius: 0px;
          padding: 0px 15px;
          cursor: pointer; }
      .browse_venue_page .browse_venue_search .search .start-search input[role="combobox"] + div {
        z-index: 9999;
        top: 45px !important;
        left: 0px !important;
        position: absolute !important;
        width: 100% !important;
        min-width: 80% !important;
        min-height: 144px !important;
        background: #fff;
        overflow-y: scroll;
        height: 130px;
        box-shadow: 0px 15px 10px -15px #ccc; }
      .browse_venue_page .browse_venue_search .search .address {
        width: 100%;
        height: 36px;
        border-radius: 6px;
        border: solid 1px #f0f0f0;
        background-color: #ffffff;
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.71;
        text-align: left;
        color: #36434b;
        padding-left: 12px;
        padding-right: 12px; }
        @media screen and (max-width: 320px) {
          .browse_venue_page .browse_venue_search .search .address {
            width: 100%; } }
        .browse_venue_page .browse_venue_search .search .address::-webkit-input-placeholder {
          color: #b0b0b0; }
        .browse_venue_page .browse_venue_search .search .address::-ms-input-placeholder {
          color: #b0b0b0; }
        .browse_venue_page .browse_venue_search .search .address::placeholder {
          color: #b0b0b0; }
        @media screen and (min-width: 376px) and (max-width: 414px) {
          .browse_venue_page .browse_venue_search .search .address {
            width: 100%; } }
        @media screen and (min-width: 785px) and (max-width: 1024px) {
          .browse_venue_page .browse_venue_search .search .address {
            width: 100%; } }
      .browse_venue_page .browse_venue_search .search .save_search {
        height: 36px; }
      .browse_venue_page .browse_venue_search .search .clearfilter {
        height: 36px; }
        @media screen and (min-width: 320px) {
          .browse_venue_page .browse_venue_search .search .clearfilter {
            margin-left: 10px; } }
      .browse_venue_page .browse_venue_search .search .to {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #36434b; }
      .browse_venue_page .browse_venue_search .search .fieldJustification {
        padding-bottom: 5px;
        padding-top: 5px; }
      .browse_venue_page .browse_venue_search .search .dateTimeFieldJustification {
        padding-bottom: 7px;
        padding-top: 7px; }
        @media screen and (max-width: 767px) {
          .browse_venue_page .browse_venue_search .search .dateTimeFieldJustification .time_select > div {
            padding: 0 10px; }
          .browse_venue_page .browse_venue_search .search .dateTimeFieldJustification .time_select svg {
            right: -7px; } }
  .browse_venue_page .browse_venue_right_panel {
    margin: 0 15px; }
    @media screen and (min-width: 1024px) {
      .browse_venue_page .browse_venue_right_panel {
        padding-left: 23px; } }
    .browse_venue_page .browse_venue_right_panel .pick_a_category, .browse_venue_page .browse_venue_right_panel .venue_heading {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      color: #36434b;
      margin: 20px auto;
      padding-left: 0px; }
      @media screen and (max-width: 768px) {
        .browse_venue_page .browse_venue_right_panel .pick_a_category, .browse_venue_page .browse_venue_right_panel .venue_heading {
          text-align: center;
          padding-left: 0px;
          margin: 20px auto 0px; } }
    @media screen and (max-width: 768px) {
      .browse_venue_page .browse_venue_right_panel .venues {
        justify-content: center;
        width: 100%;
        margin: 0px; } }
    .browse_venue_page .browse_venue_right_panel .venues .end .react-datepicker-popper {
      left: auto !important;
      right: 0px !important; }
    .browse_venue_page .browse_venue_right_panel .venue_card {
      padding: 10px 20px; }
      .browse_venue_page .browse_venue_right_panel .venue_card :hover .big_image {
        box-shadow: 0 9px 8px 0 rgba(128, 127, 127, 0.7); }
  .browse_venue_page .venue .venue_image {
    width: 266px !important; }
  .browse_venue_page .sign-up1 {
    width: 100% !important; }

.react-datepicker-popper {
  z-index: 9999 !important; }

.save_search button[disabled] {
  background-color: #bfbfbf !important; }

.browse_venue_search .custom-select {
  background-color: #ffffff !important; }

@media screen and (max-width: 767px) {
  .sign-up1 {
    width: 100% !important;
    min-width: 100%; } }

@media screen and (max-width: 405px) {
  .save_search {
    margin-bottom: 10px !important;
    text-align: center; }
  .save_search.clearfilter {
    margin: 0 !important;
    text-align: center;
    padding: 0 10px; } }

.featured_venue_slider .featured_venue_heading {
  padding-bottom: 4px; }

.featured_venue_slider .featured_venue_carousel {
  background-color: #f7f7f7;
  padding: 16px 0px; }
  @media screen and (max-width: 767px) {
    .featured_venue_slider .featured_venue_carousel .venueCardWithFav {
      margin: 0px 15px; } }

.featured_venue_slider .overlay_icon {
  right: 15px !important; }
  @media screen and (max-width: 767px) {
    .featured_venue_slider .overlay_icon {
      right: 5px !important; } }

.featured_venue_slider .venue_content {
  padding-top: 5px !important;
  padding-bottom: 0px !important; }

/***
* == Search Style
***/
/*search box css start*/
.box {
  width: 300px;
  height: 50px; }

.searchBgBox {
  width: 100%;
  max-width: 1130px;
  z-index: 9999999999; }
  @media screen and (max-width: 1024px) and (min-width: 991px) {
    .searchBgBox {
      max-width: 985px; } }

/* search box */
/*-------------------------------------*/
.cf:before,
.cf:after {
  content: "";
  display: table; }

.cf:after {
  clear: both; }

.cf {
  zoom: 1;
  width: 100%; }

/*-------------------------------------*/
.form-wrapper-2 {
  box-shadow: 5px 10px 18px #04040438; }

* {
  box-sizing: border-box; }

.search_container {
  position: relative;
  width: 100% !important; }

.search_container input#states-autocomplete {
  position: relative;
  float: left;
  width: 100%;
  padding: 0px 15px 0px 69px;
  height: 50px;
  font-size: 14px;
  border: 0;
  background: #fff; }
  .search_container input#states-autocomplete::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-style: normal; }
  .search_container input#states-autocomplete::-moz-placeholder {
    /* Firefox 19+ */
    font-style: normal; }
  .search_container input#states-autocomplete:-ms-input-placeholder {
    /* IE 10+ */
    font-style: normal; }
  .search_container input#states-autocomplete:-moz-placeholder {
    /* Firefox 18- */
    font-style: normal; }

.search_container div {
  width: 83.3% !important;
  position: relative; }
  @media screen and (max-width: 767px) {
    .search_container div {
      max-width: 80%; } }
  @media screen and (max-width: 349px) {
    .search_container div {
      max-width: 79%; } }
  @media screen and (max-width: 325px) {
    .search_container div {
      max-width: 77.7%; } }

.search_container2 .react-datepicker-popper {
  top: 40px !important; }

.search_container input:focus {
  outline: 0;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.8) inset; }

.search_container input::-webkit-input-placeholder {
  color: #b0b0b0;
  font-weight: normal;
  font-style: italic; }

.search_container input:-moz-placeholder {
  color: #b0b0b0;
  font-weight: normal;
  font-style: italic; }

.form-wrapper-2 input:-ms-input-placeholder {
  color: #b0b0b0;
  font-weight: normal;
  font-style: italic; }

/* Style the submit button */
.search_container button {
  text-transform: capitalize;
  float: left;
  width: 16.7% !important;
  margin: 0px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
  display: block;
  border-radius: 0px;
  height: 50px;
  background-image: linear-gradient(to bottom, #f1435e, #ee2738); }
  @media screen and (max-width: 767px) {
    .search_container button {
      width: 20% !important; } }
  .search_container button:before {
    content: "Search";
    cursor: pointer;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px; }

/* Clear floats */
.search_container::after {
  content: "";
  clear: both;
  display: table; }

.custom-select {
  position: relative;
  background: #f7f7f7; }
  .custom-select:before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 0.8;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 15px;
    right: 5px;
    color: #62717a;
    z-index: 0; }

select {
  text-transform: uppercase;
  font-family: 'Raleway';
  width: 99%;
  padding: 15px 25px 15px 15px;
  text-align: left;
  float: left;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #62717a;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background: transparent;
  z-index: 3; }

.searchSelect option {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #62717a;
  border: 0;
  border: 0;
  padding: 8px !important;
  min-height: 50px !important;
  height: 30px !important;
  padding: 0px 12px; }

.search_container2 {
  background-color: #eee; }

.search_container2 input {
  width: 100%;
  z-index: 2;
  background-color: transparent;
  cursor: pointer; }

div.react-datepicker__input-container {
  display: block; }

.search_container > div:first-child:before {
  content: 'search';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 15px;
  left: 25px;
  color: #ccc;
  z-index: 1; }

.search_container input[role="combobox"] + div {
  z-index: 9999;
  top: 50px !important;
  left: 0px !important;
  position: absolute !important;
  width: 120% !important;
  min-width: 80% !important;
  max-height: 145px !important;
  background: #fff;
  overflow-y: scroll;
  height: auto;
  box-shadow: 0px 15px 10px -15px #ccc;
  padding: 10px 0; }

.search_container input[role="combobox"] + div .item {
  padding: 0px 65px;
  font-size: 15px !important; }

.search_container input[role="combobox"] + div .item-highlighted {
  background-image: linear-gradient(to bottom, #eee, #eee);
  width: 100% !important;
  cursor: pointer;
  font-size: 12px; }

.home-search {
  margin-top: -90px; }

.datepiker {
  position: relative;
  text-transform: uppercase;
  font-family: 'Raleway';
  width: 100%;
  padding: 18px 44px 18px 22px;
  float: left;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #62717a;
  border: 0;
  outline: none;
  background: #f7f7f7; }
  .datepiker:before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 15px;
    right: 5px;
    color: #62717a; }

.datepiker:focus {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67; }

.suggestionDiv {
  width: 80% !important;
  position: relative;
  font-size: 12px;
  line-height: 2.3;
  word-break: break-word; }
  @media screen and (max-width: 767px) {
    .suggestionDiv {
      width: 100%; } }

.react-datepicker-wrapper {
  display: block !important; }

.search_bottom {
  width: 100% !important;
  padding: 6px 0px 0px 0px; }
  .search_bottom > div:first-child {
    float: right; }
  .search_bottom span {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #62717a; }

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .search_container2 {
    width: 100% !important;
    margin: 0px; }
    .search_container2 input {
      width: 100% !important;
      padding: 19px 0px 11px 30px; }
  .search_container input#states-autocomplete {
    position: relative;
    float: left;
    width: 98%;
    padding: 4px 6px 5px 2px;
    height: 50px;
    font-size: 14px;
    border: 0;
    background: #fff;
    border-radius: 0px; }
    .search_container input#states-autocomplete::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal; }
    .search_container input#states-autocomplete::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal; }
    .search_container input#states-autocomplete:-ms-input-placeholder {
      /* IE 10+ */
      font-style: normal; }
    .search_container input#states-autocomplete:-moz-placeholder {
      /* Firefox 18- */
      font-style: normal; }
  .search_container div {
    width: 76% !important;
    position: relative; } }
  @media screen and (max-width: 992px) and (max-width: 767px) {
    .search_container div {
      width: 100% !important; } }

@media screen and (max-width: 992px) {
  .search_container > div:first-child:before {
    content: ' '; }
  .search_container button {
    text-transform: capitalize;
    float: left;
    width: 17%;
    margin: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    height: 50px;
    background-image: linear-gradient(to bottom, #f1435e, #ee2738); } }
  @media screen and (max-width: 992px) and (max-width: 767px) {
    .search_container button {
      width: 20% !important; } }

@media screen and (max-width: 992px) {
    .search_container button:before {
      content: 'search';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: contents;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      color: #fff;
      z-index: 1;
      padding: 0px 8px 1px 10px; }
  .search_container input[role="combobox"] + div .item {
    padding: 0px 15px;
    font-size: 15px !important; }
  .form-wrapper-2 {
    box-shadow: 0px 0px 8px 0px #fff; }
  .home-search {
    margin-top: 3px; }
  .custom-select {
    width: 50%;
    position: relative;
    background: #f7f7f7; }
  select {
    padding: 12px 40px 15px 39px; }
  .search_bottom {
    padding: 5px 0px 0px 51px; } }
  @media screen and (max-width: 992px) and (max-width: 767px) {
    .search_bottom {
      padding: 5px 0px 0px 15px !important; } }

@media screen and (max-width: 992px) {
    .search_bottom > div:first-child {
      float: right;
      width: 60%; } }
    @media screen and (max-width: 992px) and (max-width: 767px) {
      .search_bottom > div:first-child {
        width: 100% !important;
        text-align: left; } }

@media screen and (max-width: 992px) {
      .search_bottom > div:first-child:before {
        content: ''; }
    .search_bottom span {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #62717a; } }
    @media screen and (max-width: 992px) and (max-width: 767px) {
      .search_bottom span {
        text-align: center;
        display: block; } }

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .search_container2 {
    width: 100% !important;
    margin: 0px; }
    .search_container2 input {
      width: 100% !important;
      padding: 19px 0px 11px 30px; } }

@media screen and (max-width: 600px) and (max-width: 768px) {
  input#states-autocomplete {
    font-size: 11px !important; }
  .search_container input[role="combobox"] + div {
    min-width: 100% !important; }
  .suggestionDiv div {
    max-width: 100% !important; } }

@media screen and (max-width: 600px) {
  .search_container input#states-autocomplete {
    position: relative;
    float: left;
    width: 98%;
    padding: 4px 6px 5px 2px;
    height: 50px;
    font-size: 14px;
    border: 0;
    background: #fff; }
    .search_container input#states-autocomplete::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal; }
    .search_container input#states-autocomplete::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal;
      color: #b0b0b0; }
    .search_container input#states-autocomplete:-ms-input-placeholder {
      /* IE 10+ */
      font-style: normal; }
    .search_container input#states-autocomplete:-moz-placeholder {
      /* Firefox 18- */
      font-style: normal; }
  .search_container div {
    width: 100% !important;
    position: relative; }
  .search_container > div:first-child:before {
    content: ' '; }
  .search_container button {
    text-transform: capitalize;
    float: left;
    width: 17%;
    margin: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    height: 50px;
    background-image: linear-gradient(to bottom, #f1435e, #ee2738); }
    .search_container button:before {
      content: 'search';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      color: #fff;
      z-index: 1;
      padding: 0px 8px 1px 10px; }
  .search_container input[role="combobox"] + div .item {
    padding: 0px 15px;
    font-size: 15px !important; }
  .form-wrapper-2 {
    box-shadow: 0px 0px 8px 0px #fff; }
  .home-search {
    margin-top: 3px; }
  .custom-select {
    width: 50%;
    position: relative;
    background: #f7f7f7; }
  select {
    padding: 12px 40px 15px 39px; }
  .search_bottom {
    padding: 5px 0px 0px 51px; }
    .search_bottom > div:first-child {
      float: right;
      width: 60%; }
      .search_bottom > div:first-child:before {
        content: ''; }
    .search_bottom span {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #62717a; } }

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .search_container2 {
    width: 100% !important;
    margin: 0px; }
    .search_container2 input {
      width: 100% !important;
      padding: 19px 0px 11px 30px; }
  .search_container input#states-autocomplete {
    position: relative;
    float: left;
    width: 100%;
    padding: 4px 14px 5px 14px;
    height: 50px;
    font-size: 14px;
    border: 0;
    background: #fff;
    border-top: 1px solid #f7f7f7;
    border-left: 1px solid #f7f7f7; }
    .search_container input#states-autocomplete::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-style: normal; }
    .search_container input#states-autocomplete::-moz-placeholder {
      /* Firefox 19+ */
      font-style: normal; }
    .search_container input#states-autocomplete:-ms-input-placeholder {
      /* IE 10+ */
      font-style: normal; }
    .search_container input#states-autocomplete:-moz-placeholder {
      /* Firefox 18- */
      font-style: normal; }
  .search_container div {
    width: 79% !important;
    position: relative; }
  .search_container > div:first-child:before {
    content: ' '; }
  .search_container button {
    text-transform: capitalize;
    float: left;
    margin: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    height: 50px;
    background-image: linear-gradient(to bottom, #f1435e, #ee2738); }
    .search_container button:before {
      content: 'search';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: contents;
      white-space: nowrap;
      word-wrap: normal;
      position: absolute;
      color: #fff;
      z-index: 1;
      top: 14px;
      text-align: center;
      left: 24px;
      padding: 0px; }
  .search_container input[role="combobox"] + div .item {
    padding: 0px 15px;
    font-size: 15px !important; }
  .form-wrapper-2 {
    box-shadow: 0px 0px 8px 0px #fff;
    padding: 0px 15px; }
  .home-search {
    margin-top: 3px; }
  .custom-select {
    width: 50%;
    position: relative;
    background: #f7f7f7; }
  select {
    padding: 12px 40px 15px 30px; }
  .search_bottom {
    text-align: right;
    padding: 5px 20px 0px 51px; }
    .search_bottom > div:first-child {
      float: right;
      width: 60%; }
      .search_bottom > div:first-child:before {
        content: ''; }
    .search_bottom span {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #62717a; } }

.date_pick {
  position: relative; }
  .date_pick::before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 0.8;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 15px;
    right: 5px;
    z-index: 1;
    color: #62717a; }

.datepiker {
  color: #62717a !important; }

@media only screen and (max-device-width: 991px) and (min-device-width: 320px) {
  .date_pick {
    width: 50%;
    position: relative; } }

::-webkit-input-placeholder {
  color: #b0b0b0; }

::-ms-input-placeholder {
  color: #b0b0b0; }

::placeholder {
  color: #b0b0b0; }

body {
  overflow-x: hidden; }

.home_page {
  overflow-x: hidden; }

.text_over_image {
  position: absolute;
  left: 0px;
  width: 100%;
  top: 149px; }
  @media screen and (max-width: 800px) {
    .text_over_image {
      display: none !important; } }

@media screen and (min-width: 800px) {
  .mobile_view {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .mobile_view {
    padding-left: 50px;
    padding-right: 50px; } }

@media screen and (max-width: 500px) {
  .mobile_view {
    padding-left: 15px;
    padding-right: 15px; } }

.banner .carousel {
  border-radius: 0 0 100% 100% / 112px;
  overflow: hidden;
  margin-left: -10%;
  width: calc(100% + 20%) !important; }

@media screen and (min-width: 800px) {
  .banner_heading {
    font-size: 48px;
    font-weight: 200; } }

@media screen and (min-width: 800px) {
  .banner_heading {
    text-align: left;
    color: #ffffff; } }

@media screen and (max-width: 800px) {
  .banner_heading {
    text-align: center;
    color: #000000; } }

@media screen and (max-width: 768px) {
  .banner_heading {
    font-size: 24px;
    font-weight: 200;
    padding-top: 20px; } }

.banner_sub_heading {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding-top: 16px; }
  @media screen and (min-width: 800px) {
    .banner_sub_heading {
      text-align: left;
      color: #ffffff;
      padding-bottom: 8px; } }
  @media screen and (max-width: 800px) {
    .banner_sub_heading {
      padding-top: 3px;
      text-align: center;
      color: #000000; } }

.banner_description {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71; }
  @media screen and (min-width: 800px) {
    .banner_description {
      text-align: left;
      width: 334px;
      color: #ffffff; } }
  @media screen and (max-width: 800px) {
    .banner_description {
      text-align: center;
      color: #000000; } }

.download_link {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  padding-top: 7px;
  padding-bottom: 10px; }
  @media screen and (min-width: 800px) {
    .download_link {
      text-align: left; } }
  @media screen and (max-width: 800px) {
    .download_link {
      text-align: center; } }
  .download_link .link:hover {
    text-decoration: none; }
  @media screen and (min-width: 800px) {
    .download_link .link {
      color: #ffffff; } }

@media screen and (min-width: 800px) {
  .banner_content {
    padding-left: 67px; } }

@media screen and (min-width: 800px) {
  .banner_button {
    text-align: left; } }

@media screen and (max-width: 800px) {
  .banner_button {
    text-align: center; } }

.previousButton {
  position: absolute !important;
  top: 250px;
  left: 26px;
  margin-left: -21px !important; }
  @media screen and (max-width: 800px) {
    .previousButton {
      display: none !important; } }
  .previousButton :hover {
    background-color: transparent !important; }

.nextButton {
  position: absolute !important;
  top: 250px;
  right: 30px; }
  @media screen and (max-width: 800px) {
    .nextButton {
      display: none !important; } }
  .nextButton :hover {
    background-color: transparent !important; }

.featured_venue_heading {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202020;
  padding: 0px; }
  @media screen and (max-width: 767px) {
    .featured_venue_heading {
      padding-bottom: 15px;
      padding-top: 12px; } }

.featured_venue_carousel .venue .venue_image {
  height: 200px !important;
  object-fit: cover;
  object-position: top center; }

.featured_venue_carousel .alice-carousel__stage-item .venue_content {
  padding-left: 5px; }

@media screen and (max-width: 767px) {
  .featured_venue_carousel .alice-carousel__stage-item {
    margin-left: auto;
    margin-right: auto; } }

.find_venue_heading {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202020;
  padding: 20px 0px; }

.find_venues {
  padding-bottom: 80px;
  position: relative;
  background: #f7f7f7; }
  .find_venues:before {
    content: '';
    top: 0;
    left: 0;
    margin-left: -10%;
    width: calc(100% + 20%);
    height: 100%;
    position: absolute;
    background: #ffffff;
    border-radius: 0 0 100% 100% / 125px; }
  .find_venues > div {
    z-index: 1; }

.event_planner {
  padding-top: 32px;
  background-color: #f7f7f7;
  padding: 0px 15px; }

.get-qualified {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202020;
  padding-top: 32px; }

.event-planner {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #202020;
  padding-top: 2px;
  padding-bottom: 17px; }

.theresa_image {
  margin-top: 52px !important; }
  @media screen and (min-width: 1024px) {
    .theresa_image {
      margin-right: -73px !important; } }
  @media screen and (min-width: 500px) and (max-width: 1024px) {
    .theresa_image {
      margin-right: -52px !important; } }

.jennie_image {
  margin-top: 35px !important; }
  @media screen and (min-width: 1024px) {
    .jennie_image {
      margin-left: -102px !important; } }
  @media screen and (min-width: 700px) and (max-width: 1024px) {
    .jennie_image {
      margin-left: -82px !important; } }

@media screen and (max-width: 360px) {
  .event_planner_image {
    width: 270px; } }

@media screen and (min-width: 375) and (max-width: 450px) {
  .event_planner_image {
    width: 350px; } }

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .event_planner_image {
    width: 271px; } }

@media screen and (max-width: 800px) {
  .event_planner_heading {
    text-align: center; } }

@media screen and (min-width: 800px) {
  .event_planner_heading {
    padding-left: 75px; } }

.event_planner_name {
  font-size: 16px;
  line-height: 1.5;
  color: #202020; }

.verified_image {
  padding-left: 5px; }

.be_ready {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center; }

.device_image {
  padding-top: 22px; }

@media screen and (max-width: 500px) {
  .device_image_dimension {
    width: 310px;
    height: 208px; } }

.event_planners {
  font-size: 16px;
  font-weight: 600;
  color: #36434b; }
  @media screen and (min-width: 1024px) {
    .event_planners {
      padding: 0px 63px; } }
  @media screen and (max-width: 700px) {
    .event_planners {
      text-align: center; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .event_planners {
      padding: 0px 12px; } }

.size_style {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #36434b;
  text-transform: uppercase; }
  @media screen and (min-width: 1024px) {
    .size_style {
      padding: 5px 50px 5px 63px; } }
  @media screen and (max-width: 700px) {
    .size_style {
      text-align: center;
      padding-top: 10px;
      font-size: 20px; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .size_style {
      padding: 0px 12px; } }

.event_planner_content {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #36434b; }
  @media screen and (min-width: 1024px) {
    .event_planner_content {
      padding: 0px 63px; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .event_planner_content {
      padding: 0px 28px 0px 12px; } }

.venue_managers {
  font-size: 16px;
  font-weight: bold;
  color: #ee2738; }
  @media screen and (max-width: 700px) {
    .venue_managers {
      text-align: center; } }
  @media screen and (min-width: 1024px) {
    .venue_managers {
      padding: 0px 76px; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .venue_managers {
      padding: 0px 12px; } }

.sync_your {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #36434b;
  text-transform: uppercase; }
  @media screen and (min-width: 1024px) {
    .sync_your {
      padding: 5px 0px 5px 76px; } }
  @media screen and (max-width: 700px) {
    .sync_your {
      text-align: center;
      padding-top: 10px;
      font-size: 20px; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .sync_your {
      padding: 0px 0px 0px 12px; } }

.venue_manager_content {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #36434b; }
  @media screen and (min-width: 1024px) {
    .venue_manager_content {
      padding: 0px 76px; } }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .venue_manager_content {
      padding: 0px 41px 0px 12px; } }

.how_it_work_screen {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px; }
  @media screen and (max-width: 768px) {
    .how_it_work_screen {
      padding-bottom: 0px; } }

.how_it_works {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #202020; }
  @media screen and (max-width: 767px) {
    .how_it_works {
      padding-top: 50px; } }
  @media screen and (max-width: 500px) {
    .how_it_works {
      padding: 50px 0px 10px 0px; } }
  @media screen and (min-width: 768px) {
    .how_it_works {
      padding: 28px 0px 22px 0px; } }

@media screen and (max-width: 768px) {
  .event_planners_section {
    padding: 10px 0px 20px 0px;
    text-align: center; } }

@media screen and (max-width: 768px) {
  .venue_managers_section {
    padding: 0px 0px 0px 0px; } }

.image_link:hover {
  text-decoration: none !important;
  cursor: pointer; }

.marginTopD {
  margin-top: 20px; }
  @media screen and (max-width: 768px) {
    .marginTopD {
      margin-top: 10px; } }

.footer {
  background-color: #1a181e; }
  @media screen and (min-width: 1024px) {
    .footer {
      padding: 52px 200px 20px 200px; } }
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    .footer {
      padding: 52px 30px 20px 30px; } }
  @media screen and (max-width: 768px) {
    .footer {
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center; } }
  .footer .copyright {
    font-size: 12px;
    padding-top: 83px;
    color: #747474; }
  .footer .logo {
    padding-bottom: 38px; }
    @media screen and (max-width: 768px) {
      .footer .logo {
        padding-bottom: 20px; } }
  .footer .footer_links {
    font-size: 13px;
    line-height: 1.54;
    color: #ffffff; }
    .footer .footer_links:hover {
      text-decoration: none;
      cursor: pointer; }
  @media screen and (min-width: 768px) {
    .footer .button_section {
      justify-content: flex-end; } }
  @media screen and (max-width: 500px) {
    .footer .button_section {
      padding-top: 10px;
      justify-content: center; } }
  @media screen and (min-width: 768px) {
    .footer .links_section {
      justify-content: left; } }
  @media screen and (max-width: 500px) {
    .footer .links_section {
      justify-content: center;
      text-align: center; } }
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .footer .footer {
      padding: 52px 50px 20px 50px !important; } }

a {
  text-decoration: none; }

button[disabled] {
  opacity: 0.5; }

.banner {
  position: relative; }
  .banner .nextButton {
    position: absolute !important;
    top: 50%;
    right: 40px; }
    .banner .nextButton:hover {
      background-color: transparent; }
  .banner .nextButton {
    position: absolute !important;
    top: 50%;
    right: auto; }
  .banner .previousButton {
    position: absolute !important;
    top: 50%;
    left: auto; }

/* container */
.fh-container {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto; }

.color-trans {
  background-color: transparent !important;
  box-shadow: none !important; }

.pos-0 {
  left: 0 !important;
  position: absolute !important; }

@media (max-width: 1199px) {
  .fh-container {
    max-width: 1170px; } }

.matter {
  font-family: Raleway !important; }

.venue-name {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  word-break: break-word;
  color: #ffffff; }

.venue_page {
  overflow-x: hidden;
  margin-top: 62px; }
  @media screen and (max-device-width: 500px) {
    .venue_page {
      margin-top: 66.5px; } }
  @media screen and (min-device-width: 501px) and (max-device-width: 1023px) {
    .venue_page {
      margin-top: 74px; } }
  .venue_page .h {
    display: none; }
  .venue_page .buttons-header {
    padding: 20px 30px 20px; }
    .venue_page .buttons-header .title {
      margin-bottom: 0px; }
      @media screen and (max-width: 767px) {
        .venue_page .buttons-header .title {
          display: none; } }
  @media screen and (max-width: 767px) {
    .venue_page .space-planner-edit-outer-grid .bordered {
      padding: 5px;
      overflow: hidden; } }
  .venue_page .space-planner-edit-outer-grid .bordered ul li {
    overflow: visible;
    padding-left: 5px !important;
    padding-right: 5px !important;
    justify-content: space-between;
    display: flex; }
    .venue_page .space-planner-edit-outer-grid .bordered ul li .delete {
      background-color: transparent; }
    @media screen and (max-width: 991px) {
      .venue_page .space-planner-edit-outer-grid .bordered ul li .text {
        width: 65%; } }
    @media screen and (max-width: 767px) {
      .venue_page .space-planner-edit-outer-grid .bordered ul li .text {
        width: 70%; } }
  @media screen and (max-width: 767px) {
    .venue_page .right-event-placehold {
      padding-top: 15px; } }
  .venue_page .venue_details_image {
    position: relative; }
  .venue_page .aboutHead {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }
  .venue_page .aboutMatter {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    word-break: break-word;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .venue_page .EventPlannerHeader-appbar-106 {
    color: #36434b;
    box-shadow: inset 0 -1px 0 0 #fff !important;
    background-color: #ffffff !important; }
  .venue_page .MuiTypography-body1-208 {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .venue_page .MuiTypography-h4-214 {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }
  .venue_page .leftPanel {
    width: 80%; }
    .venue_page .leftPanel .topImage {
      border: #000 1px solid;
      height: 394px; }
  .venue_page .rightPanel {
    width: 20%; }
    .venue_page .rightPanel .rightMap {
      border: #000 1px solid;
      height: 394px; }
  .venue_page .midContainer {
    margin: 10px 25px 25px 25px; }
    @media screen and (max-width: 767px) {
      .venue_page .midContainer .justify {
        justify-content: space-between; } }
    .venue_page .midContainer .venue-available {
      width: 100%;
      font-family: Raleway !important; }
      .venue_page .midContainer .venue-available .mr-2 {
        padding-right: 25px; }
      .venue_page .midContainer .venue-available .avilableDescriptionDiv {
        width: 80%; }
        .venue_page .midContainer .venue-available .avilableDescriptionDiv .avilableDescription {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #62717a;
          padding-top: 15px; }
      .venue_page .midContainer .venue-available .dayTimeDiv {
        width: 100%;
        color: #202020; }
        .venue_page .midContainer .venue-available .dayTimeDiv .mondFriDiv {
          width: 13%;
          float: left; }
        .venue_page .midContainer .venue-available .dayTimeDiv .satSunfDiv {
          width: 26%;
          float: left; }
      .venue_page .midContainer .venue-available .availabilitytext {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.12;
        letter-spacing: normal;
        text-align: left;
        color: #36434b; }
  .venue_page .spaceVanueDiv {
    width: 100%;
    min-height: 385px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff; }
  .venue_page .similarDiv {
    width: 100%; }
    .venue_page .similarDiv .similarDiv .active_icon_over_image {
      left: -44px; }
    .venue_page .similarDiv .similarDiv .active_icon_over_image {
      color: red;
      position: absolute;
      width: 100%;
      top: 2px; }
    .venue_page .similarDiv .similarContainer {
      width: 100%;
      padding: 10px; }
      .venue_page .similarDiv .similarContainer .similerText {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #36434b;
        padding-bottom: 10px; }
        @media screen and (max-width: 767px) {
          .venue_page .similarDiv .similarContainer .similerText {
            padding-bottom: 0px; } }
  .venue_page .what-we-love-block {
    padding-right: 25px;
    margin-top: 25px; }
    @media screen and (max-width: 767px) {
      .venue_page .what-we-love-block {
        margin: 15px auto 10px; } }
    .venue_page .what-we-love-block .what-we-love-listing {
      display: block;
      margin-left: 8px;
      width: 100%; }
      .venue_page .what-we-love-block .what-we-love-listing ul {
        display: block;
        width: 50%;
        float: left;
        list-style: none;
        padding-left: 20px; }
        @media screen and (max-width: 767px) {
          .venue_page .what-we-love-block .what-we-love-listing ul {
            width: 100%; } }
        .venue_page .what-we-love-block .what-we-love-listing ul li {
          position: relative;
          padding-top: 0px !important;
          padding-bottom: 0px !important; }
        .venue_page .what-we-love-block .what-we-love-listing ul li:before {
          content: "\2022";
          color: #3C97E1;
          font-weight: bold;
          position: absolute;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
          font-size: 25px;
          top: 0;
          margin-top: -8px; }
  .venue_page .venue-available-right {
    width: 100%;
    position: relative;
    padding-left: 10px; }
    .venue_page .venue-available-right .buttonAdd {
      position: absolute;
      right: 5px;
      top: 7px; }
    .venue_page .venue-available-right h3 {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
      float: left;
      margin: 5px 0px 20px; }
    .venue_page .venue-available-right .help_icon {
      float: left;
      padding: 6px 0px 11px !important; }
      .venue_page .venue-available-right .help_icon .material-icons {
        font-size: 13px;
        color: #1397e1; }
    .venue_page .venue-available-right .venueRightContainer {
      width: 100%;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      padding: 8px 8px; }
      .venue_page .venue-available-right .venueRightContainer .spaceImage {
        width: 100%; }
  .venue_page .photoCredit {
    font-size: 12px;
    font-style: italic;
    padding: 0px 0px 0px 12px; }
    .venue_page .photoCredit span {
      text-transform: capitalize; }
    .venue_page .photoCredit a {
      text-transform: capitalize;
      font-weight: 600;
      color: #62717a; }

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .venue_page .EventPlannerHeader-appbar-106 {
    color: #36434b;
    box-shadow: inset 0 -1px 0 0 #fff !important;
    background-color: #ffffff !important; }
  .venue_page .MuiTypography-body1-208 {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .venue_page .MuiTypography-h4-214 {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }
  .venue_page .leftPanel {
    width: 80%; }
    .venue_page .leftPanel .topImage {
      border: #000 1px solid;
      height: 394px; }
  .venue_page .rightPanel {
    width: 20%; }
    .venue_page .rightPanel .rightMap {
      border: #000 1px solid;
      height: 394px; }
  .venue_page .midContainer {
    margin: 14px;
    padding-left: 10px;
    padding-right: 10px; }
    .venue_page .midContainer .venue-available {
      width: 100%;
      font-family: Raleway !important; }
      .venue_page .midContainer .venue-available .avilableDescriptionDiv {
        width: 80%; }
        .venue_page .midContainer .venue-available .avilableDescriptionDiv .avilableDescription {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #62717a; }
      .venue_page .midContainer .venue-available .dayTimeDiv {
        width: 100%; }
        .venue_page .midContainer .venue-available .dayTimeDiv .mondFriDiv {
          width: 40%;
          float: left; }
        .venue_page .midContainer .venue-available .dayTimeDiv .satSunfDiv {
          width: 100%;
          float: left; }
      .venue_page .midContainer .venue-available .availabilitytext {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.12;
        letter-spacing: normal;
        text-align: left;
        color: #36434b; }
  .venue_page .spaceVanueDiv {
    width: 100%;
    min-height: 385px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff; }
  .venue_page .similarDiv {
    width: 100%; }
    .venue_page .similarDiv .similarContainer {
      width: 100%;
      padding: 10px; }
      .venue_page .similarDiv .similarContainer .similerText {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #36434b; }
  .venue_page .venue-available-right {
    width: 100%;
    margin-top: 10px;
    padding-left: 0px; }
    .venue_page .venue-available-right .buttonAdd {
      position: absolute;
      right: 0px; }
    .venue_page .venue-available-right h3 {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
      margin: 5px 0px 10px; }
    .venue_page .venue-available-right .venueRightContainer {
      width: 100%;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff; }
      .venue_page .venue-available-right .venueRightContainer .spaceImage {
        width: 100%; } }

.venue_space_venue_card {
  padding: 8px 8px;
  width: 50%; }

.similar_venue_card {
  padding-left: 20px;
  padding-right: 20px; }

.venue_details_image .image_of_venue {
  width: 100%;
  height: 394px;
  object-fit: cover; }
  @media screen and (max-width: 767px) {
    .venue_details_image .image_of_venue {
      height: auto; } }

.venue_details_image .venue_details_over_image {
  width: 100%;
  position: absolute;
  bottom: 3px;
  /* attaches the element to the bottom */
  left: 0;
  /* attaches the element to the left-side */
  right: 0;
  /* attaches the element to the right-side */
  padding: 20px 0 20px 20px; }
  @media screen and (max-width: 767px) {
    .venue_details_image .venue_details_over_image {
      position: relative;
      background-image: none; } }
  .venue_details_image .venue_details_over_image .div1 {
    width: 45%; }

@media screen and (min-width: 1023px) and (max-width: 1240px) {
  .venue_details_image .venue_details_over_image {
    margin-top: -62px; } }

.venue_details_image .venue_name_in_venue_details_image {
  padding-left: 0px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  text-align: left;
  color: #fff !important; }
  @media screen and (max-width: 767px) {
    .venue_details_image .venue_name_in_venue_details_image {
      color: #42434b !important; } }

.venue_details_image .detail_image .venue_address_details_in_venue_details_image span {
  margin-top: 10px;
  padding-bottom: 10px; }

@media screen and (max-width: 767px) {
  .venue_details_image .venue-gallery button {
    color: #42434b;
    border: 1px solid #42434b; } }

.venue_details_image .venue_address_details_in_venue_details_image {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  width: 40%;
  height: auto;
  display: flex;
  align-items: flex-end; }
  @media screen and (max-width: 767px) {
    .venue_details_image .venue_address_details_in_venue_details_image {
      color: #42434b;
      text-align: left !important;
      position: relative; } }
  @media screen and (max-width: 767px) {
    .venue_details_image .venue_address_details_in_venue_details_image {
      padding-bottom: 10px;
      padding-left: 20px; } }
  .venue_details_image .venue_address_details_in_venue_details_image .icon_container {
    margin-top: 4px;
    padding-left: 23px; }

.venue_details_image .place_icon_in_venue_details_image {
  width: 20px;
  height: 20px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ee2738;
  margin-right: 5px;
  margin-top: 5px; }
  @media screen and (max-device-width: 767px) {
    .venue_details_image .place_icon_in_venue_details_image {
      position: absolute;
      left: -3px;
      top: 0px; } }

.venue_details_image .view_photos {
  border: #ffffff 1px solid; }

@media screen and (max-device-width: 500px) {
  .venue_details_image .view_photos_container {
    padding: 10px 15px 10px 0px; } }

.white-button {
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  color: #ee2738;
  cursor: pointer;
  padding: 6px 12px; }
  .white-button span {
    margin: 0 !important;
    padding: 0 !important; }

.VenueCardWithFav-activeFav-295,
.VenueCardWithFav-favIcon-294 {
  margin: 10px; }

.calendar_instruction {
  padding: 30px 0px 10px 0px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #36434b; }

textarea {
  resize: none;
  height: 70px; }

@media screen and (min-device-width: 501px) and (max-device-width: 767px) {
  .venue_details_image .view_photos_container {
    padding: 10px 15px 10px 25px; }
    .venue_details_image .view_photos_container button {
      border: solid 1px black;
      color: #000; }
    .venue_details_image .view_photos_container .venue_details_over_image {
      position: absolute; } }

.venue-gallery button {
  border: 1px solid #fff; }

.satSunfDiv p {
  font-weight: bold; }

.space-planner-edit-outer-grid
ul li .delete::before, .space-planner-edit-outer-grid
ul li .delete::after {
  display: none; }

.venue_page .space-planner-edit .planner-title-block h3 {
  font-size: 13.5px; }

.venue_page .space-planner-edit .planner-title-block .help_icon {
  display: inline-flex;
  vertical-align: middle; }
  @media screen and (max-width: 767px) {
    .venue_page .space-planner-edit .planner-title-block .help_icon {
      padding-left: 10px; } }

.venue_page .add-btn {
  margin-top: 25px;
  padding: 7px 30px; }

/***
* == Monday to Friday Style
***/
.monTextDiv ul li div span {
  font-family: Raleway !important;
  color: #202020 !important;
  font-size: 15px !important; }

.monTextDiv ul li div p {
  font-weight: bold;
  color: #62717a; }

.monTextDiv .monfriText {
  font-family: Raleway !important;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #202020; }

.monTextDiv .MuiListItem-gutters-162 {
  padding: 3px 0px 20px 4px !important; }

.monfriText li {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.matter {
  font-family: Raleway !important; }

.space-info .contents {
  width: 80%;
  height: auto;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #36434b; }

@media screen and (max-width: 767px) {
  .space-info .vilableDescriptionDiv {
    padding-top: 20px; } }

.space-info .alice-carousel {
  margin-top: 0px; }

.space-info .blue-heading {
  color: #1397e1 !important;
  margin: 20px 0 5px;
  font-weight: bold; }

.space-info .black-heading {
  color: #36434b !important;
  margin: 10px 0 5px;
  font-weight: bold; }

.space-info .dayTimeDiv {
  width: 100%;
  color: #202020; }
  .space-info .dayTimeDiv .mondFriDiv {
    width: 100%;
    float: left; }
  .space-info .dayTimeDiv .monTextDiv .MuiListItem-gutters-162 {
    padding: 3px 0px 20px 0px !important; }
  .space-info .dayTimeDiv .avgPriceDiv {
    width: 100%;
    float: left;
    padding-top: 5px;
    position: relative; }
    .space-info .dayTimeDiv .avgPriceDiv .additional-list {
      list-style: none; }
      .space-info .dayTimeDiv .avgPriceDiv .additional-list li {
        padding-left: 18px; }
      .space-info .dayTimeDiv .avgPriceDiv .additional-list li:before {
        content: "";
        line-height: 7px;
        width: 7px;
        height: 7px;
        margin: 7px 5px 0 4px;
        background-color: #1397e1;
        float: left;
        border-radius: 55%;
        position: absolute;
        left: 0px; }
  .space-info .dayTimeDiv .satSunfDiv {
    width: 26%;
    float: left; }

.space-info .avilableDescriptionDiv {
  width: 80%; }
  .space-info .avilableDescriptionDiv .description {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
    .space-info .avilableDescriptionDiv .description p {
      margin: 20px 0 !important; }

@media screen and (max-width: 767px) {
  .space-info .mondFriDiv {
    width: 100% !important; } }

.contact_button button {
  color: #ee2738 !important;
  padding: 10px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Raleway !important;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  margin-top: 10px; }

.contactBtn:hover {
  color: #ee2738;
  background-color: #ffffff; }

.contackInfoDiv {
  padding-top: 15px;
  width: 100%; }
  .contackInfoDiv .managerInfo .MuiCardHeader-content-273 span {
    font-family: Raleway !important;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .contackInfoDiv .MuiPaper-elevation1-123 {
    box-shadow: none !important; }
  .contackInfoDiv .MuiCardHeader-action-246 {
    margin-right: 4px !important; }
    .contackInfoDiv .MuiCardHeader-action-246 button {
      border-radius: 6px;
      border: none;
      background-color: #fff !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.46px;
      text-align: center;
      color: #ee2738;
      top: 9px;
      padding: 11px 16px;
      text-align: center;
      text-decoration: none;
      display: inline-block; }
  .contackInfoDiv .MuiButton-contained-264 {
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    color: #ee2738; }
  .contackInfoDiv .managerDiv {
    width: 100%; }
  .contackInfoDiv .managerInfoDiv {
    width: 100%;
    padding: 0px 16px 0px 4px; }
  .contackInfoDiv .managerInfo {
    width: 100%;
    padding: 11px;
    background-color: #f0f0f0 !important; }
    .contackInfoDiv .managerInfo .contactDiv {
      width: 100%;
      min-width: 350px; }
  .contackInfoDiv .listDiv {
    padding-top: 20px; }
    .contackInfoDiv .listDiv .ulDiv {
      border-radius: 6px;
      border: solid 1px #f0f0f0;
      background-color: #f7f7f7;
      width: 100%;
      padding: 6px; }
      .contackInfoDiv .listDiv .ulDiv .ulDiv li .MuiListItem-root-154 {
        padding-top: -2px !important;
        padding-bottom: 8px !important; }
    .contackInfoDiv .listDiv .iconClass {
      background-color: #1397e1 !important;
      font-family: material;
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff; }
    .contackInfoDiv .listDiv .MuiListItem-root-154 {
      padding-top: 0px !important;
      padding-bottom: 3px !important; }
    .contackInfoDiv .listDiv .MuiAvatar-root-250 {
      width: 21px;
      height: 28px; }
    .contackInfoDiv .listDiv .MuiSvgIcon-root-279 {
      width: 20px;
      height: 20px; }
  .contackInfoDiv .MuiCardHeader-root-244 {
    width: 100% !important; }
  .contackInfoDiv .MuiCardHeader-root-244 {
    display: flex;
    padding: 0px;
    align-items: center; }
  .contackInfoDiv .MuiTypography-body2-207 {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .contackInfoDiv .MuiCardHeader-subheader-249 {
    font-family: Raleway !important;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .contackInfoDiv .infoMenu div {
    font-family: Raleway !important;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }

@media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
  .contackInfoDiv {
    padding-top: 15px;
    width: 100%; }
    .contackInfoDiv .contactBtn {
      padding: 10px 7px; }
    .contackInfoDiv .MuiCardHeader-action-246 button {
      border-radius: 6px;
      border: none;
      background-color: #fff !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.46px;
      text-align: center;
      color: #ee2738;
      top: 7px;
      padding: 11px 16px;
      text-align: center;
      text-decoration: none;
      display: inline-block; }
    .contackInfoDiv .MuiButton-contained-264 {
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      color: #ee2738; }
    .contackInfoDiv .managerDiv {
      width: 100%; }
    .contackInfoDiv .managerInfoDiv {
      width: 100%;
      padding: 0px 0px 0px 4px; }
    .contackInfoDiv .managerInfo {
      width: 100%;
      padding: 11px;
      background-color: #f0f0f0 !important; }
      .contackInfoDiv .managerInfo .contactDiv {
        width: 100%;
        min-width: 350px; }
    .contackInfoDiv .listDiv {
      padding-top: 20px; }
      .contackInfoDiv .listDiv .ulDiv {
        border-radius: 6px;
        border: solid 1px #f0f0f0;
        background-color: #f7f7f7;
        width: 100%;
        padding: 6px; }
        .contackInfoDiv .listDiv .ulDiv .ulDiv li .MuiListItem-root-154 {
          padding-top: -2px !important;
          padding-bottom: 8px !important; }
      .contackInfoDiv .listDiv .iconClass {
        background-color: #1397e1 !important;
        font-family: material;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff; }
      .contackInfoDiv .listDiv .MuiListItem-root-154 {
        padding-top: 0px !important;
        padding-bottom: 3px !important; }
      .contackInfoDiv .listDiv .MuiAvatar-root-250 {
        width: 21px;
        height: 28px; }
      .contackInfoDiv .listDiv .MuiSvgIcon-root-279 {
        width: 20px;
        height: 20px; }
    .contackInfoDiv .MuiCardHeader-root-244 {
      width: 100% !important; }
    .contackInfoDiv .MuiCardHeader-root-244 {
      display: flex;
      padding: 0px;
      align-items: center; }
    .contackInfoDiv .MuiTypography-body2-207 {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #62717a; }
    .contackInfoDiv .MuiCardHeader-subheader-249 {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #62717a; }
    .contackInfoDiv .infoMenu .MuiTypography-subheading-206 {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b; }
      .contackInfoDiv .infoMenu .MuiTypography-subheading-206:before {
        content: 'local_airport';
        font-family: 'Material Icons';
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        position: absolute;
        top: 5px;
        left: 12px;
        color: #1397e1;
        z-index: 1; } }

::-webkit-scrollbar {
  width: 5px !important; }

.MuiDialog-paperWidthSm-226 {
  max-width: 100% !important;
  width: 100% !important;
  max-height: 100% !important; }

.MuiPaper-elevation24-146 {
  box-shadow: none !important; }

.MuiBackdrop-root-308 {
  background-color: rgba(0, 0, 0, 0.7) !important; }

.MuiDialog-paper-235 {
  display: flex;
  position: relative;
  overflow-y: auto;
  flex-direction: column; }

.MuiPaper-root-120 {
  background: none !important; }

.contact_modal {
  overflow-y: scroll !important; }

.dialog-content {
  overflow-y: scroll !important; }

.react-datepicker-popper {
  position: absolute;
  will-change: transform;
  top: 0px; }

.datepiker {
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #62717a; }

.dialogActions {
  height: 40px;
  font-family: material;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  position: absolute;
  right: 0; }
  .dialogActions span {
    cursor: pointer !important; }

.closeDialog {
  width: 22px;
  height: 22px;
  font-family: material;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff; }

.dialog-content {
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 0; }

.modalBox_content {
  height: 100%;
  overflow: hidden; }

.main_place_hold_section {
  width: 600px;
  border-radius: 6px;
  background-color: #f0f0f0;
  border: 2px solid white !important;
  height: 100%; }

.header {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  margin-top: 0;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0; }

.contact_modal_container {
  width: 100% !important;
  min-height: auto !important;
  /* create new event form */
  /* Events */ }
  @media (max-width: 767px) {
    .contact_modal_container .tell-us-about-your-event {
      margin-bottom: 0px !important; } }
  .contact_modal_container .tell-us-about-your-event .form-content {
    max-height: 377px !important; }
    @media (max-width: 767px) {
      .contact_modal_container .tell-us-about-your-event .form-content {
        max-height: 370px !important; } }
  .contact_modal_container .tell-us-about-your-event .datepiker {
    padding: 0px 10px;
    font-size: 12px !important;
    height: 36px;
    border-radius: 6px;
    background-color: transparent;
    border: solid 1px #e1e1e1; }
  .contact_modal_container .tell-us-about-your-event .text-area {
    height: auto !important; }
  .contact_modal_container .tell-us-about-your-event .select-down-icon::before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 0.8;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 8px;
    right: 5px;
    color: #62717a; }
  .contact_modal_container .tell-us-about-your-event .select-down-icon {
    position: relative; }
  .contact_modal_container .tell-us-about-your-event .input-field-section {
    position: relative; }
  .contact_modal_container .tell-us-about-your-event .custom_select_with_scroll > div {
    padding: 0px 10px; }
  .contact_modal_container .tell-us-about-your-event .react-datepicker button {
    background-color: transparent; }
  .contact_modal_container .tell-us-about-your-event .form .input-field {
    font-size: 14px;
    background-color: transparent; }
  .contact_modal_container .tell-us-about-your-event textarea {
    font-size: 14px; }
  .contact_modal_container .tell-us-about-your-event .input-label {
    padding-bottom: 0 !important; }
  .contact_modal_container .tell-us-about-your-event .field-div {
    position: relative;
    margin-bottom: 5px; }
  @media screen and (max-width: 767px) {
    .contact_modal_container .tell-us-about-your-event .align-send-button {
      text-align: center !important; } }
  @media screen and (max-width: 767px) {
    .contact_modal_container .tell-us-about-your-event .align-send-button button {
      float: none;
      margin-top: 10px; } }
  .contact_modal_container .tell-us-about-your-event .error {
    position: absolute;
    display: block;
    bottom: -15px; }
    @media screen and (max-width: 767px) {
      .contact_modal_container .tell-us-about-your-event .error {
        bottom: -18px; } }
  .contact_modal_container .tell-us-about-your-event .form-content {
    margin: 5px 0px 15px;
    padding: 10px 15px !important; }
  .contact_modal_container .react-datepicker__navigation {
    padding: 0 !important; }
  .contact_modal_container .MuiDialog-paperWidthSm-226 {
    max-width: 100% !important;
    width: 100% !important;
    max-height: 100% !important; }
  .contact_modal_container .MuiPaper-elevation24-146 {
    box-shadow: none !important; }
  .contact_modal_container .MuiBackdrop-root-308 {
    background-color: rgba(0, 0, 0, 0.7) !important; }
  .contact_modal_container .MuiPaper-root-120 {
    background: none !important; }
  .contact_modal_container .MuiDialog-paper-222 {
    margin: 0 !important;
    position: absolute;
    top: 0; }
  .contact_modal_container .dialogActions {
    height: 40px;
    font-family: material;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right; }
  .contact_modal_container .closeButton {
    cursor: pointer;
    position: absolute;
    font-size: 30px;
    top: -70px;
    right: -38px;
    color: #ffffff;
    font-weight: bold; }
    .contact_modal_container .closeButton .closeButton:hover {
      color: #ee2738; }
  .contact_modal_container .dialog-content {
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 0; }
  .contact_modal_container .modalBox_content {
    height: 100%;
    overflow: hidden; }
  .contact_modal_container .main_place_hold_section {
    width: 600px;
    border-radius: 6px;
    background-color: #f0f0f0;
    border: 2px solid white !important;
    height: 100%; }
  .contact_modal_container .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .contact_modal_container .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px; }
  .contact_modal_container .header-button-div {
    float: right; }
  .contact_modal_container .align-send-button {
    float: right;
    width: 105px; }
  .contact_modal_container .uploader {
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
    background-image: url(https://www.first-hold.com/static/media/perm_contact_calendar.dd9786b4.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-color: #f0f0f0; }
  .contact_modal_container .contact_modal_container {
    min-height: 100px; }
  .contact_modal_container .uploader-content {
    width: 300px;
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto; }
  .contact_modal_container .red-text-content {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ee2738;
    margin: 140px 0 5px 0; }
  .contact_modal_container .black-text-content {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
    margin-top: 5px; }
  .contact_modal_container .white-button-big {
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    cursor: pointer; }
    .contact_modal_container .white-button-big span {
      width: 100%; }
  .contact_modal_container .select_field {
    padding: 8px 12px !important;
    border-radius: 6px;
    border: solid 1px #f0f0f0;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #202020 !important;
    text-transform: none !important; }
  .contact_modal_container .form .form-content {
    padding: 20px;
    max-height: 350px;
    overflow: auto !important; }
  .contact_modal_container .form .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .contact_modal_container .form .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px; }
  .contact_modal_container .form .header-button-div-form {
    float: right;
    width: 210px; }
  .contact_modal_container .form .dialogActions {
    height: 120px;
    font-family: material;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    padding: 20px;
    margin: 0;
    display: block;
    position: absolute;
    right: 0; }
    .contact_modal_container .form .dialogActions span {
      cursor: pointer; }
  .contact_modal_container .form .field-div {
    width: 100%;
    height: 70px;
    float: left; }
  .contact_modal_container .form .input-label {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    width: 100%;
    float: left;
    padding-bottom: 5px; }
  .contact_modal_container .form .input-field {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    float: left;
    margin: 0 !important;
    font-size: 16px;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    padding-left: 10px; }
  .contact_modal_container .form .input-field-section {
    width: 100%;
    float: left; }
  .contact_modal_container .form .big {
    width: 100% !important; }
  .contact_modal_container .form .dark-black {
    color: #36434b !important; }
  .contact_modal_container .form .light-black {
    color: #202020 !important; }
  .contact_modal_container .form .lighter-black {
    color: #62717a !important; }
  .contact_modal_container .form .small {
    width: 46% !important; }
    .contact_modal_container .form .small select {
      font-size: 15px !important; }
    .contact_modal_container .form .small input {
      font-size: 15px; }
  .contact_modal_container .form .smaller {
    width: 40% !important; }
    .contact_modal_container .form .smaller select {
      font-size: 12px !important;
      background-color: #ffffff !important;
      text-transform: uppercase !important; }
    .contact_modal_container .form .smaller input {
      font-size: 12px;
      color: #1397e1 !important;
      text-align: left;
      height: 36px;
      border-radius: 6px;
      border: solid 1px #f0f0f0;
      background-color: #ffffff; }
  .contact_modal_container .form .tall {
    height: 140px; }
  .contact_modal_container .form .spacer {
    width: 20%;
    padding: 25px 0 0 0;
    overflow: hidden;
    text-align: center; }
  .contact_modal_container .form select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    padding: 5px;
    -webkit-appearance: menulist;
    text-align: left;
    padding-left: 5px;
    width: 100%;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal; }
  .contact_modal_container .form .float-right {
    float: right; }
  .contact_modal_container .form .white-button-small {
    width: 100px !important;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    padding: 0 !important;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    float: left;
    cursor: pointer; }
    .contact_modal_container .form .white-button-small span {
      width: 100%; }
  .contact_modal_container .form .red-button-small {
    width: 100px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer; }
  @media screen and (max-device-width: 500px) {
    .contact_modal_container .form .modalBox_content {
      height: 100%;
      display: flex;
      overflow: hidden; }
    .contact_modal_container .form .newEventPopupContainer {
      width: 100% !important; }
    .contact_modal_container .form .small {
      width: 100% !important; }
    .contact_modal_container .form .header {
      height: 45px;
      padding: 0; }
    .contact_modal_container .form .header-text {
      width: 100%;
      text-align: center; }
    .contact_modal_container .form .header-button-div-form {
      width: 100%;
      margin: 10px auto;
      padding: 0 0px; }
    .contact_modal_container .form .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid; }
    .contact_modal_container .form .red-text-content {
      margin-top: 100px; }
    .contact_modal_container .form .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important; } }
  .contact_modal_container .eventDiv {
    width: 98%;
    height: 80px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    margin: 15px auto 0px;
    padding: 20px 10px; }
    .contact_modal_container .eventDiv .blue-text {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #1397e1;
      margin: 0; }
  .contact_modal_container .events-content {
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto; }
  .contact_modal_container .checkBoxDiv {
    width: 5%;
    float: left;
    display: flex;
    height: 100%;
    align-items: center; }
  .contact_modal_container .detailsDiv {
    width: 95%;
    float: left;
    display: flex;
    height: 100%; }
    .contact_modal_container .detailsDiv .actionDiv {
      width: 20%;
      float: left;
      display: flex;
      height: 100%;
      align-items: center; }
      .contact_modal_container .detailsDiv .actionDiv .hand-icon {
        width: 9.5px;
        height: 19.7px;
        background-color: #62717a;
        margin-right: 10px; }
      .contact_modal_container .detailsDiv .actionDiv .grey-text {
        font-family: Raleway;
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        color: #62717a;
        margin: 0; }
  .contact_modal_container .checkBox {
    width: 20px;
    height: 20px;
    background-color: #9aa7af !important; }
  .contact_modal_container .large-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    width: 80%; }
  .contact_modal_container .eventsSelected {
    width: 100%;
    height: 380px;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 30px; }
    .contact_modal_container .eventsSelected .footerButtonDiv {
      width: 45% !important;
      margin: 20px auto; }
    .contact_modal_container .eventsSelected .grey-button:disabled {
      height: 36px;
      border-radius: 6px;
      background-color: #9aa7af !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: none; }
    .contact_modal_container .eventsSelected .grey-button:enabled {
      height: 36px;
      border-radius: 6px;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738); }
    .contact_modal_container .eventsSelected .image {
      width: 38px;
      height: 38px;
      object-fit: contain;
      display: inline-block !important; }
    .contact_modal_container .eventsSelected .large-text {
      font-family: Raleway;
      font-size: 24px !important;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin: 10px auto; }
    .contact_modal_container .eventsSelected .description {
      font-family: Raleway;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #202020;
      margin: auto; }
    .contact_modal_container .eventsSelected .blue-text {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1397e1;
      margin: 15px auto 0 auto; }
    .contact_modal_container .eventsSelected .grey-text {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9aa7af;
      margin: 20px auto 10px auto;
      text-transform: uppercase; }
    .contact_modal_container .eventsSelected .optionsDiv {
      width: 90%;
      margin: auto;
      display: block;
      overflow: auto; }
      .contact_modal_container .eventsSelected .optionsDiv .option {
        width: 33%;
        float: left;
        text-align: center; }
      .contact_modal_container .eventsSelected .optionsDiv .option-text {
        width: 60%;
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #62717a;
        margin: auto; }
      .contact_modal_container .eventsSelected .optionsDiv .grey {
        color: #62717a !important; }
      .contact_modal_container .eventsSelected .optionsDiv .blue {
        color: #1f9ce3 !important; }
      .contact_modal_container .eventsSelected .optionsDiv .blue-circle {
        width: 20px;
        height: 20px;
        border: solid 2px #1397e1;
        background-color: #ffffff;
        margin-top: 30px; }
  .contact_modal_container .dialogFooter {
    height: 50px;
    border-radius: 6px;
    box-shadow: inset 0 1px 0 0 var(--white);
    background-color: #f7f7f7;
    display: flex;
    align-items: center; }
    .contact_modal_container .dialogFooter .buttonDiv {
      width: 20%;
      margin: auto; }
  .contact_modal_container .red-button {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer; }
  @media screen and (max-width: 500px) {
    .contact_modal_container .dialog-content {
      height: 100%;
      width: 90% !important;
      margin: auto; }
    .contact_modal_container .modalBox_content {
      height: 100%;
      overflow: hidden; }
    .contact_modal_container .header {
      height: 80x;
      margin: auto; }
    .contact_modal_container .header-text {
      width: 100%;
      text-align: center; }
    .contact_modal_container .header-button-div {
      width: 100%;
      text-align: center;
      margin: 10px 0; }
    .contact_modal_container .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid; }
    .contact_modal_container .red-text-content {
      margin-top: 100px; }
    .contact_modal_container .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important; }
    .contact_modal_container .eventDiv {
      margin: 0;
      padding: 10px;
      height: 100px; }
    .contact_modal_container .checkBoxDiv {
      width: 10% !important; }
    .contact_modal_container .detailsDiv {
      width: 90% !important;
      position: relative; }
    .contact_modal_container .large-text {
      width: 100% !important; }
    .contact_modal_container .actionDiv {
      width: 100% !important;
      height: 70% !important; }
    .contact_modal_container .eventsSelected {
      height: auto; }
    .contact_modal_container .optionsDiv {
      margin: 0 !important;
      width: 100% !important; }
    .contact_modal_container .option {
      width: 80% !important;
      float: left;
      align-items: center;
      display: flex;
      margin: auto; }
      .contact_modal_container .option .option-text {
        float: left;
        text-align: left !important; }
      .contact_modal_container .option input {
        float: left;
        margin: 0 !important; }
    .contact_modal_container .footerButtonDiv {
      width: 130px !important;
      margin: 20px auto; }
    .contact_modal_container .main_place_hold_section {
      margin: 5px auto 25px; }
      .contact_modal_container .main_place_hold_section .form-content {
        padding: 0px 0px; } }
    @media screen and (max-width: 500px) and (max-width: 767px) {
      .contact_modal_container .main_place_hold_section .form-content textarea {
        font-size: 13px; } }
  @media screen and (max-width: 500px) {
      .contact_modal_container .main_place_hold_section .date-picker {
        font-size: 12px;
        color: #1397e1 !important;
        text-align: left;
        height: 36px;
        border-radius: 6px;
        border: solid 1px #e1e1e1;
        background-color: #ffffff; }
      .contact_modal_container .main_place_hold_section .error {
        position: absolute; } }

.input-label {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #62717a;
  width: 100%;
  float: left;
  padding-bottom: 5px; }

.form-content {
  padding: 20px; }

.contact_modal_container button {
  padding: 6px 30px;
  padding-left: 30px;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  padding-left: 30px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: #ee2738; }

.textarea {
  width: 100%;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  background-color: #f7f7f7; }

.managerDiv .MuiButton-outlined-207 {
  color: #ee2738 !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  padding: 8px 15px; }

@media screen and (max-device-width: 1024px) {
  .header-text {
    font-size: 13px !important; } }

@media (max-width: 767px) {
  .start-date .react-datepicker-popper {
    left: 0px !important; }
  .end-date .react-datepicker-popper {
    right: 0px !important;
    left: auto !important; }
  .end-date .react-datepicker__triangle {
    left: auto !important;
    right: 0px; }
  .start .react-datepicker__triangle {
    left: 20px !important; } }

@media (max-width: 767px) {
  .contact_modal_container .dialog-content {
    width: 100% !important; } }

.contact_modal_container .eventsSelected .grey-button {
  margin-top: 30px !important; }

.calendar {
  border: 1px solid #f0f0f0;
  border-radius: 6px; }
  .calendar .button-calendar {
    display: block;
    text-align: center;
    border-bottom: 1px solid #f0f0f0; }
    .calendar .button-calendar button {
      background-color: transparent;
      color: #ee2738;
      box-shadow: none;
      font-weight: 700;
      font-size: 18px; }
      @media screen and (max-width: 767px) {
        .calendar .button-calendar button {
          min-width: 40px; } }
    .calendar .button-calendar .today {
      color: #1397e1;
      min-width: 140px;
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold; }
  .calendar .flexContainer {
    background-color: white; }
    .calendar .flexContainer .weekname {
      padding: 8px 0px;
      text-transform: uppercase; }
    .calendar .flexContainer .flexColumn {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #202020; }
    .calendar .flexContainer .day {
      background-color: white !important;
      color: #36434b;
      height: 50px;
      font-weight: normal;
      font-size: 14px;
      cursor: pointer; }
      .calendar .flexContainer .day .inner-grid {
        padding-left: 0px;
        position: relative;
        padding-right: 0px; }
        .calendar .flexContainer .day .inner-grid .date {
          text-align: left;
          left: 5px;
          display: block;
          top: 0;
          width: 100%;
          position: absolute;
          z-index: 2; }
        .calendar .flexContainer .day .inner-grid .event {
          position: absolute;
          width: 100%;
          height: 10px;
          top: 0px; }
        .calendar .flexContainer .day .inner-grid .single-event {
          height: 100%; }
        .calendar .flexContainer .day .inner-grid .blockOut {
          background-color: grey !important;
          height: 100%;
          cursor: default; }
        .calendar .flexContainer .day .inner-grid .makeAvailableClass {
          background-color: #ffffff !important;
          height: 100% !important; }
        .calendar .flexContainer .day .inner-grid .holdClassSecond {
          top: 0px; }
        .calendar .flexContainer .day .inner-grid .holdClass {
          top: 10px; }
        .calendar .flexContainer .day .inner-grid .holdClassSingle {
          height: 100%;
          background-color: #69c7ee !important; }
        .calendar .flexContainer .day .inner-grid .blockClassSingle {
          height: 100%;
          background-color: grey !important;
          cursor: default; }
        .calendar .flexContainer .day .inner-grid .noTimeSlotAvilable {
          height: 100%;
          background-color: #f7f7f7 !important;
          pointer-events: none; }
        .calendar .flexContainer .day .inner-grid .previous_dates {
          height: 100% !important;
          background-color: #f7f7f7 !important;
          cursor: default; }
    .calendar .flexContainer .inactive {
      background-color: #f7f7f7;
      color: #e1e1e1; }
    .calendar .flexContainer .inner-grid {
      padding: 17px 8px;
      box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
      border-radius: 0 !important; }
      .calendar .flexContainer .inner-grid .event-slot {
        min-height: auto; }

.DayPicker-Day--disabled {
  color: #DCE0E0 !important;
  background-image: none !important;
  background-color: white !important; }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: red !important;
  color: white !important; }

.DayPicker {
  font-size: 14px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #36434b; }

.DayPicker-Day {
  box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
  border-radius: 0 !important;
  padding: 17px 8px !important; }

.DayPicker-Day--outside {
  box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
  background-color: #f7f7f7;
  border-radius: 0 !important; }

.DayPicker-Weekday {
  font-size: 12px !important;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202020 !important;
  text-transform: uppercase;
  background-color: #f7f7f7;
  padding: 3px !important; }

.DayPicker-Caption {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1397e1;
  text-align: center !important;
  margin-bottom: 0px !important;
  padding: 0 0.5em 0.9em 0.5em !important;
  border-bottom: #f0f0f0 1px solid; }

.DayPicker {
  background-color: #ffffff;
  border: 1px #f0f0f0 solid;
  border-radius: 6px; }

.DayPicker-Month {
  margin: 1em 0em !important;
  margin-top: 1em;
  width: 100%; }

.DayPicker {
  width: 100%; }

.DayPicker-NavButton--prev {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABGUlEQVRoge3YMUvDQBiH8SfXwbnQoTgK9egXkECXIo7i6uDXdCzFMXMXRXeHNoNL7CBtXDw4goLQe5O0/H/jDXc85CVcAiIiIiJy9MpJPv3wV9dW+zurjWMbP/POsdjjHsvL/MbijMxi09jGz/yA3RI4/1na7mvuRq/FIuU5piHlJJ86xxIYx+s1rL/OPi/Gq1WV6iyz0QrjRCMCqFzGfcoIMHoiv4xTUGUZt8OX4in1mclDuoiAxCFdRUDCkC4jIFFI1xGQIKQPEXBgSF8i4ICQPkVAS3etNmi0gr7E6PXb1HWMrih/OYlLY3AS1/ig7Rh96v7H6K143jGYA+/R8raueTiKT92maMyGFn9QWmX9g05ERERE7HwDe2OvlhXwCscAAAAASUVORK5CYII=) !important;
  left: 20% !important; }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    .DayPicker-NavButton--prev {
      margin-right: 16.5em !important; } }
  @media screen and (max-width: 500px) {
    .DayPicker-NavButton--prev {
      margin-right: 20px !important; } }

.DayPicker-NavButton {
  width: 3.25em !important; }

.DayPicker-NavButton--next {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABRklEQVRoge2YsUoDQRCGvzkjCCGgCHYWmuJ8AT21ithY2Xi9b6dNwIcQgugLaHHRIFgEDgUbISFrY5GkiGBmdhHmK3dhhg/+3Zs9cBzHcRzHcf4N7/nhed3e347dN9MsVudFGaArjZXbj/xgR7P2b4hWoTovSkGugNWfpUHG5GT96f5Zq8ciVEQ+28dbo8akDzTnyvfDeNzZrB5eNfosQiVarao3DEgJfM3uhN1YMVOLFkCdH50J4QZYm9syj5mqCKSTUReBNDImIhBfxkwE4sqYikA8GXMRiCMTRQTsZVRnrb8SJAvL1kgeLRE6G493L8v2SHrYtSQg4fWrKQGJPojaEpBgRLGQgMhDo5UERBzjLSVA9ambTgL8qTtLq+oNg4RLYDS1PBAJpzEkQPuM7BUXEuQaeIsRJ1NS/aBzHMdxHMdx7PgGaC+8k15hqk0AAAAASUVORK5CYII=) !important;
  right: 20% !important; }
  @media screen and (max-width: 500px) {
    .DayPicker-NavButton--next {
      margin-right: 0 !important; } }

.event_calendar {
  width: 100%; }
  .event_calendar .event_info_popup {
    width: 260px;
    border-radius: 6px;
    box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
    border-radius: 6px;
    position: absolute;
    z-index: 2; }
    @media screen and (max-width: 767px) {
      .event_calendar .event_info_popup {
        margin-top: -150px; } }
    .event_calendar .event_info_popup .event_date {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      background-color: #ee2738;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      padding: 9px 13px 7px 13px; }
    .event_calendar .event_info_popup .event_name1 {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
      background: #ffffff;
      padding: 23px 13px 12px 13px; }
    .event_calendar .event_info_popup .event_name2 {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #1397e1;
      background: #ffffff; }
    .event_calendar .event_info_popup .event_type_heading {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #62717a;
      background: #ffffff;
      padding: 0px 13px; }
    .event_calendar .event_info_popup .event_type_name {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
      word-break: break-word;
      background: #ffffff;
      padding: 0px 13px 12px 13px; }
    .event_calendar .event_info_popup .calendar-close {
      margin: 0px;
      padding-right: 5px;
      color: #ffffff; }
      .event_calendar .event_info_popup .calendar-close span {
        font-size: 18px;
        color: #ffffff !important; }
    .event_calendar .event_info_popup .time_heading {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #62717a;
      background: #ffffff;
      padding: 0px 13px; }
    .event_calendar .event_info_popup .event_time {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      display: flex;
      color: #36434b;
      background: #ffffff;
      padding: 4px 13px 11px 13px; }
      .event_calendar .event_info_popup .event_time :nth-child(1)
div {
        display: flex;
        justify-content: space-between; }
      .event_calendar .event_info_popup .event_time p {
        margin-left: 45px !important; }
      .event_calendar .event_info_popup .event_time .custom_checkbox .checkmark {
        top: 3px; }
    .event_calendar .event_info_popup .event_price_heading {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #62717a;
      background: #ffffff;
      padding: 0px 13px; }
    .event_calendar .event_info_popup .event_price {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
      background: #ffffff;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      padding: 0px 13px 14px 13px; }
    .event_calendar .event_info_popup .event_price_venue {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #36434b;
      background: #ffffff;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 0px 13px 14px 13px; }

.venue-complete {
  overflow-x: hidden;
  padding-top: 180px;
  height: 100vh;
  background-color: #1397e1; }
  @media screen and (max-device-width: 500px) {
    .venue-complete {
      margin-top: 66.5px; } }
  @media screen and (min-device-width: 501px) and (max-device-width: 1023px) {
    .venue-complete {
      margin-top: 74px; } }
  .venue-complete .mask {
    background-image: linear-gradient(to top, var(#1397e1), #1cb3ff);
    justify-content: center;
    padding-bottom: 65px; }
    @media screen and (max-width: 767px) {
      .venue-complete .mask {
        height: 88vh;
        padding-bottom: 0px; } }
  .venue-complete .venueCompleteContent {
    text-align: center; }
  .venue-complete .check_circle---material {
    width: 50px;
    height: 50px;
    -webkit-text-stroke: 2px #ffffff;
    background-image: linear-gradient(to bottom, #59e8a9, #2dcc70);
    font-family: material;
    font-size: 60px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px white solid;
    border-radius: 50%; }
  .venue-complete .thankYouText {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 40px; }
  .venue-complete .venue-name {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: capitalize; }
  .venue-complete .button-bg {
    height: 36px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    margin-top: 65px; }
    .venue-complete .button-bg .button-text {
      width: 100%;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
    .venue-complete {
      padding-top: 20px; }
      .venue-complete .thankYouText {
        width: 90%;
        margin: 40px auto; }
      .venue-complete .button-bg {
        margin-top: 25px; } }

.space-complete {
  overflow-x: hidden;
  padding-top: 180px;
  background-color: #1397e1; }
  @media screen and (max-device-width: 500px) {
    .space-complete {
      margin-top: 66.5px; } }
  @media screen and (min-device-width: 501px) and (max-device-width: 1023px) {
    .space-complete {
      margin-top: 74px; } }
  .space-complete .mask {
    height: 580px;
    background-image: linear-gradient(to top, var(#1397e1), #1cb3ff);
    justify-content: center; }
  .space-complete .venueCompleteContent {
    text-align: center; }
  .space-complete .check_circle---material {
    width: 50px;
    height: 50px;
    -webkit-text-stroke: 2px #ffffff;
    background-image: linear-gradient(to bottom, #59e8a9, #2dcc70);
    font-family: material;
    font-size: 60px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px white solid;
    border-radius: 50%; }
  .space-complete .thankYouText {
    width: 420px;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ffffff;
    margin: 10px auto;
    text-align: left; }
  .space-complete .title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 10px 0 0 0;
    text-transform: uppercase; }
  .space-complete .sub-title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0; }
  .space-complete .button-bg {
    height: 36px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    margin-top: 65px; }
    .space-complete .button-bg .button-text {
      width: 100%;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
  .space-complete .link-conatiner {
    padding-top: 10px; }
    .space-complete .link-conatiner a {
      text-decoration: none;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold; }
    .space-complete .link-conatiner a:hover {
      color: #cbd0d4; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
    .space-complete {
      padding-top: 20px; }
      .space-complete .thankYouText {
        width: 90%; }
      .space-complete .button-bg {
        margin-top: 45px; } }

.step_progress_bar {
  padding: 10px 40px;
  box-shadow: inset 0 -1px 1px 0 #e1e1e1; }
  @media screen and (max-width: 767px) {
    .step_progress_bar {
      padding: 10px 15px; } }
  .step_progress_bar .mobile_view_progress_bar {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px; }
  .step_progress_bar .indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .step_progress_bar .indexedStep.accomplished {
    background-image: linear-gradient(to bottom, #2bc8f2, #1397e1); }
  .step_progress_bar .save_and_exit {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.46px;
    color: #202020;
    text-decoration: none;
    padding-top: 13px; }
  .step_progress_bar .add_venue_heading {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #202020;
    display: flex;
    align-items: center; }
  .step_progress_bar .progress_bar {
    padding-top: 9px;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .step_progress_bar .progress_bar {
        padding-top: 0px; } }

.image-design .upload-block .crop-block {
  display: block; }
  .image-design .upload-block .crop-block .wid-50 {
    width: 50%;
    display: inline-block;
    padding: 10px 0px; }
    @media screen and (max-width: 767px) {
      .image-design .upload-block .crop-block .wid-50 {
        padding: 20px 0px; } }
    .image-design .upload-block .crop-block .wid-50 input {
      position: relative !important; }
      @media screen and (max-width: 767px) {
        .image-design .upload-block .crop-block .wid-50 input {
          width: 108px; } }
  @media screen and (max-width: 767px) {
    .image-design .upload-block .crop-block button {
      width: 45% !important; } }
  .image-design .upload-block .crop-block button input {
    position: relative !important; }

.image-design .upload-block .error {
  width: auto;
  text-align: center; }

.add_venue {
  padding-top: 65px;
  height: 100%; }
  .add_venue .add_venue_name {
    padding: 11px 40px 0px 40px;
    background-color: #f7f7f7; }
    @media screen and (max-width: 767px) {
      .add_venue .add_venue_name {
        padding: 15px 15px 32px 15px; } }
    .add_venue .add_venue_name .add_venue_container {
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      margin: 0px 0px 20px 0px;
      height: 505px;
      padding-top: 42px; }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_name .add_venue_container {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 15px;
          height: auto;
          padding-bottom: 15px;
          margin-bottom: 15px; } }
    .add_venue .add_venue_name .code_visible {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      text-align: left;
      color: #62717a;
      padding-top: 5px; }
      @media screen and (max-width: 500px) {
        .add_venue .add_venue_name .code_visible {
          padding-left: 5px;
          padding-right: 5px; } }
    .add_venue .add_venue_name .venue_type {
      padding-top: 26px; }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_name .venue_type {
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 15px; } }
    .add_venue .add_venue_name .venue_code {
      padding-top: 26px; }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_name .venue_code {
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 15px; } }
      .add_venue .add_venue_name .venue_code .upper_case_input .error {
        position: relative; }
    @media screen and (max-width: 500px) {
      .add_venue .add_venue_name .venue_name {
        padding-left: 5px;
        padding-right: 5px; } }
  .add_venue .add_venue_photo {
    padding: 11px 40px 94px 6px;
    background-color: #f7f7f7; }
    .add_venue .add_venue_photo .wid-50 {
      display: inline-block !important;
      width: 50%;
      padding: 15px 0px !important; }
    @media screen and (max-width: 767px) {
      .add_venue .add_venue_photo .venue-profile-cropper-block button {
        width: 45% !important; } }
    @media screen and (max-width: 320px) {
      .add_venue .add_venue_photo .venue-profile-cropper-block button {
        width: 45% !important; } }
    @media screen and (max-width: 767px) {
      .add_venue .add_venue_photo {
        padding: 15px 15px 15px 15px; } }
    @media screen and (max-width: 767px) {
      .add_venue .add_venue_photo .image_detail_form {
        padding: 15px;
        padding-bottom: 25px; } }
    .add_venue .add_venue_photo .image_detail_form .venue_image_upload {
      padding-bottom: 6px;
      margin-bottom: 6px; }
    .add_venue .add_venue_photo .image_detail_form .field_div {
      display: block;
      padding: 10px 0px 0px; }
      .add_venue .add_venue_photo .image_detail_form .field_div .checkbox {
        margin-bottom: 5px; }
        .add_venue .add_venue_photo .image_detail_form .field_div .checkbox .checkbox .container .checkmark:after {
          left: 4px;
          top: 0px; }
    @media screen and (max-width: 767px) {
      .add_venue .add_venue_photo .image_detail_form .venue-profile-cropper-block {
        padding-top: 15px; } }
    .add_venue .add_venue_photo .image_detail_form .checkbox {
      margin-top: 10px; }
      .add_venue .add_venue_photo .image_detail_form .checkbox .container {
        margin-bottom: 0px;
        color: rgba(0, 0, 0, 0.87); }
    .add_venue .add_venue_photo .add_venue_photo_container {
      border: solid 1px #e1e1e1;
      background-color: #ffffff; }
    .add_venue .add_venue_photo .learning_hall_container {
      border-bottom: solid 1px #e1e1e1;
      word-break: break-all; }
    .add_venue .add_venue_photo .learning_hall {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      text-transform: capitalize;
      margin: 20px auto;
      color: #1397e1; }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_photo .learning_hall {
          margin: 15px auto; } }
    .add_venue .add_venue_photo .add_venue_profile_photo_heading {
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
      color: #202020;
      padding: 18px 0px 8px 0px; }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_photo .add_venue_profile_photo_heading {
          padding: 0px 0px 15px 0px; } }
    .add_venue .add_venue_photo .add_venue_description {
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
      color: #202020;
      padding: 42px 0px 10px 0px; }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_photo .add_venue_description {
          padding: 15px 0px 0px 0px; } }
    .add_venue .add_venue_photo .description {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.45;
      text-align: left;
      color: #62717a; }
    .add_venue .add_venue_photo .venue_description {
      padding-bottom: 94px; }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_photo .venue_description {
          padding-bottom: 0px; } }
      .add_venue .add_venue_photo .venue_description textarea {
        border-radius: 6px;
        border: solid 1px #e1e1e1;
        background-color: #f7f7f7;
        height: 200px;
        width: 100%;
        padding: 7px 15px;
        margin-top: 4px;
        font-size: 15px;
        line-height: 1.07;
        text-align: left; }
        .add_venue .add_venue_photo .venue_description textarea::-webkit-input-placeholder {
          font-size: 15px;
          line-height: 1.07;
          text-align: left;
          color: #b0b0b0; }
        .add_venue .add_venue_photo .venue_description textarea::-ms-input-placeholder {
          font-size: 15px;
          line-height: 1.07;
          text-align: left;
          color: #b0b0b0; }
        .add_venue .add_venue_photo .venue_description textarea::placeholder {
          font-size: 15px;
          line-height: 1.07;
          text-align: left;
          color: #b0b0b0; }
  .add_venue .add_venue_address_container, .add_venue .add_venue_contact_container {
    background-color: #f7f7f7;
    padding: 11px 20px 20px 20px;
    padding-bottom: 60px; }
    @media screen and (max-width: 767px) {
      .add_venue .add_venue_address_container, .add_venue .add_venue_contact_container {
        padding: 15px; } }
    .add_venue .add_venue_address_container .city_name, .add_venue .add_venue_contact_container .city_name {
      position: relative; }
    .add_venue .add_venue_address_container .drop-down-icon::before, .add_venue .add_venue_contact_container .drop-down-icon::before {
      content: 'arrow_drop_down';
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      bottom: 8px;
      line-height: 0.8;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      z-index: 2;
      word-wrap: normal;
      position: absolute;
      right: 15px;
      color: #62717a; }
    .add_venue .add_venue_address_container .add_venue_address, .add_venue .add_venue_address_container .add_venue_contact, .add_venue .add_venue_contact_container .add_venue_address, .add_venue .add_venue_contact_container .add_venue_contact {
      padding-bottom: 62px;
      background-color: #ffffff;
      box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb; }
      .add_venue .add_venue_address_container .add_venue_address .help_icon, .add_venue .add_venue_address_container .add_venue_contact .help_icon, .add_venue .add_venue_contact_container .add_venue_address .help_icon, .add_venue .add_venue_contact_container .add_venue_contact .help_icon {
        padding-top: 44px;
        cursor: pointer; }
        @media screen and (max-width: 767px) {
          .add_venue .add_venue_address_container .add_venue_address .help_icon, .add_venue .add_venue_address_container .add_venue_contact .help_icon, .add_venue .add_venue_contact_container .add_venue_address .help_icon, .add_venue .add_venue_contact_container .add_venue_contact .help_icon {
            padding-top: 15px; } }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_address_container .add_venue_address .add_venue_address_form, .add_venue .add_venue_address_container .add_venue_contact .add_venue_address_form, .add_venue .add_venue_contact_container .add_venue_address .add_venue_address_form, .add_venue .add_venue_contact_container .add_venue_contact .add_venue_address_form {
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 15px; } }
      .add_venue .add_venue_address_container .add_venue_address .add_venue_address_form .help_icon button, .add_venue .add_venue_address_container .add_venue_contact .add_venue_address_form .help_icon button, .add_venue .add_venue_contact_container .add_venue_address .add_venue_address_form .help_icon button, .add_venue .add_venue_contact_container .add_venue_contact .add_venue_address_form .help_icon button {
        background-color: transparent;
        border: 0px;
        box-shadow: none;
        display: block;
        padding: 0px;
        margin-left: -15px; }
      .add_venue .add_venue_address_container .add_venue_address .state, .add_venue .add_venue_address_container .add_venue_contact .state, .add_venue .add_venue_contact_container .add_venue_address .state, .add_venue .add_venue_contact_container .add_venue_contact .state {
        padding-top: 15px !important; }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_address_container .add_venue_address .add_venue_contact_form, .add_venue .add_venue_address_container .add_venue_contact .add_venue_contact_form, .add_venue .add_venue_contact_container .add_venue_address .add_venue_contact_form, .add_venue .add_venue_contact_container .add_venue_contact .add_venue_contact_form {
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 15px; } }
      .add_venue .add_venue_address_container .add_venue_address .learning_hall_container, .add_venue .add_venue_address_container .add_venue_contact .learning_hall_container, .add_venue .add_venue_contact_container .add_venue_address .learning_hall_container, .add_venue .add_venue_contact_container .add_venue_contact .learning_hall_container {
        border: solid 1px #e1e1e1;
        word-break: break-all;
        padding: 0px 5px; }
      .add_venue .add_venue_address_container .add_venue_address .learning_hall, .add_venue .add_venue_address_container .add_venue_contact .learning_hall, .add_venue .add_venue_contact_container .add_venue_address .learning_hall, .add_venue .add_venue_contact_container .add_venue_contact .learning_hall {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #1397e1;
        margin: 20px auto;
        text-transform: capitalize; }
        @media screen and (max-width: 767px) {
          .add_venue .add_venue_address_container .add_venue_address .learning_hall, .add_venue .add_venue_address_container .add_venue_contact .learning_hall, .add_venue .add_venue_contact_container .add_venue_address .learning_hall, .add_venue .add_venue_contact_container .add_venue_contact .learning_hall {
            margin: 15px auto; } }
      @media screen and (min-width: 1025px) {
        .add_venue .add_venue_address_container .add_venue_address .left_panel, .add_venue .add_venue_address_container .add_venue_contact .left_panel, .add_venue .add_venue_contact_container .add_venue_address .left_panel, .add_venue .add_venue_contact_container .add_venue_contact .left_panel {
          padding-right: 55px; } }
      @media screen and (min-width: 960px) and (max-width: 1025px) {
        .add_venue .add_venue_address_container .add_venue_address .left_panel, .add_venue .add_venue_address_container .add_venue_contact .left_panel, .add_venue .add_venue_contact_container .add_venue_address .left_panel, .add_venue .add_venue_contact_container .add_venue_contact .left_panel {
          padding-right: 20px; } }
      @media screen and (min-width: 1025px) {
        .add_venue .add_venue_address_container .add_venue_address .right_panel, .add_venue .add_venue_address_container .add_venue_contact .right_panel, .add_venue .add_venue_contact_container .add_venue_address .right_panel, .add_venue .add_venue_contact_container .add_venue_contact .right_panel {
          padding-left: 57px; } }
      @media screen and (min-width: 960px) and (max-width: 1025px) {
        .add_venue .add_venue_address_container .add_venue_address .right_panel, .add_venue .add_venue_address_container .add_venue_contact .right_panel, .add_venue .add_venue_contact_container .add_venue_address .right_panel, .add_venue .add_venue_contact_container .add_venue_contact .right_panel {
          padding-left: 20px; } }
      .add_venue .add_venue_address_container .add_venue_address .what_is_address, .add_venue .add_venue_address_container .add_venue_address .what_is_contact, .add_venue .add_venue_address_container .add_venue_contact .what_is_address, .add_venue .add_venue_address_container .add_venue_contact .what_is_contact, .add_venue .add_venue_contact_container .add_venue_address .what_is_address, .add_venue .add_venue_contact_container .add_venue_address .what_is_contact, .add_venue .add_venue_contact_container .add_venue_contact .what_is_address, .add_venue .add_venue_contact_container .add_venue_contact .what_is_contact {
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #202020;
        padding-top: 41px; }
        @media screen and (max-width: 767px) {
          .add_venue .add_venue_address_container .add_venue_address .what_is_address, .add_venue .add_venue_address_container .add_venue_address .what_is_contact, .add_venue .add_venue_address_container .add_venue_contact .what_is_address, .add_venue .add_venue_address_container .add_venue_contact .what_is_contact, .add_venue .add_venue_contact_container .add_venue_address .what_is_address, .add_venue .add_venue_contact_container .add_venue_address .what_is_contact, .add_venue .add_venue_contact_container .add_venue_contact .what_is_address, .add_venue .add_venue_contact_container .add_venue_contact .what_is_contact {
            font-size: 13px;
            padding-top: 15px; } }
      .add_venue .add_venue_address_container .add_venue_address .address_1, .add_venue .add_venue_address_container .add_venue_address .contact_name, .add_venue .add_venue_address_container .add_venue_contact .address_1, .add_venue .add_venue_address_container .add_venue_contact .contact_name, .add_venue .add_venue_contact_container .add_venue_address .address_1, .add_venue .add_venue_contact_container .add_venue_address .contact_name, .add_venue .add_venue_contact_container .add_venue_contact .address_1, .add_venue .add_venue_contact_container .add_venue_contact .contact_name {
        padding-top: 20px; }
        @media screen and (max-width: 959px) {
          .add_venue .add_venue_address_container .add_venue_address .address_1, .add_venue .add_venue_address_container .add_venue_address .contact_name, .add_venue .add_venue_address_container .add_venue_contact .address_1, .add_venue .add_venue_address_container .add_venue_contact .contact_name, .add_venue .add_venue_contact_container .add_venue_address .address_1, .add_venue .add_venue_contact_container .add_venue_address .contact_name, .add_venue .add_venue_contact_container .add_venue_contact .address_1, .add_venue .add_venue_contact_container .add_venue_contact .contact_name {
            padding-top: 15px; } }
      .add_venue .add_venue_address_container .add_venue_address .address_2, .add_venue .add_venue_address_container .add_venue_address .contact_phone, .add_venue .add_venue_address_container .add_venue_contact .address_2, .add_venue .add_venue_address_container .add_venue_contact .contact_phone, .add_venue .add_venue_contact_container .add_venue_address .address_2, .add_venue .add_venue_contact_container .add_venue_address .contact_phone, .add_venue .add_venue_contact_container .add_venue_contact .address_2, .add_venue .add_venue_contact_container .add_venue_contact .contact_phone {
        padding-top: 20px; }
        @media screen and (max-width: 959px) {
          .add_venue .add_venue_address_container .add_venue_address .address_2, .add_venue .add_venue_address_container .add_venue_address .contact_phone, .add_venue .add_venue_address_container .add_venue_contact .address_2, .add_venue .add_venue_address_container .add_venue_contact .contact_phone, .add_venue .add_venue_contact_container .add_venue_address .address_2, .add_venue .add_venue_contact_container .add_venue_address .contact_phone, .add_venue .add_venue_contact_container .add_venue_contact .address_2, .add_venue .add_venue_contact_container .add_venue_contact .contact_phone {
            padding-top: 15px; } }
      .add_venue .add_venue_address_container .add_venue_address .city_name, .add_venue .add_venue_address_container .add_venue_address .contact_email, .add_venue .add_venue_address_container .add_venue_contact .city_name, .add_venue .add_venue_address_container .add_venue_contact .contact_email, .add_venue .add_venue_contact_container .add_venue_address .city_name, .add_venue .add_venue_contact_container .add_venue_address .contact_email, .add_venue .add_venue_contact_container .add_venue_contact .city_name, .add_venue .add_venue_contact_container .add_venue_contact .contact_email {
        padding-top: 20px; }
        @media screen and (max-width: 959px) {
          .add_venue .add_venue_address_container .add_venue_address .city_name, .add_venue .add_venue_address_container .add_venue_address .contact_email, .add_venue .add_venue_address_container .add_venue_contact .city_name, .add_venue .add_venue_address_container .add_venue_contact .contact_email, .add_venue .add_venue_contact_container .add_venue_address .city_name, .add_venue .add_venue_contact_container .add_venue_address .contact_email, .add_venue .add_venue_contact_container .add_venue_contact .city_name, .add_venue .add_venue_contact_container .add_venue_contact .contact_email {
            padding-top: 15px; } }
      .add_venue .add_venue_address_container .add_venue_address .zipcode, .add_venue .add_venue_address_container .add_venue_contact .zipcode, .add_venue .add_venue_contact_container .add_venue_address .zipcode, .add_venue .add_venue_contact_container .add_venue_contact .zipcode {
        padding-top: 20px; }
        @media screen and (max-width: 959px) {
          .add_venue .add_venue_address_container .add_venue_address .zipcode, .add_venue .add_venue_address_container .add_venue_contact .zipcode, .add_venue .add_venue_contact_container .add_venue_address .zipcode, .add_venue .add_venue_contact_container .add_venue_contact .zipcode {
            padding-top: 15px; } }
      .add_venue .add_venue_address_container .add_venue_address .contact_job_title, .add_venue .add_venue_address_container .add_venue_contact .contact_job_title, .add_venue .add_venue_contact_container .add_venue_address .contact_job_title, .add_venue .add_venue_contact_container .add_venue_contact .contact_job_title {
        padding-top: 43px; }
        @media screen and (max-width: 767px) {
          .add_venue .add_venue_address_container .add_venue_address .contact_job_title, .add_venue .add_venue_address_container .add_venue_contact .contact_job_title, .add_venue .add_venue_contact_container .add_venue_address .contact_job_title, .add_venue .add_venue_contact_container .add_venue_contact .contact_job_title {
            padding-top: 15px; } }
      .add_venue .add_venue_address_container .add_venue_address .state, .add_venue .add_venue_address_container .add_venue_contact .state, .add_venue .add_venue_contact_container .add_venue_address .state, .add_venue .add_venue_contact_container .add_venue_contact .state {
        padding-top: 43px; }
        @media screen and (max-width: 767px) {
          .add_venue .add_venue_address_container .add_venue_address .state, .add_venue .add_venue_address_container .add_venue_contact .state, .add_venue .add_venue_contact_container .add_venue_address .state, .add_venue .add_venue_contact_container .add_venue_contact .state {
            padding-top: 23px; } }
      .add_venue .add_venue_address_container .add_venue_address .contact_job_title, .add_venue .add_venue_address_container .add_venue_contact .contact_job_title, .add_venue .add_venue_contact_container .add_venue_address .contact_job_title, .add_venue .add_venue_contact_container .add_venue_contact .contact_job_title {
        padding-top: 20px; }
        @media screen and (max-width: 767px) {
          .add_venue .add_venue_address_container .add_venue_address .contact_job_title, .add_venue .add_venue_address_container .add_venue_contact .contact_job_title, .add_venue .add_venue_contact_container .add_venue_address .contact_job_title, .add_venue .add_venue_contact_container .add_venue_contact .contact_job_title {
            padding-top: 15px; } }
    .add_venue .add_venue_address_container .add_venue_address, .add_venue .add_venue_contact_container .add_venue_address {
      padding-bottom: 62px; }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_address_container .add_venue_address, .add_venue .add_venue_contact_container .add_venue_address {
          padding-bottom: 15px; } }
    .add_venue .add_venue_address_container .add_venue_contact, .add_venue .add_venue_contact_container .add_venue_contact {
      padding-bottom: 140px; }
      @media screen and (max-width: 767px) {
        .add_venue .add_venue_address_container .add_venue_contact, .add_venue .add_venue_contact_container .add_venue_contact {
          padding-bottom: 15px; } }
  .add_venue .error {
    color: #FF0000;
    font-size: 11px;
    text-align: left;
    width: auto;
    display: block;
    position: absolute; }
  .add_venue input#airport {
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #f7f7f7;
    width: 100%;
    padding: 9px 15px;
    margin-top: 4px;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: #202020; }
    @media screen and (max-width: 767px) {
      .add_venue input#airport {
        padding: 9px 15px 9px 15px; } }
  .add_venue label {
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .add_venue .suggestionDiv {
    width: 100% !important;
    position: relative;
    font-size: 12px;
    line-height: 2.3;
    word-break: break-word;
    padding: 5px 0px 20px; }
  .add_venue input[role="combobox"] + div {
    z-index: 99;
    top: 37px !important;
    left: 0px !important;
    position: absolute !important;
    min-height: 95px !important;
    background: #fff;
    overflow-y: scroll;
    height: 80px;
    box-shadow: 0px 15px 10px -15px #ccc;
    border: 1px solid #e1e1e1;
    font-weight: 500;
    cursor: pointer; }
  .add_venue input[role="combobox"] + div .item {
    padding: 0px 15px; }
  .add_venue input[role="combobox"] + div div:hover {
    background-image: linear-gradient(#eeeeee, #eeeeee); }

.venue .VenueCardWithFav-favIcon-226, .VenueCardWithFav-activeFav-227 {
  margin: 10px; }

.venue_page li {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.venue_image_upload .image_upload button {
  width: auto !important;
  padding: 5px 15px; }

.edit_venue_image {
  border: 1px solid #e1e1e1;
  background-color: #f0f0f0;
  width: 100%;
  text-align: center;
  overflow: hidden;
  position: relative; }
  .edit_venue_image .image-c {
    height: 300px; }
    .edit_venue_image .image-c img {
      margin: auto;
      display: flex;
      object-position: center; }

.edit-photos {
  background-color: #ffffff; }
  .edit-photos .drag_icon_container {
    padding-left: 0px; }
    @media screen and (max-width: 767px) {
      .edit-photos .drag_icon_container {
        width: 100%;
        max-width: 100%;
        min-width: 100%; } }
  .edit-photos .edit-photos-image {
    text-align: left;
    text-align: initial; }
    @media screen and (max-width: 767px) {
      .edit-photos .edit-photos-image {
        width: 100%;
        max-width: 100%;
        min-width: 100%; } }
    .edit-photos .edit-photos-image .custom_input input[type="text"] {
      background-color: #ffffff; }
    .edit-photos .edit-photos-image .custom_input .error {
      text-align: left !important; }

.cropper-btm-section {
  padding: 15px; }
  .cropper-btm-section .wid-50 {
    width: 50%;
    display: inline-table; }
    @media screen and (max-width: 767px) {
      .cropper-btm-section .wid-50 {
        width: 100%;
        height: 60px; } }
    .cropper-btm-section .wid-50 input {
      position: relative !important; }
  @media screen and (max-width: 767px) {
    .cropper-btm-section .crop-btn button {
      width: 48.2% !important;
      height: 30px !important; } }
  @media screen and (max-width: 320px) {
    .cropper-btm-section .crop-btn button {
      width: 47.2% !important; } }
  .cropper-btm-section .help_icon button {
    background-color: #ffffff;
    border: none; }

.pdf-viewer {
  display: block;
  height: auto;
  text-align: center; }
  .pdf-viewer .has-background-black {
    background-color: #f7f7f7 !important;
    position: fixed;
    bottom: 0px;
    margin-bottom: 0px !important; }
  .pdf-viewer .text-center {
    height: auto;
    width: 100%;
    max-width: 100%; }
    .pdf-viewer .text-center .align-items-flex-end {
      align-items: center !important; }
    .pdf-viewer .text-center :last-child {
      height: auto; }
      .pdf-viewer .text-center :last-child canvas {
        height: auto; }
      .pdf-viewer .text-center :last-child div {
        height: auto !important; }
    .pdf-viewer .text-center .has-addons {
      width: 23% !important;
      display: inline-flex;
      justify-content: space-between !important; }
      .pdf-viewer .text-center .has-addons button {
        background-color: transparent; }
        .pdf-viewer .text-center .has-addons button span {
          width: auto;
          height: auto;
          color: #202020; }
    .pdf-viewer .text-center .is-2 {
      width: 10% !important;
      display: inline-block; }
    .pdf-viewer .text-center .is-gapless {
      width: 100%;
      justify-content: space-between; }

.what_is_address-top {
  padding-top: 8px; }

.what_is_address-top .emailField {
  padding-left: 30px; }

.what_is_address-top .search_container > div:first-child:before {
  top: 11px;
  left: 5px;
  color: #878787;
  content: 'search' !important; }

.venue_image_upload {
  border: dashed 1px #e1e1e1;
  background-color: #f0f0f0;
  width: 100%;
  text-align: center;
  overflow: hidden; }
  .venue_image_upload .venue_image {
    margin: auto;
    display: flex;
    object-position: center;
    width: 100% !important; }
  .venue_image_upload .image_upload {
    padding-top: 86px; }
  .venue_image_upload .file_type_field {
    display: none; }
  .venue_image_upload .file_name {
    text-align: center;
    padding-bottom: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #36434b; }
  .venue_image_upload .button_on_image {
    width: 100%;
    height: 300px;
    margin-top: -315px;
    cursor: pointer;
    padding-left: 35%;
    padding-top: 143px;
    position: absolute; }
  .venue_image_upload .button_on_image_div {
    opacity: 0.0;
    transition: all 500ms ease-in-out;
    width: 100%;
    height: 300px;
    cursor: pointer;
    position: absolute;
    top: 43%; }
    @media screen and (max-width: 767px) {
      .venue_image_upload .button_on_image_div {
        top: 40%;
        opacity: 1 !important; } }
    @media screen and (max-width: 767px) {
      .venue_image_upload .button_on_image_div button {
        height: 30px !important;
        padding: 0px 15px;
        width: auto !important; } }
  .venue_image_upload .button_on_image_div:hover {
    opacity: 1.0; }

@media screen and (max-width: 767px) {
  .venue_image_upload .file_name button {
    width: auto;
    padding: 5px 10px; } }

.add_space {
  padding-top: 65px;
  height: 100%; }
  .add_space .add_space_name {
    padding: 11px 40px 0px 40px;
    background-color: #f7f7f7; }
    @media screen and (max-width: 767px) {
      .add_space .add_space_name {
        padding: 15px;
        padding-bottom: 228px; } }
    .add_space .add_space_name .add_space_name_container {
      border: solid 1px #e1e1e1;
      background-color: #ffffff;
      margin: 0px 0px 20px 0px;
      height: 505px;
      padding-top: 42px; }
      @media screen and (max-width: 767px) {
        .add_space .add_space_name .add_space_name_container {
          height: auto;
          padding: 15px;
          margin: 0px; } }
    @media screen and (max-width: 767px) {
      .add_space .add_space_name .space_name {
        padding-left: 0px;
        padding-right: 0px; } }
  .add_space .add_space_capacity_container {
    padding: 15px !important; }
  @media screen and (max-width: 767px) {
    .add_space .add_space_rates {
      padding-bottom: 0px !important; } }
  .add_space .add_space_rates .custom_select_with_scroll > div {
    padding: 0 0px 0 15px;
    margin: 0px 2px 0px 0px; }
  .add_space .add_space_rates .help_icon button {
    padding-left: 0px !important; }
  .add_space .add_space_rates .help_icon span {
    margin-left: 0px !important; }
  .add_space .add_space_rates .holiday_rates h5 {
    font-size: 14px;
    font-weight: 600; }
  .add_space .add_space_rates .holiday_rates .override-button {
    border-radius: 20px;
    color: #1397e1;
    border: 1px solid #1397e1;
    width: 80%;
    margin: 4px 0px 4px -8px; }
  .add_space .add_space_rates .holiday_rates .override-button:hover {
    background: #1397e1;
    color: #ffffff; }
  .add_space .add_space_rates .holiday_rates .view-overrides {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    width: 76%;
    cursor: pointer; }
  .add_space .add_space_capacity_container, .add_space .add_space_availability_container, .add_space .add_space_rates_container {
    background-color: #f7f7f7;
    padding: 11px 20px 60px 20px; }
    .add_space .add_space_capacity_container .add_space_capacity, .add_space .add_space_capacity_container .add_space_availability, .add_space .add_space_capacity_container .add_space_rates, .add_space .add_space_availability_container .add_space_capacity, .add_space .add_space_availability_container .add_space_availability, .add_space .add_space_availability_container .add_space_rates, .add_space .add_space_rates_container .add_space_capacity, .add_space .add_space_rates_container .add_space_availability, .add_space .add_space_rates_container .add_space_rates {
      background-color: #ffffff;
      box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb; }
      .add_space .add_space_capacity_container .add_space_capacity .space_name_container, .add_space .add_space_capacity_container .add_space_availability .space_name_container, .add_space .add_space_capacity_container .add_space_rates .space_name_container, .add_space .add_space_availability_container .add_space_capacity .space_name_container, .add_space .add_space_availability_container .add_space_availability .space_name_container, .add_space .add_space_availability_container .add_space_rates .space_name_container, .add_space .add_space_rates_container .add_space_capacity .space_name_container, .add_space .add_space_rates_container .add_space_availability .space_name_container, .add_space .add_space_rates_container .add_space_rates .space_name_container {
        border: solid 1px #e1e1e1; }
      .add_space .add_space_capacity_container .add_space_capacity .upper_case, .add_space .add_space_capacity_container .add_space_availability .upper_case, .add_space .add_space_capacity_container .add_space_rates .upper_case, .add_space .add_space_availability_container .add_space_capacity .upper_case, .add_space .add_space_availability_container .add_space_availability .upper_case, .add_space .add_space_availability_container .add_space_rates .upper_case, .add_space .add_space_rates_container .add_space_capacity .upper_case, .add_space .add_space_rates_container .add_space_availability .upper_case, .add_space .add_space_rates_container .add_space_rates .upper_case {
        text-transform: uppercase; }
      .add_space .add_space_capacity_container .add_space_capacity .capitalise, .add_space .add_space_capacity_container .add_space_availability .capitalise, .add_space .add_space_capacity_container .add_space_rates .capitalise, .add_space .add_space_availability_container .add_space_capacity .capitalise, .add_space .add_space_availability_container .add_space_availability .capitalise, .add_space .add_space_availability_container .add_space_rates .capitalise, .add_space .add_space_rates_container .add_space_capacity .capitalise, .add_space .add_space_rates_container .add_space_availability .capitalise, .add_space .add_space_rates_container .add_space_rates .capitalise {
        text-transform: capitalize; }
      .add_space .add_space_capacity_container .add_space_capacity .space_name, .add_space .add_space_capacity_container .add_space_availability .space_name, .add_space .add_space_capacity_container .add_space_rates .space_name, .add_space .add_space_availability_container .add_space_capacity .space_name, .add_space .add_space_availability_container .add_space_availability .space_name, .add_space .add_space_availability_container .add_space_rates .space_name, .add_space .add_space_rates_container .add_space_capacity .space_name, .add_space .add_space_rates_container .add_space_availability .space_name, .add_space .add_space_rates_container .add_space_rates .space_name {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #1397e1;
        padding: 10px 0px 9px 0px; }
      .add_space .add_space_capacity_container .add_space_capacity .capacity, .add_space .add_space_capacity_container .add_space_availability .capacity, .add_space .add_space_capacity_container .add_space_rates .capacity, .add_space .add_space_availability_container .add_space_capacity .capacity, .add_space .add_space_availability_container .add_space_availability .capacity, .add_space .add_space_availability_container .add_space_rates .capacity, .add_space .add_space_rates_container .add_space_capacity .capacity, .add_space .add_space_rates_container .add_space_availability .capacity, .add_space .add_space_rates_container .add_space_rates .capacity {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #202020; }
      .add_space .add_space_capacity_container .add_space_capacity .instructions, .add_space .add_space_capacity_container .add_space_availability .instructions, .add_space .add_space_capacity_container .add_space_rates .instructions, .add_space .add_space_availability_container .add_space_capacity .instructions, .add_space .add_space_availability_container .add_space_availability .instructions, .add_space .add_space_availability_container .add_space_rates .instructions, .add_space .add_space_rates_container .add_space_capacity .instructions, .add_space .add_space_rates_container .add_space_availability .instructions, .add_space .add_space_rates_container .add_space_rates .instructions {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #62717a;
        padding-bottom: 21px; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_capacity_container .add_space_capacity .instructions, .add_space .add_space_capacity_container .add_space_availability .instructions, .add_space .add_space_capacity_container .add_space_rates .instructions, .add_space .add_space_availability_container .add_space_capacity .instructions, .add_space .add_space_availability_container .add_space_availability .instructions, .add_space .add_space_availability_container .add_space_rates .instructions, .add_space .add_space_rates_container .add_space_capacity .instructions, .add_space .add_space_rates_container .add_space_availability .instructions, .add_space .add_space_rates_container .add_space_rates .instructions {
            padding-bottom: 10px; } }
      .add_space .add_space_capacity_container .add_space_capacity .left_panel, .add_space .add_space_capacity_container .add_space_availability .left_panel, .add_space .add_space_capacity_container .add_space_rates .left_panel, .add_space .add_space_availability_container .add_space_capacity .left_panel, .add_space .add_space_availability_container .add_space_availability .left_panel, .add_space .add_space_availability_container .add_space_rates .left_panel, .add_space .add_space_rates_container .add_space_capacity .left_panel, .add_space .add_space_rates_container .add_space_availability .left_panel, .add_space .add_space_rates_container .add_space_rates .left_panel {
        padding: 10px 24px 10px 32px;
        border-right: solid 1px #e1e1e1; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_capacity_container .add_space_capacity .left_panel, .add_space .add_space_capacity_container .add_space_availability .left_panel, .add_space .add_space_capacity_container .add_space_rates .left_panel, .add_space .add_space_availability_container .add_space_capacity .left_panel, .add_space .add_space_availability_container .add_space_availability .left_panel, .add_space .add_space_availability_container .add_space_rates .left_panel, .add_space .add_space_rates_container .add_space_capacity .left_panel, .add_space .add_space_rates_container .add_space_availability .left_panel, .add_space .add_space_rates_container .add_space_rates .left_panel {
            padding: 15px; } }
      .add_space .add_space_capacity_container .add_space_capacity .right_panel, .add_space .add_space_capacity_container .add_space_availability .right_panel, .add_space .add_space_capacity_container .add_space_rates .right_panel, .add_space .add_space_availability_container .add_space_capacity .right_panel, .add_space .add_space_availability_container .add_space_availability .right_panel, .add_space .add_space_availability_container .add_space_rates .right_panel, .add_space .add_space_rates_container .add_space_capacity .right_panel, .add_space .add_space_rates_container .add_space_availability .right_panel, .add_space .add_space_rates_container .add_space_rates .right_panel {
        padding: 10px 27px 10px 24px; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_capacity_container .add_space_capacity .right_panel, .add_space .add_space_capacity_container .add_space_availability .right_panel, .add_space .add_space_capacity_container .add_space_rates .right_panel, .add_space .add_space_availability_container .add_space_capacity .right_panel, .add_space .add_space_availability_container .add_space_availability .right_panel, .add_space .add_space_availability_container .add_space_rates .right_panel, .add_space .add_space_rates_container .add_space_capacity .right_panel, .add_space .add_space_rates_container .add_space_availability .right_panel, .add_space .add_space_rates_container .add_space_rates .right_panel {
            padding: 15px; } }
      .add_space .add_space_capacity_container .add_space_capacity .how_can, .add_space .add_space_capacity_container .add_space_capacity .venue_and_space_name, .add_space .add_space_capacity_container .add_space_availability .how_can, .add_space .add_space_capacity_container .add_space_availability .venue_and_space_name, .add_space .add_space_capacity_container .add_space_rates .how_can, .add_space .add_space_capacity_container .add_space_rates .venue_and_space_name, .add_space .add_space_availability_container .add_space_capacity .how_can, .add_space .add_space_availability_container .add_space_capacity .venue_and_space_name, .add_space .add_space_availability_container .add_space_availability .how_can, .add_space .add_space_availability_container .add_space_availability .venue_and_space_name, .add_space .add_space_availability_container .add_space_rates .how_can, .add_space .add_space_availability_container .add_space_rates .venue_and_space_name, .add_space .add_space_rates_container .add_space_capacity .how_can, .add_space .add_space_rates_container .add_space_capacity .venue_and_space_name, .add_space .add_space_rates_container .add_space_availability .how_can, .add_space .add_space_rates_container .add_space_availability .venue_and_space_name, .add_space .add_space_rates_container .add_space_rates .how_can, .add_space .add_space_rates_container .add_space_rates .venue_and_space_name {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #202020; }
      .add_space .add_space_capacity_container .add_space_capacity .how_can, .add_space .add_space_capacity_container .add_space_availability .how_can, .add_space .add_space_capacity_container .add_space_rates .how_can, .add_space .add_space_availability_container .add_space_capacity .how_can, .add_space .add_space_availability_container .add_space_availability .how_can, .add_space .add_space_availability_container .add_space_rates .how_can, .add_space .add_space_rates_container .add_space_capacity .how_can, .add_space .add_space_rates_container .add_space_availability .how_can, .add_space .add_space_rates_container .add_space_rates .how_can {
        padding-bottom: 0px; }
      .add_space .add_space_capacity_container .add_space_capacity .venue_and_space_name, .add_space .add_space_capacity_container .add_space_availability .venue_and_space_name, .add_space .add_space_capacity_container .add_space_rates .venue_and_space_name, .add_space .add_space_availability_container .add_space_capacity .venue_and_space_name, .add_space .add_space_availability_container .add_space_availability .venue_and_space_name, .add_space .add_space_availability_container .add_space_rates .venue_and_space_name, .add_space .add_space_rates_container .add_space_capacity .venue_and_space_name, .add_space .add_space_rates_container .add_space_availability .venue_and_space_name, .add_space .add_space_rates_container .add_space_rates .venue_and_space_name {
        padding-bottom: 30px;
        text-transform: capitalize; }
      .add_space .add_space_capacity_container .add_space_capacity .error, .add_space .add_space_capacity_container .add_space_availability .error, .add_space .add_space_capacity_container .add_space_rates .error, .add_space .add_space_availability_container .add_space_capacity .error, .add_space .add_space_availability_container .add_space_availability .error, .add_space .add_space_availability_container .add_space_rates .error, .add_space .add_space_rates_container .add_space_capacity .error, .add_space .add_space_rates_container .add_space_availability .error, .add_space .add_space_rates_container .add_space_rates .error {
        color: #FF0000;
        font-size: 11px;
        display: block;
        width: 100%;
        text-align: left; }
    .add_space .add_space_capacity_container .checkbox_div, .add_space .add_space_availability_container .checkbox_div, .add_space .add_space_rates_container .checkbox_div {
      padding-bottom: 5px;
      padding-top: 5px; }
    .add_space .add_space_capacity_container .space_arrangement_container, .add_space .add_space_availability_container .space_arrangement_container, .add_space .add_space_rates_container .space_arrangement_container {
      padding: 10px 23px; }
    .add_space .add_space_capacity_container .arrangements_image, .add_space .add_space_availability_container .arrangements_image, .add_space .add_space_rates_container .arrangements_image {
      width: 110px; }
    .add_space .add_space_capacity_container .capacity_info, .add_space .add_space_availability_container .capacity_info, .add_space .add_space_rates_container .capacity_info {
      padding: 10px 20px 17px 20px; }
    .add_space .add_space_capacity_container .text-on-arrangement-image, .add_space .add_space_availability_container .text-on-arrangement-image, .add_space .add_space_rates_container .text-on-arrangement-image {
      text-align: center;
      padding-bottom: 12px;
      font-size: 16px;
      font-weight: 600;
      color: #1f2836;
      padding-top: 10px; }
    .add_space .add_space_capacity_container .capacity-on-arrangement-image, .add_space .add_space_availability_container .capacity-on-arrangement-image, .add_space .add_space_rates_container .capacity-on-arrangement-image {
      text-align: center;
      padding-top: 6px;
      font-size: 16px;
      font-weight: 600;
      color: #1f2836;
      padding-bottom: 18px; }
    .add_space .add_space_capacity_container .arrangement-desc, .add_space .add_space_availability_container .arrangement-desc, .add_space .add_space_rates_container .arrangement-desc {
      background-color: #f7f7f7;
      border: solid 1.5px #ebebeb;
      border-radius: 5px;
      min-height: 230px; }
  .add_space .space_arrangement .space_arrangement_description {
    width: 117px;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #62717a; }
  .add_space .space_arrangement .space_image {
    padding: 2px 0px; }
  .add_space .space_arrangement .min_max {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    padding-bottom: 3px; }
  .add_space .add_space_availability_container .add_space_availability, .add_space .add_space_availability_container .add_space_rates, .add_space .add_space_rates_container .add_space_availability, .add_space .add_space_rates_container .add_space_rates {
    padding-bottom: 220px; }
    .add_space .add_space_availability_container .add_space_availability .space_rates_heading, .add_space .add_space_availability_container .add_space_rates .space_rates_heading, .add_space .add_space_rates_container .add_space_availability .space_rates_heading, .add_space .add_space_rates_container .add_space_rates .space_rates_heading {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #36434b;
      padding: 20px 0px; }
    .add_space .add_space_availability_container .add_space_availability .add_space_availability_form, .add_space .add_space_availability_container .add_space_rates .add_space_availability_form, .add_space .add_space_rates_container .add_space_availability .add_space_availability_form, .add_space .add_space_rates_container .add_space_rates .add_space_availability_form {
      padding: 11px 20px; }
    .add_space .add_space_availability_container .add_space_availability .spaceAvailability-block, .add_space .add_space_availability_container .add_space_rates .spaceAvailability-block, .add_space .add_space_rates_container .add_space_availability .spaceAvailability-block, .add_space .add_space_rates_container .add_space_rates .spaceAvailability-block {
      display: flex; }
    .add_space .add_space_availability_container .add_space_availability .add_space_rates_form, .add_space .add_space_availability_container .add_space_rates .add_space_rates_form, .add_space .add_space_rates_container .add_space_availability .add_space_rates_form, .add_space .add_space_rates_container .add_space_rates .add_space_rates_form {
      padding: 0px 20px 11px 20px; }
      .add_space .add_space_availability_container .add_space_availability .add_space_rates_form .help_icon button, .add_space .add_space_availability_container .add_space_rates .add_space_rates_form .help_icon button, .add_space .add_space_rates_container .add_space_availability .add_space_rates_form .help_icon button, .add_space .add_space_rates_container .add_space_rates .add_space_rates_form .help_icon button {
        cursor: pointer;
        background-color: transparent;
        border: 0px;
        box-shadow: none;
        margin-left: -10px;
        margin-top: -5px; }
      .add_space .add_space_availability_container .add_space_availability .add_space_rates_form .help_icon.link_hint button, .add_space .add_space_availability_container .add_space_rates .add_space_rates_form .help_icon.link_hint button, .add_space .add_space_rates_container .add_space_availability .add_space_rates_form .help_icon.link_hint button, .add_space .add_space_rates_container .add_space_rates .add_space_rates_form .help_icon.link_hint button {
        margin-left: -4px !important; }
      .add_space .add_space_availability_container .add_space_availability .add_space_rates_form .help_icon.link_hint button span, .add_space .add_space_availability_container .add_space_rates .add_space_rates_form .help_icon.link_hint button span, .add_space .add_space_rates_container .add_space_availability .add_space_rates_form .help_icon.link_hint button span, .add_space .add_space_rates_container .add_space_rates .add_space_rates_form .help_icon.link_hint button span {
        margin-left: 2px !important; }
    .add_space .add_space_availability_container .add_space_availability .space_availability_heading, .add_space .add_space_availability_container .add_space_rates .space_availability_heading, .add_space .add_space_rates_container .add_space_availability .space_availability_heading, .add_space .add_space_rates_container .add_space_rates .space_availability_heading {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #202020; }
      .add_space .add_space_availability_container .add_space_availability .space_availability_heading .sub-heading, .add_space .add_space_availability_container .add_space_rates .space_availability_heading .sub-heading, .add_space .add_space_rates_container .add_space_availability .space_availability_heading .sub-heading, .add_space .add_space_rates_container .add_space_rates .space_availability_heading .sub-heading {
        font-size: 13px;
        color: #202020; }
      .add_space .add_space_availability_container .add_space_availability .space_availability_heading a, .add_space .add_space_availability_container .add_space_rates .space_availability_heading a, .add_space .add_space_rates_container .add_space_availability .space_availability_heading a, .add_space .add_space_rates_container .add_space_rates .space_availability_heading a {
        text-decoration: underline; }
      .add_space .add_space_availability_container .add_space_availability .space_availability_heading .select-one, .add_space .add_space_availability_container .add_space_rates .space_availability_heading .select-one, .add_space .add_space_rates_container .add_space_availability .space_availability_heading .select-one, .add_space .add_space_rates_container .add_space_rates .space_availability_heading .select-one {
        font-size: 14px;
        margin-top: 10px; }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .space_availability_heading, .add_space .add_space_availability_container .add_space_rates .space_availability_heading, .add_space .add_space_rates_container .add_space_availability .space_availability_heading, .add_space .add_space_rates_container .add_space_rates .space_availability_heading {
          font-size: 15px; } }
      .add_space .add_space_availability_container .add_space_availability .space_availability_heading .copy-availability .custom_select_with_scroll, .add_space .add_space_availability_container .add_space_rates .space_availability_heading .copy-availability .custom_select_with_scroll, .add_space .add_space_rates_container .add_space_availability .space_availability_heading .copy-availability .custom_select_with_scroll, .add_space .add_space_rates_container .add_space_rates .space_availability_heading .copy-availability .custom_select_with_scroll {
        max-width: 72%; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .space_availability_heading .copy-availability .custom_select_with_scroll, .add_space .add_space_availability_container .add_space_rates .space_availability_heading .copy-availability .custom_select_with_scroll, .add_space .add_space_rates_container .add_space_availability .space_availability_heading .copy-availability .custom_select_with_scroll, .add_space .add_space_rates_container .add_space_rates .space_availability_heading .copy-availability .custom_select_with_scroll {
            max-width: 100%; } }
      .add_space .add_space_availability_container .add_space_availability .space_availability_heading .ques-mark, .add_space .add_space_availability_container .add_space_rates .space_availability_heading .ques-mark, .add_space .add_space_rates_container .add_space_availability .space_availability_heading .ques-mark, .add_space .add_space_rates_container .add_space_rates .space_availability_heading .ques-mark {
        display: inherit; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .space_availability_heading .ques-mark, .add_space .add_space_availability_container .add_space_rates .space_availability_heading .ques-mark, .add_space .add_space_rates_container .add_space_availability .space_availability_heading .ques-mark, .add_space .add_space_rates_container .add_space_rates .space_availability_heading .ques-mark {
            display: inline-block; } }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .space_availability_heading .ques-mark .help_icon, .add_space .add_space_availability_container .add_space_rates .space_availability_heading .ques-mark .help_icon, .add_space .add_space_rates_container .add_space_availability .space_availability_heading .ques-mark .help_icon, .add_space .add_space_rates_container .add_space_rates .space_availability_heading .ques-mark .help_icon {
            display: inline-block;
            width: 8px; } }
        .add_space .add_space_availability_container .add_space_availability .space_availability_heading .ques-mark .help_icon button, .add_space .add_space_availability_container .add_space_rates .space_availability_heading .ques-mark .help_icon button, .add_space .add_space_rates_container .add_space_availability .space_availability_heading .ques-mark .help_icon button, .add_space .add_space_rates_container .add_space_rates .space_availability_heading .ques-mark .help_icon button {
          background-color: transparent;
          border: 0px; }
          @media screen and (max-width: 767px) {
            .add_space .add_space_availability_container .add_space_availability .space_availability_heading .ques-mark .help_icon button, .add_space .add_space_availability_container .add_space_rates .space_availability_heading .ques-mark .help_icon button, .add_space .add_space_rates_container .add_space_availability .space_availability_heading .ques-mark .help_icon button, .add_space .add_space_rates_container .add_space_rates .space_availability_heading .ques-mark .help_icon button {
              padding-top: 8px; } }
    .add_space .add_space_availability_container .add_space_availability .checkbox_instructions, .add_space .add_space_availability_container .add_space_rates .checkbox_instructions, .add_space .add_space_rates_container .add_space_availability .checkbox_instructions, .add_space .add_space_rates_container .add_space_rates .checkbox_instructions {
      font-size: 11px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #62717a;
      margin-top: -11px;
      padding-left: 22px; }
    .add_space .add_space_availability_container .add_space_availability .space_availability_checkboxes, .add_space .add_space_availability_container .add_space_rates .space_availability_checkboxes, .add_space .add_space_rates_container .add_space_availability .space_availability_checkboxes, .add_space .add_space_rates_container .add_space_rates .space_availability_checkboxes {
      padding: 10px 0px 10px 0px; }
      .add_space .add_space_availability_container .add_space_availability .space_availability_checkboxes .container, .add_space .add_space_availability_container .add_space_rates .space_availability_checkboxes .container, .add_space .add_space_rates_container .add_space_availability .space_availability_checkboxes .container, .add_space .add_space_rates_container .add_space_rates .space_availability_checkboxes .container {
        max-width: 100%; }
      .add_space .add_space_availability_container .add_space_availability .space_availability_checkboxes .help_icon button, .add_space .add_space_availability_container .add_space_rates .space_availability_checkboxes .help_icon button, .add_space .add_space_rates_container .add_space_availability .space_availability_checkboxes .help_icon button, .add_space .add_space_rates_container .add_space_rates .space_availability_checkboxes .help_icon button {
        background-color: transparent;
        border: 0px;
        box-shadow: none;
        margin-left: -15px;
        margin-top: -5px; }
    .add_space .add_space_availability_container .add_space_availability .form_index, .add_space .add_space_availability_container .add_space_rates .form_index, .add_space .add_space_rates_container .add_space_availability .form_index, .add_space .add_space_rates_container .add_space_rates .form_index {
      background: #ee2738;
      border-radius: 50%;
      display: block;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      text-align: center;
      border: 1px solid #ee2738;
      align-items: center;
      color: #ffffff;
      padding-top: 2px; }
    .add_space .add_space_availability_container .add_space_availability .index_container, .add_space .add_space_availability_container .add_space_rates .index_container, .add_space .add_space_rates_container .add_space_availability .index_container, .add_space .add_space_rates_container .add_space_rates .index_container {
      padding-top: 25px; }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .index_container, .add_space .add_space_availability_container .add_space_rates .index_container, .add_space .add_space_rates_container .add_space_availability .index_container, .add_space .add_space_rates_container .add_space_rates .index_container {
          padding-bottom: 0px; } }
    .add_space .add_space_availability_container .add_space_availability .rate_form_container, .add_space .add_space_availability_container .add_space_rates .rate_form_container, .add_space .add_space_rates_container .add_space_availability .rate_form_container, .add_space .add_space_rates_container .add_space_rates .rate_form_container {
      width: 100%; }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .rate_form_container .add_variable, .add_space .add_space_availability_container .add_space_rates .rate_form_container .add_variable, .add_space .add_space_rates_container .add_space_availability .rate_form_container .add_variable, .add_space .add_space_rates_container .add_space_rates .rate_form_container .add_variable {
          padding: 10px 21px; } }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .rate_form_container .add_variable .additionalPricing, .add_space .add_space_availability_container .add_space_rates .rate_form_container .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_availability .rate_form_container .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_rates .rate_form_container .add_variable .additionalPricing {
          padding: 0px; } }
    .add_space .add_space_availability_container .add_space_availability .nested_form_container, .add_space .add_space_availability_container .add_space_rates .nested_form_container, .add_space .add_space_rates_container .add_space_availability .nested_form_container, .add_space .add_space_rates_container .add_space_rates .nested_form_container {
      padding: 21px 21px 15px 21px;
      border-top: solid 1px #e1e1e1; }
    .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container {
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      padding: 17px 21px 20px 21px;
      margin-top: 39px;
      position: relative; }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container {
          margin-top: 10px; } }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .alignment_of_days, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .alignment_of_days, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .alignment_of_days, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .alignment_of_days {
        padding: 36px 59px; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .alignment_of_days, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .alignment_of_days, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .alignment_of_days, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .alignment_of_days {
            padding: 15px 20px; } }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .earliest_time, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .earliest_time, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .earliest_time, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .earliest_time {
        padding-left: 63px;
        font-size: 15px; }
        @media screen and (max-width: 991px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .earliest_time, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .earliest_time, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .earliest_time, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .earliest_time {
            padding-left: 0px; } }
      @media screen and (min-width: 1024px) {
        .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right-select, .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right-field, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right-select, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right-field, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right-select, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right-field, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right-select, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right-field {
          float: left;
          padding-left: 57px; } }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right-field, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right-field, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right-field, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right-field {
        width: 60%; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right-field, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right-field, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right-field, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right-field {
            width: 100%;
            padding-top: 12px; } }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right-select, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right-select, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right-select, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right-select {
        width: 100%; }
        @media screen and (min-width: 1024px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right-select, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right-select, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right-select, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right-select {
            width: 20%; } }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right-select, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right-select, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right-select, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right-select {
            width: 20%;
            float: left;
            padding-right: 10px; } }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .max-hours-select, .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .max-hours, .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .min-add_time_slot, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .max-hours-select, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .max-hours, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .min-add_time_slot, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .max-hours-select, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .max-hours, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .min-add_time_slot, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .max-hours-select, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .max-hours, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .min-add_time_slot {
        float: left; }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .max-hours-select, .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .min-add_time_slot, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .max-hours-select, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .min-add_time_slot, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .max-hours-select, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .min-add_time_slot, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .max-hours-select, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .min-add_time_slot {
        padding-left: 10px; }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .min-add_time_slot button, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .min-add_time_slot button, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .min-add_time_slot button, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .min-add_time_slot button {
        border: none;
        padding-left: 0px; }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .text-muted, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .text-muted, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .text-muted, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .text-muted {
        font-size: 13px;
        color: #565555;
        padding: 7px 0px; }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .additional-time, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .additional-time, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .additional-time, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .additional-time {
        width: 200px; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .additional-time, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .additional-time, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .additional-time, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .additional-time {
            width: 100%; } }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right {
        padding-left: 60px; }
        @media screen and (min-width: 1024px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right {
            padding-left: 38px; } }
        @media screen and (min-width: 1300px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right {
            padding-left: 60px; } }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right {
            padding-left: 0px; } }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right_flexible, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right_flexible, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right_flexible, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right_flexible {
        padding-top: 20px;
        padding-left: 0px; }
        @media screen and (min-width: 1024px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right_flexible, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right_flexible, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right_flexible, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right_flexible {
            padding-left: 38px; } }
        @media screen and (min-width: 1300px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right_flexible, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right_flexible, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right_flexible, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right_flexible {
            padding-left: 45px; } }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .right_flexible, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .right_flexible, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .right_flexible, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .right_flexible {
            padding-left: 0px;
            padding-top: 0px; } }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .max-hours, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .max-hours, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .max-hours, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .max-hours {
        font-size: 13px;
        color: #5a5a5a;
        padding-top: 12px; }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .padding_left_min, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .padding_left_min, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .padding_left_min, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .padding_left_min {
        padding-left: 35px; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .padding_left_min, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .padding_left_min, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .padding_left_min, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .padding_left_min {
            padding-left: 0px;
            padding: 5px 0px; } }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .max-hours-select, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .max-hours-select, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .max-hours-select, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .max-hours-select {
        width: 200px; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .max-hours-select, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .max-hours-select, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .max-hours-select, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .max-hours-select {
            width: 100%;
            padding-left: 0px; } }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container .min-add_time_slot, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container .min-add_time_slot, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container .min-add_time_slot, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container .min-add_time_slot {
        padding-top: 6px; }
      .add_space .add_space_availability_container .add_space_availability .nested_space_availability_form_container span.nested-block, .add_space .add_space_availability_container .add_space_rates .nested_space_availability_form_container span.nested-block, .add_space .add_space_rates_container .add_space_availability .nested_space_availability_form_container span.nested-block, .add_space .add_space_rates_container .add_space_rates .nested_space_availability_form_container span.nested-block {
        margin-bottom: 6px;
        display: block; }
    .add_space .add_space_availability_container .add_space_availability .add_time_slot, .add_space .add_space_availability_container .add_space_rates .add_time_slot, .add_space .add_space_rates_container .add_space_availability .add_time_slot, .add_space .add_space_rates_container .add_space_rates .add_time_slot {
      padding: 20px 0px 54px 0px; }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .add_time_slot, .add_space .add_space_availability_container .add_space_rates .add_time_slot, .add_space .add_space_rates_container .add_space_availability .add_time_slot, .add_space .add_space_rates_container .add_space_rates .add_time_slot {
          padding: 20px 0px 10px 0px; } }
    .add_space .add_space_availability_container .add_space_availability .pricing_form, .add_space .add_space_availability_container .add_space_rates .pricing_form, .add_space .add_space_rates_container .add_space_availability .pricing_form, .add_space .add_space_rates_container .add_space_rates .pricing_form {
      border-radius: 6px;
      border: solid 1px #e1e1e1; }
    @media screen and (min-width: 1024px) {
      .add_space .add_space_availability_container .add_space_availability .additionalCost, .add_space .add_space_availability_container .add_space_rates .additionalCost, .add_space .add_space_rates_container .add_space_availability .additionalCost, .add_space .add_space_rates_container .add_space_rates .additionalCost {
        padding-left: 55px; } }
    @media screen and (min-width: 1024px) {
      .add_space .add_space_availability_container .add_space_availability .time_between_events, .add_space .add_space_availability_container .add_space_rates .time_between_events, .add_space .add_space_rates_container .add_space_availability .time_between_events, .add_space .add_space_rates_container .add_space_rates .time_between_events {
        padding-left: 94px; } }
    @media screen and (min-width: 1300px) {
      .add_space .add_space_availability_container .add_space_availability .time_between_events, .add_space .add_space_availability_container .add_space_rates .time_between_events, .add_space .add_space_rates_container .add_space_availability .time_between_events, .add_space .add_space_rates_container .add_space_rates .time_between_events {
        padding-left: 102px; } }
    .add_space .add_space_availability_container .add_space_availability .additionalPricing, .add_space .add_space_availability_container .add_space_rates .additionalPricing, .add_space .add_space_rates_container .add_space_availability .additionalPricing, .add_space .add_space_rates_container .add_space_rates .additionalPricing {
      margin-left: 60px; }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .additionalPricing, .add_space .add_space_availability_container .add_space_rates .additionalPricing, .add_space .add_space_rates_container .add_space_availability .additionalPricing, .add_space .add_space_rates_container .add_space_rates .additionalPricing {
          padding-left: 30px;
          margin-left: 0px;
          min-width: 35%;
          max-width: 35%;
          margin-left: 15px !important;
          margin-top: 19px; } }
      @media screen and (max-width: 500px) {
        .add_space .add_space_availability_container .add_space_availability .additionalPricing, .add_space .add_space_availability_container .add_space_rates .additionalPricing, .add_space .add_space_rates_container .add_space_availability .additionalPricing, .add_space .add_space_rates_container .add_space_rates .additionalPricing {
          min-width: 100%;
          max-width: 100%;
          margin-left: 0px !important;
          margin-top: 0px; } }
    .add_space .add_space_availability_container .add_space_availability .optional, .add_space .add_space_availability_container .add_space_rates .optional, .add_space .add_space_rates_container .add_space_availability .optional, .add_space .add_space_rates_container .add_space_rates .optional {
      padding-bottom: 5px; }
    .add_space .add_space_availability_container .add_space_availability .pricingTypeName, .add_space .add_space_availability_container .add_space_rates .pricingTypeName, .add_space .add_space_rates_container .add_space_availability .pricingTypeName, .add_space .add_space_rates_container .add_space_rates .pricingTypeName {
      margin-left: 60px; }
    .add_space .add_space_availability_container .add_space_availability .help_icon, .add_space .add_space_availability_container .add_space_rates .help_icon, .add_space .add_space_rates_container .add_space_availability .help_icon, .add_space .add_space_rates_container .add_space_rates .help_icon {
      padding-left: 0px;
      margin-left: -4px;
      box-shadow: none; }
      .add_space .add_space_availability_container .add_space_availability .help_icon span, .add_space .add_space_availability_container .add_space_rates .help_icon span, .add_space .add_space_rates_container .add_space_availability .help_icon span, .add_space .add_space_rates_container .add_space_rates .help_icon span {
        font-size: 12px !important;
        padding-top: 0px; }
    .add_space .add_space_availability_container .add_space_availability .add_variable, .add_space .add_space_availability_container .add_space_rates .add_variable, .add_space .add_space_rates_container .add_space_availability .add_variable, .add_space .add_space_rates_container .add_space_rates .add_variable {
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      padding: 13px 5px 16px 8px;
      margin: 20px 0px 5px 0px; }
      .add_space .add_space_availability_container .add_space_availability .add_variable .add_icon_container, .add_space .add_space_availability_container .add_space_rates .add_variable .add_icon_container, .add_space .add_space_rates_container .add_space_availability .add_variable .add_icon_container, .add_space .add_space_rates_container .add_space_rates .add_variable .add_icon_container {
        margin-right: -42px !important; }
        @media screen and (min-width: 1595px) {
          .add_space .add_space_availability_container .add_space_availability .add_variable .add_icon_container, .add_space .add_space_availability_container .add_space_rates .add_variable .add_icon_container, .add_space .add_space_rates_container .add_space_availability .add_variable .add_icon_container, .add_space .add_space_rates_container .add_space_rates .add_variable .add_icon_container {
            margin-right: -50px; } }
        @media screen and (max-width: 1300px) and (min-width: 1200px) {
          .add_space .add_space_availability_container .add_space_availability .add_variable .add_icon_container, .add_space .add_space_availability_container .add_space_rates .add_variable .add_icon_container, .add_space .add_space_rates_container .add_space_availability .add_variable .add_icon_container, .add_space .add_space_rates_container .add_space_rates .add_variable .add_icon_container {
            margin-right: -32px !important; } }
        @media screen and (max-width: 1024px) and (min-width: 991px) {
          .add_space .add_space_availability_container .add_space_availability .add_variable .add_icon_container, .add_space .add_space_availability_container .add_space_rates .add_variable .add_icon_container, .add_space .add_space_rates_container .add_space_availability .add_variable .add_icon_container, .add_space .add_space_rates_container .add_space_rates .add_variable .add_icon_container {
            margin-right: -18px !important; } }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .add_variable .add_icon_container, .add_space .add_space_availability_container .add_space_rates .add_variable .add_icon_container, .add_space .add_space_rates_container .add_space_availability .add_variable .add_icon_container, .add_space .add_space_rates_container .add_space_rates .add_variable .add_icon_container {
            margin-right: 0px !important; } }
      .add_space .add_space_availability_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_availability_container .add_space_rates .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_rates .add_variable .additionalPricing {
        margin-left: 45px; }
        @media screen and (min-width: 1599px) {
          .add_space .add_space_availability_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_availability_container .add_space_rates .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_rates .add_variable .additionalPricing {
            margin-left: 54px; } }
        @media screen and (max-width: 1300px) and (min-width: 1200px) {
          .add_space .add_space_availability_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_availability_container .add_space_rates .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_rates .add_variable .additionalPricing {
            margin-left: 36px; } }
        @media screen and (max-width: 1024px) and (min-width: 992px) {
          .add_space .add_space_availability_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_availability_container .add_space_rates .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_rates .add_variable .additionalPricing {
            margin-left: 20px !important;
            padding-top: 10px; } }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          .add_space .add_space_availability_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_availability_container .add_space_rates .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_rates .add_variable .additionalPricing {
            margin-left: 20px !important; } }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_availability_container .add_space_rates .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_availability .add_variable .additionalPricing, .add_space .add_space_rates_container .add_space_rates .add_variable .additionalPricing {
            margin-left: 0px; } }
      @media screen and (max-width: 800px) {
        .add_space .add_space_availability_container .add_space_availability .add_variable, .add_space .add_space_availability_container .add_space_rates .add_variable, .add_space .add_space_rates_container .add_space_availability .add_variable, .add_space .add_space_rates_container .add_space_rates .add_variable {
          padding: 13px 11px 16px 0px; } }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .add_variable .btn-align-sm, .add_space .add_space_availability_container .add_space_rates .add_variable .btn-align-sm, .add_space .add_space_rates_container .add_space_availability .add_variable .btn-align-sm, .add_space .add_space_rates_container .add_space_rates .add_variable .btn-align-sm {
          max-width: 189px; } }
      @media screen and (max-width: 320px) {
        .add_space .add_space_availability_container .add_space_availability .add_variable .btn-align-sm, .add_space .add_space_availability_container .add_space_rates .add_variable .btn-align-sm, .add_space .add_space_rates_container .add_space_availability .add_variable .btn-align-sm, .add_space .add_space_rates_container .add_space_rates .add_variable .btn-align-sm {
          max-width: 165px;
          margin: 10px 15px; } }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .add_variable .custom_select_with_scroll div, .add_space .add_space_availability_container .add_space_rates .add_variable .custom_select_with_scroll div, .add_space .add_space_rates_container .add_space_availability .add_variable .custom_select_with_scroll div, .add_space .add_space_rates_container .add_space_rates .add_variable .custom_select_with_scroll div {
          margin-right: 10px; } }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .add_variable .custom_select_with_scroll div svg, .add_space .add_space_availability_container .add_space_rates .add_variable .custom_select_with_scroll div svg, .add_space .add_space_rates_container .add_space_availability .add_variable .custom_select_with_scroll div svg, .add_space .add_space_rates_container .add_space_rates .add_variable .custom_select_with_scroll div svg {
          right: -5px; } }
    .add_space .add_space_availability_container .add_space_availability .formula, .add_space .add_space_availability_container .add_space_rates .formula, .add_space .add_space_rates_container .add_space_availability .formula, .add_space .add_space_rates_container .add_space_rates .formula {
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      padding: 21px 20px;
      margin-bottom: 10px;
      position: relative; }
      .add_space .add_space_availability_container .add_space_availability .formula .formula_heading, .add_space .add_space_availability_container .add_space_rates .formula .formula_heading, .add_space .add_space_rates_container .add_space_availability .formula .formula_heading, .add_space .add_space_rates_container .add_space_rates .formula .formula_heading {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #202020; }
      .add_space .add_space_availability_container .add_space_availability .formula .time_slot, .add_space .add_space_availability_container .add_space_rates .formula .time_slot, .add_space .add_space_rates_container .add_space_availability .formula .time_slot, .add_space .add_space_rates_container .add_space_rates .formula .time_slot {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #36434b; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .formula .time_slot, .add_space .add_space_availability_container .add_space_rates .formula .time_slot, .add_space .add_space_rates_container .add_space_availability .formula .time_slot, .add_space .add_space_rates_container .add_space_rates .formula .time_slot {
            display: inline; } }
      .add_space .add_space_availability_container .add_space_availability .formula .time, .add_space .add_space_availability_container .add_space_rates .formula .time, .add_space .add_space_rates_container .add_space_availability .formula .time, .add_space .add_space_rates_container .add_space_rates .formula .time {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: right;
        color: #1397e1; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .formula .time, .add_space .add_space_availability_container .add_space_rates .formula .time, .add_space .add_space_rates_container .add_space_availability .formula .time, .add_space .add_space_rates_container .add_space_rates .formula .time {
            text-align: left;
            display: block;
            padding-left: 5px; } }
      .add_space .add_space_availability_container .add_space_availability .formula .space_availability_type, .add_space .add_space_availability_container .add_space_rates .formula .space_availability_type, .add_space .add_space_rates_container .add_space_availability .formula .space_availability_type, .add_space .add_space_rates_container .add_space_rates .formula .space_availability_type {
        padding: 0px 13px 0px 10px; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .formula .space_availability_type, .add_space .add_space_availability_container .add_space_rates .formula .space_availability_type, .add_space .add_space_rates_container .add_space_availability .formula .space_availability_type, .add_space .add_space_rates_container .add_space_rates .formula .space_availability_type {
            padding: 0px; } }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .formula .time_alignment, .add_space .add_space_availability_container .add_space_rates .formula .time_alignment, .add_space .add_space_rates_container .add_space_availability .formula .time_alignment, .add_space .add_space_rates_container .add_space_rates .formula .time_alignment {
          justify-content: flex-start;
          display: inline-block; } }
      @media screen and (min-width: 801px) {
        .add_space .add_space_availability_container .add_space_availability .formula .time_alignment, .add_space .add_space_availability_container .add_space_rates .formula .time_alignment, .add_space .add_space_rates_container .add_space_availability .formula .time_alignment, .add_space .add_space_rates_container .add_space_rates .formula .time_alignment {
          justify-content: flex-end; } }
      .add_space .add_space_availability_container .add_space_availability .formula .select_days, .add_space .add_space_availability_container .add_space_rates .formula .select_days, .add_space .add_space_rates_container .add_space_availability .formula .select_days, .add_space .add_space_rates_container .add_space_rates .formula .select_days {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #62717a;
        padding-left: 9px;
        padding-top: 5px; }
      .add_space .add_space_availability_container .add_space_availability .formula .days, .add_space .add_space_availability_container .add_space_rates .formula .days, .add_space .add_space_rates_container .add_space_availability .formula .days, .add_space .add_space_rates_container .add_space_rates .formula .days {
        padding-top: 9px;
        padding-right: 25px; }
      .add_space .add_space_availability_container .add_space_availability .formula .input_field, .add_space .add_space_availability_container .add_space_rates .formula .input_field, .add_space .add_space_rates_container .add_space_availability .formula .input_field, .add_space .add_space_rates_container .add_space_rates .formula .input_field {
        border: solid 1px #e1e1e1;
        background-color: #f7f7f7;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: left;
        color: #202020;
        height: 36px;
        width: 250px;
        border-radius: 6px;
        padding-left: 15px;
        padding-right: 15px; }
        @media screen and (max-width: 1024px) and (min-width: 768px) {
          .add_space .add_space_availability_container .add_space_availability .formula .input_field, .add_space .add_space_availability_container .add_space_rates .formula .input_field, .add_space .add_space_rates_container .add_space_availability .formula .input_field, .add_space .add_space_rates_container .add_space_rates .formula .input_field {
            width: 200px; } }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .formula .input_field, .add_space .add_space_availability_container .add_space_rates .formula .input_field, .add_space .add_space_rates_container .add_space_availability .formula .input_field, .add_space .add_space_rates_container .add_space_rates .formula .input_field {
            width: 100%; } }
      .add_space .add_space_availability_container .add_space_availability .formula .input_label, .add_space .add_space_availability_container .add_space_rates .formula .input_label, .add_space .add_space_rates_container .add_space_availability .formula .input_label, .add_space .add_space_rates_container .add_space_rates .formula .input_label {
        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: left;
        color: #62717a; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .formula .input_label, .add_space .add_space_availability_container .add_space_rates .formula .input_label, .add_space .add_space_rates_container .add_space_availability .formula .input_label, .add_space .add_space_rates_container .add_space_rates .formula .input_label {
            width: 90%;
            float: left; } }
      .add_space .add_space_availability_container .add_space_availability .formula .additional_input_label, .add_space .add_space_availability_container .add_space_rates .formula .additional_input_label, .add_space .add_space_rates_container .add_space_availability .formula .additional_input_label, .add_space .add_space_rates_container .add_space_rates .formula .additional_input_label {
        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: left;
        color: #62717a;
        text-transform: capitalize; }
      @media screen and (min-width: 800px) {
        .add_space .add_space_availability_container .add_space_availability .formula .rate_container, .add_space .add_space_availability_container .add_space_rates .formula .rate_container, .add_space .add_space_rates_container .add_space_availability .formula .rate_container, .add_space .add_space_rates_container .add_space_rates .formula .rate_container {
          padding-left: 48px;
          padding-right: 72px;
          padding-top: 16px; } }
      @media screen and (min-width: 800px) {
        .add_space .add_space_availability_container .add_space_availability .formula .venueRentalFields, .add_space .add_space_availability_container .add_space_rates .formula .venueRentalFields, .add_space .add_space_rates_container .add_space_availability .formula .venueRentalFields, .add_space .add_space_rates_container .add_space_rates .formula .venueRentalFields {
          padding-left: 48px;
          padding-right: 128px;
          padding-top: 16px; } }
      .add_space .add_space_availability_container .add_space_availability .formula .fAndBMin, .add_space .add_space_availability_container .add_space_rates .formula .fAndBMin, .add_space .add_space_rates_container .add_space_availability .formula .fAndBMin, .add_space .add_space_rates_container .add_space_rates .formula .fAndBMin {
        padding-top: 16px; }
      .add_space .add_space_availability_container .add_space_availability .formula .field_hint, .add_space .add_space_availability_container .add_space_rates .formula .field_hint, .add_space .add_space_rates_container .add_space_availability .formula .field_hint, .add_space .add_space_rates_container .add_space_rates .formula .field_hint {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #62717a;
        padding-top: 3px; }
      .add_space .add_space_availability_container .add_space_availability .formula .pricing_category, .add_space .add_space_availability_container .add_space_rates .formula .pricing_category, .add_space .add_space_rates_container .add_space_availability .formula .pricing_category, .add_space .add_space_rates_container .add_space_rates .formula .pricing_category {
        padding: 18px 20px; }
      @media screen and (min-width: 800px) {
        .add_space .add_space_availability_container .add_space_availability .formula .pricing_select, .add_space .add_space_availability_container .add_space_rates .formula .pricing_select, .add_space .add_space_rates_container .add_space_availability .formula .pricing_select, .add_space .add_space_rates_container .add_space_rates .formula .pricing_select {
          padding-right: 81px; } }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .formula .pricing_select .custom_select_with_scroll div, .add_space .add_space_availability_container .add_space_rates .formula .pricing_select .custom_select_with_scroll div, .add_space .add_space_rates_container .add_space_availability .formula .pricing_select .custom_select_with_scroll div, .add_space .add_space_rates_container .add_space_rates .formula .pricing_select .custom_select_with_scroll div {
          padding-right: 10px; } }
      @media screen and (min-width: 1599px) {
        .add_space .add_space_availability_container .add_space_availability .formula .add_icon_container, .add_space .add_space_availability_container .add_space_rates .formula .add_icon_container, .add_space .add_space_rates_container .add_space_availability .formula .add_icon_container, .add_space .add_space_rates_container .add_space_rates .formula .add_icon_container {
          margin-right: -52px !important; } }
      @media screen and (min-width: 800px) {
        .add_space .add_space_availability_container .add_space_availability .formula .add_icon_container, .add_space .add_space_availability_container .add_space_rates .formula .add_icon_container, .add_space .add_space_rates_container .add_space_availability .formula .add_icon_container, .add_space .add_space_rates_container .add_space_rates .formula .add_icon_container {
          margin-right: -58px; } }
      @media screen and (max-width: 767px) {
        .add_space .add_space_availability_container .add_space_availability .formula .add_icon_container, .add_space .add_space_availability_container .add_space_rates .formula .add_icon_container, .add_space .add_space_rates_container .add_space_availability .formula .add_icon_container, .add_space .add_space_rates_container .add_space_rates .formula .add_icon_container {
          max-width: 100%; } }
      .add_space .add_space_availability_container .add_space_availability .formula .add_icon_container button, .add_space .add_space_availability_container .add_space_rates .formula .add_icon_container button, .add_space .add_space_rates_container .add_space_availability .formula .add_icon_container button, .add_space .add_space_rates_container .add_space_rates .formula .add_icon_container button {
        box-shadow: none; }
        @media screen and (max-width: 767px) {
          .add_space .add_space_availability_container .add_space_availability .formula .add_icon_container button, .add_space .add_space_availability_container .add_space_rates .formula .add_icon_container button, .add_space .add_space_rates_container .add_space_availability .formula .add_icon_container button, .add_space .add_space_rates_container .add_space_rates .formula .add_icon_container button {
            padding: 0px 12px 12px 0px; } }
      .add_space .add_space_availability_container .add_space_availability .formula .days_error, .add_space .add_space_availability_container .add_space_rates .formula .days_error, .add_space .add_space_rates_container .add_space_availability .formula .days_error, .add_space .add_space_rates_container .add_space_rates .formula .days_error {
        padding-left: 45px; }
  @media screen and (max-width: 767px) {
    .add_space .add_space_availability {
      padding-bottom: 0px !important; } }
  .add_space .venue_code {
    text-transform: uppercase;
    font-weight: bold; }
  .add_space .space_name_heading {
    text-transform: capitalize;
    font-weight: bold; }
  .add_space .delete_space_rates {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 15px;
    cursor: pointer;
    display: block; }
  .add_space .close_button {
    font-size: 15px;
    margin: 12px;
    cursor: pointer; }
  .add_space .add_service {
    padding-top: 2px; }

.custom_select_with_scroll label {
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #62717a;
  margin-bottom: 4px; }

.custom_select_with_scroll .label_container {
  padding-bottom: 4px;
  padding-left: 0; }

::-webkit-scrollbar {
  width: 5px !important; }

::-webkit-scrollbar-track {
  background: #ffffff !important; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1397e1 !important;
  border-radius: 10px; }

.company-info-form .custom_select_with_scroll {
  margin-top: 8px;
  margin-bottom: 15px; }

.success_modal_container div:nth-child(2) div:first-child {
  max-width: 550px; }
  .success_modal_container div:nth-child(2) div:first-child .dialog-content {
    min-height: 400px; }

.success_modal_container_small div:nth-child(2) div:first-child {
  max-width: 550px; }
  .success_modal_container_small div:nth-child(2) div:first-child .dialog-content {
    min-height: 100px !important; }

.help-button {
  cursor: pointer; }

.view-listing-popup .header {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin-top: 5px;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-weight: 600; }
  @media screen and (max-width: 767px) {
    .view-listing-popup .header {
      margin-top: -5px;
      height: 72px !important; } }

.view-listing-popup .modal_container {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll; }

.view-listing-popup .list-box {
  padding: 10px;
  border: 1px solid #e1e1e1;
  margin: 8px;
  background-color: #ffffff; }
  .view-listing-popup .list-box .blue-text {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #1397e1;
    margin: 0; }
  .view-listing-popup .list-box .grey-text {
    font-family: Raleway;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    margin: 0; }
  .view-listing-popup .list-box .actionDiv {
    display: inline-block;
    width: 30%;
    text-align: right;
    vertical-align: top; }
    @media screen and (max-width: 767px) {
      .view-listing-popup .list-box .actionDiv {
        width: 30% !important; } }
    .view-listing-popup .list-box .actionDiv span {
      display: inline-block;
      cursor: pointer; }

.view-listing-popup .not_available {
  text-align: center;
  padding: 150px 0px 120px; }
  @media screen and (max-width: 767px) {
    .view-listing-popup .not_available {
      padding: 30px 0px; } }

.modalBox_content {
  overflow: visible; }

.success_modal_container .dialog-content {
  overflow-y: visible !important; }

.holiday-rate-container {
  border: 1px solid #e1e1e1;
  padding: 12px;
  margin-top: 12px;
  border-radius: 4px; }
  .holiday-rate-container .input_field {
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #202020;
    height: 36px;
    width: 250px;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px; }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .holiday-rate-container .input_field {
        width: 200px; } }
    @media screen and (max-width: 767px) {
      .holiday-rate-container .input_field {
        width: 100%; } }
  .holiday-rate-container .input_label {
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    margin: 8px 0 8px 0; }
    @media screen and (max-width: 767px) {
      .holiday-rate-container .input_label {
        width: 90%;
        float: left; } }
  .holiday-rate-container .checkbox-container {
    margin-top: 8px; }
    .holiday-rate-container .checkbox-container label {
      margin-right: 8px; }

.home_page {
  overflow-x: hidden; }

.search_page {
  background: #ee2738;
  text-align: center;
  color: #ffffff;
  padding: 2% 38% 4%;
  border-radius: 0 0 100% 100% / 112px;
  overflow: hidden;
  width: 100% !important;
  margin-top: 65px; }
  .search_page h1 {
    text-align: left;
    font-size: 48px;
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff; }
    @media screen and (max-width: 1599px) {
      .search_page h1 {
        font-size: 33px; } }
  .search_page p {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff; }
    .search_page p.blue-text {
      color: #1397e1; }
  .search_page .material-icons {
    font-size: 48px; }
  @media screen and (max-width: 768px) {
    .search_page {
      padding: 7% 20%; }
      .search_page h1 {
        font-size: 38px; } }

.syncing_calendar .CustomSelectWithScroll-selectContainer-99 {
  border: solid 1px #e1e1e1;
  height: 36px;
  padding: 0px 4px;
  background: #f7f7f7 !important;
  margin-top: 4px;
  border-radius: 6px; }

.syncing_calendar .custom_checkbox {
  float: right; }

.main-form {
  padding: 2% 10%; }
  .main-form .sync-year-container {
    text-align: right;
    border-bottom: 1px solid #c4d0d9;
    padding: 8px; }
    .main-form .sync-year-container .select-control {
      min-width: 100px; }
      .main-form .sync-year-container .select-control .sync-select {
        line-height: 36px;
        font-size: 14px; }
    .main-form .sync-year-container .select-label {
      font-size: 16px;
      padding: 8px; }
  .main-form .main-form .custom_checkbox {
    float: right; }
  .main-form .custom_select_with_scroll {
    width: 40%;
    float: right;
    text-align: end; }
  .main-form .custom_select_with_scroll > div {
    max-width: 200px; }
    @media screen and (max-width: 768px) {
      .main-form .custom_select_with_scroll > div {
        max-width: 100%; } }
  .main-form .custom_checkbox {
    padding-top: 10px; }
  .main-form .custom_select {
    width: 50%; }
  .main-form .second-line-text {
    font-size: 13px; }
  .main-form .third-line-text {
    font-size: 13px;
    color: #747474; }
  .main-form .search_page {
    background: #ee2738;
    text-align: center;
    color: #ffffff;
    padding: 4% 42%;
    border-radius: 0 0 100% 100% / 112px;
    overflow: hidden;
    margin-left: -10%;
    width: calc(100% + 20%) !important; }
    .main-form .search_page h1 {
      text-align: left;
      font-size: 48px;
      font-weight: 200;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff; }
    .main-form .search_page p {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff; }
    .main-form .search_page .material-icons {
      font-size: 48px; }
  .main-form .top-next {
    text-align: right; }
    .main-form .top-next .red-button {
      padding: 6px;
      min-width: 100px; }
  .main-form p {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }
  .main-form h3 {
    text-align: right;
    color: #36434b;
    font-size: 16px;
    font-weight: 600;
    margin: 0; }
  .main-form h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0; }
  .main-form .small {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    margin: 0; }
  .main-form h6 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0; }
  .main-form .MuiSelect-root-123 {
    border-radius: 0px; }
  .main-form .MuiSelect-select-124 {
    width: calc(100% - 100px);
    height: 1.1875em;
    cursor: pointer;
    min-width: 100px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding-right: 32px;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none; }
  .main-form .select_field {
    background-color: #f7f7f7; }
  .main-form .data-table {
    border: 1px solid #c4d0d9;
    padding: 20px 20px 10px;
    margin-top: 10px; }
  .main-form .custom_checkbox {
    font-size: 13px; }
  .main-form button {
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    border: 0;
    padding: 1% 6%;
    color: #ffffff;
    margin-top: 20px; }
  @media screen and (max-width: 767px) {
    .main-form .CustomSelectWithScroll-selectContainer-99 {
      width: 100%; }
    .main-form p {
      font-size: 16px; }
    .main-form button {
      padding: 15% 100%; }
    .main-form .small {
      margin: 8px auto; }
    .main-form .vertical_align_date {
      margin: 0px auto 0px 0px !important; }
    .main-form .vertical_align_reason {
      margin: 20px auto !important; }
    .main-form .custom_select_with_scroll {
      width: 80%;
      float: left; }
    .main-form .data-table .custom_checkbox {
      width: 80%;
      float: left; } }
  .main-form .vertical_align_date {
    margin: 10px 0; }
  .main-form .vertical_align_reason {
    margin: 20px 0; }
  .main-form .vertical_align_date_holiday {
    margin: 21px 0; }
  .main-form button {
    cursor: pointer; }

.main-form .loadmore-btn-block button {
  background: transparent;
  color: #62717a !important;
  box-shadow: none;
  border: 1px solid #cccccc;
  font-weight: 700;
  padding: 15px 50px; }

.data-table .custom_checkbox {
  margin-left: 15px; }

.disabled-block .custom_select_with_scroll div,
.disabled-block .custom_checkbox .container {
  cursor: not-allowed !important; }

.main-form .custom_checkbox {
  margin: 0; }

.syncing_calendar .CustomSelectWithScroll-selectContainer-99 {
  border: solid 1px #e1e1e1;
  height: 36px;
  padding: 0px 4px;
  background: #f7f7f7 !important;
  margin-top: 4px;
  border-radius: 6px; }

.syncing_calendar .custom_checkbox {
  float: right; }

.main-form {
  padding: 2% 10%; }
  .main-form .main-form .custom_checkbox {
    float: right; }
  .main-form .custom_select_with_scroll {
    width: 53%;
    float: right; }
  .main-form .custom_checkbox {
    padding-top: 10px; }
  .main-form .custom_select {
    width: 50%; }
  .main-form .search_page {
    background: #ee2738;
    text-align: center;
    color: #ffffff;
    padding: 4% 42%;
    border-radius: 0 0 100% 100% / 112px;
    overflow: hidden;
    margin-left: -10%;
    width: calc(100% + 20%) !important; }
    .main-form .search_page h1 {
      text-align: left;
      font-size: 48px;
      font-weight: 200;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff; }
    .main-form .search_page p {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff; }
    .main-form .search_page .material-icons {
      font-size: 48px; }
  .main-form p {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }
  .main-form h3 {
    text-align: right;
    color: #36434b;
    font-size: 16px;
    margin: 0; }
    @media screen and (max-width: 767px) {
      .main-form h3 {
        display: none; } }
  .main-form h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0; }
  .main-form .small {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    margin: 0; }
  .main-form h6 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0; }
  .main-form .MuiSelect-root-123 {
    border-radius: 0px; }
  .main-form .MuiSelect-select-124 {
    width: calc(100% - 100px);
    height: 1.1875em;
    cursor: pointer;
    min-width: 100px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding-right: 32px;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none; }
  .main-form .select_field {
    background-color: #f7f7f7; }
  .main-form .data-table {
    border: 1px solid #c4d0d9;
    padding: 20px 20px 10px;
    margin-top: 10px; }
  .main-form .custom_checkbox {
    font-size: 13px; }
  .main-form button {
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    border: 0;
    padding: 1% 6%;
    color: #ffffff;
    margin-top: 20px; }
  @media screen and (max-width: 768px) {
    .main-form .webCalendar .syncing_calendar form {
      width: 100%; }
    .main-form .webCalendar .main-form {
      padding: 15px !important; }
    .main-form .webCalendar form {
      width: 100%; }
    .main-form .webCalendar .blue-text {
      width: 100%;
      display: block; }
    .main-form .webCalendar .custom_select_with_scroll {
      width: 100% !important; }
    .main-form .webCalendar .left, .main-form .webCalendar .right {
      width: 50%; }
    .main-form .CustomSelectWithScroll-selectContainer-99 {
      width: 100%; }
    .main-form p {
      font-size: 16px; }
    .main-form button {
      padding: 10px 30px; }
    .main-form .small {
      margin: 8px auto; }
    .main-form .vertical_align_date {
      margin: 0px auto 0px 0px !important; }
    .main-form .vertical_align_reason {
      margin: 0 auto; }
    .main-form .custom_select_with_scroll {
      width: 80%;
      float: left; }
    .main-form .data-table .custom_checkbox {
      width: 80%;
      float: left; }
    .main-form .webCalendar .main .content .blue-text {
      display: block !important;
      width: 100% !important; }
    .main-form .webCalendar .main .content .blue-text .right {
      margin: 8px 0px; } }
  .main-form .vertical_align_date {
    margin: 10px 0; }
  .main-form .vertical_align_reason {
    margin: 20px 0; }
  .main-form button {
    cursor: pointer; }

.google_calendar .main-form {
  padding-top: 48px;
  padding-left: 44px; }
  .google_calendar .main-form .heading {
    text-transform: capitalize; }

.mob-btn-red, .mob-btn-white, .mob-contact-btn {
  text-align: left !important;
  justify-content: left !important; }
  .mob-btn-red Button, .mob-btn-white Button, .mob-contact-btn Button {
    text-align: left;
    justify-content: left !important;
    height: auto !important; }
    @media screen and (max-width: 767px) {
      .mob-btn-red Button, .mob-btn-white Button, .mob-contact-btn Button {
        background-color: transparent;
        background: none;
        color: #202020;
        box-shadow: none;
        text-align: left; } }
    .mob-btn-red Button span, .mob-btn-white Button span, .mob-contact-btn Button span {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
      font-size: 12px; }
  @media screen and (max-width: 767px) {
    .mob-btn-red Button:hover, .mob-btn-white Button:hover, .mob-contact-btn Button:hover {
      background-color: transparent;
      background: none !important;
      color: rgba(0, 0, 0, 0.87);
      box-shadow: none !important; } }
  .mob-btn-red Button:hover span:hover, .mob-btn-white Button:hover span:hover, .mob-contact-btn Button:hover span:hover {
    background: none !important;
    color: rgba(0, 0, 0, 0.87) !important;
    box-shadow: none !important;
    text-align: left; }

.mob-contact-btn button {
  padding: 5px 0px; }

.google_calendar .radio_label {
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #36434b !important;
  padding-bottom: 5px; }

.google_calendar .radio_options {
  color: #fff !important; }

.google_calendar .main-form .custom_checkbox {
  float: right; }

.google_calendar .custom_checkbox {
  padding-top: 10px; }

.google_calendar .option-text {
  font-family: Raleway;
  font-size: 15px !important;
  font-weight: normal !important;
  color: #36434b !important;
  line-height: normal !important;
  padding-top: 10px; }

.google_calendar .option {
  display: flex;
  flex-direction: row; }
  .google_calendar .option div {
    width: 20px; }
  .google_calendar .option span:first-child {
    padding-left: 0px; }
  .google_calendar .option svg {
    font-size: 14px;
    margin-left: 15px; }

.google_calendar .heading {
  margin-bottom: 69px; }

.google_calendar .radio_label {
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #36434b !important;
  padding-bottom: 5px; }

.google_calendar .radio_options {
  color: #fff !important; }

.google_calendar .main-form .custom_checkbox {
  float: right; }

.google_calendar .custom_checkbox {
  padding-top: 10px; }

.google_calendar .option-text {
  font-family: Raleway;
  font-size: 15px !important;
  font-weight: normal !important;
  color: #36434b !important;
  line-height: normal !important;
  padding-top: 10px; }

.google_calendar .option {
  display: flex;
  flex-direction: row; }
  .google_calendar .option div {
    width: 20px; }
  .google_calendar .option span:first-child {
    padding-left: 0px; }
  .google_calendar .option svg {
    font-size: 14px;
    margin-left: 15px; }

.google_calendar .heading {
  margin-bottom: 69px; }

.home_page {
  overflow-x: hidden; }

.search_page {
  background: #ee2738;
  text-align: center;
  color: #ffffff;
  padding: 2% 38% 4%;
  border-radius: 0 0 100% 100% / 112px;
  overflow: hidden;
  width: 100% !important;
  margin-top: 65px; }
  .search_page h1 {
    text-align: left;
    font-size: 48px;
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff; }
    @media screen and (max-width: 1599px) {
      .search_page h1 {
        font-size: 33px; } }
  .search_page p {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff; }
  .search_page .material-icons {
    font-size: 48px; }
  @media screen and (max-width: 768px) {
    .search_page {
      padding: 7% 20%; }
      .search_page h1 {
        font-size: 38px; } }

.syncing_calendar .CustomSelectWithScroll-selectContainer-99 {
  border: solid 1px #e1e1e1;
  height: 36px;
  padding: 0px 4px;
  background: #f7f7f7 !important;
  margin-top: 4px;
  border-radius: 6px; }

.syncing_calendar .custom_checkbox {
  float: right; }

.main-form {
  padding: 2% 10%; }
  .main-form .main-form .custom_checkbox {
    float: right; }
  .main-form .custom_select_with_scroll {
    width: 40%;
    float: right;
    text-align: end; }
  .main-form .custom_select_with_scroll > div {
    max-width: 200px; }
    @media screen and (max-width: 768px) {
      .main-form .custom_select_with_scroll > div {
        max-width: 100%; } }
  .main-form .custom_checkbox {
    padding-top: 10px; }
  .main-form .custom_select {
    width: 50%; }
  .main-form .search_page {
    background: #ee2738;
    text-align: center;
    color: #ffffff;
    padding: 4% 42%;
    border-radius: 0 0 100% 100% / 112px;
    overflow: hidden;
    margin-left: -10%;
    width: calc(100% + 20%) !important; }
    .main-form .search_page h1 {
      text-align: left;
      font-size: 48px;
      font-weight: 200;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff; }
    .main-form .search_page p {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff; }
    .main-form .search_page .material-icons {
      font-size: 48px; }
  .main-form p {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #36434b; }
  .main-form h3 {
    text-align: right;
    color: #36434b;
    font-size: 16px;
    margin: 0; }
  .main-form h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0; }
  .main-form .small {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    margin: 0; }
  .main-form h6 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    margin: 0; }
  .main-form .MuiSelect-root-123 {
    border-radius: 0px; }
  .main-form .MuiSelect-select-124 {
    width: calc(100% - 100px);
    height: 1.1875em;
    cursor: pointer;
    min-width: 100px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding-right: 32px;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none; }
  .main-form .select_field {
    background-color: #f7f7f7; }
  .main-form .data-table {
    border: 1px solid #c4d0d9;
    padding: 20px 20px 10px;
    margin-top: 10px; }
  .main-form .custom_checkbox {
    font-size: 13px; }
  .main-form button {
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    border: 0;
    padding: 1% 6%;
    color: #ffffff;
    margin-top: 20px; }
  @media screen and (max-width: 767px) {
    .main-form .CustomSelectWithScroll-selectContainer-99 {
      width: 100%; }
    .main-form p {
      font-size: 16px; }
    .main-form button {
      padding: 15% 100%; }
    .main-form .small {
      margin: 8px auto; }
    .main-form .vertical_align_date {
      margin: 0px auto 0px 0px !important; }
    .main-form .vertical_align_reason {
      margin: 20px auto !important; }
    .main-form .custom_select_with_scroll {
      width: 80%;
      float: left; }
    .main-form .data-table .custom_checkbox {
      width: 80%;
      float: left; } }
  .main-form .vertical_align_date {
    margin: 10px 0; }
  .main-form .vertical_align_reason {
    margin: 20px 0; }
  .main-form .vertical_align_date_holiday {
    margin: 21px 0; }
  .main-form button {
    cursor: pointer; }

.divider {
  opacity: 0.1; }

.contact_us input {
  background-color: #f7f7f7;
  border: 1px #f0f0f0 solid;
  border-radius: 5px;
  padding: 5px;
  width: 50%; }

.contact_us textarea {
  background-color: #f7f7f7;
  border: 1px #f0f0f0 solid;
  border-radius: 5px;
  padding: 5px;
  width: 50%; }

.contact_us .bottomSpacing {
  margin-bottom: 20px; }

.contact_us .button {
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.5px;
  background-color: #ee2738;
  color: #fff;
  border: 1px solid #ee2738;
  padding: 6px 20px;
  border-radius: 6px;
  cursor: pointer; }

@media screen and (max-device-width: 500px) {
  .contact_us input, .contact_us textarea {
    width: 90%; } }

.divider {
  opacity: 0.1; }

.aboutUs p {
  margin: 1rem 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5; }

.aboutUs h3 {
  font-size: 16px; }

.divider {
  opacity: 0.1; }

.divider {
  opacity: 0.1; }

.divider {
  opacity: 0.1; }

.avilableDescription h3 {
  font-weight: bold;
  margin-bottom: 1rem; }

.abt-us {
  margin-top: -20px; }

.divider {
  opacity: 0.1; }

.my_events {
  padding-top: 65px;
  height: 100vh; }
  .my_events .save_button {
    padding: 0px 32px; }
  .my_events .personal_info {
    padding-bottom: 50px; }
  .my_events .company_info {
    padding-bottom: 50px; }
  .my_events .change_password {
    padding-bottom: 50px; }
  .my_events .delete-image {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize; }
  .my_events .delete-image:hover {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer; }
  .my_events .form_name {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    color: #1397e1; }
  .my_events .edit_profile_component {
    padding-bottom: 0px;
    background-color: #ffffff;
    padding: 20px 20px 20px 20px;
    margin: 15px 15px;
    min-height: 85vh;
    height: auto;
    display: flex;
    width: auto !important;
    overflow: visible;
    border: 1px solid #ebebeb; }
    @media screen and (min-width: 1599px) {
      .my_events .edit_profile_component {
        padding-bottom: 150px; } }
    @media screen and (max-width: 1366px) and (min-width: 1300px) {
      .my_events .edit_profile_component {
        padding-bottom: 240px; } }
    .my_events .edit_profile_component .help_icon {
      padding-top: 44px;
      cursor: pointer; }
    @media screen and (max-width: 500px) {
      .my_events .edit_profile_component .edit_profile_component_form {
        padding-left: 20px;
        padding-right: 20px; } }
    .my_events .edit_profile_component .form_name {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #1397e1;
      text-transform: capitalize; }
      @media screen and (max-width: 767px) {
        .my_events .edit_profile_component .form_name {
          margin: 0px;
          padding-left: 25px; } }
    .my_events .edit_profile_component .form_desc {
      font-size: 14px;
      text-align: center;
      font-weight: 500;
      padding-top: 4px; }
      .my_events .edit_profile_component .form_desc a {
        color: #4a4a4a; }
        .my_events .edit_profile_component .form_desc a .info-icon {
          font-size: 14px;
          width: 20px;
          height: 20px;
          margin: -4px; }
    @media screen and (min-width: 1025px) {
      .my_events .edit_profile_component .left_panel {
        padding-right: 55px; } }
    @media screen and (min-width: 960px) and (max-width: 1025px) {
      .my_events .edit_profile_component .left_panel {
        padding-right: 20px; } }
    @media screen and (min-width: 1025px) {
      .my_events .edit_profile_component .right_panel {
        padding-left: 57px; } }
    @media screen and (min-width: 960px) and (max-width: 1025px) {
      .my_events .edit_profile_component .right_panel {
        padding-left: 20px; } }
    .my_events .edit_profile_component .password_field_div {
      padding-top: 20px;
      position: relative; }
    .my_events .edit_profile_component .field_div {
      padding-top: 20px; }
    .my_events .edit_profile_component .edit_profile_component {
      padding-bottom: 62px; }
  .my_events .error {
    color: #FF0000;
    font-size: 11px;
    text-align: left;
    display: block !important;
    position: absolute;
    bottom: -16px;
    left: 0px; }
  .my_events input#city {
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: transparent;
    width: 100%;
    padding: 9px 15px;
    font-size: 15px;
    line-height: 1.07;
    text-align: left;
    color: #202020; }
  .my_events .suggestionDiv {
    width: 100% !important;
    position: relative;
    font-size: 12px;
    line-height: 2.3;
    word-break: break-word; }
  .my_events input[role="combobox"] + div {
    z-index: 99;
    top: 35px !important;
    left: 0px !important;
    position: absolute !important;
    min-height: 95px !important;
    background: #fff;
    overflow-y: scroll;
    height: 150px;
    box-shadow: 0px 15px 10px -15px #ccc;
    border: 1px solid #e1e1e1;
    font-weight: 500;
    cursor: pointer; }
  .my_events input[role="combobox"] + div .item {
    padding: 0px 15px; }
  .my_events input[role="combobox"] + div div:hover {
    background-image: linear-gradient(#eeeeee, #eeeeee); }

.my_event_container .loading {
  display: block;
  position: absolute;
  top: 43%;
  font-size: 25px;
  left: 47%;
  font-weight: 700;
  background-color: transparent !important; }

::-webkit-scrollbar {
  width: 5px !important; }

.MuiDialog-paperWidthSm-226 {
  max-width: 100% !important;
  width: 100% !important;
  max-height: 100% !important; }

.MuiPaper-elevation24-146 {
  box-shadow: none !important; }

.MuiBackdrop-root-308 {
  background-color: rgba(0, 0, 0, 0.7) !important; }

.MuiPaper-root-120 {
  background: none !important; }

.react-datepicker-popper {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px; }

.datepiker {
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #62717a; }

.dialogActions span {
  cursor: pointer !important; }

.closeDialog {
  width: 22px;
  height: 22px;
  font-family: material;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff; }

.dialog-content {
  height: 100%;
  width: 60%;
  margin: auto; }

.modalBox_content {
  height: 100%; }

.main_place_hold_section {
  width: 100%;
  border-radius: 6px;
  background-color: transparent !important;
  border: 2px solid white !important;
  height: 100%; }

.header {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  margin-top: 0;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 0px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0; }

.success_modal_container .dialog-content {
  height: 100% !important;
  width: 100% !important;
  margin: auto !important; }

.my_event_container {
  width: 100% !important;
  min-height: auto !important;
  /* create new event form */
  /* Events */ }
  .my_event_container .custom_select_with_scroll > div {
    padding: 0px 0px 0px 15px; }
  .my_event_container .disp-none-big {
    display: none; }
    @media screen and (max-width: 767px) {
      .my_event_container .disp-none-big {
        display: block; } }
    @media screen and (max-width: 767px) {
      .my_event_container .disp-none-big .sm-pd-seating {
        padding-top: 20px !important; } }
  .my_event_container .react-datepicker__navigation {
    padding: 0 !important; }
  .my_event_container .MuiDialog-paperWidthSm-226 {
    max-width: 100% !important;
    width: 100% !important;
    max-height: 100% !important; }
  .my_event_container .MuiPaper-elevation24-146 {
    box-shadow: none !important; }
  .my_event_container .MuiBackdrop-root-308 {
    background-color: rgba(0, 0, 0, 0.7) !important; }
  .my_event_container select {
    height: 36px !important; }
  .my_event_container .MuiPaper-root-120 {
    background: none !important;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    margin: 0px; }
  .my_event_container .MuiDialog-paper-222 {
    margin: 0 !important;
    position: absolute;
    top: 0; }
  .my_event_container .dialogActions {
    height: 40px;
    font-family: material;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0px; }
  .my_event_container .closeButton {
    cursor: pointer;
    position: absolute;
    font-size: 30px;
    top: -70px;
    right: -38px;
    color: #ffffff;
    font-weight: bold; }
  .my_event_container .modalBox_content {
    height: 100%; }
  .my_event_container .main_place_hold_section {
    width: 100%;
    border-radius: 6px;
    background-color: #f0f0f0;
    border: 2px solid white !important;
    height: 100%; }
  .my_event_container .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px 10px;
    border-bottom-style: solid !important;
    border-bottom-width: 0px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .my_event_container .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px; }
    .my_event_container .header-text p {
      font-weight: normal;
      font-size: 12px; }
  .my_event_container .header-button-div {
    text-align: center; }
    .my_event_container .header-button-div .red-button-small {
      margin-left: 12%;
      cursor: pointer; }
      @media screen and (max-width: 767px) {
        .my_event_container .header-button-div .red-button-small {
          margin-bottom: 20px; } }
    .my_event_container .header-button-div .white-button-big {
      float: right; }
  .my_event_container .uploader {
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
    background-image: url(https://www.first-hold.com/static/media/perm_contact_calendar.dd9786b4.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-color: #f0f0f0; }
  .my_event_container .success_modal_container {
    min-height: 100px;
    overflow-y: auto !important; }
  .my_event_container .uploader-content {
    width: 300px;
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto; }
  .my_event_container .red-text-content {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ee2738;
    margin: 140px 0 5px 0; }
  .my_event_container .black-text-content {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
    margin-top: 5px; }
  .my_event_container .white-button-big {
    width: auto;
    color: #ee2738;
    height: 36px;
    border: solid 1px #e1e1e1;
    padding: 0px;
    font-size: 13px;
    background: #ffffff;
    font-style: normal;
    font-family: Raleway;
    font-weight: 500;
    line-height: normal;
    margin-left: 20px;
    font-stretch: normal;
    border-radius: 6px;
    padding: 0px 5px;
    cursor: pointer; }
    .my_event_container .white-button-big span {
      width: 100%; }
  .my_event_container .select_field {
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #202020 !important;
    text-transform: none !important;
    width: 100%;
    height: 36px;
    margin: 0 !important;
    padding-left: 10px; }
  .my_event_container .form .form-content {
    padding: 20px;
    display: inline-block; }
    @media screen and (max-width: 767px) {
      .my_event_container .form .form-content {
        padding: 20px 0px 0px; } }
  .my_event_container .form .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px 0px;
    border-bottom-style: solid !important;
    border-bottom-width: 0px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .my_event_container .form .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px; }
  .my_event_container .form .header-button-div-form {
    float: right;
    width: auto;
    display: flex; }
    .my_event_container .form .header-button-div-form Button {
      margin-right: 10px;
      width: auto;
      padding: 0px 15px; }
  .my_event_container .form .dialogActions {
    height: 120px;
    font-family: material;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    padding: 20px;
    margin: 0;
    display: block; }
    .my_event_container .form .dialogActions span {
      cursor: pointer; }
  .my_event_container .form .field-div {
    width: 100%;
    height: 80px;
    float: left; }
    @media screen and (max-width: 767px) {
      .my_event_container .form .field-div {
        float: none !important; } }
  @media screen and (max-width: 767px) {
    .my_event_container .form .spacer.disp-none-sm {
      display: none; } }
  @media screen and (max-width: 767px) {
    .my_event_container .form .sm-pd-start {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .my_event_container .form .sm-pd-req-up, .my_event_container .form .sm-pd-end, .my_event_container .form .sm-pd-city {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .my_event_container .form .sm-pd-req-down {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .my_event_container .form .sm-pd-req-seating {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .my_event_container .form .sm-pd-req {
      padding-top: 5px; } }
  @media screen and (max-width: 767px) {
    .my_event_container .form .disp-none-small {
      display: none; } }
  .my_event_container .form .input-label {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    width: 100%;
    float: left;
    padding-bottom: 5px; }
  .my_event_container .form .input-field {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: transparent;
    float: left;
    margin: 0 !important;
    font-size: 16px;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    padding-left: 10px; }
    @media screen and (max-width: 767px) {
      .my_event_container .form .input-field {
        font-size: 15px !important;
        padding-left: 15px; } }
  .my_event_container .form .input-field-section {
    width: 100%;
    float: left;
    position: relative; }
  .my_event_container .form .select-down-icon::before {
    content: 'arrow_drop_down';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 0.8;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    position: absolute;
    top: 8px;
    right: 5px;
    color: #62717a; }
  .my_event_container .form .select-down-icon {
    position: relative; }
  .my_event_container .form .big {
    width: 100% !important; }
  .my_event_container .form .dark-black {
    color: #36434b !important; }
  .my_event_container .form .light-black {
    color: #202020 !important; }
  .my_event_container .form .lighter-black {
    color: #62717a !important; }
  .my_event_container .form .small {
    width: 46% !important; }
    .my_event_container .form .small select {
      font-size: 15px !important; }
    .my_event_container .form .small input {
      font-size: 15px; }
  .my_event_container .form .smaller {
    width: 40% !important; }
    .my_event_container .form .smaller select {
      font-size: 12px !important;
      background-color: transparent !important;
      text-transform: uppercase !important; }
    .my_event_container .form .smaller input {
      font-size: 12px;
      color: #1397e1 !important;
      text-align: left;
      height: 36px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff; }
  .my_event_container .form .tall {
    height: 140px; }
  .my_event_container .form .spacer {
    width: 20%;
    padding: 25px 0 0 0;
    overflow: hidden;
    text-align: center; }
  .my_event_container .form select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    padding: 5px;
    -webkit-appearance: menulist;
    text-align: left;
    padding-left: 5px;
    width: 100%;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal; }
  .my_event_container .form .float-right {
    float: right; }
    .my_event_container .form .float-right select {
      width: 100%; }
  .my_event_container .form .white-button-small {
    width: 100px !important;
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    padding: 0 !important;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    float: left;
    cursor: pointer; }
    .my_event_container .form .white-button-small span {
      width: 100%; }
  .my_event_container .form .red-button-small {
    width: 100px;
    height: 36px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer; }
  @media screen and (max-width: 767px) {
    .my_event_container .form .smaller {
      width: 100% !important; }
    .my_event_container .form .small {
      width: 100%; }
    .my_event_container .form .modalBox_content {
      height: 100%;
      display: flex; }
    .my_event_container .form .newEventPopupContainer {
      width: 100% !important; }
    .my_event_container .form .small {
      width: 100% !important; }
    .my_event_container .form .header {
      padding-top: 0px;
      height: auto; }
    .my_event_container .form .header-text {
      width: 100%;
      text-align: center;
      padding-left: 15px; }
    .my_event_container .form .header-button-div-form {
      width: 100%;
      margin: 10px auto;
      padding: 0 0px; }
    .my_event_container .form .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid; }
    .my_event_container .form .red-text-content {
      margin-top: 100px; }
    .my_event_container .form .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important; } }
  .my_event_container .actionDiv .delete {
    cursor: pointer; }
  .my_event_container .eventDiv {
    width: 48%;
    height: 80px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    margin: 10px auto 15px !important;
    padding: 20px 10px;
    margin: 15px 5px !important;
    float: left; }
    @media screen and (max-width: 767px) {
      .my_event_container .eventDiv {
        margin: 10px auto !important; } }
    .my_event_container .eventDiv .blue-text {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #1397e1;
      margin: 0; }
  .my_event_container .events-content {
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    width: 100%;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    overflow: visible !important;
    margin: 30px auto; }
  .my_event_container .checkBoxDiv {
    width: 5%;
    float: left;
    display: flex;
    height: 100%;
    align-items: center; }
  .my_event_container .detailsDiv {
    width: 100%;
    float: left;
    display: flex;
    height: 100%;
    justify-content: space-between; }
    .my_event_container .detailsDiv .actionDiv {
      float: left;
      display: flex;
      height: 100%;
      align-items: center;
      text-align: center; }
      @media screen and (max-width: 767px) {
        .my_event_container .detailsDiv .actionDiv {
          width: 50%; } }
      @media screen and (max-width: 767px) and (min-width: 500px) {
        .my_event_container .detailsDiv .actionDiv {
          justify-content: space-between;
          width: 30%; } }
      .my_event_container .detailsDiv .actionDiv .hand-icon {
        width: 9.5px;
        height: 19.7px;
        background-color: #62717a;
        margin-right: 10px; }
      .my_event_container .detailsDiv .actionDiv .grey-text {
        font-family: Raleway;
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        color: #62717a;
        margin: 0;
        min-width: 80px;
        cursor: pointer; }
        @media screen and (max-width: 767px) {
          .my_event_container .detailsDiv .actionDiv .grey-text {
            min-width: 90px; } }
        @media screen and (max-width: 320px) {
          .my_event_container .detailsDiv .actionDiv .grey-text {
            min-width: 70px; } }
  .my_event_container .checkBox {
    width: 20px;
    height: 20px;
    background-color: #9aa7af !important; }
  .my_event_container .large-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    cursor: pointer;
    word-break: break-word; }
    @media screen and (max-width: 767px) {
      .my_event_container .large-text {
        widows: 50%; } }
  .my_event_container .eventsSelected {
    width: 100%;
    height: 440px;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 30px; }
    .my_event_container .eventsSelected .footerButtonDiv {
      width: 20%;
      margin: 20px auto; }
    .my_event_container .eventsSelected .grey-button:disabled {
      height: 36px;
      border-radius: 6px;
      background-color: #9aa7af !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: none; }
    .my_event_container .eventsSelected .grey-button:enabled {
      height: 36px;
      border-radius: 6px;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738); }
    .my_event_container .eventsSelected .image {
      width: 38px;
      height: 38px;
      object-fit: contain; }
    .my_event_container .eventsSelected .large-text {
      font-family: Raleway;
      font-size: 24px !important;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin: 10px auto;
      cursor: pointer;
      word-break: break-word; }
    .my_event_container .eventsSelected .description {
      font-family: Raleway;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #202020;
      margin: auto; }
    .my_event_container .eventsSelected .blue-text {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1397e1;
      margin: 15px auto 0 auto; }
    .my_event_container .eventsSelected .grey-text {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9aa7af;
      margin: 20px auto 10px auto;
      text-transform: uppercase; }
    .my_event_container .eventsSelected .optionsDiv {
      width: 90%;
      margin: auto;
      display: block;
      overflow: auto; }
      .my_event_container .eventsSelected .optionsDiv .option {
        width: 33%;
        float: left;
        text-align: center; }
        .my_event_container .eventsSelected .optionsDiv .option label {
          margin-left: 0px !important; }
      .my_event_container .eventsSelected .optionsDiv .option-text {
        width: 60%;
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #62717a;
        margin: auto; }
      .my_event_container .eventsSelected .optionsDiv .grey {
        color: #62717a !important; }
      .my_event_container .eventsSelected .optionsDiv .blue {
        color: #1f9ce3 !important; }
      .my_event_container .eventsSelected .optionsDiv .blue-circle {
        width: 20px;
        height: 20px;
        border: solid 2px #1397e1;
        background-color: #ffffff;
        margin-top: 30px; }
  .my_event_container .dialogFooter {
    height: 50px;
    border-radius: 6px;
    box-shadow: inset 0 1px 0 0 var(--white);
    background-color: #f7f7f7;
    display: flex;
    align-items: center; }
    .my_event_container .dialogFooter .buttonDiv {
      margin: auto; }
  .my_event_container .red-button {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer; }
  @media screen and (max-width: 767px) {
    .my_event_container .dialog-content {
      height: 100%;
      width: 90% !important;
      margin: auto; }
    .my_event_container .modalBox_content {
      height: 100%; }
    .my_event_container .header {
      height: auto;
      margin: 0;
      padding: 0px; }
    .my_event_container .header-text {
      width: 100%;
      text-align: center; }
    .my_event_container .header-button-div {
      width: 100%;
      text-align: center;
      margin: 10px 0; }
    .my_event_container .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid; }
    .my_event_container .red-text-content {
      margin-top: 100px; }
    .my_event_container .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important; }
    .my_event_container .eventDiv {
      margin: 0;
      padding: 20px 10px;
      height: auto; }
    .my_event_container .checkBoxDiv {
      width: 10% !important; }
    .my_event_container .detailsDiv {
      width: 100% !important;
      position: relative;
      vertical-align: middle;
      align-items: center; }
    .my_event_container .large-text {
      width: 50% !important;
      cursor: pointer; }
    .my_event_container .actionDiv {
      height: 70% !important; }
    .my_event_container .eventsSelected {
      height: auto;
      padding: 10px; }
    .my_event_container .optionsDiv {
      margin: 0 !important;
      width: 100% !important; }
    .my_event_container .option {
      width: 80% !important;
      float: left;
      align-items: center;
      display: flex;
      margin: auto; }
      .my_event_container .option .option-text {
        float: left;
        text-align: left !important; }
      .my_event_container .option input {
        float: left;
        margin: 0 !important; }
    .my_event_container .footerButtonDiv {
      width: 130px !important;
      margin: 20px auto; }
    .my_event_container .main_place_hold_section {
      margin: 0px auto; }
    .my_event_container #eventsContent {
      height: auto !important; } }

.MuiDialog-paper-235 {
  margin: 20px !important; }

@media (min-width: 1100px) {
  .my_event_container .eventDiv {
    width: 49% !important; } }

.main_place_hold_section .custom_select_with_scroll.time_select div {
  overflow: inherit; }

.modalBox_content {
  overflow: visible; }

.my_favourites {
  padding-top: 65px;
  height: 100vh; }
  .my_favourites .venueCardWithFav .venue_image {
    width: 100%;
    transition: 200ms ease-in-out; }
  .my_favourites .save_button {
    padding: 0px 32px; }
  .my_favourites .personal_info {
    padding-bottom: 50px; }
  .my_favourites .company_info {
    padding-bottom: 50px; }
  .my_favourites .change_password {
    padding-bottom: 50px; }
  .my_favourites .delete-image {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize; }
  .my_favourites .delete-image:hover {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer; }
  .my_favourites .headings {
    border-bottom: solid 1px #e1e1e1; }
  .my_favourites .form_name {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    margin: 20px auto;
    color: #1397e1; }
  .my_favourites .edit_profile_component {
    padding-bottom: 62px;
    background-color: #ffffff;
    padding: 0px 0px 20px 0px;
    margin: 15px 30px 15px 15px;
    box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb;
    width: auto !important; }
    .my_favourites .edit_profile_component .help_icon {
      padding-top: 44px;
      cursor: pointer; }
    @media screen and (max-width: 500px) {
      .my_favourites .edit_profile_component .edit_profile_component_form {
        padding-left: 20px;
        padding-right: 20px; } }
    .my_favourites .edit_profile_component .form_name {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #1397e1;
      text-transform: capitalize;
      margin: 20px auto; }
    @media screen and (min-width: 1025px) {
      .my_favourites .edit_profile_component .left_panel {
        padding-right: 55px; } }
    @media screen and (min-width: 960px) and (max-width: 1025px) {
      .my_favourites .edit_profile_component .left_panel {
        padding-right: 20px; } }
    @media screen and (min-width: 1025px) {
      .my_favourites .edit_profile_component .right_panel {
        padding-left: 57px; } }
    @media screen and (min-width: 960px) and (max-width: 1025px) {
      .my_favourites .edit_profile_component .right_panel {
        padding-left: 20px; } }
    .my_favourites .edit_profile_component .password_field_div {
      padding-top: 20px;
      position: relative; }
    .my_favourites .edit_profile_component .field_div {
      padding-top: 20px; }
    .my_favourites .edit_profile_component .edit_profile_component {
      padding-bottom: 62px; }
  .my_favourites .error {
    color: #FF0000;
    font-size: 11px;
    text-align: left;
    height: 10px; }

.my_hold_page {
  display: block;
  margin-top: 70px; }
  .my_hold_page .myhold-header {
    display: block;
    width: 95%;
    text-align: center;
    margin: auto; }
    .my_hold_page .myhold-header .filter-tabs {
      width: 52% !important;
      float: left; }
      @media screen and (max-width: 767px) {
        .my_hold_page .myhold-header .filter-tabs {
          width: 100% !important; } }
      @media screen and (max-width: 767px) {
        .my_hold_page .myhold-header .filter-tabs button {
          width: 350px; } }
    .my_hold_page .myhold-header .search-hold {
      display: inline-block;
      width: 47%;
      float: right; }
      @media screen and (max-width: 767px) {
        .my_hold_page .myhold-header .search-hold {
          width: 100%; } }
    .my_hold_page .myhold-header .search-image {
      display: inline-block;
      width: 10%;
      padding: 10px 0px 10px 15px;
      text-align: center; }
      @media screen and (max-width: 767px) {
        .my_hold_page .myhold-header .search-image {
          width: 20%;
          padding: 10px 0px 10px 0px; } }
    .my_hold_page .myhold-header .download {
      display: inline-block;
      width: 10%;
      text-align: right; }
      @media screen and (max-width: 767px) {
        .my_hold_page .myhold-header .download {
          width: 20%; } }
      .my_hold_page .myhold-header .download a {
        color: #36434b; }
    .my_hold_page .myhold-header .searchBox {
      display: inline-block;
      vertical-align: top;
      padding-top: 10px; }
      .my_hold_page .myhold-header .searchBox input {
        border: none;
        width: 100%;
        padding: 5px 15px;
        font-size: 12px;
        padding-left: 5px !important; }
  .my_hold_page .myhold-content {
    display: block;
    margin: auto;
    text-align: center;
    background-color: #f0f0f0;
    padding-top: 10px;
    min-height: 82vh; }
    @media screen and (max-width: 767px) {
      .my_hold_page .myhold-content {
        height: 100%; } }
    .my_hold_page .myhold-content .my-hold {
      width: 95%;
      background-color: #ffffff;
      align-items: center;
      margin: auto;
      border: solid 1px #e1e1e1;
      display: inline-block;
      padding: 0px 20px;
      border-radius: 6px; }
    .my_hold_page .myhold-content .first-div {
      display: block;
      width: 20%;
      float: left;
      padding: 8px 15px 0px; }
      @media screen and (max-width: 767px) {
        .my_hold_page .myhold-content .first-div {
          width: 100%; } }
      .my_hold_page .myhold-content .first-div .hold-image {
        display: inline-block;
        width: 35%;
        text-align: center; }
        @media screen and (max-width: 767px) {
          .my_hold_page .myhold-content .first-div .hold-image {
            width: 100%; } }
        .my_hold_page .myhold-content .first-div .hold-image .image {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 50%; }
          @media screen and (max-width: 767px) {
            .my_hold_page .myhold-content .first-div .hold-image .image {
              border-radius: 0px;
              max-height: 100%;
              height: auto;
              width: 100%; } }
      .my_hold_page .myhold-content .first-div .hold-details {
        width: 100%;
        display: inline-block;
        text-align: left;
        vertical-align: top;
        padding-top: 18px; }
        @media screen and (max-width: 767px) {
          .my_hold_page .myhold-content .first-div .hold-details {
            width: 100%; } }
        .my_hold_page .myhold-content .first-div .hold-details .hold-name {
          font-family: Raleway;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          word-break: break-word;
          font-style: normal;
          line-height: 1.23;
          letter-spacing: normal;
          text-align: left;
          color: #1397e1; }
          @media screen and (max-width: 991px) and (min-width: 768px) {
            .my_hold_page .myhold-content .first-div .hold-details .hold-name {
              font-size: 13px; } }
    .my_hold_page .myhold-content .middle-div {
      display: block;
      float: left;
      width: 40%;
      padding: 8px 0px; }
      @media screen and (max-width: 767px) {
        .my_hold_page .myhold-content .middle-div {
          width: 100%; } }
      .my_hold_page .myhold-content .middle-div .mid-three-sec {
        display: inline-block;
        width: 40%;
        text-align: left; }
        @media screen and (max-width: 767px) {
          .my_hold_page .myhold-content .middle-div .mid-three-sec {
            padding-left: 15px; } }
      .my_hold_page .myhold-content .middle-div .mid-two-sec {
        display: inline-block;
        width: 25%;
        text-align: left; }
        @media screen and (max-width: 767px) {
          .my_hold_page .myhold-content .middle-div .mid-two-sec {
            padding-left: 15px; } }
      .my_hold_page .myhold-content .middle-div .mid-one-sec {
        display: inline-block;
        width: 35%;
        text-align: left; }
      .my_hold_page .myhold-content .middle-div .d-ago {
        font-family: Raleway;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #9aa7af; }
      .my_hold_page .myhold-content .middle-div .date-hold {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: left;
        color: #202020; }
      .my_hold_page .myhold-content .middle-div .all-day {
        font-family: Raleway;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #9aa7af; }
      .my_hold_page .myhold-content .middle-div .date-expired {
        font-family: Raleway;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        word-break: break-word;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #1397e1; }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          .my_hold_page .myhold-content .middle-div .date-expired {
            font-size: 13px; } }
    .my_hold_page .myhold-content .last-div {
      display: block;
      float: left;
      width: 40%;
      text-align: left;
      padding: 15px 0px; }
      @media screen and (max-width: 767px) {
        .my_hold_page .myhold-content .last-div {
          width: 100%; } }
      .my_hold_page .myhold-content .last-div .last-one-sec {
        display: inline-flex;
        width: 35%;
        justify-content: center;
        padding-right: 8%; }
      .my_hold_page .myhold-content .last-div .last-two-sec {
        display: inline-block;
        width: 35%; }
        @media screen and (max-width: 767px) {
          .my_hold_page .myhold-content .last-div .last-two-sec {
            padding-left: 60px; } }
      .my_hold_page .myhold-content .last-div .last-three-sec {
        display: inline-block;
        width: 15%; }
        @media screen and (max-width: 767px) {
          .my_hold_page .myhold-content .last-div .last-three-sec {
            text-align: end;
            padding-left: 20px; } }
        .my_hold_page .myhold-content .last-div .last-three-sec .checkbox .container .checkmark:after {
          left: 3px;
          top: -1px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg); }
        .my_hold_page .myhold-content .last-div .last-three-sec .checkbox .container input:checked ~ .checkmark:after {
          display: block; }
        .my_hold_page .myhold-content .last-div .last-three-sec .checkbox .checkmark:after {
          content: "";
          position: absolute;
          display: none; }
      .my_hold_page .myhold-content .last-div .last-four-sec {
        display: inline-block;
        width: 15%; }
      .my_hold_page .myhold-content .last-div .last-text {
        font-family: Raleway;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: center;
        color: #36434b; }
        @media screen and (max-width: 767px) {
          .my_hold_page .myhold-content .last-div .last-text {
            font-size: 13px; } }
  .my_hold_page .my-hold.entry-box {
    margin-bottom: 12px; }
    .my_hold_page .my-hold.entry-box .page-select {
      padding: 6px 8px 8px 8px;
      width: 48px;
      background: #f7f7f7; }
  .my_hold_page .my-hold.no-data {
    padding: 16px; }
  .my_hold_page .event-header .first-div, .my_hold_page .event-header .middle-div, .my_hold_page .event-header .last-div {
    padding: 8px 15px 8px;
    text-align: center; }
  .my_hold_page .event-header p {
    font-family: Raleway;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #36434b;
    cursor: pointer; }
    .my_hold_page .event-header p.disable {
      cursor: default; }
    @media screen and (max-width: 767px) {
      .my_hold_page .event-header p {
        font-size: 13px; } }
    .my_hold_page .event-header p .material-icons {
      font-size: 18px;
      padding: 3px 0px 0px 0px; }
      .my_hold_page .event-header p .material-icons.disable {
        color: #929292; }
  .my_hold_page .event-header .mid-three-sec {
    padding-left: 10px; }

.tabs {
  border: none;
  box-shadow: none; }
  .tabs span {
    background-color: #ee2738 !important;
    left: 20px;
    height: 3px !important; }
  .tabs button :active {
    color: #ee2738 !important;
    font-weight: 800; }
  .tabs button span {
    color: #36434b;
    font-weight: bold;
    width: 100% !important;
    background-color: transparent !important;
    height: auto !important; }
    @media screen and (max-width: 991px) {
      .tabs button span {
        font-size: 13px !important; } }
    @media screen and (max-width: 320px) {
      .tabs button span {
        font-size: 11px !important; } }

#simple-menu :nth-child(2) {
  box-shadow: none;
  border: 1px solid #f1f1f1; }

@media screen and (max-width: 767px) {
  .tabs :nth-child(2) div {
    width: 250px; } }

@media screen and (max-width: 767px) {
  .box-tab div :nth-child(2) {
    width: 100%; } }

@media screen and (min-width: 768px) {
  .myholdpage .tabs Button {
    width: 145px;
    min-width: auto; }
    .myholdpage .tabs Button span {
      font-size: 12px !important; } }

@media screen and (min-width: 1024px) {
  .myholdpage .tabs Button {
    width: 93px;
    min-width: auto; } }

@media screen and (min-width: 1220px) {
  .myholdpage .tabs Button {
    width: 115px;
    min-width: auto; } }

.my_hold_page .event-header .first-div p {
  text-align: left;
  margin-left: 24%; }

.my_hold_page .event-header .mid-one-sec p {
  padding-left: 11%; }

.my_hold_page .event-header .last-div .last-two-sec p {
  text-align: left;
  padding-left: 17%; }

.my_hold_page .event-header .last-div .last-one-sec p {
  padding-right: 35%; }

.my_hold_page .no-data {
  background-color: #ffffff;
  margin-top: 50px;
  padding: 150px 0; }

@media screen and (min-width: 1600px) {
  .my_hold_page .event-header .last-div .last-two-sec p {
    text-align: left;
    padding-left: 25%; } }

.my_hold_page .my-hold.entry-box {
  margin-bottom: 20px !important; }

.reason-text {
  min-height: 56px; }

.place-hold-popup .add-new-form-block {
  height: auto !important; }

.success_modal_container.hold_settings div:nth-child(2) div:first-child .dialog-content {
  min-height: auto !important; }

.planner-profile-popup p {
  word-break: break-all; }

.calendar-block .searchBox.new {
  position: relative;
  padding-left: 10px; }

.calendar-block .search-image {
  position: absolute;
  top: 13px;
  padding: 0 !important;
  width: auto !important;
  height: 28px;
  border-left: 0;
  cursor: pointer; }

.calendar-block button {
  background: transparent !important;
  color: #000000  !important;
  box-shadow: none !important;
  width: auto; }

@media screen and (max-width: 1030px) {
  .search-hold.calendar-block,
  .my_hold_page .myhold-header .filter-tabs {
    width: 100% !important; } }

.searchBox.blocks {
  position: relative; }
  .searchBox.blocks label {
    position: absolute;
    top: 15px;
    right: 2px;
    cursor: pointer; }
  .searchBox.blocks .calendar-icon {
    color: rgba(0, 0, 0, 0.54);
    font-size: 18px; }
  .searchBox.blocks .search-image button {
    margin: 0;
    padding: 0;
    min-width: 30px !important; }

.searchBox.blocks {
  width: 18% !important; }

.searchBox.new {
  width: 44% !important; }

.searchBox.btn {
  width: 6% !important;
  min-width: auto !important; }

.searchBox.btn button {
  min-width: auto; }

@media screen and (max-width: 1380px) {
  .searchBox.blocks {
    width: 18% !important; } }

@media screen and (max-width: 1030px) {
  .searchBox.blocks {
    width: 15% !important; } }

@media screen and (max-width: 768px) {
  .searchBox.blocks {
    width: 20% !important; }
  .searchBox.new {
    width: 34% !important; } }

.react-datepicker__input-container {
  border: 1px solid #e1e1e1;
  border-radius: 4px; }

input#openCalndar,
.searchBox input {
  text-transform: uppercase;
  color: #36434b;
  font-weight: bold; }

.searchBox.new {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  margin-top: 10px;
  padding: 0 !important; }

.searchBox {
  margin-right: 15px; }

.searchBox.btn {
  margin: 0 !important; }

.termsPage {
  width: 100% !important; }
  @media screen and (min-width: 1920px) {
    .termsPage {
      width: 88% !important;
      margin-left: auto;
      margin-right: auto; } }
  .termsPage .myhold-header {
    display: block;
    width: 95%;
    text-align: center;
    margin: auto; }
    .termsPage .myhold-header .filter-tabs {
      width: 100% !important;
      float: left; }
      @media screen and (max-width: 767px) {
        .termsPage .myhold-header .filter-tabs {
          width: 100% !important; } }
      .termsPage .myhold-header .filter-tabs Button {
        max-width: 100%; }
        .termsPage .myhold-header .filter-tabs Button span {
          font-size: 16px !important; }

.para-content {
  width: 100% !important;
  display: block;
  padding: 15px 35px; }

.terms h3 {
  font-weight: bold;
  margin: 1rem 0px; }

.terms p {
  margin: 1rem 0px; }

.terms ul {
  list-style-type: disc;
  margin-left: 58px;
  margin-bottom: 1rem; }
  @media screen and (max-width: 767px) {
    .terms ul {
      margin-left: 0px; } }
  .terms ul li {
    padding-left: 20px; }

.terms .filter-tabs {
  width: 100% !important; }
  @media screen and (max-width: 767px) {
    .terms .filter-tabs Button {
      align-items: flex-start;
      text-align: left;
      justify-content: left; } }
  @media screen and (max-width: 767px) {
    .terms .filter-tabs Button span {
      text-align: center; } }

@media screen and (max-width: 767px) {
  .box-tab div :nth-child(2) {
    width: 100%; } }

@media screen and (max-width: 767px) {
  .tabs :nth-child(2) Button {
    display: block;
    min-width: 35px !important;
    width: 35px !important; } }

@media screen and (max-width: 767px) {
  .tabs :nth-child(2) div Button {
    width: 100%;
    min-width: 270px !important; } }

.edit_profile {
  padding-top: 65px;
  height: 100%; }
  .edit_profile .save_button {
    padding: 0px 32px; }
    @media screen and (max-width: 767px) {
      .edit_profile .save_button {
        justify-content: center;
        margin: 20px auto 0px; } }
    @media screen and (max-width: 767px) {
      .edit_profile .save_button div {
        justify-content: center; } }
  .edit_profile .personal_info {
    padding-bottom: 50px;
    padding: 11px 30px 20px 20px;
    padding-bottom: 60px; }
    @media screen and (max-width: 767px) {
      .edit_profile .personal_info {
        padding-bottom: 30px; } }
    .edit_profile .personal_info h4 {
      padding-bottom: 11px; }
  .edit_profile .company_info {
    padding-bottom: 50px;
    padding: 11px 20px 20px 20px;
    padding-bottom: 60px; }
    @media screen and (max-width: 767px) {
      .edit_profile .company_info {
        padding-bottom: 30px; } }
  .edit_profile .change_password {
    padding-bottom: 50px;
    padding: 11px 20px 20px 20px;
    padding-bottom: 60px; }
    @media screen and (max-width: 767px) {
      .edit_profile .change_password {
        padding-bottom: 30px; } }
  .edit_profile .basic_info {
    padding-bottom: 50px;
    padding: 11px 20px 20px 20px;
    padding-bottom: 60px; }
    @media screen and (max-width: 767px) {
      .edit_profile .basic_info {
        padding-bottom: 30px; } }
  .edit_profile .delete-image {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize;
    padding-left: 6px; }
  .edit_profile .delete-image:hover {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer; }
  .edit_profile .form_name {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    color: #1397e1; }
  .edit_profile .edit_profile_component {
    padding-bottom: 62px;
    background-color: #ffffff;
    box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb;
    padding: 11px 20px 20px 20px;
    padding-bottom: 60px; }
    @media screen and (max-width: 767px) {
      .edit_profile .edit_profile_component {
        padding-bottom: 25px; } }
    .edit_profile .edit_profile_component .headings h4 {
      padding-bottom: 11px; }
    .edit_profile .edit_profile_component .upload-block .error {
      text-align: center;
      width: auto; }
    .edit_profile .edit_profile_component .upload-block .crop-block {
      display: block;
      padding: 20px 0px; }
      @media screen and (max-width: 767px) {
        .edit_profile .edit_profile_component .upload-block .crop-block button {
          width: 47% !important; } }
    .edit_profile .edit_profile_component .upload-block .wid-50 {
      width: 50%;
      padding: 10px;
      display: inline-block; }
      @media screen and (max-width: 767px) {
        .edit_profile .edit_profile_component .upload-block .wid-50 {
          width: 100% !important; } }
      .edit_profile .edit_profile_component .upload-block .wid-50 input {
        position: relative !important; }
    .edit_profile .edit_profile_component .help_icon {
      padding-top: 44px;
      cursor: pointer; }
    @media screen and (max-width: 500px) {
      .edit_profile .edit_profile_component .edit_profile_component_form {
        padding-left: 20px;
        padding-right: 20px; } }
    @media screen and (max-width: 767px) {
      .edit_profile .edit_profile_component .edit_profile_component_form .field_div .checkbox .container {
        margin-bottom: 0px; } }
    .edit_profile .edit_profile_component .edit_profile_component_form .field_div .verified {
      display: flex;
      justify-content: right;
      color: #007E33; }
      .edit_profile .edit_profile_component .edit_profile_component_form .field_div .verified p {
        margin-top: -2px; }
      .edit_profile .edit_profile_component .edit_profile_component_form .field_div .verified .material-icons {
        color: #007E33;
        font-size: 18px; }
    .edit_profile .edit_profile_component .edit_profile_component_form .field_div .verify-it {
      color: #FF0000;
      display: flex;
      justify-content: right; }
      .edit_profile .edit_profile_component .edit_profile_component_form .field_div .verify-it .material-icons {
        color: #FF0000;
        font-size: 18px; }
      .edit_profile .edit_profile_component .edit_profile_component_form .field_div .verify-it p {
        margin-top: -2px; }
      .edit_profile .edit_profile_component .edit_profile_component_form .field_div .verify-it a {
        color: #FF0000;
        text-decoration: underline; }
        .edit_profile .edit_profile_component .edit_profile_component_form .field_div .verify-it a:hover {
          color: #FF0000; }
    .edit_profile .edit_profile_component .form_name {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #1397e1;
      text-transform: capitalize; }
    @media screen and (min-width: 1025px) {
      .edit_profile .edit_profile_component .left_panel {
        padding-right: 55px; } }
    @media screen and (min-width: 960px) and (max-width: 1025px) {
      .edit_profile .edit_profile_component .left_panel {
        padding-right: 20px; } }
    .edit_profile .edit_profile_component .delete-image {
      text-align: center;
      color: #1397e1;
      text-transform: capitalize; }
    @media screen and (min-width: 1025px) {
      .edit_profile .edit_profile_component .right_panel {
        padding-left: 57px; } }
    @media screen and (min-width: 960px) and (max-width: 1025px) {
      .edit_profile .edit_profile_component .right_panel {
        padding-left: 20px; } }
    .edit_profile .edit_profile_component .password_field_div {
      padding-top: 20px;
      position: relative; }
      .edit_profile .edit_profile_component .password_field_div .eye_icon, .edit_profile .edit_profile_component .password_field_div .eye_icon_disable {
        top: 56px; }
    .edit_profile .edit_profile_component .field_div {
      padding-top: 20px; }
      @media screen and (max-width: 767px) {
        .edit_profile .edit_profile_component .field_div {
          padding-top: 10px; } }
    .edit_profile .edit_profile_component .edit_profile_component {
      padding-bottom: 62px; }
  .edit_profile .error {
    color: #FF0000;
    font-size: 11px;
    text-align: left;
    height: 10px; }
    @media screen and (max-width: 767px) {
      .edit_profile .error {
        height: auto; } }

.edit_profile_component .headings p {
  text-align: center;
  padding-bottom: 15px;
  font-size: 14px;
  color: #ee2738; }

.secondary-form .emailField {
  width: 330px; }

.secondary-form .red_icon_div {
  display: inline-block;
  padding: 2px 0px 2px 10px;
  color: #e20d0d;
  cursor: pointer; }

.secondary-form .material-icons.delete {
  background: transparent; }

.secondary-form .delete::before,
.secondary-form .delete::after {
  background: transparent; }

.secondary-form .row-container-box {
  display: flex;
  flex-wrap: wrap; }

.secondary-form .div-alignment-box {
  flex: 1 1 50%;
  padding: 8px 0;
  box-sizing: border-box; }

.secondary-form input.emailField {
  border-radius: 6px;
  border: solid 1px #e1e1e1 !important;
  background-color: #f7f7f7 !important;
  padding: 9px 15px;
  margin-top: 4px;
  font-size: 15px;
  line-height: 1.07;
  text-align: left;
  color: #202020; }

@media screen and (max-width: 830px) {
  .secondary-form .div-alignment-box {
    flex: 1 1 100%; }
  .secondary-form .emailField {
    width: 85%; } }

.small-text-box {
  color: #ff0000;
  font-size: 10px;
  text-align: left; }

.edit_profile_component .emailField {
  padding-left: 30px; }

.edit_profile_component .search_container > div:first-child:before {
  top: 30px;
  left: 5px;
  color: #878787;
  content: 'search' !important; }

.edit_profile_component .with-lable-search > div:first-child:before {
  top: 55px; }

@media screen and (max-width: 767px) {
  .edit_profile_component .search_container > div:first-child:before {
    top: 20px; }
  .edit_profile_component .with-lable-search > div:first-child:before {
    top: 45px; } }

.visiblity-hidden {
  visibility: hidden; }

.my_favourites {
  padding-top: 65px;
  height: 100vh; }
  .my_favourites .venueCardWithFav .venue_image {
    width: 100%;
    transition: 200ms ease-in-out; }
  .my_favourites .save_button {
    padding: 0px 32px; }
  .my_favourites .personal_info {
    padding-bottom: 50px; }
  .my_favourites .company_info {
    padding-bottom: 50px; }
  .my_favourites .change_password {
    padding-bottom: 50px; }
  .my_favourites .delete-image {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize; }
  .my_favourites .delete-image:hover {
    text-align: center;
    color: #1397e1;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer; }
  .my_favourites .headings {
    border-bottom: solid 1px #e1e1e1;
    text-align: center; }
    .my_favourites .headings .sub-heading {
      font-weight: 500;
      margin: -18px 0 8px 0px;
      font-size: 16px; }
  .my_favourites .form_name {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    margin: 20px auto;
    color: #1397e1; }
  .my_favourites .edit_profile_component {
    padding-bottom: 62px;
    background-color: #ffffff;
    padding: 0px 0px 20px 0px;
    margin: 15px 30px 15px 15px;
    box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb;
    width: auto !important; }
    .my_favourites .edit_profile_component .help_icon {
      padding-top: 44px;
      cursor: pointer; }
    @media screen and (max-width: 500px) {
      .my_favourites .edit_profile_component .edit_profile_component_form {
        padding-left: 20px;
        padding-right: 20px; } }
    .my_favourites .edit_profile_component .form_name {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #1397e1;
      text-transform: capitalize;
      margin: 20px auto; }
    @media screen and (min-width: 1025px) {
      .my_favourites .edit_profile_component .left_panel {
        padding-right: 55px; } }
    @media screen and (min-width: 960px) and (max-width: 1025px) {
      .my_favourites .edit_profile_component .left_panel {
        padding-right: 20px; } }
    @media screen and (min-width: 1025px) {
      .my_favourites .edit_profile_component .right_panel {
        padding-left: 57px; } }
    @media screen and (min-width: 960px) and (max-width: 1025px) {
      .my_favourites .edit_profile_component .right_panel {
        padding-left: 20px; } }
    .my_favourites .edit_profile_component .password_field_div {
      padding-top: 20px;
      position: relative; }
    .my_favourites .edit_profile_component .field_div {
      padding-top: 20px; }
    .my_favourites .edit_profile_component .edit_profile_component {
      padding-bottom: 62px; }
    .my_favourites .edit_profile_component .similar_venue_card {
      padding: 0 10px 10px 10px; }
      .my_favourites .edit_profile_component .similar_venue_card .detail-box {
        margin: -54px 0 12px 12px;
        color: #ffffff; }
        .my_favourites .edit_profile_component .similar_venue_card .detail-box .space-name {
          font-weight: 500;
          font-size: 18px; }
        .my_favourites .edit_profile_component .similar_venue_card .detail-box .venue-name {
          font-size: 11px;
          padding-left: 4px; }
  .my_favourites .error {
    color: #FF0000;
    font-size: 11px;
    text-align: left;
    height: 10px; }

@media screen and (max-width: 767px) {
  .widgetImg img {
    max-width: 60px !important; } }

@media screen and (max-width: 767px) {
  .widgetMobile .calendar {
    margin: 30px 25px 10px !important; } }

::-webkit-scrollbar {
  width: 5px !important; }

.MuiDialog-paperWidthSm-226 {
  max-width: 100% !important;
  width: 100% !important;
  max-height: 100% !important; }

.MuiPaper-elevation24-146 {
  box-shadow: none !important; }

.MuiBackdrop-root-308 {
  background-color: rgba(0, 0, 0, 0.7) !important; }

.MuiPaper-root-120 {
  background: none !important; }

.react-datepicker-popper {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px; }

.datepiker {
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #62717a; }

.dialogActions span {
  cursor: pointer !important; }

.closeDialog {
  width: 22px;
  height: 22px;
  font-family: material;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff; }

.dialog-content {
  height: 100%;
  width: 60%;
  margin: auto; }

.modalBox_content {
  height: 492px; }

.main_place_hold_section {
  width: 600px;
  border-radius: 6px;
  background-color: #f0f0f0;
  border: 2px solid white !important;
  height: 100%; }
  .main_place_hold_section .error {
    display: inline-block;
    width: auto; }

.header {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  margin-top: 0;
  padding: 15px;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
  border-image-slice: 1;
  border-top: 0;
  border-left: 0;
  border-right: 0; }

.success_modal_container .dialog-content {
  height: 100% !important;
  width: 100% !important;
  margin: auto !important; }

.success_modal_container {
  width: 100% !important;
  min-height: auto !important;
  margin-top: 30px;
  /* create new event form */
  /* Events */ }
  .success_modal_container .react-datepicker__navigation {
    padding: 0 !important; }
  .success_modal_container .MuiDialog-paperWidthSm-226 {
    max-width: 100% !important;
    width: 100% !important;
    max-height: 100% !important; }
  .success_modal_container .MuiPaper-elevation24-146 {
    box-shadow: none !important; }
  .success_modal_container .MuiBackdrop-root-308 {
    background-color: rgba(0, 0, 0, 0.7) !important; }
  .success_modal_container .MuiPaper-root-120 {
    background: none !important;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    margin: 0px; }
  .success_modal_container .MuiDialog-paper-222 {
    margin: 0 !important;
    position: absolute;
    top: 0; }
  .success_modal_container .dialogActions {
    height: 40px;
    font-family: material;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0px; }
  .success_modal_container .closeButton {
    cursor: pointer;
    position: absolute;
    font-size: 30px;
    top: -70px;
    right: -38px;
    color: #ffffff;
    font-weight: bold; }
  .success_modal_container .modalBox_content {
    height: auto;
    background-color: #ffffff; }
  .success_modal_container .main_place_hold_section {
    width: 600px;
    border-radius: 6px;
    background-color: #f0f0f0;
    border: 2px solid white !important;
    height: 100%; }
    @media screen and (max-width: 767px) {
      .success_modal_container .main_place_hold_section .end-date-sm {
        padding-top: 20px; } }
  .success_modal_container .follow-inner-popup {
    display: block;
    padding: 15px;
    max-width: 450px;
    font-size: 15px;
    line-height: 23px; }
    .success_modal_container .follow-inner-popup button {
      margin-top: 15px;
      width: auto !important;
      padding: 5px 15px; }
  .success_modal_container .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .success_modal_container .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px; }
  .success_modal_container .header-button-div {
    float: right; }
  .success_modal_container .uploader {
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
    background-image: url(https://www.first-hold.com/static/media/perm_contact_calendar.dd9786b4.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-color: #f0f0f0; }
  .success_modal_container .success_modal_container {
    min-height: 100px;
    overflow-y: auto !important; }
  .success_modal_container .uploader-content {
    width: 300px;
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto; }
  .success_modal_container .red-text-content {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ee2738;
    margin: 140px 0 5px 0; }
  .success_modal_container .black-text-content {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
    margin-top: 5px; }
  .success_modal_container .white-button-big {
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    float: left;
    cursor: pointer; }
    .success_modal_container .white-button-big span {
      width: 100%; }
  .success_modal_container .select_field {
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #202020 !important;
    text-transform: none !important;
    width: 100%;
    height: 36px;
    margin: 0 !important;
    padding-left: 10px; }
  .success_modal_container .form .form-content {
    padding: 20px;
    display: inline-block; }
  .success_modal_container .form .header {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-image-source: linear-gradient(to left, #fce5e8, #ee2738) !important;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .success_modal_container .form .header-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #36434b;
    float: left;
    margin-top: 5px; }
  .success_modal_container .form .header-button-div-form {
    float: right;
    width: 210px; }
  .success_modal_container .form .dialogActions {
    height: 120px;
    font-family: material;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    padding: 20px;
    margin: 0;
    display: block; }
    .success_modal_container .form .dialogActions span {
      cursor: pointer; }
  .success_modal_container .form .field-div {
    width: 100%;
    height: 80px;
    float: left; }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .field-div {
        float: none !important; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .spacer.disp-none-sm {
      display: none; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-start {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req-up {
      margin-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-end {
      margin-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req-down {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req-seating, .success_modal_container .form .sm-pd-venue {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .success_modal_container .form .sm-pd-req {
      padding-top: 5px; } }
  .success_modal_container .form .disp-none-big {
    display: none; }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .disp-none-big {
        display: block; } }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .disp-none-big .sm-pd-seating {
        padding-top: 20px !important; } }
  .success_modal_container .form .input-label {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    position: relative;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    width: 100%;
    float: left;
    padding-bottom: 5px; }
  .success_modal_container .form .input-field {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: transparent;
    float: left;
    margin: 0 !important;
    font-size: 16px;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    padding-left: 10px; }
    @media screen and (max-width: 767px) {
      .success_modal_container .form .input-field {
        padding-left: 15px;
        font-size: 15px !important; } }
  .success_modal_container .form .input[role="combobox"] + div {
    z-index: 99;
    top: 35px !important;
    left: 0px !important;
    position: absolute !important;
    min-height: 95px !important;
    background: #fff;
    overflow-y: scroll;
    height: 150px;
    padding-left: 15px;
    box-shadow: 0px 15px 10px -15px #ccc;
    border: 1px solid #e1e1e1;
    font-weight: 500;
    cursor: pointer; }
  .success_modal_container .form .city-sm .suggestionDiv {
    padding-left: 15px; }
  .success_modal_container .form .input-field-section {
    width: 100%;
    float: left;
    color: #202020;
    position: relative; }
  .success_modal_container .form .big {
    width: 100% !important; }
  .success_modal_container .form .dark-black {
    color: #36434b !important; }
  .success_modal_container .form .light-black {
    color: #202020 !important; }
  .success_modal_container .form .lighter-black {
    color: #62717a !important; }
  .success_modal_container .form .small {
    width: 46% !important; }
    .success_modal_container .form .small select {
      font-size: 15px !important; }
    .success_modal_container .form .small input {
      font-size: 15px; }
  .success_modal_container .form .smaller {
    width: 40% !important; }
    .success_modal_container .form .smaller select {
      font-size: 12px !important;
      background-color: #ffffff !important;
      text-transform: uppercase !important; }
      @media screen and (max-width: 767px) {
        .success_modal_container .form .smaller select {
          text-transform: capitalize !important;
          font-size: 15px !important;
          border: 1px solid #e1e1e1 !important; } }
    .success_modal_container .form .smaller input {
      font-size: 12px;
      color: #1397e1 !important;
      text-align: left;
      height: 36px;
      border-radius: 6px;
      border: solid 1px #e1e1e1;
      background-color: #ffffff; }
      @media screen and (max-width: 767px) {
        .success_modal_container .form .smaller input {
          font-size: 15px;
          font-weight: normal;
          color: #202020;
          text-transform: capitalize;
          border: solid 1px #e1e1e1 !important; } }
  .success_modal_container .form .tall {
    height: 140px; }
  .success_modal_container .form .spacer {
    width: 20%;
    padding: 25px 0 0 0;
    overflow: hidden;
    text-align: center; }
  .success_modal_container .form select.select-field {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #e1e1e1;
    background-color: #f7f7f7;
    text-transform: none;
    padding: 5px;
    -webkit-appearance: menulist;
    text-align: left;
    padding-left: 5px;
    width: 100%;
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal; }
  .success_modal_container .form .float-right {
    float: right; }
  .success_modal_container .form .white-button-small {
    width: 100px !important;
    height: 30px;
    border-radius: 6px;
    border: solid 1px #e1e1e1 !important;
    background-color: #ffffff;
    padding: 0 !important;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738 !important;
    float: left;
    cursor: pointer; }
    .success_modal_container .form .white-button-small span {
      width: 100%; }
  .success_modal_container .form .red-button-small {
    width: 100px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    float: right;
    cursor: pointer; }
  @media screen and (max-device-width: 767px) {
    .success_modal_container .form .smaller {
      width: 100% !important; }
    .success_modal_container .form .small {
      width: 100%; }
    .success_modal_container .form .modalBox_content {
      height: 100%;
      display: flex; }
    .success_modal_container .form .newEventPopupContainer {
      width: 100% !important; }
    .success_modal_container .form .small {
      width: 100% !important; }
    .success_modal_container .form .header {
      height: 60px; }
    .success_modal_container .form .header-text {
      width: 100%;
      text-align: center; }
    .success_modal_container .form .header-button-div-form {
      width: 100%;
      margin: 10px auto;
      padding: 0 0px; }
    .success_modal_container .form .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid; }
    .success_modal_container .form .red-text-content {
      margin-top: 100px; }
    .success_modal_container .form .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important; } }
  .success_modal_container .eventDiv {
    width: 98%;
    height: 80px;
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    margin: 10px auto 15px !important;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between; }
    .success_modal_container .eventDiv .checkBoxDiv {
      display: flex;
      width: auto;
      align-items: center; }
      .success_modal_container .eventDiv .checkBoxDiv label .radio {
        margin: 0px 0px 50px 0px !important; }
        .success_modal_container .eventDiv .checkBoxDiv label .radio :hover {
          color: #42a5f5; }
    .success_modal_container .eventDiv .blue-text {
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #1397e1;
      margin: 0; }
    .success_modal_container .eventDiv .grey-date {
      color: #62717a; }
  .success_modal_container .events-content {
    height: 300px;
    font-family: material;
    font-size: 300px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eae9e9;
    margin: 30px auto;
    overflow-y: scroll; }
  .success_modal_container .checkBoxDiv {
    width: 5%;
    float: left;
    display: flex;
    height: 100%;
    align-items: center; }
  .success_modal_container .detailsDiv {
    width: 95%;
    float: left;
    display: flex;
    height: 100%;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .success_modal_container .detailsDiv {
        align-items: center; } }
    .success_modal_container .detailsDiv .actionDiv {
      width: 22%;
      float: left;
      display: flex;
      height: 100%;
      align-items: center; }
      .success_modal_container .detailsDiv .actionDiv .hand-icon {
        width: 9.5px;
        height: 19.7px;
        background-color: #62717a;
        margin-right: 10px; }
      .success_modal_container .detailsDiv .actionDiv .grey-text {
        font-family: Raleway;
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        color: #62717a;
        margin: 0;
        min-width: 63px; }
  .success_modal_container .checkBox {
    width: 20px;
    height: 20px;
    background-color: #9aa7af !important; }
  .success_modal_container .large-text {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #62717a;
    width: auto;
    word-break: break-word;
    cursor: pointer; }
  .success_modal_container .edit-icon {
    display: inline-flex;
    cursor: pointer; }
  .success_modal_container .eventsSelected {
    width: 100%;
    height: 380px;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 30px; }
    .success_modal_container .eventsSelected .footerButtonDiv {
      width: 45% !important;
      margin: 20px auto; }
    .success_modal_container .eventsSelected .grey-button:disabled {
      height: 36px;
      border-radius: 6px;
      background-color: #9aa7af !important;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: none; }
    .success_modal_container .eventsSelected .grey-button:enabled {
      height: 36px;
      border-radius: 6px;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase !important;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(to bottom, #f1435e, #ee2738); }
    .success_modal_container .eventsSelected .image {
      width: 38px;
      height: 38px;
      object-fit: contain;
      display: inline-block !important; }
    .success_modal_container .eventsSelected .large-text {
      font-family: Raleway;
      font-size: 24px !important;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      margin: 10px auto;
      cursor: pointer; }
    .success_modal_container .eventsSelected .description {
      font-family: Raleway;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #202020;
      margin: auto; }
    .success_modal_container .eventsSelected .blue-text {
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1397e1;
      margin: 15px auto 0 auto; }
    .success_modal_container .eventsSelected .grey-text {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #9aa7af;
      margin: 20px auto 10px auto;
      text-transform: uppercase; }
      @media screen and (max-width: 767px) {
        .success_modal_container .eventsSelected .grey-text {
          min-width: 60px; } }
    .success_modal_container .eventsSelected .optionsDiv {
      width: 90%;
      margin: auto;
      display: block;
      overflow: auto; }
      .success_modal_container .eventsSelected .optionsDiv .option {
        width: 33%;
        float: left;
        text-align: center; }
        .success_modal_container .eventsSelected .optionsDiv .option label {
          margin-left: auto !important;
          margin-right: auto !important; }
      .success_modal_container .eventsSelected .optionsDiv .option-text {
        width: 60%;
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #62717a;
        margin: auto; }
      .success_modal_container .eventsSelected .optionsDiv .grey {
        color: #62717a !important; }
      .success_modal_container .eventsSelected .optionsDiv .blue {
        color: #1f9ce3 !important; }
      .success_modal_container .eventsSelected .optionsDiv .blue-circle {
        width: 20px;
        height: 20px;
        border: solid 2px #1397e1;
        background-color: #ffffff;
        margin-top: 30px; }
  .success_modal_container .dialogFooter {
    height: 50px;
    border-radius: 6px;
    box-shadow: inset 0 1px 0 0 var(--white);
    background-color: #f7f7f7;
    display: flex;
    align-items: center; }
    .success_modal_container .dialogFooter .buttonDiv {
      margin: auto; }
  .success_modal_container .red-button {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, #f1435e, #ee2738);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding-top: 6px;
    color: #ffffff;
    float: right;
    cursor: pointer; }
  @media screen and (max-width: 767px) {
    .success_modal_container .dialog-content {
      height: 100%;
      width: 90% !important;
      margin: auto; }
    .success_modal_container .modalBox_content {
      height: 100%; }
    .success_modal_container .header {
      height: 60px;
      margin: auto; }
    .success_modal_container .header-text {
      width: 100%;
      text-align: center; }
    .success_modal_container .header-button-div {
      width: 80%;
      text-align: center;
      margin: 10px 0; }
    .success_modal_container .uploader-content {
      margin-top: 20px;
      height: 100%;
      min-height: 250px;
      display: grid; }
    .success_modal_container .red-text-content {
      margin-top: 100px; }
    .success_modal_container .MuiDialog-paperWidthSm-226 {
      max-width: 100% !important;
      width: 100% !important; }
    .success_modal_container .eventDiv {
      margin: 0;
      padding: 10px;
      height: 100px; }
    .success_modal_container .checkBoxDiv {
      width: 10% !important; }
    .success_modal_container .detailsDiv {
      width: 90% !important;
      position: relative; }
    .success_modal_container .large-text {
      width: 100% !important;
      cursor: pointer; }
    .success_modal_container .actionDiv {
      width: 100% !important;
      height: 70% !important;
      justify-content: flex-end; }
    .success_modal_container .eventsSelected {
      height: auto;
      padding: 10px; }
    .success_modal_container .optionsDiv {
      margin: 0 !important;
      width: 100% !important; }
    .success_modal_container .option {
      width: 80% !important;
      float: left;
      align-items: center;
      display: flex;
      margin: auto; }
      .success_modal_container .option .option-text {
        float: left;
        text-align: left !important; }
      .success_modal_container .option input {
        float: left;
        margin: 0 !important; }
    .success_modal_container .footerButtonDiv {
      width: 130px !important;
      margin: 20px auto; } }

.MuiDialog-paper-235 {
  margin: 20px !important; }

.event-loading .load-box {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 700;
  padding-top: 50px;
  padding-bottom: 50px; }

input#city {
  border-radius: 6px;
  border: solid 1px #e1e1e1 !important;
  background-color: #f7f7f7;
  width: 100%;
  padding: 9px 15px;
  font-size: 15px;
  line-height: 1.07;
  text-align: left;
  color: #202020; }

.suggestionDiv {
  width: 100% !important;
  position: relative;
  font-size: 12px;
  line-height: 2.3;
  word-break: break-all; }

.success_modal_container div:nth-child(2) div:first-child {
  max-width: 600px !important; }

.detailsDiv .actionDiv .delete::before, .detailsDiv .actionDiv .delete::after {
  display: none; }

.detailsDiv .actionDiv .delete {
  background-color: transparent;
  margin-top: -5px; }

.Contact-Venue-popup {
  padding-bottom: 20px; }
  .Contact-Venue-popup .header {
    border: 0px;
    text-align: center;
    display: block;
    height: auto;
    padding-bottom: 0px; }
    .Contact-Venue-popup .header .header-text {
      text-align: center;
      float: none;
      color: #1397e1; }
      @media screen and (max-width: 767px) {
        .Contact-Venue-popup .header .header-text {
          font-size: 15px !important; } }
  .Contact-Venue-popup button span {
    color: #ffffff; }
  .Contact-Venue-popup .message-section {
    padding: 10px 15px;
    line-height: 1.7; }
    @media screen and (max-width: 767px) {
      .Contact-Venue-popup .message-section {
        font-size: 13px !important; } }
  .Contact-Venue-popup .contact-box {
    padding: 16px 12% 4px 12%; }
    .Contact-Venue-popup .contact-box .dont_p {
      width: 100%;
      text-align: center !important; }

@media screen and (max-width: 767px) {
  .place-hold-popup div:nth-child(2) div:first-child {
    max-width: 280px !important; } }

.place-hold-popup .modalBox_content .add-new-form-block .custom_select_with_scroll > div {
  padding: 0px 5px 0px 15px; }

@media screen and (max-width: 767px) {
  .place-hold-popup .modalBox_content .add-new-form-block {
    padding: 10px 20px 20px;
    height: 312px; } }

@media screen and (max-width: 767px) {
  .place-hold-popup .modalBox_content .add-new-form-block .disp-none-small {
    display: none; } }

@media screen and (max-width: 767px) {
  .place-hold-popup .modalBox_content .add-new-form-block .field-div {
    height: auto; } }

.place-hold-popup .modalBox_content .add-new-form-block .smaller input {
  padding: 0px 14px; }

.place-hold-popup .modalBox_content .add-new-form-block .smaller svg {
  right: -7px; }
  @media screen and (max-width: 767px) {
    .place-hold-popup .modalBox_content .add-new-form-block .smaller svg {
      right: 0px; } }

.place-hold-popup .error {
  display: block;
  position: absolute;
  bottom: -19px; }

.place-hold-popup .add-new-form-block {
  height: 440px; }
  @media screen and (min-width: 1600px) {
    .place-hold-popup .add-new-form-block {
      height: 560px; } }
  @media screen and (max-width: 767px) {
    .place-hold-popup .add-new-form-block {
      height: 326px; } }

.place-hold-popup .grey-text {
  text-align: center;
  cursor: pointer; }

.place-hold-popup .select-down-icon::before {
  content: 'arrow_drop_down';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 0.8;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 8px;
  right: 5px;
  color: #62717a; }

.place-hold-popup .select-down-icon {
  position: relative; }

.place-hold-popup .form .smaller select {
  background-color: transparent !important; }

.place-hold-popup .events-content {
  height: 292px; }

@media screen and (max-width: 767px) {
  .place-hold-popup #eventsContent {
    margin: 18px auto 0px !important; } }

@media screen and (max-width: 767px) {
  .place-hold-popup .header-button-div {
    margin: 10px 0 0px;
    width: 85%; } }

.add-new-form-block {
  overflow-y: scroll;
  height: 422px; }

@media screen and (max-width: 767px) {
  .follow-date-popup div:nth-child(2) div:first-child {
    max-width: 280px !important; } }

@media screen and (max-width: 767px) {
  .follow-date-popup .detailsDiv {
    width: 100% !important; } }

.follow-date-popup #eventsContent {
  padding-left: 10px;
  padding-right: 6px; }
  @media screen and (max-width: 767px) {
    .follow-date-popup #eventsContent {
      margin: 5px auto 0px !important; } }
  @media screen and (max-width: 767px) {
    .follow-date-popup #eventsContent .-text {
      font-size: 15px; } }
  @media screen and (max-width: 767px) {
    .follow-date-popup #eventsContent button {
      width: 90px !important; } }

.follow-date-popup .events-content {
  margin: 5px auto 10px;
  height: 290px; }

::-webkit-input-placeholder {
  /* Edge */
  color: #62717a !important; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #62717a !important; }

.follow-up-signin > div {
  width: 100%; }

.follow-date-popup div:nth-child(2) div:first-child {
  width: 100%; }

.city-section input[role="combobox"] + div {
  z-index: 99;
  top: 35px !important;
  left: 0px !important;
  position: absolute !important;
  min-height: 95px !important;
  background: #fff;
  overflow-y: scroll;
  height: 160px;
  box-shadow: 0px 15px 10px -15px #ccc;
  border: 1px solid #e1e1e1;
  font-weight: 500;
  cursor: pointer; }

.city-section .suggestionDiv {
  width: 100% !important;
  padding-left: 15px; }

.what-we-love-block {
  padding-right: 25px;
  margin-top: 25px; }
  @media screen and (max-width: 767px) {
    .what-we-love-block {
      margin: 15px auto 10px; } }
  .what-we-love-block .what-we-love-listing {
    display: block;
    margin-left: 45px;
    width: 100%;
    margin-bottom: 25px; }
    .what-we-love-block .what-we-love-listing ul {
      display: block;
      width: 50%;
      float: left;
      list-style: none;
      padding-left: 20px; }
      @media screen and (max-width: 767px) {
        .what-we-love-block .what-we-love-listing ul {
          width: 100%; } }
      .what-we-love-block .what-we-love-listing ul li {
        position: relative;
        padding-top: 0px !important;
        padding-bottom: 0px !important; }
      .what-we-love-block .what-we-love-listing ul li:before {
        content: "\2022";
        color: #3C97E1;
        font-weight: bold;
        position: absolute;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 25px;
        top: 0;
        margin-top: -8px; }

.placehold-event .detailsDiv {
  width: 20% !important; }
  @media screen and (max-width: 767px) {
    .placehold-event .detailsDiv {
      width: 40% !important; } }
  .placehold-event .detailsDiv .actionDiv {
    width: 100%; }
    @media screen and (max-width: 767px) {
      .placehold-event .detailsDiv .actionDiv .grey-text {
        min-width: 48px; } }

.placehold-event .detailsDivLeft {
  display: flex;
  width: auto;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .placehold-event .detailsDivLeft .edit-icon {
      padding-left: 5px; } }

.placehold-event .checkBoxDiv {
  display: flex;
  width: auto;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .placehold-event .checkBoxDiv {
      width: 50% !important; } }
  .placehold-event .checkBoxDiv .radioTextLeft {
    width: 100%;
    display: flex;
    align-items: center; }
    .placehold-event .checkBoxDiv .radioTextLeft div {
      width: 10%;
      display: inline-block; }
      .placehold-event .checkBoxDiv .radioTextLeft div label .radio {
        margin: 0px 0px 0px 0px !important; }
    .placehold-event .checkBoxDiv .radioTextLeft .radioText {
      width: 90%;
      display: inline-block;
      padding-left: 10px; }
      @media screen and (max-width: 767px) {
        .placehold-event .checkBoxDiv .radioTextLeft .radioText p {
          font-size: 13px; } }
    .placehold-event .checkBoxDiv .radioTextLeft .large-text {
      display: flex; }
    .placehold-event .checkBoxDiv .radioTextLeft .grey-date {
      display: flex; }
    .placehold-event .checkBoxDiv .radioTextLeft label .radio {
      margin: 0px 0px 50px 0px !important; }
      .placehold-event .checkBoxDiv .radioTextLeft label .radio :hover {
        color: #42a5f5; }

.venue-link {
  color: #f12331;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal; }

.link-container {
  width: 168px;
  position: absolute;
  left: 47%;
  margin-left: -50px; }

.add-new-event-popup .modalBox_content {
  padding: 0; }

.add-new-event-popup .form-content.add-new-form-block {
  height: 380px !important;
  overflow-y: scroll !important; }

.add-new-event-popup .smaller input {
  font-size: 12px !important;
  padding: 6px !important; }

.add-new-event-popup .input-label {
  font-size: 11px !important; }

.add-new-event-popup .custom_select_with_scroll > div {
  padding: 0px 5px 0px 5px !important; }

@media screen and (max-width: 767px) {
  .add-new-event-popup .red-button-small,
  .add-new-event-popup .white-button-small {
    width: 66px !important; }
  .add-new-event-popup .add-new-form-block {
    height: 250px !important; }
  .add-new-event-popup .place-hold-popup div:nth-child(2) div:first-child {
    max-width: 500px !important; } }

@media screen and (max-width: 480px) {
  .add-new-event-popup .place-hold-popup div:nth-child(2) div:first-child {
    max-width: 250px !important; } }

.eventsSelected .optionsDiv .option-text {
  width: 90% !important; }

.eventsSelected p.powerby {
  font-size: 12px;
  width: 100%; }

.datepiker.color-blue {
  color: #1397e1 !important; }

.eventsSelected .optionsDiv label {
  margin: 0 !important; }

.eventsSelected .footerButtonDiv .grey-button:disabled {
  margin-top: 30px; }

.eventsSelected .mt-0 {
  margin-top: 0 !important; }

.eventsSelected .footerButtonDiv .grey-button {
  margin-top: 30px; }

@media screen and (max-width: 767px) {
  .place-hold-popup div:nth-child(2) div:first-child {
    max-width: 600px !important; } }

.MuiDialog-paperWidthSm {
  max-width: 480px; }

.dialog-container {
  padding: 24px; }
  .dialog-container .logo {
    text-align: center; }
    .dialog-container .logo img {
      height: 92px; }
  .dialog-container p {
    width: 100%; }
  .dialog-container .heading-1 {
    font-size: 14px;
    text-align: center;
    font-weight: 500; }
  .dialog-container .heading-2 {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 600;
    text-align: center; }
  .dialog-container .bottom-heading {
    margin: 16px 0;
    font-size: 14px;
    text-align: center; }
  .dialog-container .bottom-link {
    margin: 4px 0 4px;
    font-size: 14px;
    text-align: center;
    font-weight: 600; }
    .dialog-container .bottom-link a {
      color: #1397e1; }
  .dialog-container .login-container .select-box {
    padding-right: 4px; }
    .dialog-container .login-container .select-box .phone-ext {
      height: 35px; }
  .dialog-container .login-container .button-box {
    margin-top: 18px; }
    .dialog-container .login-container .button-box .sign-btn {
      background-color: #1397e1;
      font-weight: 700;
      font-size: 16px; }
  .dialog-container .login-container .bottom-link {
    margin: 18px 0 8px; }
  .dialog-container .login-container .bottom-border {
    padding: 0 18px 0 18px; }
  .dialog-container .login-container .input-box {
    margin-top: 12px; }
    .dialog-container .login-container .input-box label {
      font-size: 14px;
      font-weight: 600;
      padding-left: 4px; }
    .dialog-container .login-container .input-box .inputField {
      width: 100%; }
  .dialog-container .login-container .password_field_div {
    padding-right: 4px; }
  .dialog-container .otp-container .otp-input-box {
    display: flex;
    align-items: center;
    justify-content: center; }
    .dialog-container .otp-container .otp-input-box .otp-input {
      width: 40px !important;
      text-align: center;
      height: 40px;
      margin: 12px;
      border-radius: 4px;
      border: 1px solid #1397e1; }
  .dialog-container .otp-container .bottom-link {
    margin: 36px 0 8px; }
  .dialog-container .help-container .heading-2 {
    margin-top: 12px; }

.calendar {
  border: 1px solid #f0f0f0;
  border-radius: 6px; }
  .calendar .button-calendar {
    display: block;
    text-align: center;
    border-bottom: 1px solid #f0f0f0; }
    .calendar .button-calendar button {
      background-color: transparent;
      color: #ee2738;
      box-shadow: none;
      font-weight: 700;
      font-size: 18px; }
    .calendar .button-calendar .today {
      color: #1397e1;
      min-width: 140px;
      font-family: Raleway;
      font-size: 16px;
      font-weight: bold; }
  .calendar .flexContainer {
    background-color: #f7f7f7; }
    .calendar .flexContainer .weekname {
      padding: 8px 0px;
      text-transform: uppercase; }
    .calendar .flexContainer .flexColumn {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #202020; }
    .calendar .flexContainer .day {
      background-color: #f7f7f7;
      color: #36434b;
      height: 50px;
      font-weight: normal;
      font-size: 14px;
      cursor: pointer; }
      .calendar .flexContainer .day .inner-grid {
        padding-left: 0px;
        position: relative;
        padding-right: 0px; }
        .calendar .flexContainer .day .inner-grid .date {
          text-align: left;
          left: 5px;
          display: block;
          top: 0;
          width: 100%;
          position: absolute;
          z-index: 2; }
        .calendar .flexContainer .day .inner-grid .event {
          position: absolute;
          width: 100%;
          height: 10px;
          top: 0px; }
        .calendar .flexContainer .day .inner-grid .single-event {
          height: 100%; }
        .calendar .flexContainer .day .inner-grid .blockOut {
          background-color: #f7f7f7 !important;
          height: 100%;
          cursor: default; }
        .calendar .flexContainer .day .inner-grid .makeAvailableClass {
          background-color: #ffffff !important;
          height: 100% !important; }
        .calendar .flexContainer .day .inner-grid .holdClassSecond {
          top: 0px; }
        .calendar .flexContainer .day .inner-grid .holdClassSingle {
          height: 100%;
          background-color: #69c7ee !important; }
        .calendar .flexContainer .day .inner-grid .blockClassSingle {
          height: 100%;
          background-color: grey !important;
          cursor: default; }
        .calendar .flexContainer .day .inner-grid .noTimeSlotAvilable {
          height: 100%;
          background-color: #f7f7f7 !important;
          pointer-events: none; }
    .calendar .flexContainer .inactive {
      background-color: #f7f7f7;
      color: #e1e1e1; }
    .calendar .flexContainer .inner-grid {
      padding: 17px 8px;
      box-shadow: inset -0.5px -0.5px 0 0 #f0f0f0, inset 0.5px 0.5px 0 0 #f0f0f0;
      border-radius: 0 !important; }
      .calendar .flexContainer .inner-grid .event-slot {
        min-height: auto; }

.widget .bookClasssingle {
  background-color: red !important;
  height: 100% !important;
  cursor: default; }

.widget .h3 {
  margin-top: 105px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 16px; }
  @media screen and (max-width: 992px) {
    .widget .h3 {
      margin-top: 130px; } }

.widget .calendar {
  margin: 30px 180px 10px !important; }
  @media screen and (min-width: 1360px) {
    .widget .calendar {
      margin: 30px 400px 10px !important; } }
  @media screen and (min-width: 1024px) {
    .widget .calendar {
      margin: 30px 250px 10px !important; } }
  @media screen and (max-width: 767px) {
    .widget .calendar {
      margin: 30px 75px 10px !important; } }
  @media screen and (max-width: 520px) {
    .widget .calendar {
      margin: 10px !important; } }
  .widget .calendar .day {
    height: 80px !important; }
    @media screen and (max-width: 992px) {
      .widget .calendar .day {
        height: 48px !important; } }
  .widget .calendar .weekname {
    padding: 20px 0px !important; }
  .widget .calendar .headesearch_container p, .widget .calendar .headesearch_container b {
    word-wrap: break-word; }
  @media screen and (max-width: 767px) {
    .widget .calendar .topEventNav Button {
      width: auto !important; } }

.widget .calendar_instruction {
  padding-left: 115px; }

.widget .blockClassRight {
  width: 0;
  height: 0;
  border-top: 5rem solid grey;
  border-right: 112px solid transparent;
  background: transparent !important; }

@media screen and (max-width: 767px) {
  .widget-place-hold Button {
    width: auto !important;
    min-width: 85px !important; } }

.widget-header .topEventMenu, .widget-header .topEventNav {
  margin-right: 10px !important; }
  @media screen and (max-width: 767px) {
    .widget-header .topEventMenu, .widget-header .topEventNav {
      margin-right: 4px !important; } }

.widget .holdClassLeft {
  width: 100% !important;
  height: 100% !important; }
  @media screen and (min-width: 1200px) {
    .widget .holdClassLeft {
      border-bottom: 80px solid #ccebf9 !important; } }
  @media screen and (min-width: 1300px) {
    .widget .holdClassLeft {
      border-bottom: 80px solid #ccebf9 !important; } }
  @media screen and (max-width: 1199px) {
    .widget .holdClassLeft {
      border-bottom: 80px solid #ccebf9 !important; } }
  @media screen and (max-width: 1024px) {
    .widget .holdClassLeft {
      border-bottom: 80px solid #ccebf9 !important; } }
  @media screen and (max-width: 991px) {
    .widget .holdClassLeft {
      border-left: 30px solid transparent !important;
      border-bottom: 51px solid #ccebf9 !important; } }
  @media screen and (max-width: 860px) {
    .widget .holdClassLeft {
      border-bottom: 51px solid #ccebf9 !important; } }

.widget .blockClassLeft {
  width: 100%;
  height: 100%; }
  @media screen and (min-width: 1300px) {
    .widget .blockClassLeft {
      border-bottom: 80px solid grey !important; } }
  @media screen and (max-width: 1300px) {
    .widget .blockClassLeft {
      border-bottom: 80px solid grey !important; } }
  @media screen and (max-width: 991px) {
    .widget .blockClassLeft {
      border-bottom: 50px solid grey !important;
      border-left: 20px solid transparent !important; } }

.widget .blockClassRight {
  width: 100%;
  height: 100%; }
  @media screen and (max-width: 1300px) {
    .widget .blockClassRight {
      border-top: 5rem solid grey;
      border-right: 50px solid transparent !important;
      background: transparent !important; } }
  @media screen and (max-width: 991px) {
    .widget .blockClassRight {
      border-top: 3.1rem solid grey;
      border-right: 50px solid transparent !important;
      background: transparent !important; } }
  @media screen and (max-width: 991px) {
    .widget .blockClassRight {
      border-right: 30px solid transparent !important; } }

.widget .holdClassRight {
  width: 100% !important;
  height: 100% !important; }
  @media screen and (min-width: 1399px) {
    .widget .holdClassRight {
      border-right: 100px solid transparent !important;
      border-top: 80px solid #69c7ee; } }
  @media screen and (min-width: 1200px) {
    .widget .holdClassRight {
      border-top: 80px solid #69c7ee; } }
  @media screen and (max-width: 1199px) {
    .widget .holdClassRight {
      border-top: 80px solid #69c7ee; } }
  @media screen and (max-width: 1024px) {
    .widget .holdClassRight {
      border-top: 80px solid #69c7ee; } }
  @media screen and (max-width: 991px) {
    .widget .holdClassRight {
      border-right: 50px solid transparent; } }
  @media screen and (max-width: 991px) {
    .widget .holdClassRight {
      border-top: 51px solid #69c7ee !important; } }
  @media screen and (max-width: 610px) {
    .widget .holdClassRight {
      border-right: 65px solid transparent; } }
  @media screen and (max-width: 575px) {
    .widget .holdClassRight {
      border-right: 50px solid transparent; } }
  @media screen and (max-width: 460px) {
    .widget .holdClassRight {
      border-right: 33px solid transparent; } }

.blockClassRight,
.holdClassRight {
  -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
          clip-path: polygon(100% 0, 0 0, 0 100%); }

.blockClassLeft,
.holdClassLeft {
  -webkit-clip-path: polygon(100% 0, 0 100%, 100% 99%);
          clip-path: polygon(100% 0, 0 100%, 100% 99%); }

@media screen and (min-width: 1400px) {
  .widget .blockClassLeft {
    border-bottom: 80px solid grey !important; } }

.widget .holdClass3,
.widget .blockClass3 {
  height: 28px !important;
  top: 0px !important; }

.widget .holdClassOne3,
.widget .blockClassOne3 {
  top: 26px !important;
  height: 28px !important; }

.widget .holdClassTwo3,
.widget .blockClassTwo3 {
  top: 54px !important;
  height: 27px !important; }

.widget .holdClass,
.widget .blockClass {
  height: 20px !important;
  top: 0px !important; }

.widget .holdClassOne4,
.widget .blockClassOne4 {
  top: 20px !important;
  height: 20px !important; }

.widget .holdClassTwo4,
.widget .blockClassTwo4 {
  top: 40px !important;
  height: 20px !important; }

.widget .holdClassThree4,
.widget .blockClassThree4 {
  top: 60px !important;
  height: 20px !important; }

@media screen and (max-width: 991px) {
  .widget .holdClass3,
  .widget .blockClass3 {
    height: 16px !important;
    top: 0px !important; }
  .widget .holdClassOne3,
  .widget .blockClassOne3 {
    top: 16px !important;
    height: 16px !important; }
  .widget .holdClassTwo3,
  .widget .blockClassTwo3 {
    top: 34px !important;
    height: 16px !important; }
  .widget .holdClass,
  .widget .blockClass {
    height: 12px !important;
    top: 0px !important; }
  .widget .holdClassOne4,
  .widget .blockClassOne4 {
    top: 12px !important;
    height: 12px !important; }
  .widget .holdClassTwo4,
  .widget .blockClassTwo4 {
    top: 24px !important;
    height: 12px !important; }
  .widget .holdClassThree4,
  .widget .blockClassThree4 {
    top: 36px !important;
    height: 12px !important; } }

@media screen and (max-width: 520px) {
  .MuiDialog-paper-209,
  .jss123.jss149.jss124.jss258.jss286.jss259.jss262 {
    margin: 10px !important;
    max-width: 100% !important; }
  .dialog-container .login-container input#phone {
    max-width: 230px !important; }
  .dialog-container .login-container .button-box {
    max-width: 200px !important;
    margin: 0 auto;
    margin-top: 20px !important; } }

.venue_plan_container {
  margin-top: 60px;
  font-family: Raleway;
  width: 100%;
  background-color: #f0f0f0;
  overflow-x: hidden;
  height: 100%; }
  .venue_plan_container .plan_outer_container {
    margin: 0 auto;
    text-align: center;
    padding-top: 50px; }
    .venue_plan_container .plan_outer_container .venue-mouse-over button {
      background-color: transparent;
      color: #4a4a4a;
      box-shadow: none;
      padding: 0px;
      min-width: auto;
      font-size: 20px;
      font-weight: 400; }
    .venue_plan_container .plan_outer_container .venue-mouse-over .head-text-blue {
      color: #1397e1;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.43; }
    .venue_plan_container .plan_outer_container .venue-mouse-over .tier-box {
      display: block;
      justify-content: space-between;
      width: 100%; }
    .venue_plan_container .plan_outer_container .venue-mouse-over .blue-heading {
      color: #1397e1;
      padding-bottom: 12px;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.43; }
    .venue_plan_container .plan_outer_container .venue-mouse-over .bottom-text-blue {
      color: #1397e1;
      padding-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.43; }
      .venue_plan_container .plan_outer_container .venue-mouse-over .bottom-text-blue a {
        color: #1397e1;
        text-decoration: underline; }
    .venue_plan_container .plan_outer_container .advertisement {
      font-family: Raleway;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ee2738;
      margin: 10px auto 20px;
      text-align: center; }
    .venue_plan_container .plan_outer_container .plan_inner_container {
      width: 300px;
      min-height: auto;
      border-radius: 6px;
      vertical-align: top;
      box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 6px 0 rgba(0, 0, 0, 0.12);
      background-color: #ffffff;
      margin: 30px 15px;
      display: inline-block; }
    .venue_plan_container .plan_outer_container .heading-red {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.33;
      color: #36434b; }
    .venue_plan_container .plan_outer_container .head-text {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.43;
      color: #36434b;
      margin: auto;
      padding-top: 10px;
      padding-bottom: 10px; }
    .venue_plan_container .plan_outer_container .head-text-blue {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.43;
      color: #36434b;
      margin: auto;
      padding-top: 10px;
      padding-bottom: 10px; }
    .venue_plan_container .plan_outer_container .tier-box {
      display: block;
      justify-content: space-between;
      width: 100%; }
  .venue_plan_container .advertisement {
    font-family: Raleway;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ee2738;
    margin: 10px auto 20px;
    text-align: center; }
  .venue_plan_container .plan_inner_container {
    width: 300px;
    min-height: auto;
    border-radius: 6px;
    vertical-align: top;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    margin: 30px 15px;
    display: inline-block; }
  .venue_plan_container .plan_title {
    font-family: Raleway;
    font-size: 48px;
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.96;
    letter-spacing: normal;
    color: #36434b;
    margin: 20px auto 10px; }
  .venue_plan_container .blue {
    color: #1397e1 !important; }
  .venue_plan_container .bigsub_title {
    font-size: 20px;
    padding-bottom: 5px; }
    .venue_plan_container .bigsub_title a {
      color: #36434b; }
  .venue_plan_container .red-text {
    width: 100%;
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ee2738;
    margin: 24px auto 10px; }
  .venue_plan_container .small-font {
    width: 74px;
    height: 32px;
    font-family: Raleway;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #36434b;
    margin: 10px auto !important; }
  .venue_plan_container .sub_title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #747474; }
  .venue_plan_container .plan_description {
    width: 89% !important;
    height: 920px;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #62717a;
    list-style: none;
    margin: auto;
    text-align: right;
    -webkit-padding-start: 0 !important;
            padding-inline-start: 0 !important; }
    .venue_plan_container .plan_description li {
      overflow: auto;
      width: 100% !important;
      padding: 2px 0; }
      .venue_plan_container .plan_description li .red {
        color: #ee2738;
        font-weight: 700; }
  .venue_plan_container .list {
    display: list-item; }
  .venue_plan_container .big {
    width: 240px; }
  .venue_plan_container .text {
    display: inline-block;
    width: 90%;
    padding: 8px 0 0 4px;
    text-align: left; }
  .venue_plan_container .blue_icon_div {
    width: 10%;
    display: inline-block;
    float: left;
    padding-top: 10px; }
  .venue_plan_container .blue_icon {
    width: 16px;
    height: 16px;
    border: 0;
    background-color: none; }
  .venue_plan_container .red-section-1 {
    width: 100%;
    height: 91px;
    background-color: #ee2738;
    overflow: hidden; }
    @media only screen and (min-width: 992px) {
      .venue_plan_container .red-section-1 {
        background: transparent;
        position: relative; }
        .venue_plan_container .red-section-1:before {
          content: '';
          top: 0;
          left: 0;
          margin-left: -10%;
          width: calc(100% + 20%);
          height: 100%;
          position: absolute;
          background: #ef4a39;
          border-radius: 100% 0 0% 0% / 35%; } }
  .venue_plan_container .red-section-2 {
    width: 100%;
    height: 91px;
    overflow: hidden;
    background-color: #ee2738; }
    @media only screen and (min-width: 992px) {
      .venue_plan_container .red-section-2 {
        background: transparent;
        position: relative; }
        .venue_plan_container .red-section-2:before {
          content: '';
          top: -8px;
          left: 0;
          margin-left: -10%;
          width: calc(100% + 20%);
          height: calc(100% + 14px);
          position: absolute;
          background: #ef4a39;
          border-radius: 100% 100% 0% 0% / 25%; } }
  .venue_plan_container .red-section-3 {
    width: 100%;
    height: 91px;
    overflow: hidden;
    background-color: #ee2738; }
    @media only screen and (min-width: 992px) {
      .venue_plan_container .red-section-3 {
        background: transparent;
        position: relative; }
        .venue_plan_container .red-section-3:before {
          content: '';
          font-family: Raleway;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #ee2738;
          margin: 0px auto 26px;
          top: 0;
          left: 0;
          margin-left: -10%;
          width: calc(100% + 20%);
          height: 100%;
          position: absolute;
          background: #ef4a39;
          border-radius: 0% 100% 0% 0% / 35%; } }
  .venue_plan_container .button-bg {
    width: 130px;
    height: 36px;
    border-radius: 6px;
    background-image: linear-gradient(to bottom, #ffffff, #ececec);
    margin-top: 32px;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ee2738; }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .venue_plan_container .plan_inner_container {
      float: left;
      width: 46%; } }
  @media only screen and (max-width: 767px) {
    .venue_plan_container .plan_outer_container {
      width: 80%; }
    .venue_plan_container .plan_inner_container {
      width: 100%;
      min-width: 100%;
      padding-top: 15px;
      margin: 30px 40px 30px 0px; }
    .venue_plan_container .plan_description {
      height: auto;
      margin-bottom: 20px; } }

.small-font-text {
  font-size: 16px;
  margin-bottom: 25px;
  color: #000;
  font-weight: 600; }
  .small-font-text span {
    display: inline-block;
    margin: 0 5px; }
    .small-font-text span a {
      color: #1397e1;
      font-weight: 600; }

.list-text-center {
  padding-top: 35px !important;
  text-align: center !important; }

.tier2-list-text-center {
  padding-top: 62px !important;
  text-align: center !important; }

@media screen and (min-width: 800px) {
  .mobile-view {
    display: none !important; } }

.desktop-view {
  display: block; }
  @media screen and (max-width: 767px) {
    .desktop-view {
      display: none; } }

.landing-banner .text_over_image {
  position: absolute;
  left: 0;
  width: 100%;
  top: 149px;
  justify-content: center; }
  @media screen and (max-width: 800px) {
    .landing-banner .text_over_image {
      display: none !important; } }

@media screen and (min-width: 800px) {
  .landing-banner .banner_heading {
    font-size: 48px;
    font-weight: 600; } }

@media screen and (min-width: 800px) {
  .landing-banner .banner_heading {
    text-align: center;
    color: #ffffff; } }

@media screen and (max-width: 800px) {
  .landing-banner .banner_heading {
    text-align: center;
    color: #000000; } }

@media screen and (max-width: 768px) {
  .landing-banner .banner_heading {
    font-size: 24px;
    font-weight: 200;
    padding-top: 20px; } }

.landing-banner .banner_sub_heading {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  padding-top: 24px; }
  @media screen and (min-width: 800px) {
    .landing-banner .banner_sub_heading {
      text-align: center;
      color: #ffffff;
      padding-bottom: 8px; } }
  @media screen and (max-width: 800px) {
    .landing-banner .banner_sub_heading {
      padding-top: 12px;
      text-align: center;
      color: #000000; } }

.landing-banner .banner_description {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  margin-top: 48px; }
  @media screen and (min-width: 800px) {
    .landing-banner .banner_description {
      text-align: center;
      color: #ffffff;
      width: 100%; } }
  @media screen and (max-width: 800px) {
    .landing-banner .banner_description {
      text-align: center;
      color: #000000;
      width: 100%; } }

@media screen and (min-width: 800px) {
  .landing-banner .banner_content {
    padding-left: 67px; } }

@media screen and (min-width: 800px) {
  .landing-banner .banner_button {
    text-align: center; } }

@media screen and (max-width: 800px) {
  .landing-banner .banner_button {
    text-align: center; } }

.sectionOne-box .left-content {
  padding: 90px 52px 90px 190px; }
  .sectionOne-box .left-content h4 {
    font-size: 52px;
    color: #1397e1;
    font-weight: 600;
    line-height: 66px; }

.sectionOne-box .right-content {
  padding: 75px 36px; }
  .sectionOne-box .right-content h3 {
    color: #747474;
    font-size: 28px;
    font-weight: 600; }
  .sectionOne-box .right-content p {
    color: #747474;
    font-size: 16px;
    font-weight: 600; }
    .sectionOne-box .right-content p.tp-16 {
      margin-top: 16px; }
  .sectionOne-box .right-content .sectionOne-box-content p {
    position: relative;
    padding-left: 30px; }
  .sectionOne-box .right-content .sectionOne-box-content p:before {
    font-size: 12px;
    content: "";
    position: absolute;
    top: 6px;
    left: 1px;
    text-transform: capitalize;
    background: #747474;
    color: #ffffff;
    width: 15px;
    border-radius: 50%;
    height: 15px;
    padding: 0 5px 1px 5px; }

.sectionOne-box .full-content {
  background-color: #1397e1;
  color: #ffffff;
  text-align: center;
  padding: 120px 0;
  width: 100%; }
  .sectionOne-box .full-content h4 {
    font-size: 50px;
    font-weight: 600; }
    .sectionOne-box .full-content h4.sheading {
      font-size: 18px; }
  .sectionOne-box .full-content p {
    font-size: 18px;
    font-weight: 600; }
  .sectionOne-box .full-content .text-white {
    color: #ffffff; }

@media screen and (max-width: 767px) {
  .left-content h4,
  .sectionOne-box .full-content h4,
  .sectionTwo-box .full-content h4,
  .sectionOne-box .full-content h4,
  .sectionTwo-box .full-content h4,
  .featured_venue_slider .top-section .left-container h4,
  .sectionFive-box .heading h4,
  .sectionOne-box .right-content h3,
  .sectionTwo-box .right-content h3,
  .sectionThree-box .heading h4 {
    font-size: 25px !important;
    line-height: 30px !important; }
  .new-home-banner .banner_heading,
  .sectionFour-box .full-content .button,
  .featured_venue_heading,
  .sectionFive-box .quotes .right-container h4,
  .sectionFour-box .full-content .large-text,
  .ready-checkout .left-container h4,
  .sectionFive-box .quotes .right-container p {
    font-size: 20px !important;
    line-height: 30px !important; }
  .new-home-banner .banner_sub_heading,
  .sectionOne-box .right-content p,
  .sectionOne-box .full-content p,
  .sectionTwo-box .right-content p,
  .sectionTwo-box .full-content p,
  .sectionThree-box .mobile-view .right-container p,
  .sectionFour-box .full-content .small-text,
  .sectionFour-box .images-wrapper p,
  .featured_venue_slider .top-section .right-container p,
  .sectionFive-box .quotes .left-container .quotes-box,
  .event-planner,
  .ready-checkout .left-container p {
    font-size: 14px !important; }
  .BannerList-button-319 {
    font-size: 12px !important; } }

.sectionTwo-box .left-content {
  background: #f7f7f7;
  padding: 130px 52px 130px 170px; }
  .sectionTwo-box .left-content h4 {
    font-size: 52px;
    color: #1397e1;
    font-weight: 600;
    line-height: 66px; }

.sectionTwo-box .right-content {
  background: #f7f7f7;
  padding: 100px 36px; }
  .sectionTwo-box .right-content h3 {
    color: #747474;
    font-size: 28px;
    font-weight: 600; }
  .sectionTwo-box .right-content p {
    color: #747474;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    padding-left: 30px; }
    .sectionTwo-box .right-content p.tp-16 {
      margin-top: 16px; }
  .sectionTwo-box .right-content span.material-icons {
    color: #69C02E;
    font-size: 18px;
    position: absolute;
    left: 0;
    top: 10%; }

.sectionTwo-box .full-content {
  color: #747474;
  text-align: center;
  padding: 60px 0;
  width: 100%; }
  .sectionTwo-box .full-content h4 {
    font-size: 50px;
    font-weight: 600; }
    .sectionTwo-box .full-content h4.blue-text {
      color: #1397e1; }
  .sectionTwo-box .full-content p {
    padding-top: 4px;
    font-size: 16px;
    font-weight: 600; }

.blue-text {
  color: #1397e1; }

.sectionThree-box {
  background: #f7f7f7;
  padding: 20px; }
  .sectionThree-box .heading {
    text-align: center;
    color: #747474;
    padding: 16px 0; }
    .sectionThree-box .heading h4 {
      font-size: 32px;
      font-weight: 600;
      text-transform: none;
      text-transform: initial; }
  .sectionThree-box .mobile-view .left-container .img-container {
    padding: 10px 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    background-color: #64646f33;
    border-radius: 20px;
    margin-right: 8px; }
    @media screen and (max-width: 767px) {
      .sectionThree-box .mobile-view .left-container .img-container {
        margin-right: 0px; } }
    .sectionThree-box .mobile-view .left-container .img-container img {
      height: 420px; }
      @media screen and (max-width: 767px) {
        .sectionThree-box .mobile-view .left-container .img-container img {
          height: auto; } }
  .sectionThree-box .mobile-view .right-container .box-shadow-white {
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 10px 20px; }
    @media screen and (max-width: 767px) {
      .sectionThree-box .mobile-view .right-container .box-shadow-white {
        margin-left: 0px; } }
  .sectionThree-box .mobile-view .right-container h6 {
    padding-top: 12px;
    color: #1397e1;
    font-weight: 600;
    font-size: 20px; }
  .sectionThree-box .mobile-view .right-container p {
    padding-top: 8px;
    color: #747474;
    font-weight: 600; }
  .sectionThree-box .full-container {
    position: relative;
    margin: 16px 0 80px 0; }
    .sectionThree-box .full-container .img-container {
      position: relative;
      box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
      background-color: #64646f33;
      border-radius: 20px;
      padding: 10px 20px;
      width: 75%;
      height: 430px; }
      .sectionThree-box .full-container .img-container img {
        height: 400px; }
    .sectionThree-box .full-container .box-shadow-white {
      position: absolute;
      box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
      background-color: #ffffff;
      border-radius: 20px;
      padding: 10px 20px;
      top: 10%;
      left: 44%;
      width: 56%;
      height: 440px; }
      .sectionThree-box .full-container .box-shadow-white h6 {
        padding-top: 12px;
        color: #1397e1;
        font-weight: 600;
        font-size: 20px; }
      .sectionThree-box .full-container .box-shadow-white p {
        padding-top: 8px;
        color: #747474;
        font-weight: 600; }

.sectionFour-box .full-content {
  background-image: linear-gradient(315deg, #37d5d6 0%, #1481d7 100%);
  color: #ffffff;
  text-align: center;
  padding: 32px 0 52px;
  width: 100%; }
  .sectionFour-box .full-content .large-text {
    font-size: 36px;
    font-weight: 500; }
  .sectionFour-box .full-content .small-text {
    padding-top: 24px;
    font-size: 20px;
    font-weight: 500; }
  .sectionFour-box .full-content .bold-text {
    font-weight: 700; }
  .sectionFour-box .full-content .button {
    background-color: #1497e1;
    color: #ffffff;
    font-size: 32px;
    font-weight: 600;
    border: 1px solid;
    margin-top: 24px; }
    .sectionFour-box .full-content .button a {
      color: #ffffff; }

.sectionFour-box .left-container {
  padding: 100px 50px; }

.sectionFour-box .images-wrapper {
  justify-content: center;
  padding: 50px 0 25px; }
  .sectionFour-box .images-wrapper p {
    text-align: center;
    margin-top: 20px;
    font-weight: 600; }
  .sectionFour-box .images-wrapper .image-block {
    text-align: center;
    margin-bottom: 40px; }
    .sectionFour-box .images-wrapper .image-block li.images {
      display: inline-block;
      border: 1px solid #1497e1;
      width: 130px;
      height: 125px;
      margin: 15px;
      border-radius: 15px;
      padding: 15px; }
      .sectionFour-box .images-wrapper .image-block li.images p {
        width: 130px;
        height: 125px;
        border-radius: 15px;
        display: flex;
        position: absolute;
        margin: -121px 0 0 -16px;
        background-color: #4d4d4d;
        opacity: 0.9;
        color: #fff;
        justify-content: center;
        align-items: center;
        cursor: default; }

.bold {
  font-weight: 700 !important; }

.find_venues {
  padding-bottom: 50px; }

.sectionFive-box .heading {
  text-align: center;
  padding: 85px 0;
  width: 100%; }
  .sectionFive-box .heading h4 {
    font-size: 50px;
    font-weight: 400;
    text-transform: none;
    color: #747474; }
  .sectionFive-box .heading p {
    padding-top: 4px;
    color: #747474;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize; }

.sectionFive-box .quotes {
  background: #f7f7f7;
  padding: 60px 45px 60px 0; }
  .sectionFive-box .quotes .left-container {
    align-items: center;
    justify-content: center;
    padding: 16px 0 16px 52px; }
    .sectionFive-box .quotes .left-container .quotes-box {
      padding: 50px 30px;
      background: #36434b;
      color: #ffffff;
      font-size: 22px;
      font-weight: 500;
      box-shadow: 36px 30px 0 #ccc; }
  .sectionFive-box .quotes .right-container {
    text-align: right;
    padding-right: 24px;
    padding-top: 24px; }
    .sectionFive-box .quotes .right-container h4 {
      padding-top: 12px;
      color: #747474;
      font-weight: 600;
      font-size: 36px;
      line-height: 40px; }
    .sectionFive-box .quotes .right-container p {
      padding-top: 8px;
      color: #747474;
      font-weight: 500;
      font-size: 22px; }

.sectionFive-box .column-box {
  background: #ffffff;
  padding: 60px 0 0; }
  .sectionFive-box .column-box .column-section {
    padding: 0 32px; }
    .sectionFive-box .column-box .column-section h6 {
      font-size: 16px;
      color: #1a181e;
      text-transform: uppercase;
      font-weight: 700; }
    .sectionFive-box .column-box .column-section p {
      font-size: 14px;
      color: #747474;
      font-weight: 600; }
    .sectionFive-box .column-box .column-section .block-height {
      min-height: 150px; }

@media screen and (max-width: 1024px) {
  .sectionFive-box .column-box {
    padding: 0 20px; }
    .sectionFive-box .column-box .column-section {
      padding: 0; }
  .sectionFive-box .quotes .left-container {
    padding: 0; }
  .sectionFive-box .quotes .right-container {
    padding-right: 15px; } }

.featured_venue_slider .top-section {
  background: #f7f7f7; }
  .featured_venue_slider .top-section .left-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 36px; }
    .featured_venue_slider .top-section .left-container h4 {
      font-size: 48px;
      font-weight: 600;
      color: #747474;
      line-height: 55px; }
  .featured_venue_slider .top-section .right-container {
    padding: 48px;
    text-align: left; }
    .featured_venue_slider .top-section .right-container p {
      color: #747474;
      font-size: 18px;
      font-weight: 600; }

.featured_venue_slider .featured_venue_heading {
  padding: 20px 0 12px;
  background: #f7f7f7;
  color: #747474;
  font-weight: 600; }

.featured_venue_slider .featured_venue_carousel {
  background-color: #f7f7f7;
  padding: 16px 0 60px; }
  @media screen and (max-width: 767px) {
    .featured_venue_slider .featured_venue_carousel .venueCardWithFav {
      margin: 0 15px; } }

.featured_venue_slider .overlay_icon {
  right: 15px !important; }
  @media screen and (max-width: 767px) {
    .featured_venue_slider .overlay_icon {
      right: 5px !important; } }

.featured_venue_slider .venue_content {
  padding-top: 5px !important;
  padding-bottom: 0 !important; }

@media screen and (max-width: 1024px) {
  .featured_venue_slider .top-section {
    justify-content: center; }
    .featured_venue_slider .top-section .left-container,
    .featured_venue_slider .top-section .right-container {
      padding: 20px !important; } }

.ready-checkout {
  padding: 40px 0;
  background: #fff; }
  .ready-checkout .left-container {
    padding-left: 20%; }
    .ready-checkout .left-container h4 {
      padding-top: 4px;
      font-size: 24px;
      color: #1397e1;
      font-weight: 600; }
    .ready-checkout .left-container p {
      color: #1a181e;
      font-weight: 500; }
  .ready-checkout .right-container {
    padding: 24px 0;
    text-align: center; }
    .ready-checkout .right-container button {
      padding: 8px 40px;
      background: #ee2738;
      border: none;
      border-radius: 6px;
      box-sizing: border-box;
      color: #ffffff;
      height: 36px;
      font-size: 13px;
      text-align: center;
      font-weight: 800;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      cursor: pointer; }
    .ready-checkout .right-container p {
      padding: 8px 0 0 4px;
      color: #747474;
      font-size: 11px;
      font-weight: 600; }
  @media screen and (max-width: 768px) {
    .ready-checkout {
      justify-content: center; }
      .ready-checkout .right-container p {
        padding: 10px 0; }
      .ready-checkout .left-container {
        padding-left: 10%; } }

@media screen and (max-width: 1024px) {
  .full-content,
  .images-wrapper,
  .left-content,
  .right-content,
  .sectionFive-box .heading {
    padding: 20px !important; }
  .sectionFive-box .column-box .column-section .block-height {
    min-height: auto;
    margin-top: 20px; }
  .sectionFive-box .quotes .right-container {
    padding-top: 30px; } }

@media screen and (max-width: 767px) {
  .sectionOne-box-content {
    margin-bottom: 30px; }
  .sectionTwo-box .right-content {
    padding: 20px 20px 60px !important; }
  .sectionTwo-box .left-content h4 {
    font-size: 40px;
    line-height: 46px; }
  .sectionFour-box .full-content .button {
    font-size: 25px;
    margin-bottom: 20px; }
  .sectionThree-box .right-container {
    margin: 20px 0 28px; } }

@media screen and (max-width: 680px) {
  .event_planner .danny_image {
    margin-top: 35px; }
  .event_planner .theresa_image {
    margin-top: 0 !important; } }

.banner-image-wrapper {
  background-image: url(https://www.first-hold.com/static/media/Header_deYoung.0872a5fe.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 793px;
  border-radius: 0 0 55% 46%/30px;
  position: relative;
  margin-top: -196px; }

.banner-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  border-radius: 0 0 55% 46%/30px; }

.padding-top {
  padding-top: 350px;
  text-align: center; }

.banner-image-wrapper.landing-banner .banner_heading {
  text-align: center;
  color: #ffffff;
  display: inline-block;
  max-width: 75%; }

.new-home-banner .mobile-view {
  display: none; }

.text-space {
  display: inline-block;
  padding: 0 5px; }

@media screen and (max-width: 768px) {
  .new-home-banner .desktop-view {
    display: none; }
  .new-home-banner .mobile-view {
    display: block;
    padding-top: 10px; }
  .new-home-banner .banner_heading {
    font-size: 24px;
    font-weight: 200;
    padding-top: 20px; }
  .new-home-banner .banner_sub_heading {
    padding-top: 12px;
    text-align: center;
    color: #000;
    font-weight: 500; }
  .new-home-banner .banner_description {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    margin-top: 48px; }
  .new-home-banner .banner-image-wrapper.landing-banner {
    max-height: 380px;
    margin-top: 0; }
  .new-home-banner .banner-overlay {
    background-color: rgba(0, 0, 0, 0); }
  .new-home-banner .text-space {
    display: inline-block;
    padding: 0 3px; }
  .new-home-banner .mobile-view-img {
    border-radius: 0 0 55% 46%/30px; } }

@media screen and (max-width: 767px) {
  .banner-image-wrapper {
    background-image: none;
    height: 60px !important; } }

.explore-ui-wrapper .topspace {
  margin-top: 120px; }

.explore-ui-wrapper .heading-text h1 {
  color: #595959;
  font-weight: 700;
  margin-bottom: 40px; }

.explore-ui-wrapper .heading-text.main h1 {
  font-size: 35px;
  line-height: 45px; }

.explore-ui-wrapper .heading-text.main p {
  color: #a6a6a6;
  font-size: 14px;
  margin-bottom: 40px; }

.explore-ui-wrapper .heading-text.main.event h1 {
  font-size: 45px;
  line-height: 45px; }

.explore-ui-wrapper .search-field {
  position: relative; }
  .explore-ui-wrapper .search-field input[type="search"] {
    height: 45px;
    max-width: 400px;
    width: 100%;
    border: 1px solid #cccccc59;
    border-radius: 4px;
    padding: 0 16px 0 40px; }
  .explore-ui-wrapper .search-field .icon-search {
    position: absolute;
    top: 6px;
    left: 10px; }
  .explore-ui-wrapper .search-field img {
    width: 30px; }

.explore-ui-wrapper input::-webkit-input-placeholder {
  font-size: 14px !important;
  padding-left: 10px;
  color: #a6a6a6;
  font-weight: 400;
  opacity: 0.4; }

.explore-ui-wrapper input::-ms-input-placeholder {
  font-size: 14px !important;
  padding-left: 10px;
  color: #a6a6a6;
  font-weight: 400;
  opacity: 0.4; }

.explore-ui-wrapper input::placeholder {
  font-size: 14px !important;
  padding-left: 10px;
  color: #a6a6a6;
  font-weight: 400;
  opacity: 0.4; }

.explore-ui-wrapper .search-btn a, .explore-ui-wrapper .search-btn button {
  background-color: #d80e2a;
  color: #ffffff;
  padding: 10px 20px;
  border: 1px solid #d80e2a;
  margin-top: 20px;
  width: 100%;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 600;
  display: inline-block;
  text-align: center; }

.explore-ui-wrapper .MuiGrid-container-1 {
  align-items: center; }

.explore-ui-wrapper .search-wrapper {
  max-width: 400px; }

.explore-ui-wrapper .search-wrapper-block {
  margin-left: 50px; }

.explore-ui-wrapper .heading-text h1 {
  font-size: 26px; }

.explore-ui-wrapper .search-field h2 {
  color: #595959;
  font-size: 16px;
  font-weight: 600; }

.explore-ui-wrapper .started a {
  width: 200px; }

.explore-ui-wrapper .search-btn.started {
  text-align: center;
  margin-top: 20px; }

.explore-ui-wrapper .box-wrapper {
  padding-top: 120px; }

.explore-ui-wrapper .search-wrapper-block.two-box {
  margin-right: 100px;
  margin-left: 0;
  margin-top: 30px; }

@media screen and (max-width: 767px) {
  .explore-ui-wrapper .venueTypeCard {
    margin: auto 15px;
    width: 100%; } }

.explore-ui-wrapper .venueTypeCard a {
  text-decoration: none; }
  .explore-ui-wrapper .venueTypeCard a:hover {
    text-decoration: none; }

.explore-ui-wrapper .venueTypeCard .find_feature_venue_image:hover {
  box-shadow: 0 9px 8px 0 rgba(128, 127, 127, 0.7); }

@media screen and (min-width: 1150px) {
  .explore-ui-wrapper .venueTypeCard .find_feature_venue_image {
    width: 290px;
    max-height: 200px;
    object-fit: cover;
    object-position: bottom; } }

@media screen and (max-width: 1151px) {
  .explore-ui-wrapper .venueTypeCard .find_feature_venue_image {
    width: 265.3px;
    max-height: 160.8px;
    object-fit: cover;
    object-position: bottom; } }

@media screen and (max-width: 991px) {
  .explore-ui-wrapper .venueTypeCard .find_feature_venue_image {
    width: 100%;
    max-height: 200px; } }

.explore-ui-wrapper .venueTypeCard .find_venue_event {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #36434b;
  padding-top: 3px; }

.explore-ui-wrapper .venueTypeCard .venues {
  padding: 10px 20px !important;
  display: inline-block; }

.explore-ui-wrapper .venueTypeCard {
  width: 100%;
  margin: 0 auto;
  text-align: center; }

.explore-ui-wrapper .slider-heading-box h1 {
  margin-bottom: 0;
  margin-top: 20px; }

.explore-ui-wrapper .slider-heading-box p {
  margin-bottom: 20px; }

.explore-ui-wrapper .location-block a {
  display: block;
  margin-bottom: 10px;
  color: #1397e1;
  font-weight: 600;
  font-size: 14px; }

.explore-ui-wrapper .heading-text.block h1 {
  margin-bottom: 0; }

.explore-ui-wrapper .box-wrapper.location {
  align-items: start;
  width: 700px;
  margin: 0 auto;
  padding: 30px 0 80px 0; }
  .explore-ui-wrapper .box-wrapper.location .heading-text.block h1 {
    font-size: 18px; }
  .explore-ui-wrapper .box-wrapper.location .location-block {
    padding-left: 100px; }

@media screen and (max-width: 991px) {
  .explore-ui-wrapper .search-btn {
    margin-bottom: 15px; }
  .explore-ui-wrapper .search-wrapper-block.two-box,
  .explore-ui-wrapper .search-wrapper-block,
  .explore-ui-wrapper .slider-heading-box,
  .explore-ui-wrapper .box-wrapper.location,
  .explore-ui-wrapper .search-wrapper,
  .explore-ui-wrapper .articles-section {
    margin: 0 25px; }
  .explore-ui-wrapper .text-center-image {
    text-align: center; }
  .explore-ui-wrapper .search-btn.started {
    margin-top: 0px; }
  .explore-ui-wrapper .location-address .heading-text.block {
    margin: 0 20px; } }

.explore-ui-wrapper .heading-text.event h1 {
  margin-bottom: 0;
  font-size: 55px; }

.explore-ui-wrapper .image-block,
.explore-ui-wrapper .image-text-block {
  display: inline-block;
  vertical-align: middle; }

.explore-ui-wrapper .image-block {
  max-width: 200px;
  height: 189px; }

.explore-ui-wrapper .image-wrapper {
  background-color: #cccccc30;
  padding: 20px;
  margin-bottom: 30px; }

.explore-ui-wrapper .image-text-block {
  padding-left: 50px; }
  .explore-ui-wrapper .image-text-block h1 {
    color: #767171;
    font-weight: 700;
    font-size: 22px; }
  .explore-ui-wrapper .image-text-block p {
    color: #767171;
    font-size: 15px;
    font-weight: 500; }

@media screen and (max-width: 1024px) {
  .explore-ui-wrapper .image-text-block {
    padding-left: 10px; } }

@media screen and (max-width: 768px) {
  .explore-ui-wrapper .btn-right {
    text-align: left  !important; }
  .explore-ui-wrapper .heading-text.event h1 {
    font-size: 40px; }
  .explore-ui-wrapper .image-block {
    margin: 0 auto;
    display: block; }
  .explore-ui-wrapper .box-wrapper.location .location-block {
    padding-left: 10px; }
  .explore-ui-wrapper .box-wrapper,
  .explore-ui-wrapper .need-more-section {
    padding-top: 60px !important; }
  .explore-ui-wrapper .featured-venue-bay {
    padding-bottom: 60px !important; }
  .explore-ui-wrapper .need-more-section {
    margin-top: 60px !important; }
    .explore-ui-wrapper .need-more-section .heading-text h1 {
      text-align: center; }
  .explore-ui-wrapper .box-wrapper.location {
    padding: 30px 0 80px 0; } }

.explore-ui-wrapper .text-with-btn,
.explore-ui-wrapper .need-more-section {
  background-color: #cccccc69;
  vertical-align: middle;
  padding: 5px 20px; }

.explore-ui-wrapper .text-with-btn h1 {
  color: #36434b;
  font-size: 17px;
  font-weight: 500; }

.explore-ui-wrapper .article-btn {
  background: #ffffff;
  color: #36434b;
  padding: 5px 20px;
  border: 1px solid #00000059;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  text-align: center; }

.explore-ui-wrapper .btn-right {
  text-align: right; }

.explore-ui-wrapper .articles-section p {
  padding: 30px 20px;
  font-size: 14px;
  color: #a6a6a6; }

.explore-ui-wrapper .need-more-wrapper {
  padding: 50px 0; }
  .explore-ui-wrapper .need-more-wrapper button {
    width: auto !important; }
  .explore-ui-wrapper .need-more-wrapper .search-btn {
    text-align: center; }

.explore-ui-wrapper .search-wrapper-block p,
.explore-ui-wrapper .search-wrapper-block ul li,
.explore-ui-wrapper .slider-heading-box p,
.explore-ui-wrapper .slider-heading-box ul li {
  color: #a6a6a6;
  font-size: 14px; }

.explore-ui-wrapper .search-wrapper-block ul,
.explore-ui-wrapper .slider-heading-box ul {
  padding-left: 35px; }
  .explore-ui-wrapper .search-wrapper-block ul li,
  .explore-ui-wrapper .slider-heading-box ul li {
    list-style: disc; }

.explore-ui-wrapper .bold {
  color: #7f7f7f; }

.explore-ui-wrapper .text-center-image img {
  min-height: 566px; }

.explore-ui-wrapper .need-more-section {
  margin-top: 100px; }

.explore-ui-wrapper .third-section {
  max-width: 400px;
  margin: 0 auto;
  height: 500px !important;
  margin-top: 30px; }

.explore-ui-wrapper .heading-text.ask {
  margin-bottom: 20px; }

.explore-ui-wrapper .heading-text.main.event p {
  color: #595959;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px; }

.explore-ui-wrapper .need-more-wrapper .search-btn a {
  width: 60%; }

.explore-ui-wrapper .text-center-image {
  background-repeat: no-repeat;
  background-position: center, center;
  background-size: cover;
  height: 400px;
  object-fit: cover;
  position: relative; }

.explore-ui-wrapper .meeting-main {
  background-image: url(https://www.first-hold.com/static/media/Meeting2.9aca2e08.jpg); }

.explore-ui-wrapper .meeting-main-1 {
  background-image: url(https://www.first-hold.com/static/media/Meeting.c95c3f04.jpg); }

.explore-ui-wrapper .meeting-main-2 {
  background-image: url(https://www.first-hold.com/static/media/Meeting1.98bc8775.jpg); }

.explore-ui-wrapper .corporate-main {
  background-image: url(https://www.first-hold.com/static/media/corporate-event.3b5edccd.jpg); }

.explore-ui-wrapper .corporate-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Corporate Venue - 2nd Photo.8a61c18a.jpg"); }

.explore-ui-wrapper .corporate-main-2 {
  background-image: url("https://www.first-hold.com/static/media/Corporate Venue - 3rd.bc829ca8.jpg"); }

.explore-ui-wrapper .event-space-main {
  background-image: url("https://www.first-hold.com/static/media/Blog - Wedding - Photo7.99aa564b.jpg"); }

.explore-ui-wrapper .event-space-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Blog - Wedding - Photo1.b28d6195.jpg"); }

.explore-ui-wrapper .event-space-main-2 {
  background-image: url("https://www.first-hold.com/static/media/Unique - Photo3.58b2c559.jpg"); }

.explore-ui-wrapper .bay-area-main {
  background-image: url("https://www.first-hold.com/static/media/Bay Area - Main Photo.70cf49f3.jpg"); }

.explore-ui-wrapper .bay-area-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Blog - Wedding - Photo2.946671c6.jpg"); }

.explore-ui-wrapper .bay-area-main-2 {
  background-image: url("https://www.first-hold.com/static/media/San Francisco - Bay Area1.2ce94dd3.jpg"); }

.explore-ui-wrapper .event-main {
  background-image: url("https://www.first-hold.com/static/media/New Landing Page - Main.4f3b15ae.jpg"); }

.explore-ui-wrapper .weeding-main {
  background-image: url("https://www.first-hold.com/static/media/Wedding Page - Main.c42d6ae4.png"); }

.explore-ui-wrapper .weeding-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Blog - Wedding - Photo3.a9a673c1.jpg"); }

.explore-ui-wrapper .weeding-main-2 {
  background-image: url(https://www.first-hold.com/static/media/wedding-043.ffb4dab4.jpg); }

.explore-ui-wrapper .winery-main {
  background-image: url("https://www.first-hold.com/static/media/Blog - Interesting venue 2.334eaad9.jpg"); }

.explore-ui-wrapper .winery-main-1 {
  background-image: url(https://www.first-hold.com/static/media/Wineries.3636784e.jpg); }

.explore-ui-wrapper .winery-main-2 {
  background-image: url(https://www.first-hold.com/static/media/Wineries2.d9d1a8ad.jpg); }

.explore-ui-wrapper .silicon-valley-main-2 {
  background-image: url("https://www.first-hold.com/static/media/Silicon Valley - Bottom.1593d447.jpg");
  height: 570px !important;
  width: 380px; }

.explore-ui-wrapper .silicon-valley-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Silicon Valley - Middle.1f5d6f23.jpg"); }

.explore-ui-wrapper .silicon-valley-main {
  background-image: url("https://www.first-hold.com/static/media/Silicon Valley - Top.2a4d748b.jpg"); }

.explore-ui-wrapper .wine-country-main-2 {
  background-image: url("https://www.first-hold.com/static/media/Wine Country - Bottom.a0bb91e1.jpg");
  height: 570px !important;
  width: 380px; }

.explore-ui-wrapper .wine-country-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Wine Country - Middle.7b6fd884.jpg"); }

.explore-ui-wrapper .wine-country-main {
  background-image: url("https://www.first-hold.com/static/media/Wine Country - Top.1699f532.jpg"); }

.explore-ui-wrapper .san-francisco-main-2 {
  background-image: url("https://www.first-hold.com/static/media/San Francisco - Bottom.6c8f3ea3.jpg");
  height: 578px !important;
  width: 400px; }

.explore-ui-wrapper .san-francisco-main-1 {
  background-image: url("https://www.first-hold.com/static/media/San Francisco - Middle.6afcbcf3.jpg"); }

.explore-ui-wrapper .san-francisco-main {
  background-image: url("https://www.first-hold.com/static/media/San Francisco - Top.380e9008.jpg"); }

.explore-ui-wrapper .sonoma-county-main-2 {
  background-image: url("https://www.first-hold.com/static/media/Sonoma County - Bottom.baa9ad54.jpg"); }

.explore-ui-wrapper .sonoma-county-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Sonoma County - Middle.60acebf7.jpg"); }

.explore-ui-wrapper .sonoma-county-main {
  background-image: url("https://www.first-hold.com/static/media/Sonoma County - Top.88bcf2d8.jpg"); }

.explore-ui-wrapper .sonoma-main-2 {
  background-image: url("https://www.first-hold.com/static/media/Sonoma - Bottom.4eb07f09.jpg");
  height: 576px !important;
  width: 380px; }

.explore-ui-wrapper .sonoma-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Sonoma - Middle.bc69f528.jpg"); }

.explore-ui-wrapper .sonoma-main {
  background-image: url("https://www.first-hold.com/static/media/Sonoma - Top.7e5565ce.jpg"); }

.explore-ui-wrapper .napa-valley-main-2 {
  background-image: url("https://www.first-hold.com/static/media/Napa Valley - Bottom.00a7fa7d.jpg");
  height: 570px !important;
  width: 392px !important; }

.explore-ui-wrapper .napa-valley-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Napa Valley - Middle.59df120f.jpg"); }

.explore-ui-wrapper .napa-valley-main {
  background-image: url("https://www.first-hold.com/static/media/Napa Valley - Top.5dbd3db5.jpg"); }

.explore-ui-wrapper .napa-main-2 {
  background-image: url("https://www.first-hold.com/static/media/Napa - Bottom.50f674c2.jpg");
  height: 569px !important;
  width: 376px !important; }

.explore-ui-wrapper .napa-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Napa - Middle.dbde4673.jpg"); }

.explore-ui-wrapper .napa-main {
  background-image: url("https://www.first-hold.com/static/media/Napa - Top3.e55de821.jpg"); }

.explore-ui-wrapper .sonoma-valley-main-2 {
  background-image: url("https://www.first-hold.com/static/media/Sonoma Valley - Bottom.afb09c61.jpg");
  height: 569px !important;
  width: 381px !important; }

.explore-ui-wrapper .sonoma-valley-main-1 {
  background-image: url("https://www.first-hold.com/static/media/Sonoma Valley - Middle.967b9120.jpg"); }

.explore-ui-wrapper .sonoma-valley-main {
  background-image: url("https://www.first-hold.com/static/media/Sonoma Valley - Top.6eaf408c.jpg"); }

.explore-ui-wrapper .banner-wrapper {
  display: flex;
  align-items: center; }

.explore-ui-wrapper .location-address-wrapper {
  margin-top: 60px;
  border-top: 1px solid #cccccc;
  padding-top: 100px; }

.explore-ui-wrapper .border-line {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px; }

.explore-ui-wrapper .text-center-image a {
  position: absolute;
  right: 0;
  bottom: 20px;
  background: #ffffff;
  color: #a6a6a6;
  font-weight: 700;
  padding: 0 6px 0 10px;
  font-size: 11px; }

.explore-ui-wrapper .text-center-image.left-side a {
  left: 0;
  right: unset; }

.explore-ui-wrapper .need-more-wrapper h1 {
  margin-bottom: 0; }

.explore-ui-wrapper .margin-top {
  margin-top: 40px; }

.explore-ui-wrapper .featured-venue-bay .heading-text {
  background: rgba(204, 204, 204, 0.19);
  padding: 30px 20px; }

.explore-ui-wrapper .featured-venue-bay .heading-text h1 {
  margin: 0; }

.explore-ui-wrapper .margin-10 {
  margin-top: 10px; }

.explore-ui-wrapper .slider-heading-box.search {
  text-align: center;
  padding-bottom: 20px; }

.explore-ui-wrapper .slider-heading-box.frequently {
  max-width: 900px; }

.explore-ui-wrapper .featured-venue-bay {
  padding-bottom: 70px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 70px; }

.explore-ui-wrapper .search-wrapper-block.two-box .search-field.margin-top {
  margin-top: 20px; }

.explore-ui-wrapper .need-more-wrapper .search-btn a {
  margin-top: 0 !important; }

.explore-ui-wrapper .margin-top-5 {
  margin-top: 15px; }

.explore-ui-wrapper .box-wrapper-padding {
  padding-top: 20px; }

.explore-ui-wrapper .contact.search-btn {
  width: 200px;
  margin: 0 auto;
  padding-bottom: 120px; }

.explore-ui-wrapper .googleMap {
  text-align: center; }

.heading-text h2 {
  color: #595959;
  font-weight: 700;
  font-size: 22px; }

.divider {
  opacity: 0.1; }

.contact_us input {
  background-color: #f7f7f7;
  border: 1px #f0f0f0 solid;
  border-radius: 5px;
  padding: 5px;
  width: 70%;
  height: 40px; }

.contact_us textarea {
  background-color: #f7f7f7;
  border: 1px #f0f0f0 solid;
  border-radius: 5px;
  padding: 5px;
  width: 70%; }

.contact_us .bottomSpacing {
  margin-bottom: 20px; }

.contact_us .button {
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.5px;
  background-color: #ee2738;
  color: #fff;
  border: 1px solid #ee2738;
  padding: 6px 20px;
  border-radius: 6px;
  cursor: pointer; }

@media screen and (max-device-width: 500px) {
  .contact_us input, .contact_us textarea {
    width: 90%; } }

.avilableDescription h1 {
  font-weight: 700; }

.contact_popup .venue_page {
  margin-top: 30px; }

.thankyou-page {
  padding: 100px 0;
  text-align: center; }
  .thankyou-page p {
    font-weight: 700;
    font-size: 30px;
    margin-top: 30px; }

.contact_popup .dialog-content {
  background: #ffffff;
  border-radius: 9px; }

.help-ui-wrapper .page-body {
  background: #f5f7fa;
  padding: 50px 0; }

.help-ui-wrapper .header-section {
  background-color: #1397e1 !important; }

.help-ui-wrapper img.large {
  max-width: 200px;
  width: auto;
  max-height: 64px; }

.help-ui-wrapper .header-body {
  width: auto;
  max-width: 90%;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto; }

.help-ui-wrapper a.company-logo {
  display: inline-block;
  line-height: 100%;
  position: relative;
  z-index: 1000; }

.help-ui-wrapper .article-search-container {
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.17);
  border: solid 1px transparent;
  background: #ffffff;
  border-radius: 6px;
  position: relative; }

.help-ui-wrapper .icon-search {
  position: absolute;
  top: 16px;
  left: 8px; }

.help-ui-wrapper input::-webkit-input-placeholder {
  font-size: 17px;
  font-weight: 500; }

.help-ui-wrapper input::-ms-input-placeholder {
  font-size: 17px;
  font-weight: 500; }

.help-ui-wrapper input::placeholder {
  font-size: 17px;
  font-weight: 500; }

.help-ui-wrapper input[type="search"]:focus {
  outline: none; }

.help-ui-wrapper input[type="search"] {
  padding: 0 16px 0 56px;
  position: relative;
  z-index: 10;
  height: 60px;
  border-radius: 6px;
  width: 100%;
  background: transparent;
  border: none; }

.help-ui-wrapper form {
  margin: 0;
  border-radius: 6px; }

.help-ui-wrapper .header-title h1 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.13); }

.help-ui-wrapper .header-title {
  margin: 110px 0 40px 0; }

.help-ui-wrapper .sub-heading {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.13); }

.help-ui-wrapper .header-wrap ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%; }

.help-ui-wrapper ul {
  list-style: none;
  padding: 0;
  margin: 0 auto; }

.help-ui-wrapper .header-body {
  padding: 10px 8px 52px 8px; }

.help-ui-wrapper .header-wrap ul li a {
  color: #fff;
  font-weight: 700; }

.help-ui-wrapper nav.overlay-menu {
  position: static;
  height: auto;
  top: 0;
  right: 0;
  transform: none;
  text-align: right; }

.help-ui-wrapper div#overlay {
  top: 50%;
  transform: translateY(-50%);
  display: block;
  position: absolute;
  right: 0;
  height: auto;
  opacity: 1;
  background: transparent;
  visibility: visible; }

.help-ui-wrapper .header-wrap {
  height: auto;
  position: relative;
  width: 100%;
  margin-bottom: 20px; }

.help-ui-wrapper .header-wrap li {
  opacity: 1;
  display: inline-block;
  vertical-align: top;
  margin-left: 24px; }

.help-ui-wrapper .article-overview-list h2,
.help-ui-wrapper .articles h2 {
  color: #424242;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px; }

.help-ui-wrapper .article-body h3 {
  font-weight: 700;
  color: #424242; }

.help-ui-wrapper section.article-overview-list {
  margin-bottom: 40px; }

.help-ui-wrapper .lists {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

@media only screen and (min-width: 1024px) {
  .help-ui-wrapper a.item {
    width: 31% !important; }
  .help-ui-wrapper .page-container {
    width: auto;
    max-width: 910px !important;
    float: none;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0; }
  .help-ui-wrapper a.item {
    margin-right: 20px !important; }
  .help-ui-wrapper .header-body {
    padding: 32px 8px 52px 8px !important;
    max-width: 910px !important; }
  .help-ui-wrapper .header-title h1 {
    font-size: 30px; } }

.help-ui-wrapper .header-nav-view {
  display: block !important; }

.help-ui-wrapper .mobile-view-nav {
  display: none !important; }

@media only screen and (max-width: 767px) {
  .help-ui-wrapper .mobile-view-nav {
    display: block !important; }
  .help-ui-wrapper .header-nav-view {
    display: none !important; }
  .help-ui-wrapper .page-body {
    padding: 20px 0; }
  .help-ui-wrapper section.article-overview-list {
    margin-bottom: 15px; }
  .help-ui-wrapper .header-title {
    margin: 50px 0 40px 0; } }

.help-ui-wrapper a.item {
  text-decoration: none;
  min-width: 262px;
  width: 100%;
  margin-right: 32px;
  background: #fff;
  display: block;
  color: #666;
  box-shadow: 0 1px 4px 0 #dfe5ee;
  border-radius: 4px;
  padding: 16px;
  margin: 0 0 16px 0;
  border: solid 1px #fff;
  transition: border 250ms ease;
  cursor: pointer; }

.help-ui-wrapper a.item {
  margin-right: 0px; }

.help-ui-wrapper .item:hover {
  border: solid 1px #ee2738; }

.help-ui-wrapper span.description {
  font-size: 16px;
  margin-top: 8px;
  display: block;
  font-weight: 500; }

.help-ui-wrapper span.title {
  color: #ee2738;
  display: block;
  font-size: 17px;
  font-weight: 600; }

.help-ui-wrapper li.article-list-item {
  background-color: #fff;
  border: solid 1px #dfe5ee;
  font-size: 14px;
  color: #424242;
  margin-bottom: 20px; }

.help-ui-wrapper li.article-list-item:hover {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1); }

.help-ui-wrapper li.article-list-item a {
  text-decoration: none;
  display: block;
  padding: 24px 32px; }

.help-ui-wrapper li.article-list-item h3 {
  color: #ee2738;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500; }

.help-ui-wrapper .authors ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: 45px;
  margin-right: 8px; }

.help-ui-wrapper .item-info {
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  color: #666; }

@media only screen and (max-width: 380px) {
  .help-ui-wrapper .item-info {
    font-size: 12px !important; } }

.help-ui-wrapper li.article-list-item p {
  color: #424242;
  font-size: 16px;
  margin-top: 10px; }

.help-ui-wrapper .page-container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 0; }

.help-ui-wrapper span.description {
  font-size: 16px;
  margin-top: 8px;
  display: block;
  font-weight: 500; }

.help-ui-wrapper .btn {
  margin-bottom: 20px;
  background-color: #ee2738;
  color: #fff;
  padding: 8px 8px;
  border-radius: 2px;
  position: relative;
  display: inline-block; }

.help-ui-wrapper .btn:hover {
  color: #ffffff !important; }

.help-ui-wrapper .articles-result h2 {
  color: #424242;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px; }

.help-ui-wrapper span.title {
  color: #ee2738;
  display: block;
  font-size: 17px;
  font-weight: 600; }

.help-ui-wrapper li.article-list-item {
  background-color: #fff;
  border: solid 1px #dfe5ee;
  font-size: 14px;
  color: #424242;
  margin-bottom: 20px; }

.help-ui-wrapper li.article-list-item:hover {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1); }

.help-ui-wrapper li.article-list-item a {
  text-decoration: none;
  display: block;
  padding: 24px 32px; }

.help-ui-wrapper li.article-list-item h3 {
  color: #ee2738;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500; }

.help-ui-wrapper .authors ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: 45px;
  margin-right: 8px; }

.help-ui-wrapper .item-info {
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  color: #666; }

.help-ui-wrapper li.article-list-item p {
  color: #424242;
  font-size: 16px;
  margin-top: 10px; }

.help-ui-wrapper .article-list-item-footer {
  margin-top: 16px; }

.help-ui-wrapper .search-in span {
  font-size: 14px;
  font-weight: 500;
  color: #424242; }

.help-ui-wrapper .search-in .link {
  font-size: 14px;
  font-weight: 500;
  color: #ee2738;
  opacity: 0.6; }

.help-ui-wrapper .breadcrums-items li::after {
  content: "/";
  padding: 0 4px;
  display: inline;
  color: rgba(238, 39, 56, 0.8); }

.help-ui-wrapper .breadcrums-items li:last-child::after {
  content: ''; }

.help-ui-wrapper .breadcrums-items {
  width: auto;
  max-width: 90%;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 0; }

@media only screen and (min-width: 1024px) {
  .help-ui-wrapper .breadcrums-items {
    max-width: 910px !important; } }

.help-ui-wrapper .breadcrums-items li {
  display: inline; }

.help-ui-wrapper .breadcrums-items li a {
  text-decoration: none;
  color: rgba(238, 39, 56, 0.8); }

.help-ui-wrapper .article-body ul {
  margin: 16px 0;
  padding: 0 0 0 40px;
  list-style-position: outside; }

.help-ui-wrapper .article-body a {
  color: #ee2738; }

.help-ui-wrapper .article-body .authors li {
  list-style: none;
  margin-right: 8px; }

.help-ui-wrapper .article-body .authors ul {
  padding: 0; }

.help-ui-wrapper section.tags-section a {
  color: #ee2738; }

.help-ui-wrapper .article {
  background-color: #fff;
  padding: 16px; }

@media only screen and (min-width: 1024px) {
  .help-ui-wrapper .article {
    padding: 48px 48px; }
  .help-ui-wrapper .article h1 {
    font-size: 36px !important; }
  .help-ui-wrapper .article-body p {
    color: #424242;
    font-size: 20px; } }

.help-ui-wrapper .article h1 {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 25px; }

.help-ui-wrapper section.article-overview-list {
  margin-top: 10px; }

.help-ui-wrapper .article-subheading {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px; }

.help-ui-wrapper .article-body p {
  color: #424242;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px; }

.help-ui-wrapper .authors {
  margin-bottom: 30px; }

.help-ui-wrapper .article-body li {
  list-style: disc; }

.help-ui-wrapper .article-body ul {
  margin: 16px 0;
  padding: 0 0 0 40px;
  list-style-position: outside; }

.help-ui-wrapper .tags-section span {
  color: #dfe5ee;
  padding-right: 8px; }

.help-ui-wrapper .tags-section a.tag-item,
.help-ui-wrapper .tags-section a.tag-item:hover {
  color: #ee2738; }

.help-ui-wrapper .article-body a {
  color: #ee2738; }

.help-ui-wrapper .tags-section a.tag-item {
  text-decoration: none;
  margin-right: 8px;
  display: inline-block; }

.help-ui-wrapper section.tags-section {
  background-color: #fff;
  box-shadow: 0 1px 4px 0 #dfe5ee;
  font-size: 13px;
  font-weight: 400;
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 20px; }

.help-ui-wrapper .rating-section {
  border: 1px solid #e4e8ed;
  border-radius: 4px;
  padding: 16px 0 32px 0 !important;
  margin-top: 20px; }

.help-ui-wrapper .rating-section .rating {
  padding: 8px 8px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  height: 80px; }

.help-ui-wrapper .rating-section .rating.emoji .rating-body a {
  font-size: 24px;
  margin: 0 12px; }

.help-ui-wrapper .rating-section .rating-body a:hover {
  transform: translate3d(0, 0, 0) scale(1.1); }

.help-ui-wrapper .rating-section .rating-body a {
  font-size: 32px;
  line-height: 42px;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: transform 250ms ease;
  transform: translate3d(0, 0, 0) scale(1.01);
  display: inline-block; }

.help-ui-wrapper .rating-section .rating.emoji .rating-body a:nth-child(1)::before {
  content: '\1F603'; }

.help-ui-wrapper .rating-section .rating.emoji .rating-body a:nth-child(2)::before {
  content: '\1F642'; }

.help-ui-wrapper .rating-section .rating.emoji .rating-body a:nth-child(3)::before {
  content: '\1F615'; }

.help-ui-wrapper .rating-section .rating.emoji .rating-body a:nth-child(4)::before {
  content: '\1F61E'; }

.help-ui-wrapper .widget {
  text-align: center;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center; }

.help-ui-wrapper .widget a {
  text-decoration: none;
  color: rgba(238, 39, 56, 0.8);
  padding-left: 4px; }

.help-ui-wrapper .widget img {
  padding-right: 8px; }

.press-page-ui {
  margin-top: 65px; }
  .press-page-ui .press-banner-images {
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: cover;
    height: 355px;
    object-fit: cover;
    position: relative;
    background-image: url(https://www.first-hold.com/static/media/press-banner.2a4db09f.jpg); }
    .press-page-ui .press-banner-images p {
      font-size: 62px;
      color: #ffffff;
      font-weight: 600;
      text-align: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-shadow: 0 0 7px #000000; }
  .press-page-ui .press-description-section {
    text-align: center;
    padding: 20px 0 60px; }
    .press-page-ui .press-description-section h3 {
      color: #000000;
      font-weight: 600;
      font-size: 25px; }
    .press-page-ui .press-description-section span.black-bold {
      color: #000000; }
    .press-page-ui .press-description-section p.light-text {
      margin-top: 50px; }
    .press-page-ui .press-description-section p.blue-text {
      font-weight: 700;
      font-size: 16px;
      color: #525151; }
  .press-page-ui .search-btn a {
    background-color: #d80e2a;
    color: #ffffff;
    padding: 10px 20px;
    border: 1px solid #d80e2a;
    margin-top: 5px;
    width: 200px;
    font-size: 15px;
    border-radius: 0px;
    font-weight: 600;
    display: inline-block;
    text-align: center; }
  .press-page-ui .press-release-section {
    text-align: center;
    background-color: #cccccc36;
    padding: 50px 0;
    height: 250px; }
    .press-page-ui .press-release-section h3 {
      color: #000000;
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 15px; }
    .press-page-ui .press-release-section a,
    .press-page-ui .press-release-section a:hover {
      font-size: 15px;
      color: #000000;
      font-weight: 600; }
    .press-page-ui .press-release-section span.light-text {
      font-weight: 400; }
  .press-page-ui .press-launch-ui {
    padding-top: 100px; }
    .press-page-ui .press-launch-ui p {
      font-size: 30px;
      color: #000000;
      font-weight: 500; }
    .press-page-ui .press-launch-ui .banner-wrapper {
      display: flex;
      align-items: center; }
    .press-page-ui .press-launch-ui .search-wrapper {
      max-width: 392px; }
    .press-page-ui .press-launch-ui .logo-image img {
      max-width: 200px; }
    .press-page-ui .press-launch-ui .press-launch-description {
      padding: 50px 0 100px; }
      .press-page-ui .press-launch-ui .press-launch-description p {
        font-size: 17px;
        margin-bottom: 20px;
        font-weight: 500; }
      .press-page-ui .press-launch-ui .press-launch-description a,
      .press-page-ui .press-launch-ui .press-launch-description a:hover {
        color: #1397e1; }
    .press-page-ui .press-launch-ui .banner-section {
      padding: 80px 0; }
    .press-page-ui .press-launch-ui .social-block {
      margin-top: 20px;
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      padding: 30px 0; }
      .press-page-ui .press-launch-ui .social-block p.light-text {
        font-size: 15px;
        color: #8e8e8e;
        font-weight: 600;
        text-transform: uppercase; }
      .press-page-ui .press-launch-ui .social-block p.bold-text {
        font-size: 15px;
        font-weight: 600; }
    .press-page-ui .press-launch-ui .social-icon-block {
      padding: 0;
      margin-top: 5px; }
      .press-page-ui .press-launch-ui .social-icon-block li,
      .press-page-ui .press-launch-ui .social-icon-block a {
        display: inline-block;
        margin-right: 5px; }
        .press-page-ui .press-launch-ui .social-icon-block li img,
        .press-page-ui .press-launch-ui .social-icon-block a img {
          width: 30px;
          height: 30px; }
  @media screen and (max-width: 991px) {
    .press-page-ui .press-launch-ui {
      padding: 100px 20px 20px 20px; }
    .press-page-ui .press-description-section {
      padding: 20px 15px 60px; }
    .press-page-ui .press-release-section {
      padding: 50px 20px; } }
  @media screen and (max-width: 620px) {
    .press-page-ui .press-launch-ui .banner-section {
      text-align: center;
      padding: 50px 0; }
    .press-page-ui .press-launch-ui .search-wrapper {
      max-width: 392px;
      margin: 0 auto; }
    .press-page-ui .press-launch-ui .logo-image {
      text-align: center;
      margin-top: 20px; } }
  .press-page-ui .main a,
  .press-page-ui .main a:hover {
    color: #1397e1; }
  .press-page-ui .eventTemple {
    max-width: 410px !important; }

.blog-wrpper {
  width: 265px;
  margin: 0 auto;
  text-align: center;
  padding: 50px 0; }
  .blog-wrpper p {
    font-size: 16px;
    color: #000000;
    font-weight: 500; }
  .blog-wrpper a {
    color: #000000;
    font-size: 20px;
    font-weight: 600; }
  .blog-wrpper img {
    height: 400px; }

.image-block {
  margin: 50px 0; }

.top {
  margin-top: 50px; }

.blog-detail-page {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 200px; }
  .blog-detail-page .main-heading h1 {
    font-size: 40px;
    text-align: center;
    font-weight: 600;
    color: #000000; }
  .blog-detail-page p {
    font-size: 12pt;
    padding: 10px 0; }
  .blog-detail-page a:hover {
    color: #1497e1; }
  .blog-detail-page .section-heading h2 {
    font-size: calc((2.8 - 1) * 1.2vw + 1rem);
    color: #000000;
    font-weight: 500; }
    .blog-detail-page .section-heading h2 span {
      color: #1497e1;
      font-weight: 500; }
  .blog-detail-page .block-details h4 {
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
    color: #000000;
    font-weight: 700;
    padding: 20px 0; }
  .blog-detail-page .block-details ul {
    padding-left: 50px;
    font-size: calc((1.2 - 1) * 1.2vw + 1rem); }
    .blog-detail-page .block-details ul li {
      list-style: disc outside none;
      list-style: initial;
      padding: 10px 0;
      margin-bottom: 0 !important; }
  .blog-detail-page .image-space {
    margin: 20px 0; }
  .blog-detail-page .block-details.light-blue p {
    margin-top: 50px;
    color: #1497e1;
    font-size: calc((1.6 - 1) * 1.2vw + 1rem);
    line-height: calc(1.4 * (1 + (1 - 1.6)/25));
    font-weight: 600;
    text-align: center; }
  .blog-detail-page .btn {
    background-color: transparent;
    color: #d80e2a;
    padding: 22px 20px;
    border: 2px solid #d80e2a;
    margin-top: 20px;
    font-size: 18px;
    border-radius: 6px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    text-align: center; }
  .blog-detail-page .btn:hover {
    background-color: #d80e2a;
    color: #ffffff; }
  .blog-detail-page .text-center {
    text-align: center; }

.header-title-logo a span {
  display: inline-block;
  vertical-align: middle;
  height: 135px;
  margin-left: 20px;
  color: #000000;
  font-weight: 700;
  font-size: 45px; }

.header-title-logo img {
  width: 80px; }

.header-title-logo {
  display: inline-block;
  float: left; }

.header-nav-wrapper {
  float: right;
  margin-top: 30px; }

.header-title-nav-wrapper {
  clear: both; }

.category-btn {
  background: transparent;
  box-shadow: none;
  color: #000000;
  font-size: 20px;
  border: 0; }

.VenueHeader-signupDiv-98 {
  width: 100%;
  display: none; }

.mobile-view-nav {
  height: 160px;
  text-align: right; }
  .mobile-view-nav .category-btn-hover {
    display: none; }
    .mobile-view-nav .category-btn-hover a {
      display: block;
      text-align: right;
      color: #000000;
      font-size: 18px;
      font-weight: 300 !important;
      padding-top: 10px; }

.mobile-view-nav:hover .category-btn-hover,
.mobile-view-nav:active .category-btn-hover,
.mobile-view-nav:focus .category-btn-hover {
  display: block; }

.next-heading button {
  box-shadow: none;
  background: transparent;
  font-size: calc((2.2 - 1) * 1.2vw + 1rem);
  font-weight: 600;
  color: #000000;
  line-height: 40px;
  text-align: start; }

.next-heading .btn-block1 button:before {
  content: "<";
  font-size: 40px;
  font-weight: 200;
  margin-right: 30px; }

.next-heading .btn-block2 button::after {
  content: ">";
  font-size: 60px;
  font-weight: 200;
  margin-left: 30px; }

.next-heading {
  padding: 100px 0 50px 0; }

.next-heading button:hover {
  background: transparent;
  color: #272525c7; }

.btn-block2 button {
  text-align: right !important; }

.btn-block1 button {
  text-align: left; }

.main-wrapper.blog-detail-page.meeting-venue {
  padding: 0; }

.block-details h4 {
  padding: 5px !important; }

.capacity-block {
  right: 0 !important;
  width: 300px !important; }

.contact.search-btn {
  width: 200px;
  margin: 0 auto;
  padding-bottom: 120px; }

.search-btn button {
  flex-direction: row;
  background-color: #d80e2a;
  color: #ffffff;
  padding: 10px 20px;
  border: 1px solid #d80e2a;
  margin-top: 20px;
  width: 100%;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 600;
  display: inline-block;
  text-align: center; }

.block-details.last-block h1 {
  font-weight: bold;
  font-size: 2em; }

.block-details.last-block h2 {
  font-weight: bold;
  font-size: 1.5em; }

.block-details.last-block h3 {
  font-weight: bold;
  font-size: 1.17em; }

.block-details.last-block h4 {
  font-weight: bold;
  font-size: 1.33em; }

.block-details.last-block h5 {
  font-weight: bold;
  font-size: .83em; }

.block-details.last-block h6 {
  font-weight: bold;
  font-size: .67em; }

.block-details.last-block strong {
  font-weight: bold;
  color: unset; }

.btn-inline-block {
  display: inline-block;
  padding: 0 15px;
  vertical-align: middle; }

.block-details.last-block p {
  font-family: 'Raleway', sans-serif !important; }

.loading_blog {
  padding-bottom: 100px;
  text-align: center !important; }

@media only screen and (max-width: 767px) {
  .container.top {
    margin: 50px 25px 0; } }

.strong-text {
  color: #363636 !important; }

.strong-text:hover {
  color: #1397e1 !important; }

.dark-blue {
  color: #34495e; }

.light-blue {
  font-size: 18px !important;
  color: #1397e1 !important;
  font-weight: 700; }

.light-blue-normal {
  font-size: 18px !important;
  color: #1397e1; }

.red-color,
.red-color:hover {
  color: #ee2738 !important; }

.padding-bottom {
  padding-bottom: 150px; }

.next-heading.bottom-btn-link {
  align-items: flex-start; }

.next-heading button {
  text-align: left;
  border: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer; }

.f-18 {
  font-size: 18pt; }

.last-blue-heading {
  font-size: calc(.72vw + 1rem) !important;
  font-weight: 600; }

.f-16 {
  font-size: 16px; }

.ft-18 {
  font-size: 18pt; }

.light-black {
  color: #36434b; }

.membership-wrapper h1.main-heading {
  color: #747474;
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 20px; }

.membership-wrapper strong {
  color: #747474; }

.membership-wrapper p.content {
  padding: 15px 0;
  color: #747474;
  font-size: 16px;
  font-weight: 600; }
  .membership-wrapper p.content a {
    color: #747474;
    text-decoration: underline; }

.membership-wrapper ul.list-block {
  list-style: disc;
  padding-left: 20px; }
  .membership-wrapper ul.list-block li {
    color: #767171;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0; }
    .membership-wrapper ul.list-block li a {
      color: #747474;
      text-decoration: underline; }

.membership-wrapper .sub-heading {
  color: #747474;
  font-size: 25px;
  font-weight: 600;
  padding: 20px 0; }

.membership-wrapper .billing-list {
  padding-bottom: 20px; }

.membership-wrapper .pd-bottom {
  padding-bottom: 30px; }

.membership-wrapper .search-btn.started a,
.membership-wrapper .next-btn,
.membership-wrapper .back-btn {
  background-color: #d80e2a !important;
  color: #ffffff !important;
  padding: 10px 20px !important;
  border: 1px solid #d80e2a !important;
  margin-top: 20px;
  /* width: 100%; */
  text-decoration: none !important;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 600;
  display: inline-block;
  text-align: center; }

.membership-wrapper select.select-subscription {
  border: 1px solid #cccccc;
  width: 400px;
  border-radius: 5px;
  display: block;
  -webkit-appearance: auto;
  float: none; }

.membership-wrapper .pt-20 {
  padding-top: 20px; }

.membership-wrapper .cancel-subscription p {
  padding: 5px 0; }

.membership-wrapper .textarea {
  min-width: 500px !important;
  max-width: 500px !important; }

.membership-wrapper .text-center {
  text-align: center; }

.membership-wrapper .custom_select_with_scroll > div {
  max-width: 250px; }

.membership-wrapper .page-height {
  height: 75vh; }

.membership-wrapper .page-height2 {
  height: 85vh; }

.membership-wrapper .next-btn {
  float: right;
  color: #ffffff; }
  .membership-wrapper .next-btn span {
    color: #ffffff !important; }

.membership-wrapper .back-btn span {
  color: #ffffff !important; }

.membership-wrapper .pb-10 {
  padding-bottom: 10px; }

.membership-wrapper .subscription-list .custom_select_with_scroll > div {
  max-width: 350px; }

@media screen and (max-width: 1023px) {
  .membership-wrapper .textarea {
    min-width: auto !important;
    max-width: 500px !important; } }

.membership-wrapper .switch-btn {
  margin-top: -12px !important;
  margin-left: -15px !important; }

.membership-wrapper .text-underline {
  text-decoration: underline; }

@media screen and (max-width: 1023px) {
  .membership-wrapper {
    padding-left: 15px;
    padding-right: 15px; } }

input[type="range"] {
  -webkit-appearance: none;
  margin: 20px 0;
  width: 100%; }

input[type="range"]:focus {
  outline: none; }

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: #cccccc;
  border-radius: 25px; }

input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #cccccc;
  box-shadow: 0 0 4px 0 black;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px; }

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #cccccc; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.float-clear {
  clear: both; }

.slider-value span {
  display: inline-block;
  width: 9.9%; }

.slider-value span.ten-block.w-0 {
  width: 0; }

.slider-value .active {
  color: #1E88E5;
  font-weight: 600; }

.slider-value > *:last-child {
  width: 0 !important; }

@media screen and (max-width: 675px) {
  .slider-value span {
    display: inline-block;
    width: 9.7%; } }

