$black: #202020;
$water_blue: #1397e1;
$dark_grey: #36434b;
$off_white: #f7f7f7;
$white: #ffffff;
$primary: #ee2738;
$light_grey: #747474;
$off_black: #1a181e;
.mobile-view{
    @media screen and (min-width:800px){
        display: none !important;
    }
}
.desktop-view{
    display: block;
    @media screen and (max-width:767px){
        display: none;
    }
}
.landing-footer {
    background-color: $off_black;
    padding: 24px;
    @media screen and (min-width: 1024px) {
        }
    @media screen and (min-width:320px) and (max-width: 1024px) {
        }
    @media screen and (max-width: 768px) {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        .footer-top-links {
            text-align: center !important;
        }
    }
    .footer-top-links {
        width: 100%;
        text-align: right;
        .footer_links {
            padding: 12px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
    .footer-btn {
        margin: 20px 0;
    }
    .copyright {
        font-size: 12px;
        padding-top: 90px;
        color: $light_grey;
    }
    .logo-section{
        justify-content: center;
        margin-left: 25px;
    }
    .logo {
        // padding-bottom: 38px;
        // height: 40vh;
        // height: 100px;
        width: 100px;
        @media screen and (max-width: 768px) {
            padding-bottom: 20px;
        }
    }
    .footer_links {
        font-size: 13px;
        line-height: 1.54;
        color: $white;
        padding-top: 7px;
        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
    }
    .button_section {
        @media screen and (min-width: 768px) {
            justify-content: flex-end;
        }
        @media screen and (max-width: 500px) {
            padding-top: 10px;
            justify-content: center;
        }
    }
    .links_section {
        // @media screen and (min-width: 768px) {
        //     justify-content: left;
        // }
        // @media screen and (max-width: 500px) {
        //     justify-content: center;
        //     text-align: center;
        // }
        text-align: left;
    }
    ul.social-link{
        margin:8px 0 0 0px;
        li {
            display: inline-block;
            padding:5px 10px;
            margin: 5px;
            line-height: 0;
        }
    } 
    .social-block {
        display: inline-block;
        padding: 6px 50px;
        span {
            color: #ffffff;
            text-transform: uppercase;
        }
    }
    .footer-text {
        color: #ffffff;
        text-align: right;
        margin-top: 17px;
        h2 {
            font-weight: 700;
            margin-bottom: 10px;
        }
        p {
            font-size: 13px;
        }
    }
    .socail-link-section {
        img.icons {
            background-color: #ffffff;
            width: 30px;
            border-radius: 30px;
        }
    }
    // .links_section {
    //     justify-content: center;
    // }
    @media screen and (max-width: 768px) {
        .social-block {
            padding: 6px 30px;
            font-size: 14px;
        }
        .section-one {
            padding-left: 10% !important;
        }
        .send-mail {
            padding-left: 20px;
        }
        .logo-section {
            margin-left:0px !important;
        }
        .footer-text {
            text-align: center;
        } 
    }
    .send-mail {
        margin-top: 15px;
        padding-left:45px;
        a {
            color: #fff;
            text-decoration: underline;
            font-size: 15px;
        }
    }
    .section-one {
        padding-left: 0%;
        margin-top: 20px;
    }
    @media screen and (max-width:580px) {
        .send-mail {
            padding-left: 0px !important;
        }
        .socail-link-section {
            margin: 30px 0;
        }
        .section-one {
            padding-left: 25% !important;
        }
        .footer-top-links{
            .footer_links {
                display: block;
            }
        }
    }
    @media screen and (max-width:480px) {
        .section-one {
            padding-left: 15% !important;
        }
    }
    .socail-link-section {
        margin-left: -10px;
        margin-top: 11px;
    }
}
