$water-blue: #1397e1;
$sky-blue: #2bc8f2;
$black: #202020;
$grey: #e1e1e1;
$white: #f7f7f7;


.image-design{

 .upload-block{
    .crop-block{
        display: block;
    .wid-50{
      width:50%;
      display: inline-block;
      padding: 10px 0px;
      @media screen and (max-width:767px) {
        padding: 20px 0px;
      }
      input {
        position: relative !important;
        @media screen and (max-width:767px){
          width: 108px;
        }
      }
    }
    button{
      @media screen and (max-width:767px) {
          width:45% !important;
      }
     
      input{
        position:  relative !important;
      }
    }
  }
    .error{
      width:auto;
      text-align: center;
    }
  }
  
}
.add_venue{
    padding-top: 65px;
    height: 100%;
    // begin of add venue name css
    .add_venue_name{
        padding: 11px 40px 0px 40px;
        background-color: $white;
        @media screen and (max-width:767px) {
            padding: 15px 15px 32px 15px;
        }
        .add_venue_container{
            border: solid 1px #e1e1e1;
            background-color: #ffffff;
            margin: 0px 0px 20px 0px;
            height: 505px;
            padding-top: 42px;
            @media screen and (max-width:767px){
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 15px;
                height: auto;
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
        }
        .code_visible{
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33;
            text-align: left;
            color: #62717a;
            padding-top: 5px;
            @media screen and (max-width:'500px'){
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        .venue_type{
            padding-top: 26px;
            @media screen and (max-width:'767px'){
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 15px;
            }
        }
        .venue_code{
            padding-top: 26px;
            @media screen and (max-width:'767px'){
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 15px;
            }
            .upper_case_input{
                .error{
                    position: relative;
                }
            }
        }
        .venue_name{
            @media screen and (max-width:'500px'){
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
    // end of add venue name css
    // begin of add venue photo css
    .add_venue_photo{
        padding: 11px 40px 94px 6px;
        background-color: $white;
            .wid-50 {
                display: inline-block !important;
                width: 50%;
                padding: 15px 0px !important;
                // @media screen and (max-width:767px){
                //     width: 100% !important;
                //   }
            }
            .venue-profile-cropper-block{
                    button{
                      @media screen and (max-width:767px){
                        width: 45% !important;
                      }
                      @media screen and (max-width:320px){
                        width: 45% !important;
                      }
                    }
            }
        
        @media screen and (max-width:767px) {
            padding: 15px 15px 15px 15px;
        }
        .image_detail_form{
            @media screen and (max-width: 767px) {
                padding:15px;
                padding-bottom: 25px;
            }
            .venue_image_upload{
                padding-bottom: 6px;
                margin-bottom: 6px;
            }
            .field_div{
                display: block;
                padding: 10px 0px 0px;
                .checkbox{
                    margin-bottom: 5px;
                    .checkbox .container .checkmark:after{
                        left: 4px;
                        top: 0px;
                    }
                }
            }

            .venue-profile-cropper-block{
                @media screen and (max-width: 767px) {
                    padding-top:15px;
                }
            }
            .checkbox{
                margin-top: 10px;
                .container{
                    margin-bottom: 0px;
                    color:rgba(0, 0, 0, 0.87);
                    // padding-top: 5px;
                }
            }
        }
        // .image_upload{
        //     background-color: #e1e1e1;
        //     width: 100%;
        //     height: 260px;
        // }
        .add_venue_photo_container{
            border: solid 1px #e1e1e1;
            background-color: #ffffff;
        }
        .learning_hall_container{
            border-bottom: solid 1px #e1e1e1;
            word-break: break-all;
        }
        .learning_hall{
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            text-transform: capitalize;
            margin: 20px auto;
            color: $water-blue;
            @media screen and (max-width:767px){
                margin: 15px auto;
            }
        }
        .add_venue_profile_photo_heading{
            font-size: 16px;
            line-height: 1.5;
            text-align: left;
            color: $black;
            padding: 18px 0px 8px 0px;
            @media screen and (max-width:767px) {
                padding: 0px 0px 15px 0px;
            }
        }
        .add_venue_description{
            font-size: 16px;
            line-height: 1.5;
            text-align: left;
            color: $black;
            padding: 42px 0px 10px 0px;
            @media screen and (max-width:767px) {
                padding: 15px 0px 0px 0px;
            }
        }
        // .venue_image{
        //     width: 100%;
        // }
        .description{
            font-size: 11px;
            font-weight: 500;
            line-height: 1.45;
            text-align: left;
            color: #62717a;
        }
        .venue_description{
            padding-bottom: 94px;
            @media screen and (max-width:767px){
                padding-bottom:0px;
            }
            textarea{
                border-radius: 6px;
                border: solid 1px #e1e1e1;
                background-color: $white;
                height: 200px;
                width: 100%;
                padding: 7px 15px;
                margin-top: 4px;
                font-size: 15px;
                line-height: 1.07;
                text-align: left;
                &::placeholder{
                    font-size: 15px;
                    line-height: 1.07;
                    text-align: left;
                    color: #b0b0b0;
                }
            }
        }
    }
    // end of venue photo css
    // begin of add venue address css
    .add_venue_address_container,.add_venue_contact_container{
        background-color: $white;
        padding: 11px 20px 20px 20px;
        padding-bottom: 60px;
        @media screen and (max-width:767px){
            padding: 15px;
        }
        .city_name{
            position: relative;
            // input#airport{
            //     background-color: transparent;
            //     cursor: pointer;
            // }
        }
        .drop-down-icon::before{
            content: 'arrow_drop_down';
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            bottom: 8px;
            line-height: 0.8;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            z-index: 2;
            word-wrap: normal;
            position: absolute;
            right: 15px;
            color: #62717a;
        }
        .add_venue_address, .add_venue_contact{
            padding-bottom: 62px;
            background-color: #ffffff;
            box-shadow: inset -1px 1px 0 0 #ebebeb, inset 1px -1px 0 0 #ebebeb;
            .help_icon{
                padding-top: 44px;
                cursor: pointer;
                @media screen and (max-width:767px){
                    padding-top: 15px;
                }
            }
            .add_venue_address_form{
                @media screen and (max-width: 767px) {
                    padding-left:15px;
                    padding-right: 15px;
                    padding-bottom: 15px;
                }  
                .help_icon{
                    button{
                        background-color: transparent;
                        border: 0px;
                        box-shadow: none;
                        display: block;
                        padding: 0px;
                        margin-left: -15px;
                    }
                }    
            }
            .state{
                padding-top: 15px !important;
            }
            .add_venue_contact_form{
                @media screen and (max-width: 767px) {
                    padding-left:15px;
                    padding-right: 15px;
                    padding-bottom: 15px;
                }
            }
            .learning_hall_container{
                border: solid 1px #e1e1e1;
                word-break: break-all;
                padding:0px 5px;
            }
            .learning_hall{
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                color: $water-blue;
                margin: 20px auto;
                text-transform: capitalize;
                @media screen and (max-width:767px){
                    margin:15px auto;
                }
            }
            .left_panel{
                @media screen and (min-width:1025px){
                    padding-right: 55px;
                }
                @media screen and (min-width: 960px) and (max-width: 1025px){
                    padding-right: 20px;
                }
            }
            .right_panel{
                @media screen and (min-width:1025px){
                    padding-left: 57px;
                }
                @media screen and (min-width: 960px) and (max-width: 1025px){
                    padding-left: 20px;
                }
            }
            .what_is_address, .what_is_contact{
                font-size: 16px;
                line-height: 1.5;
                text-align: left;
                color: $black;
                padding-top: 41px;
                @media screen and (max-width:767px){
                    font-size:13px;
                    padding-top: 15px;
                }
            }
            .address_1, .contact_name{
                padding-top: 20px;
                @media screen and (max-width: 959px) {
                    padding-top: 15px;
                }
            }
            .address_2, .contact_phone{
                padding-top: 20px;
                @media screen and (max-width: 959px) {
                    padding-top: 15px;
                }
            }
            .city_name, .contact_email{
                padding-top: 20px;
                @media screen and (max-width: 959px) {
                    padding-top: 15px;
                }
            }
            .zipcode{
                padding-top: 20px;
                @media screen and (max-width: 959px) {
                    padding-top: 15px;
                }
            }
            .contact_job_title{
                padding-top: 43px;
                @media screen and (max-width:767px){
                    padding-top: 15px;
                }
            }
            .state{
                padding-top: 43px;
                @media screen and (max-width:767px){
                    padding-top: 23px;
                }
            }
            .contact_job_title{
                padding-top: 20px;
                @media screen and (max-width:767px){
                    padding-top: 15px;
                }
            }
        }
        .add_venue_address{
            padding-bottom: 62px;
            @media screen and (max-width:767px){
                padding-bottom: 15px;
            }
        }
        .add_venue_contact{
            padding-bottom: 140px;
            @media screen and (max-width:767px){
                padding-bottom:15px;
            }
        }
    }
    .error{
        color: #FF0000;
        font-size: 11px;
        text-align: left;
        width: auto;
        display: block;
        position: absolute;
    }

    // end of add veneu address css
    input#airport{
        border-radius: 6px;
        border: solid 1px #e1e1e1 !important;
        background-color: #f7f7f7;
        width: 100%;
        padding: 9px 15px;
        margin-top: 4px;
        font-size: 15px;
        line-height: 1.07;
        text-align: left;
        color: #202020;
        @media screen and (max-width:767px){
            padding: 9px 15px 9px 15px;
        }
    }
    label{
        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: left;
        color: #62717a;
    }
    .suggestionDiv {
        width: 100% !important;
        position: relative;
            font-size: 12px;
            line-height: 2.3;
            word-break: break-word;
            padding: 5px 0px 20px;
    }
          
          input[role="combobox"]+div {
            z-index: 99;
            top: 37px !important;
            left: 0px !important;
            position: absolute !important;
            min-height: 95px !important;
            background: #fff;
            overflow-y: scroll;
            height: 80px;
            box-shadow: 0px 15px 10px -15px #ccc;
            border: 1px solid #e1e1e1;
            font-weight: 500;
            cursor: pointer;
          }

          input[role="combobox"]+div .item{
            padding: 0px 15px;
            
          }

          input[role="combobox"]+div div:hover{
            background-image: linear-gradient(rgb(238, 238, 238), rgb(238, 238, 238));

          }
      
      
}
.venue .VenueCardWithFav-favIcon-226,.VenueCardWithFav-activeFav-227{
    margin: 10px;
}
.venue_page{
    li{
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
}
.venue_image_upload .image_upload button {
    width: auto !important;
    padding: 5px 15px;
}

.edit_venue_image {
    border:  1px solid #e1e1e1;
    background-color: #f0f0f0;
    width: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;
    // height: 300px;

    .image-c {
        height: 300px;
        img{
        margin: auto;
        display: flex;
        object-position: center;
        // width: 100% !important;
        // height: 100%;
    }
}
}
.edit-photos{
    background-color: #ffffff;
    .drag_icon_container{
        padding-left: 0px;
        @media screen and (max-width:767px){
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }
    }
    .edit-photos-image{
        text-align: initial;
        @media screen and (max-width:767px){
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }
        .custom_input{
            input[type="text"] {
                background-color: #ffffff;  
            }
            .error{
                text-align: left !important;
            }
        }
    }
}
.cropper-btm-section{
    padding: 15px;
    .wid-50{
        width: 50%;
        display: inline-table;
        @media screen and (max-width:767px){
            width: 100%;
            height: 60px;
        }
        input {
            position: relative !important;
        }
    }
    .crop-btn{
        button{
            @media screen and (max-width:767px){
                width: 48.2% !important;
                height: 30px !important; 
            }
        @media screen and (max-width:320px){
            width: 47.2% !important;  
        }
    }
    }

// #simple-popper{
//     left: -35px !important;
// }
.help_icon button {
    background-color: #ffffff;
    border: none;
}
}
.pdf-viewer{
    display: block;
    height: auto;
    text-align: center;
    .has-background-black{
        background-color: #f7f7f7 !important;
        position: fixed;
        bottom: 0px;
        margin-bottom: 0px !important;
    }

    .text-center{
        height: auto;
        width: 100%;
        max-width: 100%;

        .align-items-flex-end{
            align-items: center !important;
        }
        :last-child{
            height: auto;
            canvas{
                height: auto;
            }
            div{
                height: auto !important;
            }
        }
       
        .has-addons{
            width:23% !important;
            display: inline-flex;
            justify-content: space-between !important;
            button{
                background-color: transparent;
                span{
                    width: auto;
                    height: auto;
                    color: #202020;
                }
            }
        }
        .is-2 {
            width: 10% !important;
            display: inline-block;
        }
        .is-gapless{
            width: 100%;
            justify-content: space-between;
        }
    }
}

.what_is_address-top{
    padding-top:8px;
}

.what_is_address-top{
    .emailField{
      padding-left: 30px;
    }
    .search_container > div:first-child:before{
      top: 11px;
      left: 5px;
      color: #878787;
      content: 'search' !important; 
    }
}